import React from 'react'

export type DownloadIconProps = {
  strokeColor?: string
} & React.SVGProps<SVGSVGElement>

export const DownloadIcon = (props: DownloadIconProps) => {
  return (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        id="Vector"
        d="M13 5.64706H9.28571V0H3.71429V5.64706H0L6.5 12.2353L9.75 8.94118L13 5.64706ZM5.57143 7.52941V1.88235H7.42857V7.52941H8.515L6.5 9.57176L4.485 7.52941H5.57143ZM0 14.1176H13V16H0V14.1176Z"
        fill={props.strokeColor || 'white'}
      />
    </svg>
  )
}
