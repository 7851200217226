import React, { HTMLAttributes } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import {
  BarCode,
  BobaCurrency,
  BobaDisplay,
  BobaDisplayProps,
  BobaDonationDetail,
  BobaIcon,
  BobaReceiptDisplay,
  BobaReceiptDisplayAnimated,
  BobaSeries,
  CountingRow,
  Divider,
} from '../..'
import { GetBobaDontaionResponse } from '../schema/boba-response.schema'
import {
  ScoopedCornersContainer,
  ScoopedCornersContainerProps,
} from '../ScoopedCornersContainer'
import messages from './messages'

type BobaDonationReceiptProps = {
  boba_donation_details: BobaDonationDetail[]
  currency: BobaCurrency
} & HTMLAttributes<HTMLDivElement> &
  Pick<
    GetBobaDontaionResponse,
    'donor_note' | 'donor_name' | 'donated_amount' | 'is_public_by_supporter'
  >

const Details = (
  props: ScoopedCornersContainerProps & {
    boba_donation_details: BobaDonationDetail[]
    donated_amount: number
    currency: BobaCurrency
  },
) => {
  const intl = useIntl()
  const getRandomSeriesBasedOnDonatedAmount = (
    donatedAmount: number,
  ): BobaSeries =>
    ['matcha', 'sunset', 'peach'][
      donatedAmount
        .toString()
        .split('')
        .reduce((prev, curr) => prev + curr, 0) % 3
    ]

  return (
    <ScoopedCornersContainer
      style={{ marginBottom: '14px', fontSize: '14px' }}
      {...props}
    >
      <BobaReceiptDisplayAnimated
        className="animated-receipt-image"
        style={{ margin: '20px' }}
        series={getRandomSeriesBasedOnDonatedAmount(props.donated_amount)}
      />
      <BobaReceiptDisplay
        className="static-receipt-image hide"
        style={{ margin: '20px' }}
        series={getRandomSeriesBasedOnDonatedAmount(props.donated_amount)}
      />
      {props.boba_donation_details.map(({ count, name, value, boba_type }) => {
        if (count > 0) {
          return (
            <CountingRow
              style={{
                color: '#929292',
                fontWeight: 600,
                marginBottom: '8px',
                alignItems: 'center',
              }}
              value={value}
              count={count}
              children={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 700,
                    color: '#4a1e11',
                  }}
                >
                  <StyledBobaDisplay
                    style={{ marginRight: '16px' }}
                    padding={4}
                    size={30}
                    series={boba_type.series}
                    backgroundColor={boba_type.background_color}
                  />
                  <div children={name} />
                </div>
              }
            />
          )
        } else {
          return <></>
        }
      })}
      <Divider
        style={{ margin: '12px 0', borderWidth: '1px', borderColor: '#ad5138' }}
      />
      <CountingRow
        style={{ color: '#AD5138', fontSize: '16px', fontWeight: 700 }}
        valuePrefix={`${props.currency.toUpperCase()} `}
        value={props.donated_amount}
        children={<div children={intl.formatMessage(messages.total)} />}
      />
    </ScoopedCornersContainer>
  )
}

const Notes = (
  props: ScoopedCornersContainerProps & {
    donor_note?: string
    donor_name?: string
    is_public_by_supporter?: boolean
  },
) => {
  const intl = useIntl()
  const parsedDonorName = `@${props.donor_name}`
  const { donor_note, donor_name, is_public_by_supporter, ...rest } = props

  return (
    <>
      {donor_name && donor_note && is_public_by_supporter ? (
        <ScoopedCornersContainer style={{ marginBottom: '24px' }} {...rest}>
          <div style={{ display: 'flex', marginBottom: '12px' }}>
            <BobaIcon style={{ marginRight: '12px' }} />
            <NoteName children={parsedDonorName} />
          </div>
          <NoteMessage children={donor_note} />
        </ScoopedCornersContainer>
      ) : !donor_name && donor_note && is_public_by_supporter ? (
        <ScoopedCornersContainer style={{ marginBottom: '24px' }} {...rest}>
          <div style={{ display: 'flex', marginBottom: '12px' }}>
            <BobaIcon style={{ marginRight: '12px' }} />
            <NoteName
              style={{ color: '#929292' }}
              children={intl.formatMessage(messages.emptyName)}
            />
          </div>
          <NoteMessage children={donor_note} />
        </ScoopedCornersContainer>
      ) : (donor_name && !donor_note) ||
        (donor_name && !is_public_by_supporter) ? (
        <ScoopedCornersContainer style={{ marginBottom: '24px' }} {...rest}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '16px 0',
            }}
          >
            <BobaIcon style={{ marginRight: '12px' }} />
            <NoteName children={parsedDonorName} />
          </div>
        </ScoopedCornersContainer>
      ) : (
        <></>
      )}
    </>
  )
}

export const BobaDonationReceipt = (props: BobaDonationReceiptProps) => {
  const intl = useIntl()

  return (
    <Container {...props}>
      <Title children={intl.formatMessage(messages.title)} />
      <Details
        currency={props.currency}
        donated_amount={props.donated_amount}
        boba_donation_details={props.boba_donation_details}
      />
      <Notes
        donor_name={props.donor_name}
        donor_note={props.donor_note}
        is_public_by_supporter={props.is_public_by_supporter}
      />
      <BarCode width={'100%'} />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  border-radius: 24px;
  padding: 24px 36px;
  background-color: #f2eadf;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.div`
  color: #4a1e11;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 20px;
`

const NoteName = styled.div`
  color: #bc795f;
  font-weight: 600;
  font-size: 14px;
`
const NoteMessage = styled.div`
  color: #ab5337;
  font-weight: 600;
  font-size: 16px;
  background-color: #e6cdb4;
  border-radius: 20px;
  padding: 36px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`
// NOTE: Don't know why but this fix the bug in DonationHistory modal.
const StyledBobaDisplay = styled((props: BobaDisplayProps) => (
  <BobaDisplay {...props} />
))`
  svg {
    width: 100%;
    height: 100%;
  }
`
