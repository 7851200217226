import { IWidget } from '@anyonelab/frontend/models/Widget'
import { motion, MotionProps } from 'framer-motion'
import React, { HTMLAttributes } from 'react'
import { useWidget } from '../..'
import { BobaCircleIcon } from '../BobaCircleIcon'
import { BobaPillIcon } from '../BobaPillIcon'

type BobaPrimaryIconWidgetProps = {
  data: IWidget<'bobame'>['data']['primaryButton']
  size?: number
} & HTMLAttributes<HTMLDivElement> &
  MotionProps

export const BobaPrimaryIconWidget = ({
  size,
  data: { backgroundColor, content },
  ...rest
}: BobaPrimaryIconWidgetProps) => {
  const { displayMode } = useWidget()
  return (
    <motion.div
      style={{ cursor: 'pointer' }}
      whileHover={{ scale: displayMode ? 1.1 : 1 }}
      {...rest}
    >
      {content === '' || undefined ? (
        <BobaCircleIcon size={size} backgroundColor={backgroundColor} />
      ) : (
        <BobaPillIcon
          content={content as string}
          backgroundColor={backgroundColor}
        />
      )}
    </motion.div>
  )
}
