import {
  AnalyticsContext,
  BobaBarWithCharacter,
  BobaCharacterDisplay,
  BobaCharacterDisplayProps,
  BobaLoaderSVG,
  BobaMePublicPageEvent,
  captureElement,
  DesktopOnly,
  DownloadIcon,
  getBobaMePublicUrl,
  message,
  MobileOnly,
  openInNewTab,
  track,
  useResponsive,
} from '@anyonelab/common/'
import { useIntl } from 'react-intl'
import styled, { useTheme } from 'styled-components/macro'
import { PrimaryButton, PrimaryButtonProps } from '../../../PrimaryButton'
import messages from './messages'
import desktopRibbon from '../../../../assets/boba-desktop-share-modal-bottom-ribbon.svg'
import mobileRibbon from '../../../../assets/boba-mobile-share-modal-bottom-ribbon.svg'
import React from 'react'

interface ShareContentProps {
  fullName: string
  avatarImageUrl?: string
  domain: string
  aboutMe?: string
  displayMode?: boolean
}

export function ShareContent({
  fullName,
  avatarImageUrl,
  domain,
  aboutMe,
  displayMode = false,
}: ShareContentProps) {
  const intl = useIntl()
  const { isMobileLayout } = useResponsive()
  const theme = useTheme()
  const bobaPublicPageUrl = getBobaMePublicUrl(domain)
  const [animationState, setAnimationState] = React.useState<
    'none' | 'entry' | 'wink' | undefined
  >(undefined)
  const DEFAULT_BOBA_URL = 'BobaMe'
  const DEFAULT_DOWNLOAD_FILE_NAME = 'share-image'
  const newUrl =
    'https://lh3.googleusercontent.com/acezh821GREUTqwpxvzCnLtRfEkmkLF1aHSj3d8RYRFMvdCA0KL-9IN7XvJnke2agLv3pq-dpU2ceTehC3MFB22EnOgps0UbLVU0ujg=w600'
  const MAX_NUMBER_OF_CHARACTER_SHOWN = 10

  const { amplitudeInit } = React.useContext(AnalyticsContext)

  React.useEffect(() => {
    if (animationState !== 'none') return
    const element = document.querySelector(
      '.share-modal-container .message-container',
    ) as HTMLElement
    captureElement(element, DEFAULT_DOWNLOAD_FILE_NAME, () =>
      setAnimationState(undefined),
    )
    amplitudeInit &&
      track(BobaMePublicPageEvent.downloadSharePicClick, {
        domain: domain,
        from: displayMode ? 'supporter' : 'creator',
      })
  }, [amplitudeInit, animationState, displayMode, domain])

  const downloadImage = () => {
    setAnimationState('none')
  }

  const copyUrl = () => {
    navigator.clipboard.writeText(bobaPublicPageUrl)
    message.success('copiedLink')
    amplitudeInit &&
      track(BobaMePublicPageEvent.copyShareLinkClick, {
        domain: domain,
        from: displayMode ? 'supporter' : 'creator',
      })
  }

  return (
    <Container
      className={`share-modal-container ${
        isMobileLayout ? 'mobile' : 'desktop'
      }`}
    >
      <Title className="title" children={intl.formatMessage(messages.title)} />
      <Subtitle
        className="subtitle"
        children={intl.formatMessage(messages.subtitle, { br: <br /> })}
      />
      <DesktopOnly>
        <MessageContainer
          style={{ position: 'relative' }}
          className="message-container rounded"
        >
          <div
            className="message-boba"
            style={{
              fontFamily: theme.bobaFontFamily.secondary,
              color: '#62463e',
              fontSize: '16px',
            }}
            children={DEFAULT_BOBA_URL}
          />
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: '4%',
              marginBottom: '12px',
            }}
          >
            <BobaBarWithCharacter
              animation={animationState}
              style={{ placeSelf: 'center' }}
              imageUrl={avatarImageUrl}
              fullName={
                fullName.slice(0, MAX_NUMBER_OF_CHARACTER_SHOWN) +
                (fullName.length > MAX_NUMBER_OF_CHARACTER_SHOWN ? '...' : '')
              }
              filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                fontFamily: theme.bobaFontFamily.primary,
              }}
            >
              <MessageTitle className="message-title" children={fullName} />
              <MessageUrl
                onClick={() => openInNewTab(bobaPublicPageUrl)}
                className="message-url"
                children={bobaPublicPageUrl}
              />
              <MessageSubtitle
                className="message-subtitle"
                children={aboutMe}
              />
            </div>
          </div>
          <MessageBubbleContainer className="message-bubble-container">
            <MessageBubble
              className="message-bubble"
              children={intl.formatMessage(messages.description, {
                br: <br />,
              })}
            />
            <BobaLoaderSVG
              className="message-boba-character-display"
              liquidHeightPercentage={70}
              width="66"
              height="66"
              filter="drop-shadow(0px 4px 4px #794E2E)"
            />
          </MessageBubbleContainer>
        </MessageContainer>
      </DesktopOnly>
      <MobileOnly>
        <MessageContainer className="message-container rounded">
          <MessageTitle className="message-title" children={fullName} />
          <MessageSubtitle className="message-subtitle" children={aboutMe} />
          <MessageUrl
            onClick={() => openInNewTab(bobaPublicPageUrl)}
            className="message-url"
            children={bobaPublicPageUrl}
          />
          <div className="message-url-triangle" />
          <BobaBarWithCharacter
            animation={animationState}
            style={{ placeSelf: 'center', marginBottom: '32px' }}
            imageUrl={avatarImageUrl}
            fullName={
              fullName.slice(0, MAX_NUMBER_OF_CHARACTER_SHOWN) +
              (fullName.length > MAX_NUMBER_OF_CHARACTER_SHOWN ? '...' : '')
            }
            filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
          />
          <MessageBubbleContainer className="message-bubble-container">
            <BobaLoaderSVG
              className="message-boba-character-display"
              liquidHeightPercentage={70}
              width="66"
              height="66"
              filter="drop-shadow(0px 4px 4px #794E2E)"
            />
            <MessageBubble
              className="message-bubble"
              children={intl.formatMessage(messages.description, {
                br: <br />,
              })}
            />
          </MessageBubbleContainer>
          <div
            className="message-boba"
            style={{
              color: '#EED7C2',
              fontSize: '12px',
              alignSelf: 'center',
            }}
            children={DEFAULT_BOBA_URL}
          />
        </MessageContainer>
      </MobileOnly>
      <ButtonContainer className="button-container">
        <StyledPrimaryButton
          className="button"
          content={
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <DownloadIcon
                style={{
                  marginRight: '16px',
                }}
                filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
              />
              {intl.formatMessage(messages.downloadPicture)}
            </div>
          }
          onClick={downloadImage}
        />
        <StyledPrimaryButton
          className="button"
          content={intl.formatMessage(messages.copyLink)}
          onClick={copyUrl}
        />
      </ButtonContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  &.mobile {
    .title {
      font-size: 24px;
      margin-bottom: 12px;
    }
    .subtitle {
      margin-bottom: 16px;
    }
    .message-container {
      background-image: url(${mobileRibbon});
      background-repeat: no-repeat;
      background-position: center bottom;
      background-size: contain;
      margin: 32px 0px 16px;
    }
    .button {
      margin-bottom: 16px;
    }
    .button-container {
      align-self: stretch;
    }
    .message-title {
      font-size: 32px;
      margin-bottom: 12px;
    }
    .message-subtitle {
      font-size: 12px;
      text-align: center;
      margin-bottom: 24px;
    }
    .message-url {
      align-self: center;
      padding: 8px 16px;
    }
    .message-url-triangle {
      width: 0px;
      height: 0px;
      align-self: center;
      margin-bottom: 12px;
      border-left: 12px solid transparent;
      border-right: 12px solid transparent;
      border-top: 12px solid #f2eadf;
    }
    .message-boba {
      font-family: '${({ theme }) => theme.bobaFontFamily.title}';
    }
    .message-bubble {
      border-radius: 20px;
      padding: 12px 16px;
    }
    .message-bubble-container {
      margin-bottom: 36px;
    }
  }
  &.desktop {
    padding-left: 60px;
    padding-right: 60px;
    min-width: 600px;
    max-width: 720px;
    .title {
      font-size: 28px;
      margin-bottom: 12px;
    }
    .subtitle {
      margin-bottom: 24px;
    }
    .message-container {
      background-image: url(${desktopRibbon});
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: contain;
      margin-bottom: 32px;
    }
    .button-container {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 48px;
    }
    .message-title {
      font-size: 24px;
      margin-bottom: 20px;
    }
    .message-url {
      padding: 8px 16px;
      margin-bottom: 16px;
      font-size: 12px;
    }
    .message-subtitle {
      font-size: 12px;
      margin-bottom: 100px;
    }
    .message-bubble {
      border: none;
      text-align: center;
    }
    .message-bubble-container {
      font-size: 10px;
      position: relative;
    }
    .message-boba-character-display {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
`

const Title = styled.div`
  font-family: '${({ theme }) => theme.bobaFontFamily.title}';
  font-weight: 700;
  color: ${({ theme }) => theme.bobaColor.textPrimary};
  text-align: center;
  line-height: 33px;
`

const Subtitle = styled.div`
  text-align: center;
  font-family: '${({ theme }) => theme.bobaFontFamily.primary}';
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize[20]};
  color: #656565;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ButtonContainer = styled.div``

const MessageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 24px;
  background-color: #eed7c2;
  &.rounded {
    border-radius: 36px;
  }
`
const StyledPrimaryButton = styled((props: PrimaryButtonProps) => (
  <PrimaryButton padding="0" {...props} />
))`
  flex: 1;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  width: 100%;
  height: 40px;
`

const MessageTitle = styled.div`
  color: #62463e;
  font-weight: 600;
  text-align: center;
`

const MessageSubtitle = styled.div`
  color: #946a59;
  font-weight: 500;
  text-align: center;
`

const MessageUrl = styled.div`
  color: #7d3733;
  font-weight: 600;
  text-align: center;
  background-color: #f2eadf;
  border-radius: 36px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const MessageBubbleContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  color: #f2eadf;
  font-size: 12px;
`

const MessageBubble = styled.div`
  box-sizing: border-box;
  padding: 8px 12px;
  flex: 1;
  border: #f2eadf solid 1px;
`
