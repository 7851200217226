import { useResponsive } from '@anyonelab/common'
import React from 'react'
import styled from 'styled-components'

interface LoginPopUpContextProps {
  targetOrigin: string
}

export function LoginPopUpContext({ targetOrigin }: LoginPopUpContextProps) {
  const { isMobileLayout } = useResponsive()

  React.useEffect(() => {}, [])

  return (
    <Container
      className={`share-modal-container ${
        isMobileLayout ? 'mobile' : 'desktop'
      }`}
    >
      <StyledIframe
        id="login-popup"
        src={`${targetOrigin}/login?type=BOBA_DONATOR`}
        style={{ overflow: 'hidden' }}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`

const StyledIframe = styled.iframe`
  width: 100%;
  height: 80vh;
`
