function convertToBase16(input: string) {
  return parseInt(input, 16)
}

export function convertHexToArray(input: string): number[] {
  const inputLength = input.length
  const RGB_BASE = 255
  let r = RGB_BASE
  let g = RGB_BASE
  let b = RGB_BASE
  let a = RGB_BASE

  switch (inputLength) {
    case 4:
      r = convertToBase16(input[1].repeat(2))
      g = convertToBase16(input[2].repeat(2))
      b = convertToBase16(input[3].repeat(2))
      break
    case 5:
      r = convertToBase16(input[1].repeat(2))
      g = convertToBase16(input[2].repeat(2))
      b = convertToBase16(input[3].repeat(2))
      a = convertToBase16(input[4].repeat(2))
      break
    case 7:
      r = convertToBase16(input.slice(1, 3))
      g = convertToBase16(input.slice(3, 5))
      b = convertToBase16(input.slice(5, 7))
      break
    case 9:
      r = convertToBase16(input.slice(1, 3))
      g = convertToBase16(input.slice(3, 5))
      b = convertToBase16(input.slice(5, 7))
      a = convertToBase16(input.slice(7, 9))
      break
    default:
      break
  }

  const output = [r, g, b, a].map((item) => item / RGB_BASE)

  return output
}
