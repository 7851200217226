import * as React from 'react'

import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import messages from './messages'
import { Form, Input, Space, FormProps } from 'antd'
import {
  SocialMediaLinkButtonWidgetConfigDefaults,
  SOCIAL_MEDIA_LINK_BUTTON_WIDGET_ID,
} from '../constants'
import { SocialMediaLinkButtonWidgetConfigSchema } from '../types'
import {
  SocialMediaLinkButtonConfigurationEvent,
  track,
  withWidgetConfig,
} from '@anyonelab/common'
import { SocialMediaButtonGroupWidget } from '@anyonelab/frontend/models/Widget'

export interface SocialMediaLinkButtonWidgetConfigProps {
  data: SocialMediaButtonGroupWidget
}

function capitalize(word) {
  return word[0].toUpperCase() + word.substring(1).toLowerCase()
}

export const SocialMediaLinkButtonWidgetConfig = withWidgetConfig<
  SocialMediaLinkButtonWidgetConfigProps
>(
  {
    widgetId: SOCIAL_MEDIA_LINK_BUTTON_WIDGET_ID,
  },
  ({ data, onChange }) => {
    const intl = useIntl()

    const [form] = Form.useForm<SocialMediaButtonGroupWidget>()

    const initialValues: SocialMediaButtonGroupWidget = {
      ...data,
    }

    const mediaTypeList = Object.keys(SocialMediaLinkButtonWidgetConfigDefaults)
    const socialMediaColor = data.email.color

    function reformData(originalData: SocialMediaButtonGroupWidget) {
      const returnValue: any = {}
      returnValue.color = originalData.email.color

      mediaTypeList.forEach((type) => {
        returnValue[type] = originalData[type].url
      })
      return returnValue
    }
    const _initialValues = reformData(initialValues)

    const socialMediaFormItemOnFocus = (
      type: keyof SocialMediaButtonGroupWidget,
    ): void => {
      switch (type) {
        case 'email':
          track(SocialMediaLinkButtonConfigurationEvent.emailInputOnChange)
          break
        case 'facebook':
          track(SocialMediaLinkButtonConfigurationEvent.facebookInputOnChange)
          break
        case 'instagram':
          track(SocialMediaLinkButtonConfigurationEvent.instagramInputOnChange)
          break
        case 'tiktok':
          track(SocialMediaLinkButtonConfigurationEvent.tiktokInputOnChange)
          break
        case 'twitter':
          track(SocialMediaLinkButtonConfigurationEvent.twitterInputOnChange)
          break
        case 'youtube':
          track(SocialMediaLinkButtonConfigurationEvent.youtubeInputOnChange)
          break
        default:
          break
      }
    }

    const onValuesChange: FormProps<
      SocialMediaButtonGroupWidget
    >['onValuesChange'] = (_, formValues) => {
      console.log(formValues)

      const color = formValues.color
      delete formValues['color']
      Object.keys(formValues).forEach((type) => {
        formValues[type] = {
          url: formValues[type],
          color,
        }
      })

      onChange(formValues)
    }

    const SocialMediaFormList = mediaTypeList.map((socialMediaType, index) => {
      return (
        <Form.Item
          key={index}
          label={intl.formatMessage(messages[socialMediaType])}
          name={socialMediaType}
        >
          <Input
            onFocus={() => socialMediaFormItemOnFocus(socialMediaType)}
            className="profile-input"
            addonBefore={`${
              socialMediaType !== 'email'
                ? socialMediaType + '.com/'
                : socialMediaType
            }`}
            placeholder="handle"
          />
        </Form.Item>
      )
    })

    return (
      <SocialMediaLinkButtonWidgetConfigStyled>
        <Space style={{ width: '100%' }}>
          <Form
            form={form}
            initialValues={_initialValues}
            onValuesChange={onValuesChange}
            layout="vertical"
            style={{ width: '100%' }}
          >
            <Form.Item label={intl.formatMessage(messages.color)} name="color">
              <Input defaultValue={socialMediaColor} type="color" />
            </Form.Item>
            {SocialMediaFormList}
          </Form>
        </Space>
      </SocialMediaLinkButtonWidgetConfigStyled>
    )
  },
)

const SocialMediaLinkButtonWidgetConfigStyled = styled.div`
  .profile-input {
    .ant-input-group-addon {
    }
  }
  width: 100%;
  .ant-space-item {
    width: 100%;
  }
`
