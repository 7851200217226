import React from 'react'

export const BobaHomepageRibbonMobile = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      width="413"
      height="69"
      viewBox="0 0 413 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame 15" clip-path="url(#clip0_1855_287)">
        <path
          id="Vector 80"
          d="M138.993 19.6905C62.8169 37.1819 23.8761 21.4962 0 3.72021V-256H413V-0.223068C388.555 40.3926 329.543 45.7043 279.407 19.6905C253.115 6.04825 225.97 19.6904 229.381 45.7043C231.21 59.6516 219.95 69.4872 210.621 68.9814C199.978 68.4043 189.179 56.9543 191.577 45.7043C199.65 7.83368 151.926 16.7208 138.993 19.6905Z"
          fill="#AD5138"
        />
      </g>
      <defs>
        <clipPath id="clip0_1855_287">
          <rect width="413" height="69" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
