import {
  AnalyticsContext,
  bobaBackgroundColor,
  BobaDonationDetail,
  BobaItem,
  BobaMeOnboardingEvent,
  BobaMePublicPageEvent,
  HomeProfileLayout,
  Message as MessageType,
  track,
} from '@anyonelab/common/'
import {
  GetBobaDonationWithCommentInfoResponse,
  GetBobaDontaionResponse,
  GetBobaPublicUserAccountResponse,
  GetBobaUserAccountResponse,
} from '@anyonelab/common/boba-components/schema/boba-response.schema'
import { createDonationPayment } from '@anyonelab/public-page/api/boba-api/BobaPaymentApi'
import {
  BobaMenu,
  CurrencyType,
} from '@anyonelab/public-page/api/boba-api/schema/boba-common.schema'
import React from 'react'
import { LanguageContext } from '../../providers/LanguageProvider'
import { useLoginPop } from '../../providers/LoginPopUpProvider'

function bobaMenuParse(
  bobaUserAccount: GetBobaPublicUserAccountResponse,
): BobaDonationDetail[] {
  return bobaUserAccount?.boba_menu.map((bobaItem) => {
    return {
      ...bobaItem,
      count: 0,
    }
  })
}

// ! Temporarily use the parser until developer come up the working logic and schema
function bobaDonationParse(
  currency: CurrencyType,
  donations: GetBobaDonationWithCommentInfoResponse[],
): MessageType[] {
  return donations
    .filter((donation) => {
      // * condition, has note,   is_display_by_user: true, is_public_by_supporter: true
      return donation.is_display_by_user && donation.is_public_by_supporter
    })
    .map((donation) => {
      return {
        donationId: donation?.id,
        content: donation?.donor_note || '',
        profilePictureUrl: '',
        name: donation?.donor_name || '',
        count: donation.boba_count,
        value: donation.donated_amount,
        currency,
        creator_boba_user_account: donation?.creator_boba_user_account,
        donor_boba_user_account: donation?.donor_boba_user_account,
        boba_donation_comments: donation?.boba_donation_comments?.['0'],
      }
    })
}

const DEFAULT_SKIP_NUMBER = 10

export function BobaPublicPage({
  data,
}: {
  data: GetBobaPublicUserAccountResponse
}) {
  const {
    loginModalToggle,
    isAuthenticated,
    isLoggedInCheck,
    user,
    bobaUser,
  } = useLoginPop()
  const [bobaItems, setBobaItems] = React.useState<BobaDonationDetail[]>(
    bobaMenuParse(data),
  )

  const [bobaDonationsMessage, _] = React.useState<MessageType[]>(
    bobaDonationParse(data.currency, data.donations),
  )

  const [donationPage, setDonationPage] = React.useState(10)

  const { setLanguage } = React.useContext(LanguageContext)

  const { amplitudeInit } = React.useContext(AnalyticsContext)

  const donationMessageNextPage = () => {
    donationPage < data.donations.length &&
      setDonationPage((current) => current + DEFAULT_SKIP_NUMBER)
  }

  React.useEffect(() => {
    amplitudeInit &&
      track(BobaMePublicPageEvent.pageView, {
        url: data.domain,
        userId: data.user_id,
        pageId: data.id,
      })
  }, [amplitudeInit, data])

  return (
    <HomeProfileLayout
      donationMessageNextPage={donationMessageNextPage}
      BobaUserInformation={data}
      bobaItems={bobaItems}
      setBobaItems={setBobaItems}
      bobaDonationsMessage={bobaDonationsMessage}
      changeLocale={setLanguage}
      displayMode={true}
      handleCreatePaymentApiCall={createDonationPayment}
      isPublicPageAuthenticated={isAuthenticated}
      isLoggedInCheck={isLoggedInCheck}
      publicPageBobaUser={bobaUser}
      loginPopClick={loginModalToggle} // if check and not authenticated then show login button otherwise undefined and hide the button
      postComment={() => {}}
      editComment={() => {}}
      removeComment={() => {}}
    />
  )
}
