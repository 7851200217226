import React from 'react'

export const Sunset = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="53"
      height="82"
      viewBox="0 0 53 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Group 4534639" filter="url(#filter0_d_1038_1771)">
        <path
          id="Vector"
          d="M46.7821 26.7024C46.7821 23.9975 46.2484 21.3191 45.2116 18.8203C44.1747 16.3215 42.655 14.0513 40.7393 12.1392C38.8236 10.2272 36.5494 8.71088 34.0467 7.67692C31.544 6.64297 28.8618 6.11163 26.1534 6.11329C20.6857 6.11329 15.4419 8.28248 11.5757 12.1437C7.7094 16.0049 5.53735 21.2418 5.53735 26.7024H46.7821Z"
          fill="#E3E7F5"
        />
        <path
          id="Vector_2"
          d="M5.78936 28.2657L11.9011 71.9482C11.9609 72.2803 12.1369 72.5803 12.3977 72.7948C12.6586 73.0092 12.9873 73.1239 13.3251 73.1186H39.007C39.3447 73.1239 39.6734 73.0092 39.9343 72.7948C40.1951 72.5803 40.3712 72.2803 40.4309 71.9482L46.5679 28.2657C46.5934 28.0675 46.5757 27.8662 46.5159 27.6755C46.4562 27.4849 46.3558 27.3094 46.2217 27.1611C46.0876 27.0128 45.923 26.8953 45.7391 26.8166C45.5552 26.7379 45.3565 26.6999 45.1565 26.7051H7.23853C7.03544 26.6942 6.83242 26.7281 6.64389 26.8043C6.45536 26.8805 6.28595 26.9972 6.14765 27.1461C6.00934 27.2951 5.90554 27.4726 5.84363 27.666C5.78171 27.8595 5.76318 28.0642 5.78936 28.2657Z"
          fill="#F2F2F2"
        />
        <path
          id="Vector_3"
          d="M28.4467 1.88775C28.4467 0.845175 27.6004 0 26.5565 0C25.5125 0 24.6663 0.845175 24.6663 1.88775V40.4734C24.6663 41.5159 25.5125 42.3611 26.5565 42.3611C27.6004 42.3611 28.4467 41.5159 28.4467 40.4734V1.88775Z"
          fill="#CED8ED"
        />
        <path
          id="Vector_4"
          d="M46.6813 24.1641H6.31868C5.03811 24.1641 4 25.2008 4 26.4797C4 27.7586 5.03811 28.7953 6.31868 28.7953H46.6813C47.9619 28.7953 49 27.7586 49 26.4797C49 25.2008 47.9619 24.1641 46.6813 24.1641Z"
          fill="#CBD3E8"
        />
        <path
          id="Vector_5"
          d="M44.8414 39.5438L40.4561 71.9502C40.3963 72.2823 40.2203 72.5824 39.9594 72.7968C39.6986 73.0112 39.3699 73.126 39.0321 73.1206H13.325C12.9872 73.126 12.6585 73.0112 12.3977 72.7968C12.1368 72.5824 11.9608 72.2823 11.9011 71.9502L7.3645 39.5312L44.8414 39.5438Z"
          fill="#F8E0BE"
        />
        <path
          id="Vector_6"
          d="M24.5405 6.19404C25.9414 6.02405 27.3598 6.06223 28.7494 6.30732V24.1529H24.2129C24.2129 24.1529 24.7044 6.19404 24.5405 6.19404Z"
          fill="#E3E7F5"
        />
        <path
          id="Vector_7"
          d="M16.4755 66.183C17.7283 66.183 18.7438 65.1687 18.7438 63.9176C18.7438 62.6666 17.7283 61.6523 16.4755 61.6523C15.2228 61.6523 14.2073 62.6666 14.2073 63.9176C14.2073 65.1687 15.2228 66.183 16.4755 66.183Z"
          fill="#D79B7A"
        />
        <path
          id="Vector_8"
          d="M13.8293 58.9095C15.082 58.9095 16.0976 57.8953 16.0976 56.6442C16.0976 55.3931 15.082 54.3789 13.8293 54.3789C12.5766 54.3789 11.561 55.3931 11.561 56.6442C11.561 57.8953 12.5766 58.9095 13.8293 58.9095Z"
          fill="#D79B7A"
        />
        <path
          id="Vector_9"
          d="M22.6377 71.8861C23.8904 71.8861 24.9059 70.8719 24.9059 69.6208C24.9059 68.3697 23.8904 67.3555 22.6377 67.3555C21.3849 67.3555 20.3694 68.3697 20.3694 69.6208C20.3694 70.8719 21.3849 71.8861 22.6377 71.8861Z"
          fill="#D79B7A"
        />
        <path
          id="Vector_10"
          d="M34.9116 56.4603C36.1643 56.4603 37.1799 55.4461 37.1799 54.195C37.1799 52.9439 36.1643 51.9297 34.9116 51.9297C33.6588 51.9297 32.6433 52.9439 32.6433 54.195C32.6433 55.4461 33.6588 56.4603 34.9116 56.4603Z"
          fill="#D79B7A"
        />
        <path
          id="Vector_11"
          d="M38.3645 64.3353C39.6172 64.3353 40.6327 63.3211 40.6327 62.07C40.6327 60.8189 39.6172 59.8047 38.3645 59.8047C37.1117 59.8047 36.0962 60.8189 36.0962 62.07C36.0962 63.3211 37.1117 64.3353 38.3645 64.3353Z"
          fill="#D79B7A"
        />
        <path
          id="Vector_12"
          d="M29.9213 66.8119C31.1741 66.8119 32.1896 65.7976 32.1896 64.5466C32.1896 63.2955 31.1741 62.2812 29.9213 62.2812C28.6686 62.2812 27.6531 63.2955 27.6531 64.5466C27.6531 65.7976 28.6686 66.8119 29.9213 66.8119Z"
          fill="#D79B7A"
        />
        <path
          id="Vector_13"
          d="M36.323 71.4447C37.5757 71.4447 38.5912 70.4304 38.5912 69.1793C38.5912 67.9283 37.5757 66.9141 36.323 66.9141C35.0702 66.9141 34.0547 67.9283 34.0547 69.1793C34.0547 70.4304 35.0702 71.4447 36.323 71.4447Z"
          fill="#D79B7A"
        />
        <path
          id="Vector_14"
          d="M24.9184 61.0579C26.1711 61.0579 27.1867 60.0437 27.1867 58.7926C27.1867 57.5415 26.1711 56.5273 24.9184 56.5273C23.6657 56.5273 22.6501 57.5415 22.6501 58.7926C22.6501 60.0437 23.6657 61.0579 24.9184 61.0579Z"
          fill="#D79B7A"
        />
        <path
          id="Vector_15"
          d="M26.5063 18.7132C25.8596 18.6965 25.2377 18.4613 24.7421 18.0462C24.7179 18.0249 24.6986 17.9988 24.6854 17.9695C24.6721 17.9402 24.6653 17.9084 24.6653 17.8763C24.6653 17.8441 24.6721 17.8124 24.6854 17.7831C24.6986 17.7538 24.7179 17.7276 24.7421 17.7064C24.7645 17.682 24.7918 17.6625 24.8221 17.6491C24.8525 17.6358 24.8853 17.6289 24.9185 17.6289C24.9517 17.6289 24.9845 17.6358 25.0149 17.6491C25.0452 17.6625 25.0725 17.682 25.0949 17.7064C25.4597 18.0398 25.9362 18.2247 26.4307 18.2247C26.9251 18.2247 27.4017 18.0398 27.7664 17.7064C27.7889 17.682 27.8161 17.6625 27.8465 17.6491C27.8769 17.6358 27.9097 17.6289 27.9429 17.6289C27.976 17.6289 28.0088 17.6358 28.0392 17.6491C28.0696 17.6625 28.0968 17.682 28.1193 17.7064C28.1618 17.7528 28.1853 17.8134 28.1853 17.8763C28.1853 17.9392 28.1618 17.9998 28.1193 18.0462C27.9114 18.2629 27.6607 18.4342 27.383 18.549C27.1053 18.6639 26.8068 18.7198 26.5063 18.7132Z"
          fill="#42210B"
        />
        <g id="Group" opacity="0.28">
          <path
            id="Vector_16"
            opacity="0.28"
            d="M46.6812 26.9662C46.6812 22.2199 44.7933 17.668 41.4328 14.3119C38.0723 10.9558 33.5144 9.07031 28.7619 9.07031C24.0094 9.07031 19.4515 10.9558 16.091 14.3119C12.7305 17.668 10.8425 22.2199 10.8425 26.9662H46.6812Z"
            fill="#E3E7F5"
          />
          <path
            id="Vector_17"
            opacity="0.28"
            d="M11.0569 28.3258L16.3243 66.2696C16.3832 66.5581 16.5416 66.8169 16.7718 67.0008C17.0021 67.1848 17.2896 67.2823 17.5844 67.2764H39.9395C40.2343 67.2823 40.5219 67.1848 40.7521 67.0008C40.9824 66.8169 41.1408 66.5581 41.1997 66.2696L46.4797 28.3006C46.4937 28.1271 46.4714 27.9525 46.4143 27.788C46.3573 27.6235 46.2667 27.4726 46.1482 27.3449C46.0298 27.2171 45.8861 27.1153 45.7263 27.0459C45.5664 26.9764 45.3939 26.9409 45.2196 26.9414H12.2918C12.1173 26.944 11.9452 26.9828 11.7865 27.0553C11.6278 27.1278 11.4859 27.2325 11.3698 27.3626C11.2537 27.4928 11.1659 27.6456 11.112 27.8114C11.0582 27.9772 11.0394 28.1523 11.0569 28.3258Z"
            fill="#F2F2F2"
          />
          <path
            id="Vector_18"
            opacity="0.28"
            d="M46.6057 24.7539H11.5231C10.4095 24.7539 9.50684 25.6554 9.50684 26.7675C9.50684 27.8796 10.4095 28.7811 11.5231 28.7811H46.6057C47.7192 28.7811 48.6219 27.8796 48.6219 26.7675C48.6219 25.6554 47.7192 24.7539 46.6057 24.7539Z"
            fill="#CBD3E8"
          />
        </g>
        <path
          id="Vector_19"
          d="M17.6853 20.2973C19.3347 20.2973 20.6718 18.962 20.6718 17.3147C20.6718 15.6674 19.3347 14.332 17.6853 14.332C16.0359 14.332 14.6987 15.6674 14.6987 17.3147C14.6987 18.962 16.0359 20.2973 17.6853 20.2973Z"
          fill="#F2F2F2"
        />
        <path
          id="Vector_20"
          d="M14.6736 17.3294C14.6736 16.5417 14.9869 15.7863 15.5446 15.2293C16.1024 14.6723 16.8588 14.3594 17.6475 14.3594C18.4374 14.3594 19.1952 14.6719 19.7549 15.2285C20.3146 15.7851 20.6308 16.5406 20.6341 17.3294"
          fill="#51260E"
        />
        <path
          id="Vector_21"
          d="M18.2021 17.2891C18.2021 17.7563 18.0162 18.2045 17.6853 18.5349C17.3545 18.8653 16.9057 19.051 16.4378 19.051C16.2057 19.0526 15.9755 19.0082 15.7607 18.9203C15.5459 18.8323 15.3508 18.7026 15.1866 18.5387C15.0225 18.3747 14.8926 18.1798 14.8045 17.9653C14.7164 17.7508 14.672 17.5209 14.6736 17.2891"
          fill="#47220D"
        />
        <path
          id="Vector_22"
          d="M35.3398 20.2973C36.9893 20.2973 38.3264 18.962 38.3264 17.3147C38.3264 15.6674 36.9893 14.332 35.3398 14.332C33.6904 14.332 32.3533 15.6674 32.3533 17.3147C32.3533 18.962 33.6904 20.2973 35.3398 20.2973Z"
          fill="#F2F2F2"
        />
        <path
          id="Vector_23"
          d="M32.3281 17.3294C32.3315 16.5406 32.6476 15.7851 33.2073 15.2285C33.7671 14.6719 34.5248 14.3594 35.3147 14.3594C36.1034 14.3594 36.8599 14.6723 37.4176 15.2293C37.9753 15.7863 38.2886 16.5417 38.2886 17.3294"
          fill="#51260E"
        />
        <path
          id="Vector_24"
          d="M35.8566 17.2891C35.8566 17.7563 35.6707 18.2045 35.3398 18.5349C35.009 18.8653 34.5602 19.051 34.0923 19.051C33.6245 19.051 33.1757 18.8653 32.8448 18.5349C32.514 18.2045 32.3281 17.7563 32.3281 17.2891"
          fill="#47220D"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1038_1771"
          x="0"
          y="0"
          width="53"
          height="81.1211"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1038_1771"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1038_1771"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
