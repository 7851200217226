export const LINK_PORTAL_SPLASH_INSTANCE_ID = 'linkportal-profilesplash'

export const DefaultLinkPortalWidgetObject = {
  avatar: {
    imageUrl: '',
  },
  titleLabel: {
    text: 'Title',
    fontSize: '38px',
    fontColor: '#FFFFFF',
    fontStyle: 'normal',
    fontFamily: 'Lato',
    fontWeight: 'bold',
  },
  linkButtons: [
    {
      url: '',
      type: 'external',
      title: '',
      fontSize: '16px',
      fontColor: '#000000',
      fontStyle: 'normal',
      buttonType: 'fill_rounded',
      fontFamily: 'roboto',
      fontWeight: 'normal',
      titleEmoji: '',
      shouldDisplay: true,
      backgroundColor: '#ffffff',
      hasRoundedCorners: true,
    },
  ],
  profileSplash: {
    singleColor: '#EE8183',
    gradientColor: [
      {
        color: 'rgb(253, 219, 219)',
        offset: '0.00',
      },
      {
        color: 'rgb(238, 129, 131)',
        offset: '1.00',
      },
    ],
  },
  subtitleLabel: {
    text: 'Subtitle',
    fontSize: '20px',
    fontColor: '#FFFFFF',
    fontStyle: 'italic',
    fontFamily: 'Lato',
    fontWeight: 'lighter',
  },
  socialMediaButtonGroup: {
    email: {
      url: '',
      color: '#fff',
    },
    tiktok: {
      url: '',
      color: '#fff',
    },
    twitter: {
      url: '',
      color: '#fff',
    },
    youtube: {
      url: '',
      color: '#fff',
    },
    facebook: {
      url: '',
      color: '#fff',
    },
    instagram: {
      url: '',
      color: '#fff',
    },
  },
}
