export const primaryColorToTextColorConvertor = (input: string | undefined) => {
  switch (input) {
    case '#CCA78C':
      return '#fff'
    case '#FFFFFF':
      return '#C07C62'
    case '#AD5138':
    case '#070606':
    case '#A0ADA6':
    default:
      return '#F8E0BE'
  }
}

export const primaryColorToSecondaryColorConvertor = (
  input: string | undefined,
) => {
  switch (input) {
    case '#A0ADA6':
      return '#9C4A46'
    case '#CCA78C':
      return '#AD5138'
    case '#BF7C62':
    default:
      return '#7D3733'
  }
}

export const primaryColorToBobaCharacterClotheConvertor = (
  input: string | undefined,
) => {
  switch (input) {
    case '#BF7C62':
      return '#AF5136'
    case '#CCA78C':
      return '#C07C62'
    case '#A0ADA6':
    default:
      return '#BF7C62'
  }
}

export const primaryColorToBobaCharacterBobaColorConvertor = (
  input: string | undefined,
) => {
  switch (input) {
    case '#A0ADA6':
      return '#F8E0BE'
    case '#CCA78C':
      return '#D79B7A'
    case '#BF7C62':
    default:
      return '#D79B7A'
  }
}
