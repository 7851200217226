import React, { PropsWithChildren } from 'react'
import styled from 'styled-components/macro'
import { CloseOutlined, Modal, ModalProps, useResponsive } from '../..'

export interface BobaResponsiveModalProps extends ModalProps {
  onClose: () => void
  closeIconVisible?: boolean
  modalBodyStyle?: React.CSSProperties
  className?: string
}

export function BobaResponsiveModal({
  onClose,
  children,
  visible = true,
  modalBodyStyle,
  closeIconVisible = true,
  className,
  ...props
}: PropsWithChildren<BobaResponsiveModalProps>) {
  const { isMobileLayout } = useResponsive()
  return (
    <StyledModal
      closable={false}
      className={isMobileLayout ? `is-mobile ${className}` : `${className}`}
      footer={null}
      centered
      onCancel={onClose}
      visible={visible}
      destroyOnClose={true}
      maskStyle={{ backgroundColor: '#8e7462ce' }}
      {...props}
    >
      <Container>
        {closeIconVisible && (
          <CloseIcon onClick={onClose}>
            <CloseOutlined />
          </CloseIcon>
        )}
        {children}
      </Container>
    </StyledModal>
  )
}

// export default BobaResponsiveModal

const CloseIcon = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 50%;
  height: 26px;
  width: 26px;
  margin: 30px;
  background-color: #cbcbcb;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  cursor: pointer;
`
const StyledModal = styled(Modal)`
  &.ant-modal {
    width: auto !important;
  }

  .ant-modal-content {
    background: #fafafa;
    box-shadow: 5px 10px 14px rgba(0, 0, 0, 0.25);
    border-radius: 48px;
  }
  .ant-modal-body {
    padding: 60px 100px;
  }

  &.is-mobile {
    .ant-modal-content {
      border-radius: 22px;
      width: 95vw;
    }
    .ant-modal-body {
      padding: 50px 20px 40px 20px;
    }
  }
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`
