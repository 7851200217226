import React, { HTMLAttributes } from 'react'
import styled from 'styled-components/macro'
import { BobaLoaderSVG } from '../BobaLoader'
import { primaryColorToTextColorConvertor } from '../../widgets/Bobame/utils'

type BobaPillIconProps = {
  content: string
  backgroundColor?: string
} & HTMLAttributes<HTMLDivElement>

export const BobaPillIcon = ({
  content,
  backgroundColor,
  ...rest
}: BobaPillIconProps) => {
  return (
    <Container backgroundColor={backgroundColor} {...rest}>
      <BobaLoaderSVG angle={0} size={20} liquidHeightPercentage={80} />
      <div
        style={{
          fontSize: 12,
          fontWeight: 'bold',
          color: primaryColorToTextColorConvertor(backgroundColor),
        }}
        children={content}
      />
    </Container>
  )
}

const Container = styled.div<{ backgroundColor?: string }>`
  border-radius: 30px;
  display: flex;
  width: 100%;
  padding: 4px 8px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px rgba(179, 109, 109, 0.25);
  background-color: ${({ backgroundColor }) => backgroundColor || '#AD5138'};
`
