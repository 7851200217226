import React, { HTMLAttributes } from 'react'
import styled from 'styled-components/macro'
import { BobaSeries } from '../type'
import { Sunset, Peach, Matcha } from './presets/'

export type BobaReceiptDisplayProps = {
  series?: BobaSeries
} & HTMLAttributes<HTMLDivElement>

export const BobaReceiptDisplay = (props: BobaReceiptDisplayProps) => {
  return (
    <Container {...props}>
      {props.series === 'sunset' ? (
        <Sunset width={'100%'} height={'100%'} />
      ) : props.series === 'peach' ? (
        <Peach width={'100%'} height={'100%'} />
      ) : props.series === 'matcha' ? (
        <Matcha width={'100%'} height={'100%'} />
      ) : (
        <Sunset />
      )}
    </Container>
  )
}

const Container = styled.div`
  &.hide {
    display: none;
  }
`
