import {
  Button,
  defaultSanitizeOptions,
  Form,
  FormProps,
  HTMLEmbedBlockConfigurationEvent,
  Input,
  LEAD_FORM_WIDGET_ID,
  Modal,
  track,
  withWidgetConfig,
} from '@anyonelab/common/'
import { LeadFormWidget } from '@anyonelab/frontend/models/Widget'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import DOMPurify from 'dompurify'
import messages from './messages'

export interface LeadFormButtonWidgetConfigProps {
  data: LeadFormWidget
}

export const LeadFormWidgetConfig = withWidgetConfig<
  LeadFormButtonWidgetConfigProps
>(
  {
    widgetId: LEAD_FORM_WIDGET_ID,
  },
  ({ data, onChange, onRemove }) => {
    const intl = useIntl()
    const [form] = Form.useForm<LeadFormWidget>()
    const [showModal, setShowModal] = React.useState(false)

    const initialValues: LeadFormWidget = {
      ...data,
    }

    const contentOnFocus = (): void => {
      // Todo @louie add track
    }

    const onValuesChange: FormProps<LeadFormWidget>['onValuesChange'] = (
      changedValues: Partial<LeadFormWidget>,
      formValues,
    ) => {
      const clean = DOMPurify.sanitize(formValues.htmlTag, {
        ...defaultSanitizeOptions,
      })

      onChange({
        ...data,
        ...formValues,
        htmlTag: clean,
      })
    }

    const trackMoreEmbedRequest = () => {
      setShowModal(true)
      track(HTMLEmbedBlockConfigurationEvent.wantMoreEmbedClick)
    }

    return (
      <LeadFormWidgetConfigStyled>
        <Form
          form={form}
          initialValues={initialValues}
          onValuesChange={onValuesChange}
          layout="vertical"
        >
          <Form.Item
            label={intl.formatMessage(messages.pasteTheCode)}
            name="htmlTag"
            required
            // rules={[
            //   {
            //     validator: (_, value) => {
            //       const regex = RegExp(/<\/?[a-z][\s\S]*>/i)
            //       const match = regex.test(value)

            //       if (!match) {
            //         return Promise.reject(
            //           new Error('please input proper html string'),
            //         )
            //       }
            //       return Promise.resolve(value)
            //     },
            //   },
            // ]}
          >
            <Input.TextArea
              onFocus={contentOnFocus}
              autoSize={{ minRows: 10, maxRows: 30 }}
            />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage(messages.nameTheElement)}
            name="name"
            required
          >
            <Input onFocus={() => {}} />
          </Form.Item>
        </Form>
        <Button
          onClick={() => onRemove()}
          children={intl.formatMessage(messages.delete)}
        />
        <StyledText
          onClick={trackMoreEmbedRequest}
          children={intl.formatMessage(messages.addMoreEmbedRequestMessage)}
        />
        <StyledModal
          width={280}
          visible={showModal}
          centered
          wrapClassName={''}
          maskClosable
          onCancel={() => setShowModal(false)}
          closable={false}
          footer={<></>}
        >
          <ModalContainer>
            <div
              style={{ fontWeight: 600, fontSize: 28 }}
              children={intl.formatMessage(messages.addMoreEmbedMessageTitle)}
            />
            <div
              children={intl.formatMessage(
                messages.addMoreEmbedMessageDescription,
                { br: <br /> },
              )}
            />
            <div
              style={{
                backgroundColor: '#EE8183',
                borderRadius: 24,
                padding: '8px 24px',
                color: 'white',
                cursor: 'pointer',
              }}
              onClick={() => setShowModal(false)}
              children={intl.formatMessage(messages.addMoreEmbedMessageAction)}
            />
          </ModalContainer>
        </StyledModal>
      </LeadFormWidgetConfigStyled>
    )
  },
)

const LeadFormWidgetConfigStyled = styled.div``

const StyledText = styled.div`
  margin: 8px 0;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 150%;
  color: #929292;
  cursor: pointer;
  text-decoration: underline;
`

const StyledModal = styled(Modal)`
  .ant-modal-content {
    border-radius: 24px;
  }
  .ant-modal-footer {
    display: none;
  }
`

const ModalContainer = styled.div`
  color: #808182;
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  > div {
    margin-bottom: 24px;
  }
`
