import * as React from 'react'
import { Layout, ResponsiveContext } from './ResponsiveContext'
import { useMediaQuery } from 'react-responsive'
import { clsx } from 'clsx'
import { responsiveClassNames } from './const'

const isTouchDevice = () => {
  if (!global.window) return false

  return (
    'ontouchstart' in window ||
    navigator.maxTouchPoints > 0 ||
    (navigator as any).msMaxTouchPoints > 0
  )
}

export interface ResponsiveProviderProps {}

const DEFAULT_MOBILE_MAX_WIDTH = 480
const DEFAULT_TABLET_MIN_WIDTH = 744
const DEFAULT_TABLET_MAX_WIDTH = 1024

export const ResponsiveProvider = ({
  children,
}: React.PropsWithChildren<ResponsiveProviderProps>) => {
  const [layout, setLayout] = React.useState({ width: 0, height: 0 })
  const [isMobileLayout, setIsMobileLayout] = React.useState(true)
  const [isTabletLayoutSmall, setIsTabletLayoutSmall] = React.useState(true)
  const [isTabletLayoutBig, setIsTabletLayoutBig] = React.useState(true)
  const [isDesktopLayout, setIsDesktopLayout] = React.useState(true)
  const responsiveClassName = clsx({
    [responsiveClassNames.mobile]: isMobileLayout,
    [responsiveClassNames.tabletSmall]: isTabletLayoutSmall,
    [responsiveClassNames.tabletBig]: isTabletLayoutBig,
    [responsiveClassNames.desktop]: isDesktopLayout,
  })

  const isMobile = useMediaQuery({
    maxWidth: DEFAULT_MOBILE_MAX_WIDTH,
    maxAspectRatio: '16/9',
  })
  const isTabletSmall = useMediaQuery({
    minWidth: DEFAULT_MOBILE_MAX_WIDTH,
    maxWidth: DEFAULT_TABLET_MIN_WIDTH,
  })
  const isTabletBig = useMediaQuery({
    minWidth: DEFAULT_TABLET_MIN_WIDTH,
    maxWidth: DEFAULT_TABLET_MAX_WIDTH,
  })
  const isDesktop = useMediaQuery({
    minWidth: DEFAULT_TABLET_MAX_WIDTH,
  })

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      setLayout({
        width: window.innerWidth || 0,
        height: window.innerHeight || 0,
      })
      setIsMobileLayout(isMobile)
      setIsTabletLayoutSmall(isTabletSmall)
      setIsTabletLayoutBig(isTabletBig)
      setIsDesktopLayout(isDesktop)
    }
  }, [isMobile, isTabletSmall, isTabletBig, isDesktop])

  return (
    <ResponsiveContext.Provider
      value={{
        isMobileLayout,
        isTabletLayoutSmall,
        isTabletLayoutBig,
        isDesktopLayout,
        responsiveClassName,
        layout,
      }}
    >
      {children}
    </ResponsiveContext.Provider>
  )
}
