import { FontFamily } from './enum'
import { TextWidgetConfigSchema } from './types'

export const TEXT_WIDGET_ID = 'TEXT'

export const TextWidgetConfigDefaults: TextWidgetConfigSchema = {
  content: '',
  fontSize: '38px',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontFamily: FontFamily.Roboto,
  color: 'white',
  align: 'center',
}
