import { defineMessages } from 'react-intl'

export default defineMessages({
  imageSource: {
    defaultMessage: 'Upload Background',
  },
  singleColor: {
    defaultMessage: 'Single Color',
  },
  gradientColor: {
    defaultMessage: 'Gradient Color',
  },
  pleaseMakeSureYourImageIsUnder3Mb: {
    defaultMessage: 'Please make sure your image is under 3MB!',
  },
  weOnlySupportImageTypeMessage: {
    defaultMessage:
      'We only support the following image types: jpeg, jpg, png, gif',
  },
  uploadFail: {
    defaultMessage: 'Upload Fail',
  },
})
