import * as React from 'react'

import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import messages from './messages'
import { Form, Input, Select, Space, FormProps, Radio } from 'antd'
import { TEXT_WIDGET_ID } from '../constants'
import { TextWidgetConfigSchema } from '../types'
import {
  getInstanceName,
  ProfileSplashWidgetInstanceIds,
  SubtitleConfigurationEvent,
  TitleConfigurationEvent,
  track,
  withWidgetConfig,
} from '@anyonelab/common'
import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
} from '@ant-design/icons'
import { FontFamily } from '../enum'
import { capitalCase } from 'change-case'

export interface TextWidgetConfigProps {
  data: TextWidgetConfigSchema
}

export const TextWidgetConfig = withWidgetConfig<TextWidgetConfigProps>(
  {
    widgetId: TEXT_WIDGET_ID,
  },
  ({ instanceId, data, onChange }) => {
    const intl = useIntl()

    const [form] = Form.useForm<TextWidgetConfigSchema>()

    const initialValues: TextWidgetConfigSchema = {
      ...data,
    }

    const onValuesChange: FormProps<
      TextWidgetConfigSchema
    >['onValuesChange'] = (_, formValues) => {
      onChange(formValues)
    }

    const contentOnFocus = (): void => {
      switch (getInstanceName(instanceId)) {
        case ProfileSplashWidgetInstanceIds.TITLE:
          track(TitleConfigurationEvent.textInputOnChange)
          break
        case ProfileSplashWidgetInstanceIds.SUBTITLE:
          track(SubtitleConfigurationEvent.textInputOnChange)
          break
        default:
          break
      }
      return
    }

    const colorPickerOnClick = (): void => {
      switch (instanceId) {
        case ProfileSplashWidgetInstanceIds.TITLE:
          track(TitleConfigurationEvent.fontColorOnChange)
          break
        case ProfileSplashWidgetInstanceIds.SUBTITLE:
          track(SubtitleConfigurationEvent.fontColorOnChange)
          break
        default:
          break
      }
      return
    }

    return (
      <TextWidgetConfigStyled>
        <Form
          form={form}
          initialValues={initialValues}
          onValuesChange={onValuesChange}
          layout="vertical"
        >
          <Form.Item
            label={intl.formatMessage(messages.text)}
            name="text"
            required
          >
            <Input.TextArea
              onFocus={contentOnFocus}
              value={data.content}
              autoSize
            />
          </Form.Item>

          <Form.Item
            label={intl.formatMessage(messages.fontSize)}
            name="fontSize"
          >
            <Select value={data.fontSize}>
              <Select.Option value={'38px'}>
                {intl.formatMessage(messages['38pt'])}
              </Select.Option>
              <Select.Option value={'30px'}>
                {intl.formatMessage(messages['30pt'])}
              </Select.Option>
              <Select.Option value={'24px'}>
                {intl.formatMessage(messages['24pt'])}
              </Select.Option>
              <Select.Option value={'20px'}>
                {intl.formatMessage(messages['20pt'])}
              </Select.Option>
              <Select.Option value={'16px'}>
                {intl.formatMessage(messages['16pt'])}
              </Select.Option>
            </Select>
          </Form.Item>

          {/* <Form.Item label="Alignment" name="align">
            <Radio.Group value={data.align} buttonStyle="solid">
              <Radio.Button value="left">
                <AlignLeftOutlined />
              </Radio.Button>
              <Radio.Button value="center">
                <AlignCenterOutlined />
              </Radio.Button>
              <Radio.Button value="right">
                <AlignRightOutlined />
              </Radio.Button>
            </Radio.Group>
          </Form.Item> */}

          <Form.Item
            label={intl.formatMessage(messages.font)}
            name="fontFamily"
          >
            <Select value={data.fontFamily}>
              {/* NOTE: Map out all available fonts. */}
              {Object.keys(FontFamily).map((type) => {
                return (
                  <Select.Option value={FontFamily[type]}>
                    <span
                      style={{
                        fontFamily: capitalCase(FontFamily[type]),
                      }}
                    >
                      {capitalCase(type, { stripRegexp: /[\p{L}]/gi })}
                    </span>
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label={intl.formatMessage(messages.fontStyle)}
            name="fontStyle"
          >
            <Select value={data.fontStyle}>
              <Select.Option value={'normal'}>
                {intl.formatMessage(messages.normal)}
              </Select.Option>
              <Select.Option value={'italic'}>
                {intl.formatMessage(messages.italic)}
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={intl.formatMessage(messages.fontWeight)}
            name="fontWeight"
          >
            <Select value={data.fontWeight}>
              <Select.Option value={'normal'}>
                {intl.formatMessage(messages.normal)}
              </Select.Option>
              <Select.Option value={'bold'}>
                {intl.formatMessage(messages.bold)}
              </Select.Option>
              <Select.Option value={'lighter'}>
                {intl.formatMessage(messages.light)}
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={intl.formatMessage(messages.color)}
            name="fontColor"
          >
            <Input onFocus={colorPickerOnClick} type="color" />
          </Form.Item>
        </Form>
      </TextWidgetConfigStyled>
    )
  },
)

const TextWidgetConfigStyled = styled.div``
