import { defineMessages } from 'react-intl'

export default defineMessages({
  choose: { defaultMessage: 'Choose one' },
  delete: { defaultMessage: 'Delete' },
  instruction1: { defaultMessage: 'On the top right corner' },
  instruction2: {
    defaultMessage: 'In the below button list',
  },
  remark: {
    defaultMessage: 'You can change the order in "Customize" page.',
  },
})
