import {
  ALCarousel,
  AppletIcon,
  BobaIcon,
  CarouselImage,
  DownOutlined,
  UpOutlined,
  useResponsive,
} from '@anyonelab/common/'
import { AnimatePresence, motion } from 'framer-motion'
import React, { HTMLAttributes } from 'react'
import styled from 'styled-components/macro'

type AppRowProps = {
  appIcon: React.FunctionComponent<React.SVGAttributes<SVGElement>>
  appIconOnclick: () => void
  title: string
  description: string
  images: CarouselImage[]
  appKey: string
} & HTMLAttributes<HTMLDivElement>

export const AppRow = (props: AppRowProps) => {
  const {
    appIcon: AppIcon,
    title,
    description,
    appIconOnclick,
    images,
    appKey,
  } = props
  const [isToggleOpen, setIsToggleOpen] = React.useState(false)
  const { isMobileLayout, responsiveClassName } = useResponsive()
  return (
    <Container className={responsiveClassName}>
      <AnimatePresence>
        <InfoContainer className="info-container">
          <StyledAppletIcon
            className="applet-icon"
            children={
              <AppIcon onClick={appIconOnclick} width="100%" height="100%" />
            }
          />
          <AppletTitle className="applet-title" children={title} />
          <ToggleButtonContainer
            className="toggle-button-container"
            onClick={() => setIsToggleOpen((cur) => !cur)}
            children={
              isMobileLayout ? (
                <UpOutlined
                  style={{
                    transition: 'all 0.2s',
                    transform: isToggleOpen ? 'rotate(0.5turn)' : 'none',
                  }}
                />
              ) : (
                <></>
              )
            }
          />
          <AppletDescription
            className="applet-description"
            children={description}
          />
        </InfoContainer>
        {isToggleOpen || !isMobileLayout ? (
          <Card
            className="card"
            key={'toggle-card' + appKey}
            children={
              <ALCarousel
                containerProps={{ style: { paddingBottom: 20 } }}
                images={images}
              />
            }
          />
        ) : (
          <></>
        )}
      </AnimatePresence>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
  .info-container {
    margin-bottom: 16px;
  }
  &.mobile {
    .info-container {
      width: 100%;
      grid-template-columns: minmax(48px, 60px) 1fr auto;
    }
    .applet-icon {
      min-width: 48px;
      min-height: 48px;
    }
    .card {
      width: calc(100vw - 2.5rem);
      min-width: calc(320px - 2.5rem);
    }
  }
  &.tablet-small,
  &.tablet-big {
    .info-container {
      grid-template-columns: 1fr 4fr auto;
    }
    .applet-icon {
      min-width: 60px;
      min-height: 60px;
    }
  }
  &.desktop {
    .info-container {
      grid-template-columns: 1fr 4fr auto;
    }
    .applet-icon {
      min-width: 80px;
      min-height: 80px;
    }
  }
`

// NOTE: Need to fix responsiveness
const InfoContainer = styled.div`
  display: grid;
  column-gap: 20px;
  grid-template-rows: auto auto;
  font-weight: ${({ theme }) => theme.fontWeight[700]};
  font-family: '${({ theme }) => theme.bobaFontFamily.primary}';
  color: #929292;
`

const StyledAppletIcon = styled((props) => <AppletIcon {...props} />)`
  max-width: 80px;
  max-height: 80px;
  grid-row: span 2;
  cursor: pointer;
`
const AppletTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize[12]};
  font-weight: bold;
  display: flex;
  align-items: center;
`
const AppletDescription = styled.div`
  font-size: ${({ theme }) => theme.fontSize[10]};
  display: flex;
  align-items: center;
`
const ToggleButtonContainer = styled.div`
  grid-row: span 2;
  display: grid;
  place-items: center;
`

const Card = styled((props) => (
  <motion.div
    initial={{ y: -10, height: 0, opacity: 0 }}
    animate={{
      y: 0,
      height: 'auto',
      opacity: 1,
    }}
    exit={{
      y: 0,
      height: 0,
      opacity: 0,
    }}
    {...props}
  />
))`
  height: auto;
`
