import { HTMLMotionProps, motion } from 'framer-motion'
import React, { HTMLAttributes } from 'react'
import styled from 'styled-components/macro'
import { ReactComponent as HamburgerMenuIcon } from '../../assets/hamburger-menu-icon.svg'
import { CalendarNotificationBox } from '../CalendarNotificationBox'

type HomeNavigationBarProps = {
  openDrawer: () => void
  openCalendar: () => void
  showCalendar: boolean
  hasMessage?: boolean
  containerProps?: HTMLAttributes<HTMLDivElement>
}

export const HomeNavigationBar = (props: HomeNavigationBarProps) => {
  const {
    containerProps,
    openCalendar,
    openDrawer,
    hasMessage,
    showCalendar,
  } = props
  return (
    <Container {...containerProps}>
      <ButtonContainer
        style={{ color: '#6C7B8A' }}
        onClick={openDrawer}
        children={<HamburgerMenuIcon />}
      />
      {showCalendar ? (
        <ButtonContainer
          onClick={openCalendar}
          animate={
            hasMessage ? { rotateZ: [-10, 10, -5, 5, 0] } : { rotateZ: 0 }
          }
          transition={{
            duration: 1,
            times: [0, 0.25, 0.5, 0.75, 1],
            repeat: Infinity,
            repeatDelay: 4,
          }}
          children={<CalendarNotificationBox hasMessage={hasMessage} />}
        />
      ) : (
        <></>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`

const ButtonContainer = styled((props) => (
  <motion.div whileTap={{ scale: 0.8 }} {...props} />
))`
  color: #6c7b8a;
  display: grid;
  place-items: center;
`
