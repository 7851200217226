import { BOBAME_WIDGET_ID } from './../widgets/Bobame/const'
import { LEAD_FORM_WIDGET_ID } from '@anyonelab/common/'
import { WidgetType } from '@anyonelab/frontend/models/Widget'
import { AVATAR_WIDGET_ID } from '../widgets/Avatar/constants'
import { LINK_BLOCK_WIDGET_ID } from '../widgets/LinkBlock/constants'
import { PROFILE_SPLASH_WIDGET_ID } from '../widgets/ProfileSplash/constants'
import { SOCIAL_MEDIA_LINK_BUTTON_WIDGET_ID } from '../widgets/SocialMediaLinkButton/constants'
import { TEXT_WIDGET_ID } from '../widgets/TextWidget/constants'

export const convertTypeToWidgetId = (type: keyof WidgetType): string => {
  switch (type) {
    case 'avatar':
      return AVATAR_WIDGET_ID
    case 'titleLabel':
    case 'subtitleLabel':
      return TEXT_WIDGET_ID
    case 'linkButton':
      return LINK_BLOCK_WIDGET_ID
    case 'profileSplash':
      return PROFILE_SPLASH_WIDGET_ID
    case 'socialMediaButtonGroup':
      return SOCIAL_MEDIA_LINK_BUTTON_WIDGET_ID
    case 'leadForm':
      return LEAD_FORM_WIDGET_ID
    case 'bobame':
      return BOBAME_WIDGET_ID
    default:
      return ''
  }
}
