import {
  CaretRightOutlined,
  checkImage,
  useResponsive,
} from '@anyonelab/common'
import { Avatar } from 'antd'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { PrimaryButton, PrimaryButtonProps } from '../../PrimaryButton'
import messages from '../messages'
import { ReactComponent as DefaultAvatar } from '../../../assets/boba-avatar-default.svg'
import DefaultBobaThankYouImage from '../../../assets/boba-thank-you-animation.gif'

export type ThankYouPageContentProps = {
  avatar?: string
  message?: string
  isDefaultImage?: boolean
  image?: string
  onClick: () => void
}

export const ThankYouPageContent = (props: ThankYouPageContentProps) => {
  const { avatar, message, isDefaultImage, image, onClick } = props
  const intl = useIntl()
  const { responsiveClassName } = useResponsive()
  const [imageIsValid, setImageIsValid] = React.useState(false)

  React.useEffect(() => {
    image &&
      checkImage(image)
        .then(() => setImageIsValid(true))
        .catch(() => setImageIsValid(false))
  }, [image])

  return (
    <Container className={`container ${responsiveClassName}`}>
      <Title
        className="title"
        children={intl.formatMessage(messages.paymentSuccessMessage, {
          br: <br />,
        })}
      />
      <MessageBubble
        className="message-bubble"
        children={
          <>
            <StyledAvatar
              className="avatar"
              src={
                avatar || (
                  <DefaultAvatar style={{ width: '60px', height: '60px' }} />
                )
              }
            />
            <div
              children={
                message || intl.formatMessage(messages.thankyouDefaultMessage1)
              }
            />
          </>
        }
      />
      <StyledImage
        className="image"
        src={
          isDefaultImage || (!isDefaultImage && !imageIsValid)
            ? DefaultBobaThankYouImage
            : image
        }
      />
      <StyledPrimaryButton
        className="button"
        onClick={onClick}
        content={
          <>
            <span children={intl.formatMessage(messages.seeReceiptMessage)} />
            <CaretRightOutlined />
          </>
        }
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  &.desktop,
  &.tablet-small,
  &.tablet-big {
    .avatar {
      top: -16px;
      left: -16px;
    }
    .title,
    .message-bubble {
      margin-bottom: 36px;
    }
  }
  &.mobile {
    .avatar {
      top: -40px;
      left: 0;
    }
    .title,
    .message-bubble {
      margin-bottom: 60px;
    }
  }
`

const Title = styled.div`
  font-family: '${({ theme }) => theme.bobaFontFamily.title}';
  font-weight: 700;
  font-size: 28px;
  color: ${({ theme }) => theme.bobaColor.textPrimary};
  line-height: 34px;
  text-align: center;
`

const MessageBubble = styled.div`
  width: 100%;
  background: #f0f0f0;
  border-radius: 30px;
  padding: 30px 40px;
  position: relative;
  margin-bottom: 60px;
  font-family: '${({ theme }) => theme.bobaFontFamily.primary}';
  font-weight: 500;
  font-size: 12.5px;
  color: #7c7c7c;
`

const StyledAvatar = styled((props) => <Avatar {...props} />)`
  width: 60px;
  height: 60px;
  position: absolute;
  left: 0;
`

const StyledPrimaryButton = styled((props: PrimaryButtonProps) => (
  <PrimaryButton {...props} />
))`
  width: unset;
  font-size: 16px;
`

const StyledImage = styled.img`
  width: 180px;
  margin-bottom: 60px;
`
