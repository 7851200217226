import { RootState } from '@anyonelab/frontend/types'
import { createSelector } from '@reduxjs/toolkit'

import { initialState } from '.'

const selectSlice = (state: RootState) => state.linkPortal || initialState

export const selectLinkPortal = createSelector([selectSlice], (state) => state)

export const makeSelectLinkPortal = () => (state: RootState) =>
  state.linkPortal || initialState

export const makeSelectProfileSplash = () =>
  createSelector(selectLinkPortal, (state) => state.profileSplash)

export const makeSelectWidgetList = () =>
  createSelector(selectLinkPortal, (state) => state.widgetList)

export const makeSelectSavedStated = () =>
  createSelector(selectLinkPortal, (state) => state.savedState)
