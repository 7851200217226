import { WidgetType } from '@anyonelab/frontend/models/Widget'
import { ProfileSplashWidgetInstanceIds } from '../widgets/ProfileSplash/constants'

export const convertTypeToInstanceId = (type: keyof WidgetType): string => {
  switch (type) {
    case 'avatar':
      return ProfileSplashWidgetInstanceIds.AVATAR
    case 'titleLabel':
      return ProfileSplashWidgetInstanceIds.TITLE
    case 'subtitleLabel':
      return ProfileSplashWidgetInstanceIds.SUBTITLE
    case 'linkButton':
      return ProfileSplashWidgetInstanceIds.LINKS
    case 'socialMediaButtonGroup':
      return ProfileSplashWidgetInstanceIds.SOCIAL_MEDIA
    case 'leadForm':
      return ProfileSplashWidgetInstanceIds.LEAD_FORM
    case 'bobame':
      return ProfileSplashWidgetInstanceIds.BOBAME
    case 'profileSplash':
    default:
      return ''
  }
}
