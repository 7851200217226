import React, { HTMLAttributes } from 'react'
import styled from 'styled-components/macro'
import { BobaDisplay, BobaSeries } from '../..'

type BobaCountDisplayProps = {
  boba_type: {
    background_color: string
    type: {
      series: BobaSeries
    }
  }
  value: number
  count: number
  padding?: number
}

export const BobaCountDisplay = (props: BobaCountDisplayProps) => {
  return (
    <Container {...props}>
      <BobaDisplay
        style={{ marginRight: '12px' }}
        padding={props.padding}
        backgroundColor={props.boba_type.background_color}
        series={props.boba_type.type.series}
        size={24}
      />
      <CountingContainer>
        <Value children={`$${props.value}`} />
        <Times children={'x'} />
        <Amount children={props.count} />
      </CountingContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
`

const CountingContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const Value = styled.div`
  color: #929292;
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
`

const Times = styled(Value)`
  margin: 0 12px;
`

const Amount = styled(Value)`
  color: #ad5138;
`
