import React from 'react'
import { ReactComponent as BobaDefaultAvator0 } from '../../assets/boba-default-avatar/boba-avatar-0.svg'
import { ReactComponent as BobaDefaultAvator1 } from '../../assets/boba-default-avatar/boba-avatar-1.svg'
import { ReactComponent as BobaDefaultAvator2 } from '../../assets/boba-default-avatar/boba-avatar-2.svg'
import { ReactComponent as BobaDefaultAvator3 } from '../../assets/boba-default-avatar/boba-avatar-3.svg'
import { ReactComponent as BobaDefaultAvator4 } from '../../assets/boba-default-avatar/boba-avatar-4.svg'
import { ReactComponent as BobaDefaultAvator5 } from '../../assets/boba-default-avatar/boba-avatar-5.svg'
import { ReactComponent as BobaDefaultAvator6 } from '../../assets/boba-default-avatar/boba-avatar-6.svg'
import { ReactComponent as BobaDefaultAvator7 } from '../../assets/boba-default-avatar/boba-avatar-7.svg'
import { ReactComponent as BobaDefaultAvator8 } from '../../assets/boba-default-avatar/boba-avatar-8.svg'
import { ReactComponent as BobaDefaultAvator9 } from '../../assets/boba-default-avatar/boba-avatar-9.svg'

export const BobaDefaulticonList = [
  { icon: <BobaDefaultAvator0 />, key: 'boba-avatar-0' },
  { icon: <BobaDefaultAvator1 />, key: 'boba-avatar-1' },
  { icon: <BobaDefaultAvator2 />, key: 'boba-avatar-2' },
  { icon: <BobaDefaultAvator3 />, key: 'boba-avatar-3' },
  { icon: <BobaDefaultAvator4 />, key: 'boba-avatar-4' },
  { icon: <BobaDefaultAvator5 />, key: 'boba-avatar-5' },
  { icon: <BobaDefaultAvator6 />, key: 'boba-avatar-6' },
  { icon: <BobaDefaultAvator7 />, key: 'boba-avatar-7' },
  { icon: <BobaDefaultAvator8 />, key: 'boba-avatar-8' },
  { icon: <BobaDefaultAvator9 />, key: 'boba-avatar-9' },
]

export const BobaDefaultIcons = {
  'boba-avatar-0': <BobaDefaultAvator0 />,
  'boba-avatar-1': <BobaDefaultAvator1 />,
  'boba-avatar-2': <BobaDefaultAvator2 />,
  'boba-avatar-3': <BobaDefaultAvator3 />,
  'boba-avatar-4': <BobaDefaultAvator4 />,
  'boba-avatar-5': <BobaDefaultAvator5 />,
  'boba-avatar-6': <BobaDefaultAvator6 />,
  'boba-avatar-7': <BobaDefaultAvator7 />,
  'boba-avatar-8': <BobaDefaultAvator8 />,
  'boba-avatar-9': <BobaDefaultAvator9 />,
}

export const DEFAULT_BOBA_AVATAR_KEY = 'boba-avatar-0'

export const BobaAvatarKeys = [
  'boba-avatar-0',
  'boba-avatar-1',
  'boba-avatar-2',
  'boba-avatar-3',
  'boba-avatar-4',
  'boba-avatar-5',
  'boba-avatar-6',
  'boba-avatar-7',
  'boba-avatar-8',
  'boba-avatar-9',
] as const
