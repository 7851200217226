import React, { HTMLAttributes } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import {
  BobaBoardContainer,
  convertCurrencyToSymbol,
  DesktopOnly,
  MobileOnly,
} from '../..'
import messages from './messages'
import CountUp from 'react-countup'

type BalanceStatusBoardProps = {
  total_money_made?: number
  total_received_boba_count?: number
} & HTMLAttributes<HTMLDivElement>

export const BalanceStatusBoard = (props: BalanceStatusBoardProps) => {
  const intl = useIntl()
  return (
    <>
      <MobileOnly>
        <BobaBoardContainer
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Title children={intl.formatMessage(messages.title)} />
          <ItemContainer style={{ marginBottom: '24px' }}>
            <Number
              style={{ marginBottom: '8px' }}
              useEasing
              formattingFn={(value) => `$ ${value.toLocaleString()}`}
              end={props.total_money_made || 0}
            />
            <Description children={intl.formatMessage(messages.totalMade)} />
          </ItemContainer>
          <ItemContainer>
            <Number
              style={{ marginBottom: '8px' }}
              useEasing
              end={props.total_received_boba_count || 0}
            />
            <Description children={intl.formatMessage(messages.totalReceive)} />
          </ItemContainer>
        </BobaBoardContainer>
      </MobileOnly>
      <DesktopOnly>
        <BobaBoardContainer
          style={{
            minWidth: 'unset',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Title children={intl.formatMessage(messages.title)} />
          <DesktopItemContainer>
            <ItemContainer style={{ flex: 1, justifyContent: 'end' }}>
              <Number
                style={{ marginBottom: '12px' }}
                useEasing
                formattingFn={(value) => `$ ${value.toLocaleString()}`}
                end={props.total_money_made || 0}
              />
              <Description children={intl.formatMessage(messages.totalMade)} />
            </ItemContainer>
            <ItemContainer style={{ flex: 1, justifyContent: 'end' }}>
              <Number
                style={{ marginBottom: '12px' }}
                useEasing
                end={props.total_received_boba_count || 0}
              />
              <Description
                children={intl.formatMessage(messages.totalReceive)}
              />
            </ItemContainer>
          </DesktopItemContainer>
        </BobaBoardContainer>
      </DesktopOnly>
    </>
  )
}

const DesktopItemContainer = styled.div`
  display: flex;
  flex: 1;
`

const Title = styled.div`
  color: #4a1e11;
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 4px;
`

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Number = styled(CountUp)`
  color: #ad5138;
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
`

const Description = styled.div`
  color: #929292;
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`
