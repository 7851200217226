import * as React from 'react'

export interface WidgetContextValue {
  widgetId: string | null
  instanceId: string | null
  hovered: boolean
  active: boolean
  selectWidget(): void
  displayMode: boolean
}

export const WidgetContext = React.createContext<WidgetContextValue>({
  widgetId: null,
  instanceId: null,
  hovered: false,
  active: false,
  selectWidget: () => undefined,
  displayMode: false,
})

export const useWidget = () => React.useContext(WidgetContext)
