import { motion } from 'framer-motion'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { openInNewTab } from '../..'
import { AppLogo } from '../AppLogo'
import messages from './messages'

export const DashboardFooter = () => {
  const intl = useIntl()
  const FEEDBACK_GOOGLE_FORM_URL = 'https://tally.so/r/w4MQrn'
  return (
    <Container>
      <AppLogo style={{ marginRight: '24px' }} />
      <Title
        onClick={() => openInNewTab(FEEDBACK_GOOGLE_FORM_URL)}
        children={intl.formatMessage(messages.callToAction)}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e9e9e9;
  font-family: '${({ theme }) => theme.bobaFontFamily.primary}';
  color: ${({ theme }) => theme.colors.pinkDark};
  padding: 20px 0;
`

const Title = styled((props) => (
  <motion.div whileTap={{ scale: 0.8 }} {...props} />
))`
  cursor: pointer;
`
