import * as React from 'react'

export interface ResponsiveContextValue {
  isMobileLayout: boolean
  isTabletLayoutSmall: boolean
  isTabletLayoutBig: boolean
  isDesktopLayout: boolean
  responsiveClassName: string
  layout: Layout
}

export interface Layout {
  width: number
  height: number
}

export const ResponsiveContext = React.createContext<ResponsiveContextValue>({
  isMobileLayout: true,
  isTabletLayoutSmall: true,
  isTabletLayoutBig: true,
  isDesktopLayout: true,
  responsiveClassName: '',
  layout: { width: 0, height: 0 },
})

export const useResponsive = () => React.useContext(ResponsiveContext)
