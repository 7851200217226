import { defineMessages } from 'react-intl'

export default defineMessages({
  external: {
    defaultMessage: 'External URL',
  },
  internal: {
    defaultMessage: 'Internal URL',
  },
  whatsapp: {
    defaultMessage: 'WhatsApp',
  },
  phoneNumber: {
    defaultMessage: 'Phone number',
  },
  email: {
    defaultMessage: 'Email',
  },
  linkButtonText: {
    defaultMessage: 'Link Button Text',
  },
  changeOrder: {
    defaultMessage: 'Change Order',
  },
  text: {
    defaultMessage: 'Text',
  },
  fontSize: {
    defaultMessage: 'Font Size',
  },
  buttonType: {
    defaultMessage: 'Button Type',
  },
  font: {
    defaultMessage: 'Font',
  },
  fontStyle: {
    defaultMessage: 'Font Style',
  },
  fontWeight: {
    defaultMessage: 'Font Weight',
  },
  backgroundColor: {
    defaultMessage: 'Background Color',
  },
  backgroundColorOpacity: {
    defaultMessage: 'Background Color Opacity',
  },
  fontColor: {
    defaultMessage: 'Font Color',
  },
  '38pt': {
    defaultMessage: '38pt',
  },
  '30pt': {
    defaultMessage: '30pt',
  },
  '24pt': {
    defaultMessage: '24pt',
  },
  '20pt': {
    defaultMessage: '20pt',
  },
  '16pt': {
    defaultMessage: '16pt',
  },
  normal: {
    defaultMessage: 'Normal',
  },
  italic: {
    defaultMessage: 'Italic',
  },
  bold: {
    defaultMessage: 'Bold',
  },
  light: {
    defaultMessage: 'Light',
  },
  delete: {
    defaultMessage: 'Delete',
  },
  fillRounded: {
    defaultMessage: 'Fill rounded',
  },
  fillRectangle: {
    defaultMessage: 'Fill rectangle',
  },
  fillPill: {
    defaultMessage: 'Fill pill',
  },
  outlineRounded: {
    defaultMessage: 'Outline rounded',
  },
  outlineRectangle: {
    defaultMessage: 'Outline rectangle',
  },
  outlinePill: {
    defaultMessage: 'Outline pill',
  },
  softShadowRounded: {
    defaultMessage: 'Soft shadow rounded',
  },
  softShadowRectangle: {
    defaultMessage: 'Soft shadow rectangle',
  },
  softShadowPill: {
    defaultMessage: 'Soft shadow pill',
  },
  hardShadowRounded: {
    defaultMessage: 'Hard shadow rounded',
  },
  hardShadowRectangle: {
    defaultMessage: 'Hard shadow rectangle',
  },
  hardShadowPill: {
    defaultMessage: 'Hard shadow pill',
  },
  oops: {
    defaultMessage: 'Oops!',
  },
  pleaseCheckForTyposMessage: {
    defaultMessage: `"{link}" doesn't look like a valid {urlType}. Please check for typos.`,
  },
  save: {
    defaultMessage: 'Save',
  },
  linkType: {
    defaultMessage: 'Link type',
  },
  duplicate: {
    defaultMessage: 'Duplicate',
  },
})
