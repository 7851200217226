import { css } from 'styled-components/macro'

export const noSelectDragCss = css`
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-drag: none;
  -webkit-user-drag: none;
`
