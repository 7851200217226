import { LinkButtonUrlType } from '../types'

export const getPlaceholder = (linkType: LinkButtonUrlType): string => {
  let output = ''
  switch (linkType) {
    case 'email':
      break
    case 'external':
      break
    case 'internal':
      break
    case 'phoneNumber':
      break
    case 'whatsapp':
      break
    default:
      break
  }
  return output
}
