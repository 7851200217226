import { GradientColorType } from '@anyonelab/common/'

export const gradientPreset: GradientColorType[][] = [
  [
    { id: 1, color: '#808080', active: false, offset: '0.000' },
    { id: 7, color: '#000000', active: true, offset: '0.702' },
  ],
  [
    { id: 1, color: '#FFFFFF', active: false, offset: '0.000' },
    { id: 9, color: '#00FF00', active: false, offset: '0.288' },
  ],

  [
    { id: 1, color: '#FFFF00', active: true, offset: '0.185' },
    { id: 8, color: '#FFFFFF', active: false, offset: '1.000' },
  ],

  [
    { id: 1, color: '#FF0000', active: true, offset: '0.000' },
    { id: 8, color: '#FFFFFF', active: false, offset: '1.000' },
  ],

  [
    { id: 1, color: '#FF0000', active: true, offset: '0.000' },
    { id: 8, color: '#0000FF', active: false, offset: '1.000' },
  ],

  [
    { id: 1, color: '#FF00FF', active: true, offset: '0.000' },
    { id: 8, color: '#00FFFF', active: false, offset: '1.000' },
  ],

  [
    { id: 1, color: '#FF0000', active: true, offset: '0.000' },
    { id: 8, color: '#000000', active: false, offset: '1.000' },
  ],

  [
    { id: 1, color: '#C0C0C0', active: true, offset: '0.086' },
    { id: 8, color: '#00FFFF', active: false, offset: '1.000' },
  ],

  [
    { id: 1, color: '#808080', active: false, offset: '0.000' },
    { id: 6, color: '#C0C0C0', active: false, offset: '0.163' },
    { id: 3, color: '#FFFFFF', active: false, offset: '0.503' },
    { id: 7, color: '#FFFFFF', active: true, offset: '0.607' },
    { id: 5, color: '#808080', active: false, offset: '0.855' },
    { id: 4, color: '#C0C0C0', active: false, offset: '1.000' },
  ],
]
