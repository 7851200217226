import * as React from 'react'
import { useSelector } from 'react-redux'
import { convertWidgetIdToType, SaveButton, usePageBuilder } from '../..'
import { makeSelectWidgetList } from '../../../../frontend/src/app/pages/LinkTreePage/slice/selectors'

export interface WidgetConfigComponentChangeValues<T> {
  instanceId: string
  widgetId: string
  data: T
}

export interface WithWidgetConfigComponentProps<T> {
  instanceId: string
  onChange(data: T): void
  onDuplicate(): void
  onRemove(): void
  onPromote(): void
  onDemote(): void
  data: T
}

export interface WithWidgetConfigComponentPropsResult<T> {
  instanceId: string
  onChange(data: WidgetConfigComponentChangeValues<T>): void
  onDuplicate(data: any): void
  onRemove(data: { widgetId: string; instanceId: string }): void
  onPromote(data: { widgetId: string; instanceId: string }): void
  onDemote(data: { widgetId: string; instanceId: string }): void
  data: T
}

export const withWidgetConfig = <
  WidgetConfigComponentProps extends {
    data: unknown
    hasSaveButton?: boolean
  }
>(
  { widgetId }: { widgetId: string },
  WidgetConfigComponent: React.ComponentType<
    WidgetConfigComponentProps &
      WithWidgetConfigComponentProps<WidgetConfigComponentProps['data']>
  >,
) => {
  const WithBaseAttributeConfig = (
    props: WidgetConfigComponentProps &
      WithWidgetConfigComponentPropsResult<WidgetConfigComponentProps['data']>,
  ) => {
    const {
      data,
      onDuplicate,
      onChange,
      onRemove,
      onPromote,
      onDemote,
      instanceId,
      hasSaveButton = false,
    } = props
    const { selectWidget, activeWidgetId } = usePageBuilder()
    const selectWidgetList = useSelector(makeSelectWidgetList())

    const _onChange = (data) => {
      onChange({
        data,
        widgetId,
        instanceId,
      })
    }

    const _onDuplicate = () => {
      onDuplicate({
        data,
        type: convertWidgetIdToType(activeWidgetId || ''),
      })
    }

    const _onRemove = () => {
      onRemove({
        widgetId,
        instanceId,
      })
    }
    // TODO: Deprecated code, should be removed.
    const _onPromote = () => {
      onPromote({
        widgetId,
        instanceId,
      })
      const id = parseInt(instanceId[instanceId.length - 1])
      const promotedId = instanceId.slice(0, -1) + (id - 1).toString()
      selectWidget(widgetId, promotedId)
    }

    // TODO: Deprecated code, should be removed.
    const _onDemote = () => {
      onDemote({
        widgetId,
        instanceId,
      })
      const id = parseInt(instanceId[instanceId.length - 1])
      const demotedId = instanceId.slice(0, -1) + (id + 1).toString()
      selectWidget(widgetId, demotedId)
    }

    return (
      <>
        <WidgetConfigComponent
          {...props}
          onChange={_onChange}
          onDuplicate={_onDuplicate}
          onRemove={_onRemove}
          onPromote={_onPromote}
          onDemote={_onDemote}
        />
        {hasSaveButton && <SaveButton onClick={() => {}} />}
      </>
    )
  }

  return WithBaseAttributeConfig
}
