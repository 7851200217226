import React from 'react'
import styled from 'styled-components'
import { motion, MotionProps } from 'framer-motion'

export const WideRoundedButton = (
  props: MotionProps & React.HTMLAttributes<HTMLDivElement>,
) => {
  return (
    <Container whileTap={{ scale: 0.9 }} {...props}>
      {props.children}
    </Container>
  )
}

const Container = styled(motion.div)`
  background: #e6e8ea;
  min-height: 56px;
  font-size: ${({ theme }) => theme.fontSize[16]};
  border-radius: ${({ theme }) => theme.borderRadius[16]};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeight[700]};
  cursor: pointer;
`
