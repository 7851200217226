import { rem, rgba } from 'polished'

export const theme = {
  colors: {
    background: '#F2F2F2',
    blue: '#3354A5',
    blueLight: '#19a0fb',
    border: '#E0E0E0',
    checkbox: '#FFD08A',
    green: '#51BD90',
    grey: '#AFAFBD',
    grey60: '#F0F2F5',
    grey80: '#CCCCCC',
    grey85: '#d9d9d9',
    grey90: '#eeeeee',
    grey95: '#f9f9f9',
    offwhite: '#F3F3F5',
    pink: '#F386A4',
    pinkLight: '#FCE6E6',
    pinkDark: '#ee8183',
    primary: '#F88065',
    textPrimary: '#696661',
    white: '#FFFFFF',
    widgetBlue: rgba('#19a0fb', 0.9),
    yellow: '#FDC52F',
    pageView: '#27CCDF',
    pageViewGradient: 'linear-gradient(20deg, #2DC9EB 0%, #14D2B8 100%)',
    pageClick: '#EE8183',
  },
  bobaColor: {
    textPrimary: '#62463E',
    primary: '#AB5337',
    secondary: '#EED7C2',
    backgroundWhite: '#fafafa',
    backgroundPrimary: '#F2EADF',
    backgroundSecondary: '#CCA78C',
  },
  bobaFontFamily: {
    title: 'Baloo Tamma 2',
    primary: 'Montserrat',
    secondary: 'Roboto',
  },
  fontSize: {
    6: rem(6),
    10: rem(10),
    12: rem(12),
    14: rem(14),
    16: rem(16),
    24: rem(24),
    28: rem(28),
    32: rem(32),
  },
  fontWeight: {
    500: 500,
    600: 600,
    700: 700,
  },
  padding: {
    6: rem(6),
    8: rem(8),
    12: rem(12),
    14: rem(14),
    16: rem(16),
    20: rem(20),
    28: rem(28),
    32: rem(32),
    36: rem(36),
    bottomNavbarHeight: rem(80),
  },
  borderRadius: {
    0: 0,
    4: rem(4),
    12: rem(12),
    16: rem(16),
    pill: rem(160),
  },
  borderWidth: {
    2: rem(2),
    4: rem(4),
  },
  zIndex: {
    100: 100,
    500: 500,
    1000: 1000,
  },
  opacity: {
    full: 1,
  },
  boxShadow: {
    defaultShadowColor: rgba('black', 0.75),
    defaultAvatarShadow: `0 2px 32px ${rgba('black', 0.2)}`,
  },
  DesktopModalWidth: rem(440),
  snackBioCardWidth: {
    m: '288px',
    l: '344px',
  },
}
