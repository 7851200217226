import { defineMessages } from 'react-intl'

export default defineMessages({
  title: { defaultMessage: 'BobaMe Embed' },
  instruction1: { defaultMessage: 'Oops!' },
  instruction2: {
    defaultMessage: "You don't have BobaMe account yet. Apply for one!",
  },
  actionVerb: { defaultMessage: 'Have fun now' },
  remarks: {
    defaultMessage:
      'BobaMe is donation platform. You can open a Boba bar to receive others love, or support your favorite creator by sending special Bobas!',
  },
})
