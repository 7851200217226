import { BobaItem } from '../boba-components'

export const calculateTotalDonation = (bobaItems: BobaItem[]) => {
  const total = bobaItems.reduce((prev, curr) => {
    // * calculate inside the component instead of passing the total outside
    return (prev += curr.count * curr.value)
  }, 0)

  return total
}
