import React, { HTMLAttributes } from 'react'
import styled from 'styled-components/macro'
import { BobaSeries } from '../type'
import { Matcha, Peach, Sunset } from './presets'

export type BobaElements = {}

export type BobaDisplayProps = {
  size?: number
  padding?: number
  backgroundColor?: string
  series?: BobaSeries
} & BobaElements &
  HTMLAttributes<HTMLDivElement>

export const DEFAULT_SIZE = 95

export const BobaDisplay = (props: BobaDisplayProps) => {
  return (
    <Container backgroundColor={props.backgroundColor} {...props}>
      {props.series === 'sunset' ? (
        <Sunset width={'100%'} height={'100%'} />
      ) : props.series === 'peach' ? (
        <Peach width={'100%'} height={'100%'} />
      ) : props.series === 'matcha' ? (
        <Matcha width={'100%'} height={'100%'} />
      ) : (
        <Sunset width={'100%'} height={'100%'} />
      )}
    </Container>
  )
}

const Container = styled.div<BobaDisplayProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => (size ? size : DEFAULT_SIZE)}px;
  height: ${({ size }) => (size ? size : DEFAULT_SIZE)}px;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ? backgroundColor : 'unset'};
  padding: ${({ padding }) => (padding ? padding : 0)}px;
  border-radius: 50%;
  box-sizing: border-box;
`
