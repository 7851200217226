import React from 'react'
import styled from 'styled-components'
import { useResponsive } from '../..'
import {
  BobaResponsiveModal,
  BobaResponsiveModalProps,
} from './BobaResponsiveModal'
import { ShareContent } from './PopupContentComponent/ShareContent/ShareContent'

// ! missing snackbio page image here
export interface BobaSharePopupModalProps extends BobaResponsiveModalProps {
  fullName: string
  avatarImageUrl?: string
  domain: string
  aboutMe?: string
  displayMode?: boolean
}

function BobaSharePopupModal({
  fullName,
  avatarImageUrl,
  domain,
  aboutMe,
  displayMode = false,
  ...props
}: BobaSharePopupModalProps) {
  const { isMobileLayout } = useResponsive()
  return (
    <BobaResponsiveModal
      bodyStyle={{ margin: isMobileLayout ? '24px' : '0' }}
      {...props}
    >
      <ShareContent
        fullName={fullName}
        avatarImageUrl={avatarImageUrl} // ! need to pass snackbio avatar here if avatarImageUrl is undefined
        domain={domain}
        aboutMe={aboutMe}
        displayMode={displayMode}
      />
    </BobaResponsiveModal>
  )
}

export default BobaSharePopupModal
