import { message } from '@anyonelab/common/'
import * as React from 'react'

import { IntlProvider, useIntl } from 'react-intl'

export interface LanguageProviderProps {
  messages: object
  locale?: string
  fullLocale?: string
}

export const LanguageContext = React.createContext<{
  language: 'zh' | 'en'
  setLanguage: () => void
}>({
  language: 'zh',
  setLanguage: () => {},
})

const localStorageKey = {
  locale: 'locale',
}

export const LanguageProvider = React.memo(
  ({
    children,
    messages = {},
  }: React.PropsWithChildren<LanguageProviderProps>) => {
    const isRunningOnClient = typeof window !== 'undefined'
    const [language, setLanguage] = React.useState<'zh' | 'en'>(
      (isRunningOnClient &&
        (localStorage?.getItem(localStorageKey.locale) as
          | 'zh'
          | 'en'
          | undefined)) ||
        'zh',
    )
    const changeLanguage = () => {
      if (language === 'zh') {
        setLanguage('en')
        isRunningOnClient && localStorage?.setItem(localStorageKey.locale, 'en')
        message.success('Language is now changed to English')
      } else {
        setLanguage('zh')
        isRunningOnClient && localStorage?.setItem(localStorageKey.locale, 'zh')
        message.success('語言已更改為中文')
      }
    }

    return (
      <LanguageContext.Provider
        value={{
          language: language,
          setLanguage: changeLanguage,
        }}
      >
        <IntlProvider
          locale={language}
          key={language}
          messages={messages[language]}
          wrapRichTextChunksInFragment={true}
        >
          {React.Children.only(children)}
        </IntlProvider>
      </LanguageContext.Provider>
    )
  },
)
