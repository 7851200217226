/**
 *
 * Pagebuilder
 *
 */
import * as React from 'react'

import styled, { css } from 'styled-components/macro'
import { useIntl } from 'react-intl'
import messages from './messages'
import {
  Row,
  Col,
  Card,
  Button,
  EyeOutlined,
  PageHeader,
  Spacer,
  Drawer,
  Space,
  Dropdown,
  Menu,
  MobileOutlined,
  track,
  LinkPortalEvent,
} from '@anyonelab/common'
import {
  PageBuilderContext,
  PageBuilderContextValues,
} from './context/PageBuilderContext'
import { PageBuilderWorkspace } from './components/PageBuilderWorkspace'
import {
  PageBuilderConfigPanel,
  PageBuilderConfigPanelProps,
} from './components/PageBuilderConfigPanel'
// import { AppHeader } from '../../components/AppLayout'
import { DesktopOnly, MobileOnly } from '@anyonelab/common'
import { useResponsive } from '@anyonelab/common'
import { WithWidgetConfigComponentPropsResult } from '../../hocs/withWidgetConfig'
import { Spinner } from '@anyonelab/common'
import { PageWidgetType } from '@anyonelab/frontend/models/Widget'

type ConfigPanelContentType = '' | 'theme' | 'customize'

export type PageBuilderWidgetConfig = {
  widgetId: string
  instanceId: string
  instanceLabel: string
  data: any
  component: React.ComponentType<WithWidgetConfigComponentPropsResult<any>>
}

export type PageBuilderWidgetConfigs = {
  [instanceId: string]: PageBuilderWidgetConfig
}

export interface PagebuilderProps {
  isLoading: boolean
  isPreviewMode: boolean
  onExitPreview: () => void
  onConfigChange: (data) => void
  onDuplicateInstance: PageBuilderConfigPanelProps['onWidgetDuplicate']
  onRemoveInstance: PageBuilderConfigPanelProps['onWidgetRemove']
  onPromoteInstance: PageBuilderConfigPanelProps['onWidgetPromote']
  onDemoteInstance: PageBuilderConfigPanelProps['onWidgetDemote']
  onLinkPortalThemeSave: () => void
  elementData: PageWidgetType[]
  previewComponent: React.ReactNode
  setSelectedLinkPortalTheme: React.Dispatch<React.SetStateAction<any>>
}

export const Pagebuilder = ({
  children,
  isLoading = false,
  isPreviewMode,
  onExitPreview = () => undefined,
  onConfigChange = () => undefined,
  onDuplicateInstance = () => undefined,
  onRemoveInstance = () => undefined,
  onPromoteInstance = () => undefined,
  onDemoteInstance = () => undefined,
  onLinkPortalThemeSave = () => undefined,
  previewComponent,
  elementData,
  setSelectedLinkPortalTheme,
}: React.PropsWithChildren<PagebuilderProps>) => {
  const intl = useIntl()

  const [configPanelContent, setConfigPanelContent] = React.useState<
    ConfigPanelContentType
  >('')

  const [activeWidgetId, setActiveWidgetId] = React.useState<
    PageBuilderContextValues['activeWidgetId']
  >('')
  const [activeInstanceId, setActiveInstanceId] = React.useState<
    PageBuilderContextValues['activeInstanceId']
  >('')
  const [hoveredWidgetId, setHoveredWidgetId] = React.useState<
    PageBuilderContextValues['hoveredWidgetId']
  >('')
  const [hoveredInstanceId, setHoveredInstanceId] = React.useState<
    PageBuilderContextValues['hoveredInstanceId']
  >('')

  const [registeredWidgetConfigs, setRegisteredWidgetConfigs] = React.useState<
    PageBuilderWidgetConfigs
  >({})

  const [previewViewMode, setPreviewViewMode] = React.useState<
    'desktop' | 'mobile'
  >('desktop')

  const { isMobileLayout } = useResponsive()

  const DEFAULT_NAV_BAR_HEIGHT = 54

  // unselect if pass null
  const selectWidget: PageBuilderContextValues['selectWidget'] = React.useCallback(
    (widgetId, instanceId?) => {
      if (widgetId) {
        setActiveWidgetId(widgetId)
        setActiveInstanceId(instanceId)
      } else {
        setActiveWidgetId(null)
        setActiveInstanceId(null)
      }
    },
    [],
  )

  const hoverWidget: PageBuilderContextValues['hoverWidget'] = React.useCallback(
    (widgetId, instanceId?) => {
      if (widgetId) {
        setHoveredWidgetId(widgetId)
        setHoveredInstanceId(instanceId)
      } else {
        setHoveredWidgetId(null)
        setHoveredInstanceId(null)
      }
    },
    [],
  )

  const registerWidgetConfig: PageBuilderContextValues['registerWidgetConfig'] = React.useCallback(
    ({ widgetId, instanceLabel, instanceId, component }) => {
      setRegisteredWidgetConfigs((widgets) => {
        if (widgets[instanceId]) {
          widgets[instanceId] = {
            ...widgets[instanceId],
            widgetId,
            instanceLabel,
            component,
          }

          return {
            ...widgets,
          }
        }

        widgets[instanceId] = {
          widgetId,
          instanceId,
          instanceLabel,
          component,
          data: {},
        }

        return {
          ...widgets,
        }
      })
    },
    [],
  )

  const updateConfigData: PageBuilderContextValues['updateConfigData'] = React.useCallback(
    (instanceId, data) => {
      setRegisteredWidgetConfigs((widgets) => {
        if (!widgets[instanceId]) return widgets

        widgets[instanceId].data = {
          ...widgets[instanceId].data,
          ...data,
        }

        return {
          ...widgets,
        }
      })
    },
    [],
  )

  const onWidgetDuplicate: PageBuilderConfigPanelProps['onWidgetDuplicate'] = (
    data,
  ) => {
    onDuplicateInstance(data)
    selectWidget(null)
  }

  const onWidgetRemove: PageBuilderConfigPanelProps['onWidgetRemove'] = (
    index,
  ) => {
    onRemoveInstance(index)
    selectWidget(null)
  }

  const onWidgetPromote: PageBuilderConfigPanelProps['onWidgetPromote'] = (
    index: number,
  ) => {
    onPromoteInstance(index)
  }

  const onWidgetDemote: PageBuilderConfigPanelProps['onWidgetDemote'] = (
    index: number,
  ) => {
    onDemoteInstance(index)
  }

  const onDrawerClose = () => {
    selectWidget(null)
    setConfigPanelContent('')
    track(LinkPortalEvent.configPanelCloseDrawerClick)
  }

  const onLinkPortalThemeSaveModify = () => {
    onDrawerClose()
    onLinkPortalThemeSave()
  }

  const togglePreviewViewMode = () => {
    if (previewViewMode === 'desktop') {
      setPreviewViewMode('mobile')
      track(LinkPortalEvent.mobileViewClick)
    } else {
      setPreviewViewMode('desktop')
      track(LinkPortalEvent.desktopViewClick)
    }
  }

  const CONFIG_WIDTH = '360px'
  const HEADER_HEIGHT = '54px'

  return (
    <PagebuilderStyled isMobile={isMobileLayout} isPreviewMode={isPreviewMode}>
      <PageBuilderContext.Provider
        value={{
          activeWidget: activeInstanceId
            ? registerWidgetConfig[activeInstanceId]
            : null,
          hoveredWidgetId,
          hoveredInstanceId,
          activeWidgetId,
          activeInstanceId,
          selectWidget,
          hoverWidget,
          registerWidgetConfig,
          updateConfigData,
          isPreviewMode,
          previewViewMode,
          configPanelContent,
          setConfigPanelContent,
          setSelectedLinkPortalTheme,
          onLinkPortalThemeSave: onLinkPortalThemeSaveModify,
        }}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <>
            <DesktopOnly>
              {isPreviewMode ? (
                <PageBuilderWorkspaceContainer
                  isMobile={false}
                  isPreviewMode={isPreviewMode}
                  previewViewMode={previewViewMode}
                >
                  {isPreviewMode && (
                    <div className="menu">
                      <Space direction="horizontal">
                        <Button onClick={togglePreviewViewMode}>
                          {previewViewMode === 'desktop'
                            ? intl.formatMessage(messages.desktopView)
                            : intl.formatMessage(messages.mobileView)}
                        </Button>
                        <Button onClick={onExitPreview}>
                          {intl.formatMessage(messages.exitPreview)}
                        </Button>
                      </Space>
                    </div>
                  )}

                  <div className="desktop-container">{children}</div>
                </PageBuilderWorkspaceContainer>
              ) : (
                <Row>
                  <Col flex="1" style={{ padding: 0 }}>
                    {children}
                  </Col>
                  <Col
                    style={{
                      padding: 0,
                      width: CONFIG_WIDTH,
                      position: 'relative',
                    }}
                  >
                    <Card
                      style={{
                        height: `calc(100vh - ${HEADER_HEIGHT})`,
                        border: 'initial',
                        position: 'sticky',
                        right: 0,
                        top: DEFAULT_NAV_BAR_HEIGHT,
                        width: CONFIG_WIDTH,
                        overflow: 'scroll',
                      }}
                      bodyStyle={{ padding: 0 }}
                    >
                      <PageBuilderConfigPanel
                        key={activeInstanceId}
                        widgetConfigs={registeredWidgetConfigs}
                        onChange={onConfigChange}
                        onWidgetDuplicate={onWidgetDuplicate}
                        onWidgetRemove={onWidgetRemove}
                        onWidgetPromote={onWidgetPromote}
                        onWidgetDemote={onWidgetDemote}
                        previewComponent={previewComponent}
                        elementData={elementData}
                      />
                    </Card>
                  </Col>
                </Row>
              )}
            </DesktopOnly>

            <MobileOnly>
              <PageBuilderWorkspaceContainer
                isMobile
                isPreviewMode={isPreviewMode}
                previewViewMode={previewViewMode}
              >
                <div className="menu">
                  {isPreviewMode && (
                    <Button onClick={onExitPreview}>
                      {intl.formatMessage(messages.exitPreview)}
                    </Button>
                  )}
                </div>
                {children}
              </PageBuilderWorkspaceContainer>

              <Drawer
                bodyStyle={{ padding: 0, overflowY: 'hidden', zIndex: 1 }}
                visible={!!activeInstanceId || Boolean(configPanelContent)}
                placement="bottom"
                destroyOnClose
                closable={false}
                onClose={onDrawerClose}
                height="100%"
              >
                <PageBuilderConfigPanel
                  key={activeInstanceId}
                  widgetConfigs={registeredWidgetConfigs}
                  onChange={onConfigChange}
                  onWidgetDuplicate={onWidgetDuplicate}
                  onWidgetRemove={onWidgetRemove}
                  onWidgetPromote={onWidgetPromote}
                  onWidgetDemote={onWidgetDemote}
                  previewComponent={previewComponent}
                  elementData={elementData}
                />
              </Drawer>
            </MobileOnly>
          </>
        )}
      </PageBuilderContext.Provider>
    </PagebuilderStyled>
  )
}

const PagebuilderStyled = styled.div<{
  isPreviewMode: boolean
  isMobile: boolean
}>``

const PageBuilderWorkspaceContainer = styled.div<{
  isPreviewMode: boolean
  isMobile: boolean
  previewViewMode: 'desktop' | 'mobile'
}>`
  position: relative;

  ${(props) =>
    props.isPreviewMode &&
    css`
      position: fixed;

      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      z-index: 100;

      overflow: auto;

      background: #e5e5e5;
    `}
  ${(props) =>
    props.previewViewMode === 'mobile' &&
    css`
      .desktop-container {
        width: 480px;
        margin: 0 auto;
      }
    `}
  .menu {
    position: fixed;
    top: 16px;
    right: 16px;
    z-index: 101;
  }
`

Pagebuilder.Workspace = PageBuilderWorkspace
Pagebuilder.ConfigPanel = PageBuilderConfigPanel
