import { SaveButton, usePageBuilder } from '@anyonelab/common/'
import { Spinner } from '@anyonelab/common'
import { apiClient } from '@anyonelab/frontend/utils/api-client'
import React from 'react'
import styled from 'styled-components'
import { Theme } from '@anyonelab/frontend/src/models/Theme'
import { message } from 'antd'

export function LinkPortalThemeConfig() {
  const [themes, setThemes] = React.useState<Array<Theme> | null>(null)
  const [themeIsSelected, setThemeIsSelected] = React.useState<number | null>(
    null,
  )
  const { setSelectedLinkPortalTheme, onLinkPortalThemeSave } = usePageBuilder()

  // reset the clicked theme but not saved
  React.useEffect(() => {
    async function getTheme() {
      try {
        const res = await apiClient.get('/theme')
        setThemes(res.data.data)
      } catch (err) {
        console.log(err)
      }
    }
    getTheme()

    return () => {
      setSelectedLinkPortalTheme(null)
    }
  }, [])

  if (!themes) {
    return <Spinner />
  }

  return (
    <div>
      <ThemeButtonContainerStyled>
        <ThemeButtonBoxStyled
          className={`${themeIsSelected === 0 ? 'isSelected' : ''} none`}
          key={0}
          onClick={() => {
            setThemeIsSelected(0)
            setSelectedLinkPortalTheme(null)
          }}
        >
          NONE
        </ThemeButtonBoxStyled>
        {themes
          .filter((theme) => theme.is_active)
          .map((theme, index) => {
            const { profileSplash, widgetList, iconUrl } = theme.theme_data
            return (
              <ThemeButtonBoxStyled
                className={themeIsSelected === index + 1 ? 'isSelected' : ''}
                key={index + 1}
                onClick={() => {
                  setThemeIsSelected(index + 1)
                  setSelectedLinkPortalTheme({
                    profileSplash: profileSplash,
                    widgetList: widgetList,
                  })
                }}
              >
                <img className="image-icon" src={iconUrl} />
              </ThemeButtonBoxStyled>
            )
          })}
      </ThemeButtonContainerStyled>
      <SaveButton onClick={onLinkPortalThemeSave} />
    </div>
  )
}

const ThemeButtonContainerStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-bottom: ${({ theme }) => theme.padding[20]};
  justify-items: center;
  gap: ${({ theme }) => theme.padding[20]};
`
const ThemeButtonBoxStyled = styled.div`
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius[16]};
  width: 56px;
  height: 56px;

  .image-icon {
    width: 100%;
    height: 100%;
    border-radius: ${({ theme }) => theme.borderRadius[16]};
  }

  &.none {
    background-color: #e5e5e5;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }

  &.isSelected {
    border: solid 1px grey;
  }
`
