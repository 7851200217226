import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Carousel, CarouselProps } from 'antd'
import { CarouselRef } from 'antd/lib/carousel'
import React, { HTMLAttributes, useRef } from 'react'
import styled from 'styled-components/macro'
import { CarouselImage } from './const'

type ALCarouselProps = {
  images: CarouselImage[]
  carouselProps?: CarouselProps
  containerProps?: HTMLAttributes<HTMLDivElement>
}

export const ALCarousel = (props: ALCarouselProps) => {
  const { images, carouselProps, containerProps } = props
  const carouselRef = useRef<CarouselRef>(null)
  const hasMultipleImages = images.length > 1
  return (
    <Container {...containerProps}>
      <Carousel
        style={{ width: '100%' }}
        ref={carouselRef}
        dots={false}
        {...carouselProps}
        children={images.map((image, index) => (
          <Slide key={index + 'slide'} src={image.src} />
        ))}
      />
      {hasMultipleImages ? (
        <>
          <Button
            style={{ left: 0, top: 0 }}
            children={
              <LeftOutlined onClick={() => carouselRef.current?.prev()} />
            }
          />
          <Button
            style={{ right: 0, top: 0 }}
            children={
              <RightOutlined onClick={() => carouselRef.current?.next()} />
            }
          />
        </>
      ) : (
        <></>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 100%;
`

const Slide = styled.img`
  width: 100%;
  height: auto;
  user-select: none;
  -webkit-user-drag: none;
`

const Button = styled.div`
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 24px;
  > * {
    padding: 8px;
    color: #ffffff80;
  }
`
