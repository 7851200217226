import React from 'react'
import styled, { keyframes } from 'styled-components/macro'
import { useImageUrlChecker } from '../..'

export type BobaLoaderProps = {
  liquidHeightPercentage?: number
  image?: string
  size?: number
  direction?: 'left' | 'right'
  angle?: number
} & React.SVGProps<SVGSVGElement>

export const BobaLoaderSVG = (props: BobaLoaderProps) => {
  const { isWaitingThumbnail } = useImageUrlChecker(props.image)
  const DEFAULT_ANGLE = 16

  return (
    <svg
      width={props.size || '90'}
      height={props.size || '90'}
      viewBox="0 0 115 165"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        id="boba-container"
        transform={
          props.angle !== undefined
            ? `rotate(${props.angle - 20})`
            : props.direction === 'left'
            ? `rotate(${-DEFAULT_ANGLE * 2})`
            : 'rotate(0)'
        }
        transform-origin="57.5 82.5"
      >
        <path
          id="straw"
          d="M87.1885 1.49036L87.17 1.48335C84.9964 0.664118 82.1765 2.81654 80.8714 6.29093L32.4953 135.077C31.1902 138.552 31.8942 142.032 34.0677 142.852L34.0863 142.859C36.2598 143.678 39.0797 141.525 40.3848 138.051L88.761 9.26464C90.066 5.79025 89.362 2.30959 87.1885 1.49036Z"
          fill="#CED8ED"
        />
        <g id="cup-body-group">
          <mask
            id="mask0_1452_468"
            maskUnits="userSpaceOnUse"
            x="0"
            y="41"
            width="107"
            height="124"
          >
            <path
              id="cup-body-mask"
              d="M21.631 44.3143L0.0513142 140.476C-0.0810976 141.205 0.0446236 141.957 0.406743 142.603C0.768863 143.249 1.34465 143.747 2.03474 144.013L55.5873 164.239C56.2961 164.546 57.093 164.579 57.825 164.334C58.557 164.088 59.1728 163.58 59.5542 162.908L106.542 76.322C106.749 75.9255 106.869 75.4889 106.893 75.0419C106.917 74.5949 106.845 74.1478 106.681 73.7313C106.517 73.3147 106.266 72.9384 105.944 72.6277C105.622 72.3171 105.238 72.0794 104.816 71.9309L25.7763 42.1286C25.3626 41.9658 24.9188 41.8942 24.475 41.9188C24.0311 41.9433 23.5977 42.0635 23.2043 42.2709C22.8109 42.4783 22.4669 42.7682 22.1954 43.1208C21.9239 43.4734 21.7314 43.8805 21.631 44.3143V44.3143Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_1452_468)">
            <path
              id="cup-body"
              d="M21.631 44.3143L0.0513142 140.476C-0.0810976 141.205 0.0446236 141.957 0.406743 142.603C0.768863 143.249 1.34465 143.747 2.03474 144.013L55.5873 164.239C56.2961 164.546 57.093 164.579 57.825 164.334C58.557 164.088 59.1728 163.58 59.5542 162.908L106.542 76.322C106.749 75.9255 106.869 75.4889 106.893 75.0419C106.917 74.5949 106.845 74.1478 106.681 73.7313C106.517 73.3147 106.266 72.9384 105.944 72.6277C105.622 72.3171 105.238 72.0794 104.816 71.9309L25.7763 42.1286C25.3626 41.9658 24.9188 41.8942 24.475 41.9188C24.0311 41.9433 23.5977 42.0635 23.2043 42.2709C22.8109 42.4783 22.4669 42.7682 22.1954 43.1208C21.9239 43.4734 21.7314 43.8805 21.631 44.3143V44.3143Z"
              fill="#F2F2F2"
            />
            <path
              id="straw_2"
              d="M87.1885 1.49036L87.17 1.48335C84.9964 0.664118 82.1765 2.81654 80.8714 6.29093L32.4953 135.077C31.1902 138.552 31.8942 142.032 34.0677 142.852L34.0863 142.859C36.2598 143.678 39.0797 141.525 40.3848 138.051L88.761 9.26464C90.066 5.79025 89.362 2.30959 87.1885 1.49036Z"
              fill="#CED8ED"
            />
            <rect
              id="liquid"
              x="12.7627"
              y="41.7031"
              width="108"
              height={
                props.liquidHeightPercentage
                  ? (props.liquidHeightPercentage / 100) * 99.8
                  : '99.704'
              }
              transform={
                props.liquidHeightPercentage
                  ? `translate(-${
                      ((100 - props.liquidHeightPercentage) / 100) * 20
                    } ${
                      ((100 - props.liquidHeightPercentage) / 100) * 99.8
                    }) rotate(20.65 12.7627 41.7031)`
                  : `rotate(20.65 12.7627 41.7031)`
              }
              fill="#F8E0BE"
            />
            <g id="bobas">
              <path
                id="boba01"
                d="M15.8989 132.312C18.6813 132.312 20.9368 130.052 20.9368 127.265C20.9368 124.478 18.6813 122.219 15.8989 122.219C13.1166 122.219 10.861 124.478 10.861 127.265C10.861 130.052 13.1166 132.312 15.8989 132.312Z"
                fill="#D79B7A"
              />
              <path
                id="boba02"
                d="M16.0973 115.031C18.8797 115.031 21.1352 112.771 21.1352 109.984C21.1352 107.197 18.8797 104.938 16.0973 104.938C13.3149 104.938 11.0594 107.197 11.0594 109.984C11.0594 112.771 13.3149 115.031 16.0973 115.031Z"
                fill="#D79B7A"
              />
              <path
                id="boba03"
                d="M24.2492 149.085C27.0315 149.085 29.2871 146.826 29.2871 144.039C29.2871 141.252 27.0315 138.992 24.2492 138.992C21.4668 138.992 19.2112 141.252 19.2112 144.039C19.2112 146.826 21.4668 149.085 24.2492 149.085Z"
                fill="#D79B7A"
              />
              <path
                id="boba04"
                d="M61.974 126.452C64.7564 126.452 67.0119 124.193 67.0119 121.406C67.0119 118.619 64.7564 116.359 61.974 116.359C59.1916 116.359 56.9361 118.619 56.9361 121.406C56.9361 124.193 59.1916 126.452 61.974 126.452Z"
                fill="#D79B7A"
              />
              <path
                id="boba05"
                d="M63.0054 145.648C65.7878 145.648 68.0434 143.388 68.0434 140.601C68.0434 137.814 65.7878 135.555 63.0054 135.555C60.2231 135.555 57.9675 137.814 57.9675 140.601C57.9675 143.388 60.2231 145.648 63.0054 145.648Z"
                fill="#D79B7A"
              />
              <path
                id="boba06"
                d="M43.429 144.202C46.2113 144.202 48.4669 141.943 48.4669 139.156C48.4669 136.369 46.2113 134.109 43.429 134.109C40.6466 134.109 38.3911 136.369 38.3911 139.156C38.3911 141.943 40.6466 144.202 43.429 144.202Z"
                fill="#D79B7A"
              />
              <path
                id="boba07"
                d="M53.1478 158.921C55.9301 158.921 58.1857 156.662 58.1857 153.875C58.1857 151.088 55.9301 148.828 53.1478 148.828C50.3654 148.828 48.1099 151.088 48.1099 153.875C48.1099 156.662 50.3654 158.921 53.1478 158.921Z"
                fill="#D79B7A"
              />
              <path
                id="boba08"
                d="M37.5184 128.242C40.3007 128.242 42.5563 125.982 42.5563 123.195C42.5563 120.408 40.3007 118.148 37.5184 118.148C34.736 118.148 32.4805 120.408 32.4805 123.195C32.4805 125.982 34.736 128.242 37.5184 128.242Z"
                fill="#D79B7A"
              />
            </g>
          </g>
        </g>
        <g id="cup-cover">
          <path
            id="Vector"
            d="M108.327 73.244C112.449 61.8607 111.925 49.3086 106.869 38.3095C101.814 27.3103 92.6334 18.7506 81.3194 14.4865C70.0053 10.2224 57.4695 10.5975 46.4297 15.5306C35.3899 20.4636 26.7362 29.5572 22.345 40.8391L108.327 73.244Z"
            fill="#E3E7F5"
          />
          <path
            id="Vector_2"
            d="M78.0793 12.8828C81.1317 13.6119 84.0547 14.8046 86.7469 16.4194L72.7242 53.7519L63.283 50.1954C63.283 50.1954 78.4165 13.0219 78.0793 12.8828Z"
            fill="#E3E7F5"
          />
          <path
            id="Vector_3"
            d="M110.173 67.8474L25.9874 36.1162C23.3217 35.1114 20.3476 36.4614 19.3446 39.1316L19.3376 39.1503C18.3346 41.8204 19.6826 44.7996 22.3482 45.8043L106.534 77.5355C109.2 78.5403 112.174 77.1902 113.177 74.52L113.184 74.5014C114.187 71.8312 112.839 68.8521 110.173 67.8474Z"
            fill="#CBD3E8"
          />
          <path
            id="mouth"
            d="M72.3276 40.618C70.9981 40.0658 69.8889 39.0875 69.1737 37.8364C69.1351 37.7744 69.1096 37.705 69.0987 37.6327C69.0878 37.5604 69.0917 37.4866 69.1103 37.4159C69.1289 37.3452 69.1616 37.2792 69.2067 37.2216C69.2517 37.1641 69.3082 37.1164 69.3723 37.0814C69.4988 37.0088 69.6487 36.989 69.7897 37.0261C69.9307 37.0633 70.0515 37.1545 70.126 37.2801C70.126 37.439 72.2679 40.9755 75.6794 39.4258C75.7454 39.393 75.8175 39.3743 75.891 39.3707C75.9646 39.3671 76.0381 39.3785 76.107 39.4047C76.1759 39.4308 76.2385 39.4708 76.2913 39.5223C76.344 39.5738 76.3855 39.6356 76.4133 39.7039C76.4428 39.769 76.459 39.8393 76.461 39.9108C76.463 39.9823 76.4508 40.0535 76.4249 40.1201C76.399 40.1868 76.3601 40.2475 76.3104 40.2989C76.2607 40.3502 76.2012 40.3911 76.1356 40.4192C75.547 40.7078 74.9064 40.8742 74.2519 40.9084C73.5975 40.9425 72.9429 40.8439 72.3276 40.618V40.618Z"
            fill="#42210B"
          />
          <g id="eye-2">
            <path
              id="Vector_4"
              d="M55.0321 37.4211C58.7017 37.4211 61.6766 34.4412 61.6766 30.7652C61.6766 27.0893 58.7017 24.1094 55.0321 24.1094C51.3624 24.1094 48.3876 27.0893 48.3876 30.7652C48.3876 34.4412 51.3624 37.4211 55.0321 37.4211Z"
              fill="#F2F2F2"
            />
            <path
              id="Vector_5"
              d="M48.7446 28.4232C49.0439 27.5945 49.5043 26.8334 50.0995 26.1844C50.6947 25.5354 51.4127 25.0112 52.2118 24.6424C53.0108 24.2735 53.875 24.0672 54.7542 24.0356C55.6335 24.0039 56.5101 24.1473 57.3335 24.4578C58.1569 24.7683 58.9107 25.2396 59.5508 25.8441C60.191 26.4487 60.705 27.1743 61.0629 27.9794C61.4208 28.7845 61.6155 29.6529 61.6357 30.534C61.656 31.415 61.5013 32.2914 61.1808 33.1121"
              fill="#51260E"
            />
            <path
              id="Vector_6"
              d="M56.1228 31.1055C55.749 32.0766 55.0098 32.8621 54.064 33.2931C53.1183 33.7241 52.0413 33.7661 51.0649 33.4103C50.0954 33.0358 49.3115 32.2951 48.8813 31.3478C48.451 30.4004 48.409 29.3218 48.7643 28.3438"
              fill="#47220D"
            />
          </g>
          <g id="eye-1">
            <path
              id="Vector_7"
              d="M91.8445 51.3117C95.5142 51.3117 98.489 48.3318 98.489 44.6559C98.489 40.9799 95.5142 38 91.8445 38C88.1749 38 85.2 40.9799 85.2 44.6559C85.2 48.3318 88.1749 51.3117 91.8445 51.3117Z"
              fill="#F2F2F2"
            />
            <path
              id="Vector_8"
              d="M85.5569 42.2935C86.1936 40.6635 87.4457 39.3501 89.042 38.6381C90.6382 37.9261 92.4502 37.8726 94.0855 38.4892C95.7209 39.1058 97.0479 40.343 97.7793 41.9326C98.5107 43.5223 98.5874 45.3364 97.9931 46.9824"
              fill="#51260E"
            />
            <path
              id="Vector_9"
              d="M92.9355 44.9925C92.4806 45.8415 91.7333 46.4961 90.8327 46.8343C89.932 47.1726 88.9393 47.1713 88.0393 46.8312C87.1394 46.491 86.3937 45.835 85.9407 44.985C85.4876 44.1351 85.3583 43.1492 85.5768 42.2109"
              fill="#47220D"
            />
          </g>
        </g>
        <g id="photo">
          <mask
            id="mask1_1452_468"
            maskUnits="userSpaceOnUse"
            x="22"
            y="64"
            width="58"
            height="58"
          >
            <circle
              id="photo-mask"
              cx="51.1957"
              cy="93.1957"
              r="28.5"
              transform="rotate(15.8365 51.1957 93.1957)"
              fill="#fff"
            />
          </mask>
          <g mask="url(#mask1_1452_468)">
            {props.image && !isWaitingThumbnail ? (
              <image
                href={props.image}
                id="photo-content"
                x="34.5473"
                y="56.4766"
                width="57.0812"
                height="57.12"
                transform="rotate(20.65 34.5473 56.4766)"
                preserveAspectRatio="xMidYMid slice"
              />
            ) : (
              <></>
            )}
          </g>
        </g>
      </g>
    </svg>
  )
}

const liquidFlowing = keyframes`
0%{
  height:99.704px;
}
50%{
  height:0px;
  transform: translate(-20px, 99.8px) rotate(20.65deg);
}
100%{
  height:99.704px;
}
`

const eyesRotating = keyframes`
from{
  transform: rotate(0deg);
}
50%{
  transform: rotate(-60deg);
}
from{
  transform: rotate(0deg);
}
`

export const BobaLoader = styled(BobaLoaderSVG)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  #liquid {
    animation: ${liquidFlowing} 2.5s ease-in-out infinite;
  }
  #eye-1,
  #eye-2 {
    animation: ${eyesRotating} 2.5s ease-in-out infinite;
    transform-box: fill-box;
    transform-origin: center;
  }
`
