import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import {
  bobaBackgroundColor,
  CountingRow,
  Divider,
  PrimaryButton,
  useResponsive,
  getMinimumCashoutAmountFromCurrency,
  BobaDisplay,
  PrimaryButtonProps,
  BobaBoardTitleMobile,
  BobaBoardTitleDesktop,
} from '../..'
import { BobaBoardContainer } from '../BobaBoardContainer'
import { GetBobaUserAccountResponse } from '../schema/boba-response.schema'
import messages from './messages'

// type BobaMenuItem = {
//   boba_type: {
//     background_color: string
//     type: {
//       series: BobaSeries
//     }
//   }
//   name: string
//   value: number
//   count: number
// }

export type BalanceBoardProps = {
  balanceBoardData?: GetBobaUserAccountResponse
  cashoutOnClick: () => void
}

const RecentlyReceivedBobaComponent = () => {
  const intl = useIntl()
  return (
    <div style={{ display: 'flex', alignItems: 'center', color: '#794E2E' }}>
      <Item
        className="item"
        style={{ marginRight: '12px' }}
        children={intl.formatMessage(messages.recentlyReceivedBobas)}
      />
      <BobaDisplay
        className="boba-display"
        backgroundColor={bobaBackgroundColor.brownLight}
        series="sunset"
        size={30}
        padding={2}
      />
    </div>
  )
}

export const BalanceBoard = (props: BalanceBoardProps) => {
  const intl = useIntl()
  const { isMobileLayout } = useResponsive()

  if (!props.balanceBoardData) return <></>

  const minimumCashoutAmount = getMinimumCashoutAmountFromCurrency(
    props.balanceBoardData.currency,
  )
  const isReachedMinimumAmount =
    props.balanceBoardData.cashout_available_balance >= minimumCashoutAmount
  const betaTesterPlatformFee =
    props.balanceBoardData.beta_tester_balance -
    props.balanceBoardData.beta_tester_cashout_available_balance
  const isBetaTester = Boolean(props.balanceBoardData.boba_beta_tester)
  const betaTesterBobaCount = props.balanceBoardData.beta_tester_boba_count
  const betaTesterRemainingCount =
    props.balanceBoardData.boba_beta_tester?.discount_boba_count
  const platformFeeAmount =
    props.balanceBoardData.balance -
    props.balanceBoardData.cashout_available_balance -
    betaTesterPlatformFee

  return (
    <BobaBoardContainer>
      <Container className={isMobileLayout ? 'mobile' : 'desktop'}>
        {isMobileLayout ? (
          <BobaBoardTitleMobile children={intl.formatMessage(messages.title)} />
        ) : (
          <BobaBoardTitleDesktop
            children={intl.formatMessage(messages.title)}
          />
        )}
        {props.balanceBoardData ? (
          <>
            <DesktopTotalRow
              className="total"
              children={intl.formatMessage(messages.total)}
            />
            <CountingRow
              className="counting-row recently-received-boba"
              value={props.balanceBoardData.balance}
              subtotalStyle={{ color: '#AD5138', fontSize: '14px' }}
              children={<RecentlyReceivedBobaComponent />}
            />
            <StyledDivider className="divider" />
            <CountingRow
              valuePrefix={
                intl.formatMessage(messages.bobas, {
                  itemCount: props.balanceBoardData.boba_count,
                }) + ' '
              }
              className="counting-row platform-fee"
              subtotalStyle={{ color: '#929292', fontSize: '14px' }}
              value={-platformFeeAmount}
              children={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: '#794E2E',
                  }}
                >
                  <Item
                    className="item"
                    style={{ marginRight: '4px' }}
                    children={intl.formatMessage(messages.platformFee)}
                  />
                  <div className="percentage" style={{ fontSize: '14px' }}>
                    10%
                  </div>
                </div>
              }
            />
            {isBetaTester ? (
              <>
                <StyledDivider className="divider" />
                <CountingRow
                  className="counting-row early-bird-platform-fee"
                  valuePrefix={
                    intl.formatMessage(messages.bobas, {
                      itemCount: betaTesterBobaCount,
                    }) + ' '
                  }
                  subtotalStyle={{ color: '#929292', fontSize: '14px' }}
                  value={-betaTesterPlatformFee}
                  children={
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'start',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          color: '#AD5138',
                        }}
                      >
                        <Item
                          className="item"
                          style={{ marginRight: '4px' }}
                          children={intl.formatMessage(
                            messages.earlyBirdPlatformFee,
                          )}
                        />
                        <div
                          className="percentage"
                          style={{ fontSize: '14px' }}
                        >
                          2%
                        </div>
                      </div>
                      <Remark
                        children={intl.formatMessage(messages.remainCup, {
                          itemCount: betaTesterRemainingCount,
                          bold: (str) => (
                            <span style={{ fontWeight: 600 }}>{str}</span>
                          ),
                        })}
                      />
                    </div>
                  }
                />
              </>
            ) : (
              <></>
            )}
            <StyledDivider className="divider" />
            <CountingRow
              className="counting-row available-cashout-amount"
              style={{ marginBottom: '12px' }}
              subtotalStyle={{
                color: isReachedMinimumAmount ? '#AD5138' : '#929292',
                fontSize: '14px',
              }}
              valuePrefix={props.balanceBoardData.currency.toUpperCase() + ' '}
              value={props.balanceBoardData.cashout_available_balance}
              children={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    color: '#794E2E',
                  }}
                >
                  <Item
                    className="item"
                    children={intl.formatMessage(
                      messages.availableCashoutAmount,
                    )}
                  />
                  {isReachedMinimumAmount ? (
                    <Remark
                      className="remark"
                      children={intl.formatMessage(
                        messages.reachedMinimumAmountMessage,
                      )}
                    />
                  ) : (
                    <></>
                  )}
                  <Remark
                    className="remark"
                    children={
                      isReachedMinimumAmount
                        ? `(${intl.formatMessage(messages.minimumAmount, {
                            currency: props.balanceBoardData.currency.toUpperCase(),
                            amount: minimumCashoutAmount,
                          })})`
                        : `${intl.formatMessage(
                            messages.failedToReachedMinimumAmountMessage,
                            {
                              currency: props.balanceBoardData.currency.toUpperCase(),
                              amount: minimumCashoutAmount,
                            },
                          )}`
                    }
                  />
                </div>
              }
            />
            <StyledPrimaryButton
              className="button"
              onClick={props.cashoutOnClick}
              disabled={!isReachedMinimumAmount}
              style={{ width: 'auto' }}
              content={intl.formatMessage(messages.cashout)}
            />
          </>
        ) : (
          <></>
        )}
      </Container>
    </BobaBoardContainer>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  &.mobile {
    .button {
      align-self: center;
      width: auto;
      font-size: 16px;
    }
  }
  &.desktop {
    .button {
      align-self: end;
    }
  }
  .counting-row.early-bird-platform-fee,
  .counting-row.available-cashout-amount {
    * {
      align-self: start;
    }
  }
`

const StyledPrimaryButton = styled((props: PrimaryButtonProps) => (
  <PrimaryButton {...props} />
))`
  font-size: 14px;
  > span {
    padding: 4px 36px;
  }
`

const BobaMenuItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  margin: 0 12px;
`

const Item = styled.div`
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
`

const StyledDivider = styled(Divider)`
  border-color: #ad5138;
  margin: 12px 0;
`

const Remark = styled.div`
  color: #929292;
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
`

const DesktopTotalRow = styled.div`
  color: #929292;
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: end;
  margin-bottom: 12px;
`
