import { defineMessages } from 'react-intl'

export default defineMessages({
  title: { defaultMessage: 'Current Balance' },
  platformFee: { defaultMessage: 'Platform Fee' },
  earlyBirdPlatformFee: { defaultMessage: 'Early Bird Platform Fee' },
  availableCashoutAmount: { defaultMessage: 'Available cashout amount' },
  cashout: { defaultMessage: 'Cash out' },
  total: { defaultMessage: 'TOTAL' },
  failedToReachedMinimumAmountMessage: {
    defaultMessage: 'The minimum ammount is {currency} {amount}.',
  },
  reachedMinimumAmountMessage: {
    defaultMessage: 'Congratulations, you reached the amount requested!',
  },
  minimumAmount: {
    defaultMessage: 'Minimum: {currency} {amount}',
  },
  platformFeeTooltipMessage: {
    defaultMessage: 'The current platform fee is {percentage}%.',
  },
  recentlyReceivedBobas: {
    defaultMessage: 'Recently Received Bobas',
  },
  bobas: {
    defaultMessage:
      '{itemCount, plural, =0 {0 bobas} =1 {# boba} other {# bobas}}',
  },
  remainCup: {
    defaultMessage:
      '{itemCount, plural, =1 {<bold>#</bold> boba} other {<bold>#</bold> bobas}} count remaining.',
  },
})
