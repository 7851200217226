import { defineMessages } from 'react-intl'

export default defineMessages({
  sunset: { defaultMessage: 'Sunset' },
  matcha: { defaultMessage: 'Matcha' },
  peach: { defaultMessage: 'Peach' },
  twd: { defaultMessage: 'TWD' },
  hkd: { defaultMessage: 'HKD' },
  usd: { defaultMessage: 'USD' },
  price: { defaultMessage: '{currency} {value}' },
})
