import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components/macro'
import { SpacerStyled } from '../Spacer/Spacer'
import { useIntl } from 'react-intl'
import messages from './messages'
import { motion } from 'framer-motion'
import { BobaComponent } from '../BobaComponent'
import { ReactComponent as ChipComponent } from '../../assets/bobame-app-icon-outlined.svg'
import { openInNewTab } from '../..'

const SNACKBIO_GET_STARTED_LINK = 'https://getstarted.anyonelab.com/snack-bio'
const BOBAME_GET_STARTED_LINK = 'https://getstarted.anyonelab.com/bobame'
const DEFAULT_ICON_SIZE = 32

const FooterButton = styled((props) => (
  <motion.div whileTap={{ scale: 0.9 }} {...props} />
))`
  display: flex;
  align-items: center;
  width: fit-content;
  justify-content: center;
  padding: 8px 16px;
  backdrop-filter: blur(20px);
  border-radius: 12px;
  text-decoration: none !important;
  text-shadow: 1px 2px 1px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  cursor: pointer;
  &:hover {
    text-decoration: none !important;
    color: rgb(248, 224, 190);
  }
`

type PoweredByFooterProps = {
  footerType: 'snackbio' | 'bobame'
} & HTMLAttributes<HTMLDivElement>

export function PoweredByFooter({
  footerType = 'snackbio',
}: PoweredByFooterProps) {
  const intl = useIntl()
  return (
    <>
      <SpacerStyled space={5} />
      <FooterButton
        onClick={() =>
          openInNewTab(
            footerType === 'bobame'
              ? BOBAME_GET_STARTED_LINK
              : SNACKBIO_GET_STARTED_LINK,
          )
        }
        style={{
          backgroundColor:
            footerType === 'snackbio' ? '#F0F2FA88' : '#CCA78C80',
          color: footerType === 'snackbio' ? '#8890AA' : 'white',
        }}
      >
        {footerType === 'bobame' ? (
          <BobaComponent
            style={{
              width: DEFAULT_ICON_SIZE,
              height: DEFAULT_ICON_SIZE,
              marginRight: 12,
            }}
          />
        ) : (
          <ChipComponent
            style={{
              width: DEFAULT_ICON_SIZE,
              height: DEFAULT_ICON_SIZE,
              marginRight: 12,
            }}
          />
        )}
        <div
          style={{ textAlign: 'center' }}
          children={intl.formatMessage(messages.callToAction, {
            sth: footerType === 'bobame' ? 'BobaMe' : 'SnackBio',
          })}
        />
      </FooterButton>
    </>
  )
}
