import React, { ReactHTMLElement } from 'react'
import styled from 'styled-components'
import { motion, MotionProps } from 'framer-motion'
import { GradientColorType } from '@anyonelab/common/'

type GradientPresetButtonType = React.HTMLProps<HTMLDivElement> & {
  palette?: GradientColorType[]
  totalPresets: number
  index: number
}

const presetButtonSize = 40
const gradientCenter = 'at 50% 70%'

export const GradientPresetButton = (props: GradientPresetButtonType) => {
  const getRadialGradientValue = (palette: GradientColorType[]) => {
    if (palette.length > 1) {
      return palette
        .reduce((prev, current) => {
          return `${prev} ${current.color} ${current.offset * 100}%, `
        }, '')
        .slice(0, -2)
    } else {
      return `${palette[0].color} 0%, ${palette[0].color} 100%`
    }
  }
  const gradientValue = getRadialGradientValue(
    props.palette as GradientColorType[],
  )
  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{
        opacity: 1,
        transition: { delay: (0.5 / props.totalPresets) * props.index },
      }}
      whileTap={{ scale: 0.8, transition: { delay: 0, duration: 0.1 } }}
      gradientColor={gradientValue}
      {...props}
    />
  )
}

const Container = styled(motion.div)<{ gradientColor: any }>`
  background: radial-gradient(
    ${gradientCenter},
    ${({ gradientColor }) => gradientColor}
  );
  border-radius: 50%;
  width: ${presetButtonSize}px;
  height: ${presetButtonSize}px;
  cursor: pointer;
`
