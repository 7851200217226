import { defineMessages } from 'react-intl'

export default defineMessages({
  title: { defaultMessage: 'Boba Bar Menu' },
  description: {
    defaultMessage: 'Pick the cups and express your special affection.',
  },
  chitChatMessage1: {
    defaultMessage: 'A Boba a day, keeps a smile on your face! ',
  },
})
