import { createGlobalStyle } from 'styled-components/macro'

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    /* min-width: 855px; */
    background-color: #e5e5e5;
    font-size: 16px;
  }

  body {
    font-family: 'Roboto', Arial, sans-serif;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }

  svg {
    vertical-align: initial;
  }
`
