import * as React from 'react'

import { useResponsive } from '../../providers/ResponsiveProvider/ResponsiveContext'

export const MobileOnly = ({ children }: React.PropsWithChildren<{}>) => {
  const { isMobileLayout } = useResponsive()
  return isMobileLayout ? <>{children}</> : null
}

export const DesktopOnly = ({ children }: React.PropsWithChildren<{}>) => {
  const { isMobileLayout } = useResponsive()
  return !isMobileLayout ? <>{children}</> : null
}
