import { Input, Spin } from 'antd'
import React, { HTMLAttributes } from 'react'
import { useIntl } from 'react-intl'
import styled, { useTheme } from 'styled-components/macro'
import {
  BobaDonationDetail,
  useResponsive,
  BobaCurrency,
  ProfilePicture,
} from '../..'
import {
  DONATION_MESSAGE_MAX_LENGTH,
  DONATION_MESSAGE_MIN_LENGTH,
  DONOR_NAME_MAX_LENGTH,
  DONOR_NAME_MIN_LENGTH,
} from '../const'
import { CountingTable } from '../CountingTable'
import { PrimaryButton } from '../PrimaryButton'
import { PrimaryCheckBox } from '../PrimaryCheckBox'
import { PrimaryTextArea } from '../PrimaryTextArea'
import { PrimaryTextAreaOutlined } from '../PrimaryTextAreaOutlined'
import { PrimaryTextInput } from '../PrimaryTextInput'
import { PrimaryTextInputOutlined } from '../PrimaryTextInputOutlined'
import { BobaPaymentRequest } from '../schema/boba-request.schema'
import { GetBobaUserAccountResponse } from '../schema/boba-response.schema'
import messages from './messages'
import { LoadingOutlined } from '@ant-design/icons'
import { ReactComponent as BobaDonatorLoginIcon } from '../../assets/boba-donator-login.svg'

type BillSummaryProps = {
  className?: string
  currency: BobaCurrency
  name?: string
  total?: number
  bobaItems?: BobaDonationDetail[]
  paymentSupporterData: Partial<BobaPaymentRequest>
  setPaymentSupporterData: React.Dispatch<
    React.SetStateAction<Partial<BobaPaymentRequest>>
  >
  supportOnClick?: () => void
  totalDonatedAmount: number
  displayMode: boolean
  loginPopClick?: () => void
  isPublicPageAuthenticated: boolean
  publicPageBobaUser?: GetBobaUserAccountResponse
  isLoggedInCheck?: boolean
} & HTMLAttributes<HTMLDivElement>

export const BillSummary = ({
  paymentSupporterData,
  setPaymentSupporterData,
  totalDonatedAmount,
  displayMode,
  currency,
  loginPopClick, // login pop up click
  isLoggedInCheck, // is finish log in check
  publicPageBobaUser, // is boba user
  isPublicPageAuthenticated, // is logged in user
  ...props
}: BillSummaryProps) => {
  const theme = useTheme()
  const intl = useIntl()
  const { isMobileLayout } = useResponsive()

  React.useEffect(() => {
    if (!publicPageBobaUser?.display_name) return
    setPaymentSupporterData({
      ...paymentSupporterData,
      name: publicPageBobaUser?.display_name,
    })
  }, [publicPageBobaUser?.display_name])

  return (
    <Container
      className={`${isMobileLayout ? 'mobile' : 'desktop'} bill`}
      {...props}
    >
      <Title
        className="title"
        children={intl.formatMessage(messages.buySomeoneABoba, {
          someone: props.name || 'Them',
          bold: (text) => <span style={{ color: '#ad5138' }}>{text}</span>,
        })}
      />
      <ScrollableContainer>
        <CountingTable
          currency={currency}
          sumValue={totalDonatedAmount}
          totalName={'total'}
          bobaItems={props.bobaItems}
        />
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <NameInput
            defaultValue={publicPageBobaUser?.display_name || ''} // if public page is boba user then use the display username
            readOnly={Boolean(publicPageBobaUser?.display_name)} // if public page is boba user then use the display username
            minLength={DONOR_NAME_MIN_LENGTH}
            maxLength={DONOR_NAME_MAX_LENGTH}
            prefix={
              <ProfilePicture
                publicPageBobaUser={publicPageBobaUser}
                style={{
                  width: '24px',
                  height: '24px',
                  marginRight: '12px',
                }}
              />
            }
            placeholder={intl.formatMessage(messages.namePlaceholder)}
            borderColor={theme.bobaColor.primary}
            value={
              publicPageBobaUser?.display_name || paymentSupporterData.name
            }
            onChange={(e) => {
              setPaymentSupporterData({
                ...paymentSupporterData,
                name: e.target.value,
              })
            }}
          />

          {displayMode &&
            !isLoggedInCheck && ( // * is public page and not check log in status yet
              <PublicPageLoginButton>
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 24, color: '#AB5337' }}
                      spin
                    />
                  }
                />
              </PublicPageLoginButton>
            )}
          {displayMode &&
            isLoggedInCheck &&
            !publicPageBobaUser && ( // * is public page and already check log in status and is logged in
              <PublicPageLoginButton onClick={loginPopClick}>
                <BobaDonatorLoginIcon style={{ margin: '4px' }} />
                {isPublicPageAuthenticated // is boba user or not?
                  ? intl.formatMessage(messages.activate)
                  : intl.formatMessage(messages.login)}
              </PublicPageLoginButton>
            )}
        </div>

        <MessageInput
          style={{ resize: 'none' }}
          minLength={DONATION_MESSAGE_MIN_LENGTH}
          maxLength={DONATION_MESSAGE_MAX_LENGTH}
          rows={3}
          placeholder={intl.formatMessage(messages.messagePlaceholder)}
          borderColor={theme.bobaColor.primary}
          value={paymentSupporterData.note}
          onChange={(e) => {
            setPaymentSupporterData({
              ...paymentSupporterData,
              note: e.target.value,
            })
          }}
        />
        <CheckBox
          style={{ marginBottom: '20px' }}
          children={intl.formatMessage(messages.checkbox1Message, {
            br: <br />,
          })}
          checked={!paymentSupporterData.is_public_by_supporter}
          onChange={(e) => {
            setPaymentSupporterData({
              ...paymentSupporterData,
              is_public_by_supporter: !e.target.checked,
            })
          }}
        />
      </ScrollableContainer>
      <SubmitButton
        disabled={!displayMode || totalDonatedAmount === 0}
        onClick={props.supportOnClick}
        content={intl.formatMessage(messages.submit)}
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: stretch;
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  background-color: white;
  border-radius: 36px;
  overflow: hidden;
  &.mobile {
    padding: 20px 20px;
    .title {
      font-size: 16px;
      margin-bottom: 24px;
    }
  }
  &.desktop {
    padding: 40px 60px;
    .title {
      font-size: ${({ theme }) => theme.fontSize[24]};
      margin-bottom: 36px;
    }
  }
`
const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`
const Title = styled.div`
  color: #4a1e11;
  text-align: center;
  font-weight: ${({ theme }) => theme.fontWeight[600]};
`

const NameInput = styled(PrimaryTextInputOutlined)`
  height: 32px;
  margin-bottom: 12px;
  width: calc(100% - 4px);
  .ant-input {
    width: 100%;
  }
`

const MessageInput = styled(PrimaryTextAreaOutlined)`
  height: 90px;
  margin-bottom: 6px;
  width: calc(100% - 4px);
`

const CheckBox = styled(PrimaryCheckBox)`
  font-size: 9px;
  color: #bcb9b9;
`

const SubmitButton = styled(PrimaryButton)`
  width: 100%;
  height: 40px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
`

const PublicPageLoginButton = styled.div`
  margin: 0px 0px 12px 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 13px;

  color: #99a8c6;
`
