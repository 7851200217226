import { CarouselRef } from 'antd/lib/carousel'
import React, { HTMLAttributes } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import {
  BobaBartender,
  Carousel,
  LeftArrow,
  RightArrow,
  useResponsive,
  BobaBartenderAnimated,
} from '../..'
import { BobaMenuItem } from '../BobaMenuItem'
import { ExclamationMarkIcon } from '../ExclamationMarkIcon'
import { BobaCurrency, BobaDonationDetail } from '../type'
import messages from './messages'

type BobaMenuProps = {
  className?: string
  fullName?: string
  currency: BobaCurrency
  items: BobaDonationDetail[]
  updateItems: React.Dispatch<React.SetStateAction<BobaDonationDetail[]>>
} & HTMLAttributes<HTMLDivElement>

const DEFAULT_VERTICAL_PADDING = 40
const DEFAULT_HORIZONTAL_PADDING = 24

export const BobaMenu = (props: BobaMenuProps) => {
  const intl = useIntl()
  const { isMobileLayout } = useResponsive()
  const carouselRef = React.useRef<CarouselRef>(null)
  const updateCount = (index: number, count: number) => {
    props.updateItems((current) => {
      const update = [...current]
      update[index].count = count
      return update
    })
  }

  return (
    <Container
      className={`${props.className} ${isMobileLayout ? 'mobile' : 'desktop'}`}
      isMobileLayout={isMobileLayout}
    >
      <Title className="title" children={intl.formatMessage(messages.title)} />
      {isMobileLayout ? (
        <CarouselMenuItemContainer className="carousel-menu-item-container">
          <LeftArrowContainer
            onClick={() => carouselRef.current?.prev()}
            children={<LeftArrow />}
          />
          <CarouselContainer>
            <Carousel
              style={{ width: '100%' }}
              ref={carouselRef}
              dots={false}
              children={props.items.map(
                ({ name, value, count, boba_type }, index) => {
                  return (
                    <MobileItemsContainer
                      children={
                        <BobaMenuItem
                          style={{
                            width: '50%',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                            boxShadow: '5px 5px 8px rgba(0, 0, 0, 0.25)',
                            marginBottom: '16px',
                          }}
                          updateCount={(count) => updateCount(index, count)}
                          index={index}
                          name={name}
                          value={value}
                          currency={props.currency}
                          count={count}
                          boba_type={boba_type}
                        />
                      }
                    />
                  )
                },
              )}
            />
          </CarouselContainer>
          <RightArrowContainer
            onClick={() => carouselRef.current?.next()}
            children={<RightArrow />}
          />
        </CarouselMenuItemContainer>
      ) : (
        <>
          <Description
            children={intl.formatMessage(messages.description, {
              br: <br />,
              someone: props.fullName || 'them',
              someones: props.fullName ? props.fullName + "'s" : 'their',
            })}
          />
          <DesktopItemsContainer
            children={props.items.map(
              ({ name, value, count, boba_type }, index) => {
                return (
                  <BobaMenuItem
                    updateCount={(count) => updateCount(index, count)}
                    index={index}
                    name={name}
                    value={value}
                    currency={props.currency}
                    count={count}
                    boba_type={boba_type}
                  />
                )
              },
            )}
          />
        </>
      )}
      {isMobileLayout ? (
        <></>
      ) : (
        <ChitChatContainer>
          <ChitChatBubble
            children={intl.formatMessage(messages.chitChatMessage1)}
          />
          <BobaBartenderAnimated />
        </ChitChatContainer>
      )}
    </Container>
  )
}

const Container = styled.div<{ isMobileLayout: boolean }>`
  color: #706966;
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  background-color: #eed7c2;
  box-sizing: border-box;
  padding: ${({ isMobileLayout }) =>
    isMobileLayout
      ? '14px 0px 0px'
      : DEFAULT_VERTICAL_PADDING + 'px ' + DEFAULT_HORIZONTAL_PADDING + 'px'};
  border-radius: 36px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  position: relative;
  min-width: ${({ isMobileLayout }) => (isMobileLayout ? 'unset' : '600px')};
  box-sizing: border-box;
  overflow: hidden;

  &.mobile {
    .title {
      margin-bottom: 8px;
      font-size: 16px;
    }
    .carousel-menu-item-container {
    }
  }

  &.desktop {
  }
`
const DesktopItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 12px;
`

const CarouselMenuItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
`

const CarouselContainer = styled.div`
  width: 100%;
`

const ArrowContainer = styled.div`
  width: 20%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

const LeftArrowContainer = styled(ArrowContainer)`
  border-bottom-left-radius: 36px;
  background: linear-gradient(
    to right,
    rgb(238, 215, 194),
    rgba(238, 215, 194, 0)
  );
  left: 0;
`
const RightArrowContainer = styled(ArrowContainer)`
  border-bottom-right-radius: 36px;
  background: linear-gradient(
    to left,
    rgb(238, 215, 194),
    rgba(238, 215, 194, 0)
  );
  right: 0;
`

const MobileItemsContainer = styled.div`
  margin-top: 50px;
  width: 100%;
`

const Title = styled.div`
  color: #ad5138;
  font-weight: ${({ theme }) => theme.fontWeight[600]};
  font-size: ${({ theme }) => theme.fontSize[28]};
  margin-bottom: 32px;
`

const Description = styled.div`
  font-size: ${({ theme }) => theme.fontSize[14]};
  text-align: center;
  margin-bottom: 30px;
`

const StyledExclamationMarkIcon = styled(ExclamationMarkIcon)`
  position: absolute;
  top: ${DEFAULT_VERTICAL_PADDING}px;
  right: ${DEFAULT_HORIZONTAL_PADDING}px;
`

const ChitChatContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px 0px 0px;
`

const ChitChatBubble = styled.div`
  color: #706966;
  background-color: #f2eadf;
  padding: 12px 36px;
  border-radius: 20px;
  width: 60%;
  position: relative;
  margin-right: 20px;
  &::after {
    content: '';
    width: 0;
    height: 0;
    border-left: 16px solid #f2eadf;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    top: 50%;
    right: -16px;
    position: absolute;
    transform: translateY(-50%);
  }
`
