import {
  CreateBobaUserAccountRequest,
  PatchBobaUserAccountRequest,
} from './schema/boba-request.schema'
import { GetBobaUserAccountPaginatedResponse } from './schema/boba-response.schema'
import { apiClient } from '@anyonelab/frontend/utils/api-client'

export const getBobaUserAccounts = (): Promise<
  GetBobaUserAccountPaginatedResponse
> => {
  try {
    return apiClient
      .get<GetBobaUserAccountPaginatedResponse>('/boba-user-accounts')
      .then((res) => res.data)
  } catch (err) {
    throw err
  }
}

export const createBobaUserAccounts = (data: CreateBobaUserAccountRequest) => {
  try {
    return apiClient.post('/boba-user-accounts', data).then((res) => res.data)
  } catch (err) {
    throw err
  }
}

export const updateBobaUserAccounts = (
  id,
  data: PatchBobaUserAccountRequest,
) => {
  try {
    return apiClient
      .patch(`/boba-user-accounts/${id}`, data)
      .then((res) => res.data)
  } catch (err) {
    throw err
  }
}
