import React from 'react'

type ProfilePicPlaceholderProps = {
  size?: number
  color?: string
} & React.SVGProps<SVGSVGElement>

export const ProfilePicPlaceholder = (props: ProfilePicPlaceholderProps) => {
  const { size = 35, color } = props
  return (
    <svg
      width={size || '121'}
      height={size || '121'}
      viewBox="0 0 121 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Group 4534574">
        <g id="Ellipse 96" filter="url(#filter0_d_1561_446)">
          <circle cx="60.5" cy="59.5" r="56.5" fill="#DEC9AF" />
        </g>
        <g id="coolicon">
          <path
            d="M36.8451 45.9932C36.8451 33.5274 47.1311 23.4219 59.8195 23.4219C72.5079 23.4219 82.7939 33.5274 82.7939 45.9932C82.7939 58.459 72.5079 68.5645 59.8195 68.5645C47.1311 68.5645 36.8451 58.459 36.8451 45.9932ZM59.8195 59.536C67.4326 59.536 73.6042 53.4727 73.6042 45.9932C73.6042 38.5137 67.4326 32.4504 59.8195 32.4504C52.2065 32.4504 46.0349 38.5137 46.0349 45.9932C46.0349 53.4727 52.2065 59.536 59.8195 59.536Z"
            fill={color || '#F3DDC1'}
          />
          <path
            d="M33.827 83.6564C26.9333 90.4291 26.4641 93.5363 23.7412 103.747L32.2502 109.193C32.2502 102.009 35.1549 95.1201 40.3251 90.0405C45.4953 84.961 52.5077 82.1073 59.8195 82.1073C67.1314 82.1073 74.1437 84.961 79.314 90.0405C84.4842 95.1201 87.3888 102.009 87.3888 109.193L95.2171 103.747C93.8557 95.5785 92.7057 90.4291 85.8121 83.6564C78.9184 76.8837 69.5686 73.0788 59.8195 73.0788C50.0704 73.0788 40.7206 76.8837 33.827 83.6564Z"
            fill={color || '#F3DDC1'}
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1561_446"
          x="0"
          y="0"
          width="121"
          height="121"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1561_446"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1561_446"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
