import {
  BobaIcon,
  convertCurrencyToSymbol,
  DEFAULT_BOBA_AVATAR_KEY,
  message,
  useImageUrlChecker,
  useResponsive,
} from '@anyonelab/common/'
import { Message as MessageType } from '@anyonelab/common/boba-components/type'
import { apiClient } from '@anyonelab/frontend/utils/api-client'
import React, { HTMLAttributes } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { DONATION_ID_PREFIX } from '../../const'
import { ReplyMessage } from '../ReplyMessage'
import { ReplyMessageMode } from '../ReplyMessage/type'
import messages from './messages'

// TODO: Add boba icon and image url...

type MessageProps = {
  isReplyable: boolean
  displayMode: boolean
  postComment: (
    data: any,
    callback?: () => void,
    failCallback?: () => void,
  ) => void
  editComment: (
    id: number,
    data: any,
    callback?: () => void,
    failCallback?: () => void,
  ) => void
  removeComment: (
    id: number,
    data: any,
    callback?: () => void,
    failCallback?: () => void,
  ) => void
} & MessageType &
  HTMLAttributes<HTMLDivElement>

export const Message = (props: MessageProps) => {
  const intl = useIntl()
  const { isMobileLayout, responsiveClassName } = useResponsive()
  const {
    isReplyable,
    displayMode,
    postComment,
    editComment,
    removeComment,
    donationId,
    donor_boba_user_account,
    creator_boba_user_account,
    boba_donation_comments,
  } = props
  const [mode, setMode] = React.useState<ReplyMessageMode>(
    boba_donation_comments?.is_active && boba_donation_comments?.message
      ? 'view'
      : 'hide',
  )
  const [existingComment, setExistingComment] = React.useState(
    boba_donation_comments,
  )
  const replyOnClick = () => setMode('create')
  const cancelOnClick = () => {
    existingComment?.is_active && existingComment?.message
      ? setMode('view')
      : setMode('hide')
  }
  const postOnClick = (values: any) => {
    postComment(
      values,
      () => {
        message.success(intl.formatMessage(messages.postSucceeded))
        setMode('view')
      },
      () => {
        message.success(intl.formatMessage(messages.postFailed))
      },
    )
    setExistingComment((current) => {
      return { ...current, is_active: true }
    })
  }
  const editOnClick = (values: any) => {
    if (!boba_donation_comments?.id) return
    editComment(
      boba_donation_comments.id,
      values,
      () => {
        message.success(intl.formatMessage(messages.editSucceeded))
        setMode('view')
      },
      () => {
        message.success(intl.formatMessage(messages.editFailed))
      },
    )
    setExistingComment((current) => {
      return { ...current, is_active: true }
    })
  }
  const removeOnClick = (values: any) => {
    if (!boba_donation_comments?.id) return
    removeComment(
      boba_donation_comments?.id,
      values,
      () => {
        message.success(intl.formatMessage(messages.removeSucceeded))
        setMode('hide')
        setExistingComment((current) => {
          return { ...current, is_active: false }
        })
      },
      () => {
        message.success(intl.formatMessage(messages.removeFailed))
      },
    )
  }

  return (
    <Container>
      <MessageContainer
        id={
          props.donationId
            ? DONATION_ID_PREFIX + props.donationId.toString()
            : undefined
        }
        className={`container ${responsiveClassName}`}
        {...props}
      >
        <IconContainer>
          <BobaIcon
            imageUrl={donor_boba_user_account?.avatar_image_url ?? ''}
            defaultBobaAvatarKey={
              donor_boba_user_account?.default_boba_avatar_key ??
              DEFAULT_BOBA_AVATAR_KEY
            }
            isUsingDefaultBobaAvatar={
              donor_boba_user_account?.is_using_default_boba_avatar ?? true
            }
            size={36}
            style={{ marginRight: '16px' }}
          />
        </IconContainer>
        <MessageContentContainer>
          <MessageContent
            children={
              props.content || intl.formatMessage(messages.emptyMessageReplacer)
            }
          />
          <SenderInfo
            children={
              <>
                <span data-name children={props.name} />
                <span
                  data-info
                  children={`${intl.formatMessage(messages.numberOfCup, {
                    number: props.count,
                  })} ${convertCurrencyToSymbol(props.currency)}${props.value}`}
                />
              </>
            }
          />
        </MessageContentContainer>
        <FunctionKeyContainer
          children={
            !displayMode && isReplyable && mode === 'hide' ? (
              <FunctionKeyButton
                onClick={replyOnClick}
                children={intl.formatMessage(messages.reply)}
              />
            ) : !displayMode &&
              isReplyable &&
              (mode === 'create' || mode === 'edit') ? (
              <FunctionKeyButton
                onClick={cancelOnClick}
                children={intl.formatMessage(messages.cancel)}
              />
            ) : (
              <></>
            )
          }
        />
      </MessageContainer>
      <ReplyMessage
        bobaDonationId={donationId}
        displayMode={displayMode}
        mode={mode}
        profileIcon={creator_boba_user_account?.avatar_image_url}
        isDefaultBobaAvatar={
          creator_boba_user_account?.is_using_default_boba_avatar
        }
        defaultBobaAvatar={creator_boba_user_account?.default_boba_avatar_key}
        changeMode={(mode: ReplyMessageMode) => setMode(mode)}
        postOnClick={postOnClick}
        removeOnClick={removeOnClick}
        editOnClick={editOnClick}
        defaultContent={existingComment?.message}
        commentIsActive={existingComment?.is_active}
        hasReplied={Boolean(existingComment?.id)}
      />
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

const MessageContainer = styled.div`
  display: flex;
  position: relative;
`

const IconContainer = styled.div``

const MessageContentContainer = styled.div`
  border: ${({ theme }) => theme.bobaColor.primary} solid 1px;
  border-radius: 24px;
  padding: 8px 24px 4px;
  flex: 1;
  height: min-content;
  min-height: 44px;
`

const FunctionKeyContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: -16px;
  left: 80px;
  font-size: 8px;
  color: #8b8b8b;
`

const FunctionKeyButton = styled.div`
  cursor: pointer;
`

const MessageContent = styled.div`
  font-weight: 500;
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  font-size: 12px;
  color: ${({ theme }) => theme.bobaColor.primary};
`

const SenderInfo = styled.div`
  color: #8b8b8b;
  font-size: 9px;
  text-align: end;
  [data-name] {
    font-family: 'Inter';
    font-weight: 400;
    margin-right: 9px;
  }
  [data-info] {
    font-family: ${({ theme }) => theme.bobaFontFamily.primary};
    font-weight: 500;
  }
`
