export enum FontFamily {
  Arial = 'arial',
  Arvo = 'arvo',
  Bitter = 'bitter',
  CormorantGaramond = 'cormorant_garamond',
  CrimsonPro = 'crimson_pro',
  Lato = 'lato',
  LibreBaskerville = 'libre_baskerville',
  Lora = 'lora',
  Merriweather = 'merriweather',
  Montserrat = 'montserrat',
  NotoSans = 'noto_sans',
  NotoSerif = 'noto_serif',
  NunitoSans = 'nunito_sans',
  OpenSans = 'open_sans',
  Oswald = 'oswald',
  PlayfairDisplay = 'playfair_display',
  Poppins = 'poppins',
  PtSerif = 'pt_serif',
  Raleway = 'raleway',
  Roboto = 'roboto',
  SourceSansPro = 'source_sans_pro',
  Ubuntu = 'ubuntu',
  思源黑體 = 'noto_sans_tc',
  思源宋體 = 'noto_serif_tc',
}
