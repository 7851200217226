import * as React from 'react'

import styled, { useTheme } from 'styled-components/macro'
import { useIntl } from 'react-intl'
import { useHotkeys } from 'react-hotkeys-hook'
import messages from './messages'
import { usePageBuilder } from '../../context/PageBuilderContext'
import { PageBuilderWidgetConfigs } from '../..'
import {
  Divider,
  isEmptySocialMediaButtonGroup,
  LinkPortalEvent,
  LinkPortalThemeConfig,
  ProfileSplashElementWidgetUpdate,
  Space,
  Spacer,
  track,
  Typography,
} from '@anyonelab/common'
import { CloseOutlined } from '@ant-design/icons'
import { PAGE_BUILDER_CONFIG_PANEL_EMPTY_MIN_HEIGHT } from './styles'
import { rem, transitions } from 'polished'
import { useResponsive } from '../../../../providers/ResponsiveProvider/ResponsiveContext'
import { PageWidgetType } from '@anyonelab/frontend/models/Widget'
import { ConfigPanelBlock } from '../ConfigPanelBlock'
import { LINK_PORTAL_SPLASH_INSTANCE_ID } from '../../../../../../frontend/src/app/pages/LinkTreePage/constants'
import { convertTypeToWidgetId } from '../../../../utils/convertTypeToWidgetId'
import { convertTypeToInstanceId } from '../../../../utils/convertTypeToInstanceId'
import { motion, AnimatePresence } from 'framer-motion'
import { linkBlockAnimation } from '../../../../../../common/src/widgets/styles'
import { delay } from 'redux-saga/effects'

export interface PageBuilderConfigPanelProps {
  widgetConfigs: PageBuilderWidgetConfigs
  onChange(data: any): void
  onWidgetDuplicate(data: any): void
  onWidgetRemove(data: any): void
  onWidgetPromote(data: any): void
  onWidgetDemote(data: any): void
  previewComponent: React.ReactNode
  elementData: PageWidgetType[]
}

export const PageBuilderConfigPanel = ({
  widgetConfigs,
  onChange = () => undefined,
  onWidgetDuplicate = () => undefined,
  onWidgetRemove = () => undefined,
  onWidgetPromote = () => undefined,
  onWidgetDemote = () => undefined,
  previewComponent,
  elementData,
}: React.PropsWithChildren<PageBuilderConfigPanelProps>) => {
  const intl = useIntl()
  const {
    selectWidget,
    activeInstanceId,
    configPanelContent,
    setConfigPanelContent,
  } = usePageBuilder()

  const { isMobileLayout, layout } = useResponsive()
  const [contentHeight, setContentHeight] = React.useState('auto')

  React.useEffect(() => {
    if (!isMobileLayout) return
    const headerHeight = document.querySelector('#config-header-container')
      ?.clientHeight
    const previewHeight = document.querySelector('#config-preview-container')
      ?.clientHeight
    const height = layout.height - (headerHeight || 0) - (previewHeight || 0)
    setContentHeight(height + 'px')
  }, [layout.height])

  useHotkeys('esc', () => unselectWidget())

  const theme = useTheme()

  const unselectWidget = () => {
    selectWidget(null)
  }

  const PageBuilderConfigPanelWidgetHeaderComponent = ({
    title,
    closeOnClick,
  }: React.HTMLProps<HTMLDivElement> & {
    title: string
    closeOnClick?: () => void
  }) => {
    const reformedWidgetTitleLabel = title.split('-')[0]
    return (
      <PageBuilderConfigPanelHeaderContainer id="config-header-container">
        <PageBuilderConfigPanelHeaderTitle
          children={reformedWidgetTitleLabel}
        />
        {closeOnClick ? (
          <PageBuilderConfigPanelHeaderBackButton
            onClick={closeOnClick}
            children={<CloseOutlined />}
          />
        ) : (
          <></>
        )}
      </PageBuilderConfigPanelHeaderContainer>
    )
  }

  const renderConfig = () => {
    // click linkPortal theme config
    if (configPanelContent === 'theme') {
      return (
        <PageBuilderConfigPanelWidgetStyled key={activeInstanceId}>
          <PageBuilderConfigPanelWidgetHeaderComponent
            id="config-header-container"
            title={intl.formatMessage(messages.theme)}
            closeOnClick={() => {
              setConfigPanelContent('')
            }}
          />
          <ProfilePreviewComponentStyled id="config-preview-container">
            {previewComponent}
          </ProfilePreviewComponentStyled>
          <WidgetConfigContainerStyled height={contentHeight}>
            <LinkPortalThemeConfig />
          </WidgetConfigContainerStyled>
        </PageBuilderConfigPanelWidgetStyled>
      )
    }

    // not click anything, initial config page
    if (
      configPanelContent === 'customize' ||
      !activeInstanceId ||
      !widgetConfigs[activeInstanceId]
    ) {
      const animationTime = 0.3
      const numberOfConfig = 2 + elementData.length
      const animationDelay = animationTime / numberOfConfig
      return (
        <>
          <PageBuilderConfigPanelWidgetHeaderComponent
            id="config-header-container"
            title={intl.formatMessage(messages.customize)}
            closeOnClick={
              isMobileLayout
                ? () => {
                    setConfigPanelContent('')
                  }
                : undefined
            }
          />
          <PageBuilderConfigPanelWidgetContent
            height={contentHeight}
            style={{ paddingTop: 0 }}
          >
            <AnimatePresence>
              <motion.div
                variants={linkBlockAnimation}
                initial={'initial'}
                animate={{
                  ...linkBlockAnimation.enter,
                  transition: { delay: animationDelay * 1 },
                }}
                key={'theme-key'}
                exit={'exit'}
                whileTap={'whileTap'}
                style={{ marginBottom: theme.padding[16] }}
              >
                <ConfigPanelBlock
                  title={intl.formatMessage(messages.theme)}
                  onClick={() => {
                    setConfigPanelContent('theme')
                  }}
                  blockType={'theme'}
                />
              </motion.div>
              <motion.div
                variants={linkBlockAnimation}
                initial={'initial'}
                animate={{
                  ...linkBlockAnimation.enter,
                  transition: { delay: animationDelay * 2 },
                }}
                key={'splash-key'}
                exit={'exit'}
                whileTap={'whileTap'}
                style={{ marginBottom: theme.padding[16] }}
              >
                <ConfigPanelBlock
                  title={intl.formatMessage(messages.background)}
                  blockType={'profileSplash'}
                  onClick={() => {
                    setConfigPanelContent('')
                    selectWidget(
                      'PROFILE-SPLASH',
                      LINK_PORTAL_SPLASH_INSTANCE_ID,
                    )
                  }}
                />
              </motion.div>
              {elementData.map((item, index) => {
                const widgetId = convertTypeToWidgetId(item.type)
                const instanceId = `${LINK_PORTAL_SPLASH_INSTANCE_ID}.${convertTypeToInstanceId(
                  item.type,
                )}.${index}`

                let title = intl.formatMessage(messages[item.type])
                switch (item.type) {
                  case 'linkButton': {
                    title =
                      item.data.title || intl.formatMessage(messages.linkButton)
                    break
                  }
                  case 'leadForm': {
                    title =
                      item.data.name || intl.formatMessage(messages.leadForm)
                    break
                  }
                }

                // NOTE: Temporarily unhide isEmptySocialMediaButtonGroup
                // if (isEmptySocialMediaButtonGroup(item)) return <></>
                return (
                  <motion.div
                    layout
                    variants={linkBlockAnimation}
                    initial={'initial'}
                    animate={{
                      ...linkBlockAnimation.enter,
                      transition: { delay: animationDelay * (index + 2) },
                    }}
                    exit={'exit'}
                    key={item.id}
                    style={{ marginBottom: theme.padding[16] }}
                  >
                    <ConfigPanelBlock
                      title={title}
                      blockType={item.type}
                      onClick={() => {
                        setConfigPanelContent('')
                        console.log(widgetId, instanceId)
                        selectWidget(widgetId, instanceId)
                      }}
                      promoteOnClick={() => {
                        onWidgetPromote({
                          instanceId,
                        })
                      }}
                      demoteOnClick={() => {
                        onWidgetDemote({
                          instanceId,
                        })
                      }}
                    />
                  </motion.div>
                )
              })}
            </AnimatePresence>
          </PageBuilderConfigPanelWidgetContent>
        </>
      )
    }

    const widgetConfig = widgetConfigs[activeInstanceId]
    const WidgetConfigComponent = widgetConfig.component

    // have active widget
    return (
      <PageBuilderConfigPanelWidgetStyled key={activeInstanceId}>
        <PageBuilderConfigPanelWidgetHeaderComponent
          title={widgetConfig.instanceLabel}
          closeOnClick={() => {
            track(LinkPortalEvent.configPanelBackClick)
            unselectWidget()
          }}
        />

        <ProfilePreviewComponentStyled id="config-preview-container">
          {previewComponent}
        </ProfilePreviewComponentStyled>

        <WidgetConfigContainerStyled height={contentHeight}>
          <WidgetConfigComponent
            onDuplicate={onWidgetDuplicate}
            onRemove={onWidgetRemove}
            onPromote={onWidgetPromote}
            onDemote={onWidgetDemote}
            instanceId={activeInstanceId}
            data={widgetConfig.data}
            onChange={onChange}
          />
        </WidgetConfigContainerStyled>
      </PageBuilderConfigPanelWidgetStyled>
    )
  }

  return (
    <PageBuilderConfigPanelStyled>
      {renderConfig()}
    </PageBuilderConfigPanelStyled>
  )
}

const PageBuilderConfigPanelWidgetContent = styled.div<{ height: string }>`
  flex: 1;
  padding: ${({ theme }) => theme.padding[16]};
  height: ${({ height }) => height};
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
`

const PageBuilderConfigPanelStyled = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
`

const PageBuilderConfigPanelWidgetStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .widget-label {
    margin: 0;
    font-size: ${({ theme }) => theme.fontSize[24]};
    font-weight: ${({ theme }) => theme.fontWeight[700]};
  }
`

const ProfilePreviewComponentStyled = styled.div`
  width: 100%;
  background-color: #f2f2f2;
`

const WidgetConfigContainerStyled = styled.div<{ height: string }>`
  height: ${({ height }) => height};
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  padding: ${({ theme }) => theme.padding[16]};
`

const PageBuilderConfigPanelHeaderTitle = styled.div`
  font-size: ${({ theme }) => theme.fontSize[24]};
  font-weight: ${({ theme }) => theme.fontWeight[700]};
`

const PageBuilderConfigPanelHeaderBackButton = styled.button`
  cursor: pointer;
  padding: ${({ theme }) => theme.padding[12]};
  line-height: 1;
  background: none;
  border: none;
  outline: none;
`

const PageBuilderConfigPanelHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.padding[16]};
`
