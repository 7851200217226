import { BOBAME_WIDGET_ID } from './../widgets/Bobame/const'
import { LEAD_FORM_WIDGET_ID } from '@anyonelab/common/'
import { WidgetType } from '@anyonelab/frontend/models/Widget'
import { AVATAR_WIDGET_ID } from '../widgets/Avatar/constants'
import { LINK_BLOCK_WIDGET_ID } from '../widgets/LinkBlock/constants'
import { PROFILE_SPLASH_WIDGET_ID } from '../widgets/ProfileSplash/constants'
import { SOCIAL_MEDIA_LINK_BUTTON_WIDGET_ID } from '../widgets/SocialMediaLinkButton/constants'
import { TEXT_WIDGET_ID } from '../widgets/TextWidget/constants'

export const convertWidgetIdToType = (widgetId: string): keyof WidgetType => {
  switch (widgetId) {
    case 'AVATAR_WIDGET_ID':
      return 'avatar'
    case 'TEXT_WIDGET_ID':
      return 'subtitleLabel'
    case 'LINK_BLOCK_WIDGET_ID':
      return 'linkButton'
    case 'PROFILE_SPLASH_WIDGET_ID':
      return 'profileSplash'
    case 'SOCIAL_MEDIA_LINK_BUTTON_WIDGET_ID':
      return 'socialMediaButtonGroup'
    case 'LEAD_FORM_WIDGET_ID':
      return 'leadForm'
    case 'BOBAME_WIDGET_ID':
      return 'bobame'
    default:
      return 'linkButton'
  }
}
