import { Button, ButtonProps } from 'antd'
import React, { HTMLAttributes } from 'react'
import styled from 'styled-components/macro'

export type PrimaryButtonProps = {
  backgroundColor?: string
  color?: string
  content?: string | React.ReactNode
  padding?: string
} & HTMLAttributes<HTMLButtonElement> &
  ButtonProps

export const PrimaryButton = (props: PrimaryButtonProps) => {
  return (
    <StyledButton type="primary" shape="round" {...props}>
      {props.content || 'OK'}
    </StyledButton>
  )
}

const StyledButton = styled(Button)<
  Pick<PrimaryButtonProps, 'backgroundColor' | 'color' | 'padding'>
>`
  width: 100%;
  box-sizing: border-box;
  font-family: '${({ theme }) => theme.bobaFontFamily.primary}';
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSize[24]};
  line-height: 29px;
  height: unset;
  margin: 0;
  padding: 0;
  > span {
    padding: ${({ padding }) => (padding ? padding : '12px 20px')};
  }
  &.ant-btn-primary {
    color: ${({ color }) => color || '#fff'};
    border-color: ${({ theme, backgroundColor }) =>
      backgroundColor || theme.bobaColor.primary};
    background: ${({ theme, backgroundColor }) =>
      backgroundColor || theme.bobaColor.primary};
    text-shadow: unset;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  &.ant-btn-primary:disabled {
    color: #ffffff80;
    border-color: #aeabab;
    background: #aeabab;
    text-shadow: unset;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  &.ant-btn-primary:disabled:hover {
    color: #ffffff80;
    border-color: #929292;
    background: #929292;
    text-shadow: unset;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`
