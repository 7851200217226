import React, { HTMLAttributes } from 'react'
import styled from 'styled-components/macro'
import { LeftArrow } from '../..'

type BobaBackButtonProps = {
  text: string
  color?: string
  hideArrow?: boolean
  onClick: () => void
} & HTMLAttributes<HTMLDivElement>

export const BobaBackButton = (props: BobaBackButtonProps) => {
  const { hideArrow = false } = props
  return (
    <Container {...props}>
      {hideArrow ? <></> : <StyledLeftArrow stroke={props.color} />}
      <div>{props.text}</div>
    </Container>
  )
}

const Container = styled.div`
  color: ${({ color }) => (color ? color : '#706966')};
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  cursor: pointer;
`

const StyledLeftArrow = styled(LeftArrow)`
  margin-right: 20px;
`
