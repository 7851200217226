type ValueOf<T> = T[keyof T]

export interface UserPropertiesInterface {
  email: string
  area_code?: string
}

export const LoginPageEvent = {
  googleLoginClick: 'login_page_google_login_click',
  facebookLoginClick: 'login_page_facebook_login_click',
  googleLoginSuccess: 'login_page_google_login_success',
  facebookLoginSuccess: 'login_page_facebook_login_success',
  googleLoginFail: 'login_page_google_login_fail',
  facebookLoginFail: 'login_page_facebook_login_fail',
} as const

type LoginPageEventValue = ValueOf<typeof LoginPageEvent>

export const HomePageEvent = {} as const

type HomePageEventValue = ValueOf<typeof HomePageEvent>

export const NavBarEvent = {
  dashboardClick: 'nav_bar_dashboard_click',
  linkPortalClick: 'nav_bar_link_portal_click',
  feedbackClick: 'nav_bar_feedback_click',
  changeLanguageClick: 'nav_bar_change_language_click',
  anyonelabZhInstagramLinkClick: 'nav_bar_anyonelab_zh_instagram_link_click',
  anywhereDiscordChannelInvitationLinkClick:
    'nav_bar_anywhere_discord_channel_invitation_link_click',
  logoutClick: 'nav_bar_logout_click',
} as const

type NavBarEventValue = ValueOf<typeof NavBarEvent>

export const LinkPortalEvent = {
  publishLinkClick: 'link_portal_publish_link_click',
  copyLinkClick: 'link_portal_copy_link_click',
  desktopViewClick: 'link_portal_desktop_view_click',
  mobileViewClick: 'link_portal_mobile_view_click',
  previewClick: 'link_portal_preview_click',
  exitPreviewClick: 'link_portal_exit_preview_click',
  unpublishClick: 'link_portal_unpublish_click',
  launchPublishPageModalClick: 'link_portal_launch_publish_page_modal_click',
  usernameIsTakenIsShown: 'link_portal_username_is_taken_is_shown',
  publishPageClick: 'link_portal_publish_page_click',
  cancelPublishClick: 'link_portal_cancel_publish_click',
  customizationTabClick: 'link_portal_customization_tab_click',
  socialMediaLinkButtonTabClick:
    'link_portal_social_media_link_button_tab_click',
  avatarClick: 'link_portal_avatar_click',
  titleClick: 'link_portal_title_click',
  subtitleClick: 'link_portal_subtitle_click',
  linkBlockClick: 'link_portal_link_block_click',
  profileSplashClick: 'link_portal_profile_splash_click',
  addLinkBlockClick: 'link_portal_add_link_block_click',
  addSocialMediaLinkButtonClick:
    'link_portal_add_social_media_link_button_click',
  socialMediaLinkButtonClick: 'link_portal_social_media_link_button_click',
  configPanelBackClick: 'link_portal_config_panel_back_click',
  configPanelCloseDrawerClick: 'link_portal_config_panel_close_drawer_click',
} as const

type LinkPortalEventValue = ValueOf<typeof LinkPortalEvent>

export const AvatarConfigurationEvent = {
  uploadClick: 'avatar_configuration_upload_click',
  imageUpload: 'avatar_configuration_avatar_image_upload',
} as const

type AvatarConfigurationEventValue = ValueOf<typeof AvatarConfigurationEvent>

export const TitleConfigurationEvent = {
  textAlignOnChange: 'title_configuration_text_align_on_change',
  textInputOnChange: 'title_configuration_text_input_on_change',
  fontSizeOnChange: 'title_configuration_font_size_on_change',
  fontFamilyOnChange: 'title_configuration_font_family_on_change',
  fontStyleOnChange: 'title_configuration_font_style_on_change',
  fontWeightOnChange: 'title_configuration_font_weight_on_change',
  fontColorOnChange: 'title_configuration_font_color_on_change',
} as const

type TitleConfigurationEventValue = ValueOf<typeof TitleConfigurationEvent>

export const SubtitleConfigurationEvent = {
  textAlignOnChange: 'subtitle_configuration_text_align_on_change',
  textInputOnChange: 'subtitle_configuration_text_input_on_change',
  fontSizeOnChange: 'subtitle_configuration_font_size_on_change',
  fontFamilyOnChange: 'subtitle_configuration_font_family_on_change',
  fontStyleOnChange: 'subtitle_configuration_font_style_on_change',
  fontWeightOnChange: 'subtitle_configuration_font_weight_on_change',
  fontColorOnChange: 'subtitle_configuration_font_color_on_change',
} as const

type SubtitleConfigurationEventValue = ValueOf<
  typeof SubtitleConfigurationEvent
>

export const LinkBlockConfigurationEvent = {
  externalUrlInputOnChange:
    'link_block_configuration_external_url_input_on_change',
  textInputOnChange: 'link_block_configuration_text_input_on_change',
  buttonTypeOnChange: 'link_block_configuration_button_type_on_change',
  fontFamilyOnChange: 'link_block_configuration_font_family_on_change',
  fontSizeOnChange: 'link_block_configuration_font_size_on_change',
  fontStyleOnChange: 'link_block_configuration_font_style_on_change',
  fontWeightOnChange: 'link_block_configuration_font_weight_on_change',
  fontColorOnChange: 'link_block_configuration_font_color_on_change',
  backgroundColorOnChange:
    'link_block_configuration_background_color_on_change',
  backgroundColorOpacityOnChange:
    'link_block_configuration_background_color_opacity_on_change',
  deleteClick: 'link_block_configuration_delete_click',
} as const

type LinkBlockConfigurationEventValue = ValueOf<
  typeof LinkBlockConfigurationEvent
>

export const SocialMediaLinkButtonConfigurationEvent = {
  facebookInputOnChange:
    'social_media_link_button_configuration_facebook_input_on_change',
  instagramInputOnChange:
    'social_media_link_button_configuration_instagram_input_on_change',
  youtubeInputOnChange:
    'social_media_link_button_configuration_youtube_input_on_change',
  twitterInputOnChange:
    'social_media_link_button_configuration_twitter_input_on_change',
  tiktokInputOnChange:
    'social_media_link_button_configuration_tiktok_input_on_change',
  emailInputOnChange:
    'social_media_link_button_configuration_email_input_on_change',
} as const

type SocialMediaLinkButtonConfigurationEventValue = ValueOf<
  typeof SocialMediaLinkButtonConfigurationEvent
>

export const ProfileSplashConfigurationEvent = {
  backgroundColorOnChange:
    'profile_splash_configuration_background_color_on_change',
  singleColorOnChange: 'profile_splash_configuration_single_color_on_change',
  gradientColorOnChange:
    'profile_splash_configuration_gradient_color_on_change',
}

type ProfileSplashConfigurationEventValue = ValueOf<
  typeof ProfileSplashConfigurationEvent
>

export const PublicPageEvent = {
  pageView: 'public_page_page_view',
  linkBlockClick: 'public_page_link_block_click',
  socialMediaLinkButtonFacebookClick:
    'public_page_social_media_link_button_facebook_click',
  socialMediaLinkButtonInstagramClick:
    'public_page_social_media_link_button_instagram_click',
  socialMediaLinkButtonYoutubeClick:
    'public_page_social_media_link_button_youtube_click',
  socialMediaLinkButtonTwitterClick:
    'public_page_social_media_link_button_twitter_click',
  socialMediaLinkButtonTiktokClick:
    'public_page_social_media_link_button_tiktok_click',
  socialMediaLinkButtonEmailClick:
    'public_page_social_media_link_button_email_click',
  anyoneLabHomePageClick: 'public_page_anyone_lab_home_page_click',
} as const

export interface PublicPageEventProperty {
  username: string
}

type PublicPageEventValue = ValueOf<typeof PublicPageEvent>

export const MarketplaceEvent = {
  openAppPopUpCardClick: 'marketplace_open_app_pop_up_card_click',
  closeAppPopUpCardClick: 'marketplace_close_app_pop_up_card_click',
  openAppletClick: 'marketplace_open_applet_click',
}

type MarketplaceEventValue = ValueOf<typeof MarketplaceEvent>

export const DataInsightEvent = {
  requestUnlock30DaysCardClick:
    'data_insight_request_unlock_30_days_card_click',
}

type DataInsightEventValue = ValueOf<typeof DataInsightEvent>

export const BobaMeOnboardingEvent = {
  landingPageView: 'bobame_onboarding_landing_page_view',
  startBobaBarClick: 'bobame_onboarding_start_boba_bar_click',
  signUpPageView: 'bobame_onboarding_sign_up_page_view',
  submitSignUpClick: 'bobame_onboarding_submit_sign_up_click',
}

type BobaMeOnboardingEventValue = ValueOf<typeof BobaMeOnboardingEvent>

export const BobaMeSettingPageEvent = {
  updateSettingClick: 'bobame_setting_page_update_setting_click',
  repost_click: 'bobame_setting_page_repost_click',
  downloadReceiptClick: 'bobame_setting_page_download_receipt_click',
}

type BobaMeSettingPageEventValue = ValueOf<typeof BobaMeSettingPageEvent>

export const BobaMePublicPageEvent = {
  pageView: 'bobame_public_page_page_view',
  snackBioButtonClick: 'bobame_public_page_snack_bio_button_click',
  shareButtonClick: 'bobame_public_page_share_button_click',
  downloadSharePicClick: 'bobame_public_page_download_share_pic_click',
  copyShareLinkClick: 'bobame_public_page_copy_share_link_click',
  makeOrderClick: 'bobame_public_page_make_order_click',
  confirmPaymentClick: 'bobame_public_page_confirm_payment_click',
  paymentSuccess: 'bobame_public_page_payment_success',
  paymentFailed: 'bobame_public_page_payment_failed',
  downloadReceiptClick: 'bobame_public_page_download_receipt_click',
}

type BobaMePublicPageEventValue = ValueOf<typeof BobaMePublicPageEvent>

export const HTMLEmbedBlockConfigurationEvent = {
  wantMoreEmbedClick: 'html_embed_block_configuration_want_more_embed_click',
}

type HTMLEmbedBlockConfigurationEventValue = ValueOf<
  typeof HTMLEmbedBlockConfigurationEvent
>

export type EventName =
  | LoginPageEventValue
  | HomePageEventValue
  | MarketplaceEventValue
  | NavBarEventValue
  | LinkPortalEventValue
  | AvatarConfigurationEventValue
  | TitleConfigurationEventValue
  | SubtitleConfigurationEventValue
  | LinkBlockConfigurationEventValue
  | SocialMediaLinkButtonConfigurationEventValue
  | PublicPageEventValue
  | ProfileSplashConfigurationEventValue
  | DataInsightEventValue
  | BobaMeOnboardingEventValue
  | BobaMeSettingPageEventValue
  | BobaMePublicPageEventValue
  | HTMLEmbedBlockConfigurationEventValue

export type EventProperty = any
