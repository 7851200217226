export const openEmail = (input: {
  email: string
  subject?: string
  body?: string
}): void => {
  const { email, subject = '', body = '' } = input
  window.open(
    'mailto:' + email + '?cc=' + '&subject=' + subject + '&body=' + body,
    '_blank',
    'noopener,noreferrer',
  )
}
