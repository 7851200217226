import { WithWidgetConfigComponentPropsResult } from '@anyonelab/common'
import { PageWidget } from '@anyonelab/frontend/models/Widget'
import * as React from 'react'
import { PageBuilderWidgetConfig, PageBuilderWidgetConfigs } from '..'

export const PAGE_BUILDER_DEFAULT_CONTEXT_VALUES: PageBuilderContextValues = {
  activeWidget: null,
  hoveredWidgetId: null,
  hoveredInstanceId: null,
  activeWidgetId: null,
  activeInstanceId: null,
  selectWidget: () => undefined,
  hoverWidget: () => undefined,
  registerWidgetConfig: () => undefined,
  updateConfigData: () => undefined,
  isPreviewMode: false,
  previewViewMode: null,
  configPanelContent: '',
  setConfigPanelContent: () => undefined,
  setSelectedLinkPortalTheme: () => undefined,
  onLinkPortalThemeSave: () => undefined,
}

export interface PageBuilderContextValues {
  activeWidget: PageBuilderWidgetConfig | null
  hoveredWidgetId: string | null
  hoveredInstanceId: string | null
  activeWidgetId: string | null
  activeInstanceId: string | null
  selectWidget(widgetId: string, instanceId: string): void
  selectWidget(widgetId: null): void
  hoverWidget(widgetId: string, instanceId: string): void
  hoverWidget(widgetId: null): void
  registerWidgetConfig(data: {
    widgetId: string
    instanceId: string
    instanceLabel: string
    component: React.ComponentType<WithWidgetConfigComponentPropsResult<any>>
  }): void
  updateConfigData(instanceId: string, data: any): void
  isPreviewMode: boolean
  previewViewMode: string | null
  configPanelContent: '' | 'theme' | 'customize'
  setConfigPanelContent(configPanelContent: '' | 'theme' | 'customize'): void
  setSelectedLinkPortalTheme(selectedLinkPortalTheme: PageWidget | null): void
  onLinkPortalThemeSave: () => void
}

export const PageBuilderContext = React.createContext<PageBuilderContextValues>(
  PAGE_BUILDER_DEFAULT_CONTEXT_VALUES,
)

export const usePageBuilder = () => React.useContext(PageBuilderContext)
