import { Input } from 'antd'
import { TextAreaProps } from 'antd/lib/input'
import React from 'react'
import styled from 'styled-components/macro'

export type PrimaryTextAreaProps = {
  borderColor?: string
} & TextAreaProps

export const PrimaryTextArea = (props: PrimaryTextAreaProps) => {
  return <StyledInput {...props} />
}

const StyledInput = styled(Input.TextArea)<{ borderColor?: string }>`
  height: 64px;
  border-radius: 84px;
  justify-content: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  background: #fafafa;
  color: #929292;
  overflow: hidden;
  font-family: 'Roboto';
  font-size: ${({ theme }) => theme.fontSize[28]};
  font-weight: bold;
  &.ant-input:focus,
  &.ant-input:hover,
  &.ant-input-focused {
    border-color: ${({ borderColor }) => borderColor + '80'};
    box-shadow: 0 0 0 2px ${({ borderColor }) => borderColor + '80'};
  }
  > .ant-input {
    width: max-content;
    font-family: 'Roboto';
    font-weight: lighter;
    font-size: ${({ theme }) => theme.fontSize[28]};
    color: #929292;
    background-color: #fafafa;
  }
`
