import React, { HTMLAttributes } from 'react'
import styled from 'styled-components/macro'

// NOTE: The props are not dynamic due to incorrect calculation (was rushing!).
export type ScoopedCornersContainerProps = {
  backgroundColor?: string
  borderColor?: string
  borderWidth?: number
  gap?: number
  padding?: string
} & HTMLAttributes<HTMLDivElement>

export const ScoopedCornersContainer = (
  props: ScoopedCornersContainerProps,
) => {
  return (
    <Container {...props}>
      <OuterContainer
        borderColor={props.borderColor || '#96552C'}
        borderWidth={props.borderWidth || 2}
        gap={props.gap || 8}
      >
        <InnerContainer
          padding={props.padding || '20px 32px'}
          borderColor={props.borderColor || '#96552C'}
          borderWidth={props.borderWidth || 2}
        >
          <>
            {props.children}
            <Circle
              borderColor={props.borderColor || '#96552C'}
              borderWidth={props.borderWidth || 2}
              className="inner top left"
              size={props.gap || 8}
              zIndex={100}
              backgroundColor={props.backgroundColor || '#F2EADF'}
            />
            <Circle
              borderColor={props.borderColor || '#96552C'}
              borderWidth={props.borderWidth || 2}
              className="inner top right"
              size={props.gap || 8}
              zIndex={100}
              backgroundColor={props.backgroundColor || '#F2EADF'}
            />
            <Circle
              borderColor={props.borderColor || '#96552C'}
              borderWidth={props.borderWidth || 2}
              className="inner bottom left"
              size={props.gap || 8}
              zIndex={100}
              backgroundColor={props.backgroundColor || '#F2EADF'}
            />
            <Circle
              borderColor={props.borderColor || '#96552C'}
              borderWidth={props.borderWidth || 2}
              className="inner bottom right"
              size={props.gap || 8}
              zIndex={100}
              backgroundColor={props.backgroundColor || '#F2EADF'}
            />
          </>
        </InnerContainer>
        <Circle
          borderColor={props.borderColor || '#96552C'}
          borderWidth={props.borderWidth || 2}
          className="top left"
          size={props.gap || 8}
          zIndex={200}
          backgroundColor={props.backgroundColor || '#F2EADF'}
        />
        <Circle
          borderColor={props.borderColor || '#96552C'}
          borderWidth={props.borderWidth || 2}
          className="top right"
          size={props.gap || 8}
          zIndex={200}
          backgroundColor={props.backgroundColor || '#F2EADF'}
        />
        <Circle
          borderColor={props.borderColor || '#96552C'}
          borderWidth={props.borderWidth || 2}
          className="bottom left"
          size={props.gap || 8}
          zIndex={200}
          backgroundColor={props.backgroundColor || '#F2EADF'}
        />
        <Circle
          borderColor={props.borderColor || '#96552C'}
          borderWidth={props.borderWidth || 2}
          className="bottom right"
          size={props.gap || 8}
          zIndex={200}
          backgroundColor={props.backgroundColor || '#F2EADF'}
        />
      </OuterContainer>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const Circle = styled.div<{
  size: number
  zIndex: number
  backgroundColor: string
  borderColor: string
  borderWidth: number
}>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  position: absolute;
  box-sizing: border-box;
  border-radius: 50%;
  width: ${({ size }) => size * 2}px;
  height: ${({ size }) => size * 2}px;
  z-index: ${({ zIndex }) => zIndex};

  &.top.left {
    top: -${({ borderWidth }) => borderWidth}px;
    left: -${({ borderWidth }) => borderWidth}px;
    border-radius: 0px 0px 100% 0px;
    border-bottom: ${({ borderColor }) => borderColor}
      ${({ borderWidth }) => borderWidth}px solid;
    border-right: ${({ borderColor }) => borderColor}
      ${({ borderWidth }) => borderWidth}px solid;
  }
  &.top.right {
    top: -${({ borderWidth }) => borderWidth}px;
    right: -${({ borderWidth }) => borderWidth}px;
    border-radius: 0px 0px 0px 100%;
    border-bottom: ${({ borderColor }) => borderColor}
      ${({ borderWidth }) => borderWidth}px solid;
    border-left: ${({ borderColor }) => borderColor}
      ${({ borderWidth }) => borderWidth}px solid;
  }
  &.bottom.left {
    bottom: -${({ borderWidth }) => borderWidth}px;
    left: -${({ borderWidth }) => borderWidth}px;
    border-radius: 0px 100% 0px 0px;
    border-top: ${({ borderColor }) => borderColor}
      ${({ borderWidth }) => borderWidth}px solid;
    border-right: ${({ borderColor }) => borderColor}
      ${({ borderWidth }) => borderWidth}px solid;
  }
  &.bottom.right {
    bottom: -${({ borderWidth }) => borderWidth}px;
    right: -${({ borderWidth }) => borderWidth}px;
    border-radius: 100% 0px 0px 0px;
    border-top: ${({ borderColor }) => borderColor}
      ${({ borderWidth }) => borderWidth}px solid;
    border-left: ${({ borderColor }) => borderColor}
      ${({ borderWidth }) => borderWidth}px solid;
  }
`

const OuterContainer = styled.div<{
  borderColor?: string
  borderWidth?: number
  gap?: number
}>`
  position: relative;
  box-sizing: border-box;
  z-index: 200;
  border: ${({ borderColor }) => borderColor}
    ${({ borderWidth }) => borderWidth}px solid;
  padding: ${({ gap }) => gap}px;
  width: 100%;
  height: 100%;
`

const InnerContainer = styled.div<{
  borderColor?: string
  borderWidth?: number
  padding?: string
}>`
  position: relative;
  box-sizing: border-box;
  z-index: 100;
  border: ${({ borderColor }) => borderColor}
    ${({ borderWidth }) => borderWidth}px solid;
  padding: ${({ padding }) => padding};
  width: 100%;
  height: 100%;
`

// &.top {
//   top: -${({ size }) => size * 2}px;
// }
// &.bottom {
//   bottom: -${({ size }) => size * 2}px;
// }
// &.left {
//   left: -${({ size }) => size * 2}px;
// }
// &.right {
//   right: -${({ size }) => size * 2}px;
// }

// &.inner {
//   &.top {
//     top: -${({ size }) => size * 2.5}px;
//   }
//   &.bottom {
//     bottom: -${({ size }) => size * 2.5}px;
//   }
//   &.left {
//     left: -${({ size }) => size * 2.5}px;
//   }
//   &.right {
//     right: -${({ size }) => size * 2.5}px;
//   }
// }
