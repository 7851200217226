import React, { HTMLAttributes } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import {
  BobaLoaderSVG,
  CloseOutlined,
  PrimaryButton,
  useResponsive,
} from '../..'
import messages from './messages'

type BobaAccountNotFoundRedirectMessageProps = {
  onClose: () => void
  redirectOnClick: () => void
} & HTMLAttributes<HTMLDivElement>

export const BobaAccountNotFoundRedirectMessage = ({
  onClose,
  redirectOnClick,
  ...rest
}: BobaAccountNotFoundRedirectMessageProps) => {
  const intl = useIntl()
  const { isMobileLayout } = useResponsive()
  return (
    <Container
      onClick={(e) => e.stopPropagation()}
      className={isMobileLayout ? 'mobile' : 'desktop'}
    >
      <Title className="title">
        <div
          children={intl.formatMessage(messages.title)}
          style={{ flex: 1 }}
        />
        <CloseOutlined
          onClick={(e: any) => {
            e.stopPropagation()
            onClose()
          }}
        />
      </Title>
      <Content className="content">
        <div children={intl.formatMessage(messages.instruction1)} />
        <div children={intl.formatMessage(messages.instruction2)} />
        <BobaLoaderSVG
          style={{ marginTop: '32px' }}
          size={120}
          liquidHeightPercentage={70}
        />
        <PrimaryButton
          style={{ marginTop: '48px' }}
          content={intl.formatMessage(messages.actionVerb)}
          onClick={() => redirectOnClick()}
        />
      </Content>
      <Remarks
        className="remarks"
        children={intl.formatMessage(messages.remarks)}
      />
    </Container>
  )
}

const Container = styled.div`
  &.mobile {
    min-height: calc(100vh - 64px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .title {
      padding: 24px 36px;
    }
    > div:nth-child(n + 2) {
      margin: 24px;
    }
  }
  &.desktop {
    .title {
      padding: 24px 36px;
    }
    > div:nth-child(n + 2) {
      padding: 24px;
    }
  }
`

const Title = styled.div`
  font-size: 24px;
  font-weight: 700;
  font-family: ${({ theme }) => theme.bobaFontFamily.secondary};
  display: flex;
  align-items: center;
`

const Content = styled.div`
  color: #62463e;
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  > div:first-child {
    font-weight: 700;
    font-size: 24px;
    text-align: center;
  }
  > div:nth-child(2) {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
  }
`
const Remarks = styled.div`
  color: #787878;
  font-size: 12px;
  font-family: ${({ theme }) => theme.bobaFontFamily.secondary}; ;
`
