export const bobaBackgroundColor = {
  brownLight: '#CCA78C',
  green: '#A0ADA6',
  brown: '#C07C62',
}

export const MAX_BOBA_COUNT = 9

export const BAR_THANK_YOU_MESSAGE_MAX_LENGTH = 250
export const BAR_THANK_YOU_MESSAGE_MIN_LENGTH = 0

export const BAR_FULL_NAME_MAX_LENGTH = 30
export const BAR_FULL_NAME_MIN_LENGTH = 1

export const BAR_ABOUT_MAX_LENGTH = 250
export const BAR_ABOUT_MIN_LENGTH = 0

export const BOBA_NAME_MAX_LENGTH = 20
export const BOBA_NAME_MIN_LENGTH = 1

export const DONOR_NAME_MAX_LENGTH = 30
export const DONOR_NAME_MIN_LENGTH = 0

export const DONATION_MESSAGE_MAX_LENGTH = 350
export const DONATION_MESSAGE_MIN_LENGTH = 0

export const CURRENT_PLATFORM_FEE_RATIO = 0.1

export const BOBA_PRICE_OPTIONS = {
  hkd: [25, 50, 100, 200, 500],
  twd: [100, 150, 200, 500, 1500],
  usd: [3, 5, 10, 20, 50],
}
