import React from 'react'
import {
  BobaResponsiveModal,
  BobaResponsiveModalProps,
} from './BobaResponsiveModal'
import EmbedContent from './PopupContentComponent/EmbedContent'

interface BobaEmbedPopupModalProps extends BobaResponsiveModalProps {
  domain: string
}

export function BobaEmbedPopupModal({
  domain,
  ...props
}: BobaEmbedPopupModalProps) {
  return (
    <BobaResponsiveModal closeIconVisible={true} {...props}>
      <EmbedContent domain={domain} />
    </BobaResponsiveModal>
  )
}
