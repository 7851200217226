import { normalize } from 'polished'
import { createGlobalStyle } from 'styled-components/macro'

export const GlobalStyles = createGlobalStyle`
  ${normalize}
  
  body, html {
    margin: 0;
    font-family: 'Lato', sans-serif;
    /* -webkit-font-smoothing: antialiased; */
    -moz-osx-font-smoothing: grayscale;

    background-color: ${(props) => (props.theme as any).colors.background};
    min-height: 100vh;

    color: ${(props) => (props.theme as any).colors.textPrimary};
  }

  * {
    box-sizing: border-box;
  }

  a {
    cursor: pointer;
    color: ${(props) => (props.theme as any).colors.blue};
  }
`
