import { css } from 'styled-components/macro'
import { rem, rgba } from 'polished'
import { LinkButtonType } from './enum'

const rectangleStyle = css`
  border-radius: ${({ theme }) => theme.borderRadius[0]};
`
const roundedStyle = css`
  border-radius: ${({ theme }) => theme.borderRadius[12]};
`
const pillStyle = css`
  border-radius: ${({ theme }) => theme.borderRadius['pill']};
`
const softShadowStyle = css`
  box-shadow: ${rem(0)} ${rem(0)} ${rem(6)}
    ${({ theme }) => theme.boxShadow.defaultShadowColor};
`
const hardShadowStyle = css`
  box-shadow: ${rem(6)} ${rem(6)}
    ${({ theme }) => theme.boxShadow.defaultShadowColor};
`

const fillRoundedStyle = css`
  ${roundedStyle}
`
const fillRectangleStyle = css`
  ${rectangleStyle}
`
const fillPillStyle = css`
  ${pillStyle}
`
const outlineRoundedStyle = css`
  ${roundedStyle}
`
const outlineRectangleStyle = css`
  ${rectangleStyle}
`
const outlinePillStyle = css`
  ${pillStyle}
`
const softShadowRoundedStyle = css`
  ${roundedStyle}
  ${softShadowStyle}
`
const softShadowRectangleStyle = css`
  ${rectangleStyle}
  ${softShadowStyle}
`
const softShadowPillStyle = css`
  ${pillStyle}
  ${softShadowStyle}
`
const hardShadowRectangleStyle = css`
  ${rectangleStyle}
  ${hardShadowStyle}
`
const hardShadowRoundedStyle = css`
  ${roundedStyle}
  ${hardShadowStyle}
`
const hardShadowPillStyle = css`
  ${pillStyle}
  ${hardShadowStyle}
`

export const getButtonTypeCSS = (type: LinkButtonType) => {
  switch (type) {
    case LinkButtonType.FillRectangle:
      return fillRectangleStyle
    case LinkButtonType.FillRounded:
      return fillRoundedStyle
    case LinkButtonType.FillPill:
      return fillPillStyle
    case LinkButtonType.OutlineRectangle:
      return outlineRectangleStyle
    case LinkButtonType.OutlineRounded:
      return outlineRoundedStyle
    case LinkButtonType.OutlinePill:
      return outlinePillStyle
    case LinkButtonType.SoftShadowRectangle:
      return softShadowRectangleStyle
    case LinkButtonType.SoftShadowRounded:
      return softShadowRoundedStyle
    case LinkButtonType.SoftShadowPill:
      return softShadowPillStyle
    case LinkButtonType.HardShadowRounded:
      return hardShadowRoundedStyle
    case LinkButtonType.HardShadowRectangle:
      return hardShadowRectangleStyle
    case LinkButtonType.HardShadowPill:
      return hardShadowPillStyle
    default:
      return fillRoundedStyle
  }
}

export const getButtonColors = (type: LinkButtonType, color: string) => {
  const fillStyle = css`
    background: ${({ theme }) => color || theme.colors.white};
    border: 0;
  `
  const outlineStyle = css`
    background: transparent;
    border: solid ${({ theme }) => color || theme.colors.white}
      ${({ theme }) => theme.borderWidth[4]};
  `

  switch (type) {
    case LinkButtonType.FillRectangle:
    case LinkButtonType.FillRounded:
    case LinkButtonType.FillPill:
    case LinkButtonType.SoftShadowRectangle:
    case LinkButtonType.SoftShadowRounded:
    case LinkButtonType.SoftShadowPill:
    case LinkButtonType.HardShadowRounded:
    case LinkButtonType.HardShadowRectangle:
    case LinkButtonType.HardShadowPill:
      return fillStyle
    case LinkButtonType.OutlineRectangle:
    case LinkButtonType.OutlineRounded:
    case LinkButtonType.OutlinePill:
      return outlineStyle
    default:
      return fillStyle
  }
}
