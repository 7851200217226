import { GetBobaBetaTesterResponsePaginatedResponse } from './schema/boba-response.schema'
import { apiClient } from '@anyonelab/frontend/utils/api-client'
import {
  CreateBobaBetaTesterRequest,
  PatchBobaBetaTesterRequest,
} from './schema/boba-request.schema'

export const getBobaBetaTesters = (): Promise<
  GetBobaBetaTesterResponsePaginatedResponse
> => {
  try {
    return apiClient
      .get<GetBobaBetaTesterResponsePaginatedResponse>('/boba-beta-tester')
      .then((res) => res.data)
  } catch (err) {
    throw err
  }
}

// ! Admin action
export const createBobaBetaTester = (data: CreateBobaBetaTesterRequest) => {
  try {
    return apiClient.post('/boba-beta-testers', data).then((res) => res.data)
  } catch (err) {
    throw err
  }
}

// ! Admin action
export const updateBobaBetaTester = (data: PatchBobaBetaTesterRequest) => {
  try {
    return apiClient.post('/boba-beta-testers', data).then((res) => res.data)
  } catch (err) {
    throw err
  }
}
