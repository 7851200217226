import React from 'react'
import { LoginPopUpModal, message } from '@anyonelab/common'
import { getCookie } from '@anyonelab/public-page/utils/cookies'
import { getUsersList } from '@anyonelab/public-page/api/UsersAPI'
import { User } from '@anyonelab/public-page/models/User'
import { useGetUsers } from '@anyonelab/public-page/api/hooks/Users/useGetUsers'
import { useGetBobaUserAccount } from '@anyonelab/public-page/api/boba-api/hooks/useGetBobaUserAccount'
import { GetBobaUserAccountResponse } from '@anyonelab/public-page/api/boba-api/schema/boba-response.schema'
import {
  apiClient,
  setApiClientAccessToken,
} from '@anyonelab/public-page/api/apiClient'
import { TARGET_ORIGIN } from '@anyonelab/public-page/config'

interface LoginPopContextProps {
  loginModalToggle: () => void
  isLoggedInCheck: boolean
  user?: User | null
  bobaUser?: GetBobaUserAccountResponse
  isAuthenticated: boolean
}

const LoginPopContexet = React.createContext<LoginPopContextProps>({
  loginModalToggle: () => undefined,
  isLoggedInCheck: false,
  user: null,
  isAuthenticated: false,
})

export const useLoginPop = () => React.useContext(LoginPopContexet)

interface LoginPopUpProviderProps {}

function LoginPopUpProvider({
  children,
}: React.PropsWithChildren<LoginPopUpProviderProps>) {
  const [iframeUrl, setIframeUrl] = React.useState('')
  const [isModalVisible, setIsModaVisible] = React.useState(false)
  const modalToggle = () => setIsModaVisible((prev) => !prev)
  const [isLoggedInCheck, setIsLoggedInCheck] = React.useState(false)
  const [isAuthenticated, setIsAuthenticated] = React.useState(false)
  const iframeRef = React.useRef<HTMLIFrameElement | null>(null)

  const {
    data: userList,
    refetch: userRefetch,
    isLoading: isLoadingUser,
  } = useGetUsers()
  const {
    data: bobaUserList,
    refetch: bobaUserRetch,
    isLoading: isLoadingBobaUser,
  } = useGetBobaUserAccount()

  console.log({
    isLoggedInCheck,
    isAuthenticated,
    userList,
    bobaUserList,
    isLoadingUser,
    isLoadingBobaUser,
  })

  function refreshPage() {
    window.location.reload()
  }

  React.useEffect(() => {
    setIframeUrl(TARGET_ORIGIN)
  }, [])

  React.useEffect(() => {
    if (userList && isLoggedInCheck) {
      setIsAuthenticated(true)
    }
  }, [userList])

  React.useEffect(() => {
    if (!isLoggedInCheck) {
      return
    }

    if (userList && isLoggedInCheck) {
      setIsAuthenticated(true)
    } else {
      userRefetch()
      bobaUserRetch()
    }
  }, [isLoggedInCheck])

  React.useEffect(() => {
    ;(window as any).addEventListener('message', receiveMessage, false)
    function receiveMessage(e) {
      try {
        if (isAuthenticated || isLoggedInCheck) return
        if (!(e.origin === TARGET_ORIGIN)) return
        const data = JSON.parse(e.data)

        switch (data.key) {
          case 'LOGIN_SYNC':
            console.log('message type: LOGIN_SYNC')
            if (data.accessToken) {
              // window.localStorage.setItem('JWT_TOKEN', data.accessToken)
              setApiClientAccessToken(apiClient, data.accessToken)
            } else {
              // window.localStorage.removeItem('JWT_TOKEN')
            }
            setIsLoggedInCheck(true)
            break
          case 'BOBA_DONATOR_LOGIN':
            console.log('message type: BOBA_DONATOR_LOGIN')
            if (data.hasBobaAccount) {
              modalToggle()
              refreshPage()
            }
            break
        }
      } catch (err) {
        console.error(err)
      }
    }
  }, [])

  return (
    <LoginPopContexet.Provider
      value={{
        loginModalToggle: modalToggle,
        isLoggedInCheck:
          isLoggedInCheck && !isLoadingUser && !isLoadingBobaUser,
        user: userList?.data[0],
        bobaUser: bobaUserList?.data[0],
        isAuthenticated,
      }}
    >
      <iframe
        src={iframeUrl}
        ref={iframeRef}
        style={{
          display: 'block',
          width: 0,
          height: 0,
          margin: 0,
          padding: 0,
        }}
      ></iframe>
      {children}
      <LoginPopUpModal
        onClose={modalToggle}
        visible={isModalVisible}
        closeIconVisible={false}
        targetOrigin={iframeUrl}
      />
    </LoginPopContexet.Provider>
  )
}

export default LoginPopUpProvider
