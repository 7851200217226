import React from 'react'
import { checkImage } from '.'

export const useImageUrlChecker = (url?: string) => {
  const [thumbnailPingTime, setThumbnailPingTime] = React.useState<number>(0)
  const [isWaitingThumbnail, setIsWaitingThumbnail] = React.useState<boolean>(
    false,
  )

  React.useEffect(() => {
    async function pingTest() {
      try {
        await checkImage(url)
        setIsWaitingThumbnail(false)
      } catch (err) {
        setIsWaitingThumbnail(true)
        setTimeout(() => {
          setThumbnailPingTime((prev) => prev + 1)
        }, 1000)
      }
    }
    if (url) {
      pingTest()
    }
  }, [url, thumbnailPingTime])

  return { isWaitingThumbnail }
}
