import { useQuery, UseQueryResult } from 'react-query'
import { getBobaBetaTesters } from '../BobaBetaTesterAPI'
import {
  getBobaDonations,
  getBobaDonationsWithDonorInfo,
  getBobaDonationWithCommentInfo,
  getBobaDonorDonations,
} from '../BobaDonationAPI'
import { getBobaPayouts } from '../BobaPayoutAPI'
import { getBobaTotalCurrencyAccountBalance } from '../BobaTotalCurrencyAccountBalanceAPI'
import { getBobaUserAccounts } from '../BobaUserAccountAPI'
import {
  GetBobaBetaTesterResponsePaginatedResponse,
  GetBobaDonationWithCommentInfoResponsePaginatedResponse,
  GetBobaDonorDonationResponsePaginatedResponse,
  GetBobaDontaionResponsePaginatedResponse,
  GetBobaPayoutResponsePaginatedResponse,
  GetBobaUserAccountPaginatedResponse,
  GetTotalBobalCurrencyAccountBalanceResponsePaginatedResponse,
} from '../schema/boba-response.schema'

export const useGetBobaUserAccount = (): UseQueryResult<
  GetBobaUserAccountPaginatedResponse,
  Error
> => {
  return useQuery<GetBobaUserAccountPaginatedResponse, Error>(
    'boba-user-accounts',
    getBobaUserAccounts,
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  )
}

export const useGetBobaBetaTester = (): UseQueryResult<
  GetBobaBetaTesterResponsePaginatedResponse,
  Error
> => {
  return useQuery<GetBobaBetaTesterResponsePaginatedResponse, Error>(
    'boba-beta-tester',
    getBobaBetaTesters,
    {
      refetchOnWindowFocus: false,
    },
  )
}

export const useGetBobaDonation = (
  skip: number = 0,
): UseQueryResult<GetBobaDontaionResponsePaginatedResponse, Error> => {
  return useQuery<GetBobaDontaionResponsePaginatedResponse, Error>(
    ['boba-donations', skip],
    () => getBobaDonations(skip),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  )
}

export const useGetBobaDonationWithDonorInfo = (
  skip: number = 0,
): UseQueryResult<GetBobaDontaionResponsePaginatedResponse, Error> => {
  return useQuery<GetBobaDontaionResponsePaginatedResponse, Error>(
    ['boba-donations-with-donor-info', skip],
    () => getBobaDonationsWithDonorInfo(skip),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  )
}

export const useGetDonorBobaDonation = (
  skip: number = 0,
): UseQueryResult<GetBobaDonorDonationResponsePaginatedResponse, Error> => {
  return useQuery<GetBobaDonorDonationResponsePaginatedResponse, Error>(
    ['boba-donor-donations', skip],
    () => getBobaDonorDonations(skip),
    {
      refetchOnWindowFocus: false,
    },
  )
}

export const useGetBobaDonationWithCommentInfo = (
  skip: number = 0,
): UseQueryResult<
  GetBobaDonationWithCommentInfoResponsePaginatedResponse,
  Error
> => {
  return useQuery<
    GetBobaDonationWithCommentInfoResponsePaginatedResponse,
    Error
  >(
    ['boba-donations-with-comment-info', skip],
    () => getBobaDonationWithCommentInfo(skip),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    },
  )
}

export const useGetBobaPayout = (
  skip: number = 0,
): UseQueryResult<GetBobaPayoutResponsePaginatedResponse, Error> => {
  return useQuery<GetBobaPayoutResponsePaginatedResponse, Error>(
    ['boba-payout', skip],
    () => getBobaPayouts(skip),
    {
      refetchOnWindowFocus: false,
    },
  )
}

export const useGetBobaTotalBalance = (): UseQueryResult<
  GetTotalBobalCurrencyAccountBalanceResponsePaginatedResponse,
  Error
> => {
  return useQuery<
    GetTotalBobalCurrencyAccountBalanceResponsePaginatedResponse,
    Error
  >('boba-payout', getBobaTotalCurrencyAccountBalance, {
    refetchOnWindowFocus: false,
  })
}
