import React, { ReactNode, useEffect } from 'react'
import { initAnalytics } from './functions'

export interface AnalyticsContextValue {
  amplitudeInit: boolean
}

export const AnalyticsContext = React.createContext<AnalyticsContextValue>({
  amplitudeInit: false,
})

export function AnalyticsProvider({ children }: { children: ReactNode }) {
  const [amplitudeInit, setAmplitudeInit] = React.useState<boolean>(false)
  useEffect(() => {
    initAnalytics((client) => {
      setAmplitudeInit(true)
    })
  }, [])
  return (
    <AnalyticsContext.Provider value={{ amplitudeInit }}>
      {children}
    </AnalyticsContext.Provider>
  )
}
