import React, { ReactSVGElement } from 'react'

export type BobaCharacterDisplayProps = {
  image?: string
  size?: number
  direction?: 'left' | 'right'
} & React.SVGProps<SVGSVGElement>

export const BobaCharacterDisplay = (props: BobaCharacterDisplayProps) => {
  const DEFAULT_ANGLE = 16
  return (
    <svg
      width={props.size || '165'}
      height={props.size || '165'}
      viewBox="0 0 165 165"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        id="boba-container"
        transform={
          props.direction === 'left'
            ? `rotate(${-DEFAULT_ANGLE * 2})`
            : 'rotate(0)'
        }
        transform-origin="82.5 82.5"
      >
        <g id="boba">
          <path
            id="Vector"
            d="M133.327 73.244C137.449 61.8607 136.925 49.3086 131.869 38.3095C126.814 27.3103 117.633 18.7506 106.319 14.4865C95.0053 10.2224 82.4695 10.5975 71.4297 15.5306C60.3899 20.4636 51.7362 29.5572 47.345 40.8391L133.327 73.244Z"
            fill="#E3E7F5"
          />
          <path
            id="Vector_2"
            d="M46.631 44.3143L25.0513 140.476C24.9189 141.205 25.0446 141.957 25.4067 142.603C25.7689 143.249 26.3447 143.747 27.0347 144.013L80.5873 164.239C81.2961 164.546 82.093 164.579 82.825 164.334C83.557 164.088 84.1728 163.58 84.5542 162.908L131.542 76.322C131.749 75.9255 131.869 75.4889 131.893 75.0419C131.917 74.5949 131.845 74.1478 131.681 73.7313C131.517 73.3147 131.266 72.9384 130.944 72.6277C130.622 72.3171 130.238 72.0794 129.816 71.9309L50.7763 42.1286C50.3626 41.9658 49.9188 41.8942 49.475 41.9188C49.0311 41.9433 48.5977 42.0635 48.2043 42.2709C47.8109 42.4783 47.4669 42.7682 47.1954 43.1208C46.9239 43.4734 46.7314 43.8805 46.631 44.3143V44.3143Z"
            fill="#F2F2F2"
          />
          <path
            id="Vector_3"
            d="M112.189 1.49036L112.17 1.48335C109.996 0.664118 107.571 1.76499 106.754 3.94222L76.4386 84.6464C75.6208 86.8236 76.7198 89.2527 78.8933 90.072L78.9119 90.079C81.0854 90.8982 83.5104 89.7973 84.3282 87.6201L114.643 6.91593C115.461 4.7387 114.362 2.30959 112.189 1.49036Z"
            fill="#CED8ED"
          />
          <path
            id="Vector_4"
            d="M135.173 67.8474L50.9874 36.1162C48.3217 35.1114 45.3476 36.4614 44.3446 39.1316L44.3376 39.1503C43.3346 41.8204 44.6826 44.7996 47.3482 45.8043L131.534 77.5355C134.2 78.5403 137.174 77.1902 138.177 74.52L138.184 74.5014C139.187 71.8312 137.839 68.8521 135.173 67.8474Z"
            fill="#CBD3E8"
          />
          <path
            id="Vector_5"
            d="M130.5 77L84.5939 162.907C84.2125 163.58 83.5967 164.087 82.8647 164.333C82.1326 164.579 81.3358 164.545 80.627 164.239L27.0744 144.013C26.3843 143.747 25.8085 143.248 25.4464 142.602C25.0843 141.957 24.9586 141.205 25.091 140.476L46.5 45L130.5 77Z"
            fill="#F8E0BE"
          />
          <path
            id="Vector_6"
            d="M103.079 12.8828C106.132 13.6119 109.055 14.8046 111.747 16.4194L97.7242 53.7519L88.283 50.1954C88.283 50.1954 103.417 13.0219 103.079 12.8828Z"
            fill="#E3E7F5"
          />
          <path
            id="Vector_7"
            d="M40.8989 132.312C43.6813 132.312 45.9368 130.052 45.9368 127.265C45.9368 124.478 43.6813 122.219 40.8989 122.219C38.1166 122.219 35.861 124.478 35.861 127.265C35.861 130.052 38.1166 132.312 40.8989 132.312Z"
            fill="#D79B7A"
          />
          <path
            id="Vector_8"
            d="M41.0973 115.027C43.8797 115.027 46.1352 112.767 46.1352 109.98C46.1352 107.193 43.8797 104.934 41.0973 104.934C38.3149 104.934 36.0594 107.193 36.0594 109.98C36.0594 112.767 38.3149 115.027 41.0973 115.027Z"
            fill="#D79B7A"
          />
          <path
            id="Vector_9"
            d="M49.2492 149.081C52.0315 149.081 54.2871 146.822 54.2871 144.035C54.2871 141.248 52.0315 138.988 49.2492 138.988C46.4668 138.988 44.2112 141.248 44.2112 144.035C44.2112 146.822 46.4668 149.081 49.2492 149.081Z"
            fill="#D79B7A"
          />
          <path
            id="Vector_10"
            d="M86.974 126.452C89.7564 126.452 92.0119 124.193 92.0119 121.406C92.0119 118.619 89.7564 116.359 86.974 116.359C84.1916 116.359 81.9361 118.619 81.9361 121.406C81.9361 124.193 84.1916 126.452 86.974 126.452Z"
            fill="#D79B7A"
          />
          <path
            id="Vector_11"
            d="M88.0054 145.644C90.7878 145.644 93.0434 143.384 93.0434 140.597C93.0434 137.81 90.7878 135.551 88.0054 135.551C85.2231 135.551 82.9675 137.81 82.9675 140.597C82.9675 143.384 85.2231 145.644 88.0054 145.644Z"
            fill="#D79B7A"
          />
          <path
            id="Vector_12"
            d="M68.429 144.195C71.2113 144.195 73.4669 141.935 73.4669 139.148C73.4669 136.361 71.2113 134.102 68.429 134.102C65.6466 134.102 63.3911 136.361 63.3911 139.148C63.3911 141.935 65.6466 144.195 68.429 144.195Z"
            fill="#D79B7A"
          />
          <path
            id="Vector_13"
            d="M78.1478 158.917C80.9301 158.917 83.1857 156.658 83.1857 153.871C83.1857 151.084 80.9301 148.824 78.1478 148.824C75.3654 148.824 73.1099 151.084 73.1099 153.871C73.1099 156.658 75.3654 158.917 78.1478 158.917Z"
            fill="#D79B7A"
          />
          <path
            id="Vector_14"
            d="M62.5184 128.238C65.3007 128.238 67.5563 125.978 67.5563 123.191C67.5563 120.404 65.3007 118.145 62.5184 118.145C59.736 118.145 57.4805 120.404 57.4805 123.191C57.4805 125.978 59.736 128.238 62.5184 128.238Z"
            fill="#D79B7A"
          />
          <path
            id="Vector_15"
            d="M97.3276 40.618C95.9981 40.0658 94.8889 39.0875 94.1737 37.8364C94.1351 37.7744 94.1096 37.705 94.0987 37.6327C94.0878 37.5604 94.0917 37.4866 94.1103 37.4159C94.1289 37.3452 94.1616 37.2792 94.2067 37.2216C94.2517 37.1641 94.3082 37.1164 94.3723 37.0814C94.4988 37.0088 94.6487 36.989 94.7897 37.0261C94.9307 37.0633 95.0515 37.1545 95.126 37.2801C95.126 37.439 97.2679 40.9755 100.679 39.4258C100.745 39.393 100.817 39.3743 100.891 39.3707C100.965 39.3671 101.038 39.3785 101.107 39.4047C101.176 39.4308 101.239 39.4708 101.291 39.5223C101.344 39.5738 101.386 39.6356 101.413 39.7039C101.443 39.769 101.459 39.8393 101.461 39.9108C101.463 39.9823 101.451 40.0535 101.425 40.1201C101.399 40.1868 101.36 40.2475 101.31 40.2989C101.261 40.3502 101.201 40.3911 101.136 40.4192C100.547 40.7078 99.9064 40.8742 99.2519 40.9084C98.5975 40.9425 97.9429 40.8439 97.3276 40.618V40.618Z"
            fill="#42210B"
          />
          <path
            id="Vector_16"
            d="M80.0321 37.4211C83.7017 37.4211 86.6766 34.4412 86.6766 30.7652C86.6766 27.0893 83.7017 24.1094 80.0321 24.1094C76.3624 24.1094 73.3876 27.0893 73.3876 30.7652C73.3876 34.4412 76.3624 37.4211 80.0321 37.4211Z"
            fill="#F2F2F2"
          />
          <path
            id="Vector_17"
            d="M73.7446 28.4193C74.0439 27.5905 74.5043 26.8295 75.0995 26.1805C75.6947 25.5315 76.4127 25.0073 77.2118 24.6385C78.0108 24.2696 78.875 24.0633 79.7542 24.0316C80.6335 24 81.5101 24.1434 82.3335 24.4539C83.1569 24.7643 83.9107 25.2357 84.5508 25.8402C85.191 26.4447 85.705 27.1704 86.0629 27.9755C86.4208 28.7805 86.6155 29.649 86.6357 30.5301C86.656 31.4111 86.5013 32.2874 86.1808 33.1082"
            fill="#51260E"
          />
          <path
            id="Vector_18"
            d="M81.1228 31.1016C80.749 32.0727 80.0098 32.8582 79.064 33.2892C78.1183 33.7202 77.0413 33.7622 76.0649 33.4063C75.0954 33.0319 74.3115 32.2912 73.8813 31.3438C73.451 30.3965 73.409 29.3179 73.7643 28.3398"
            fill="#47220D"
          />
          <path
            id="Vector_19"
            d="M116.845 51.3117C120.514 51.3117 123.489 48.3318 123.489 44.6559C123.489 40.9799 120.514 38 116.845 38C113.175 38 110.2 40.9799 110.2 44.6559C110.2 48.3318 113.175 51.3117 116.845 51.3117Z"
            fill="#F2F2F2"
          />
          <path
            id="Vector_20"
            d="M110.557 42.2896C111.194 40.6596 112.446 39.3462 114.042 38.6342C115.638 37.9221 117.45 37.8687 119.086 38.4853C120.721 39.1018 122.048 40.3391 122.779 41.9287C123.511 43.5184 123.587 45.3325 122.993 46.9785"
            fill="#51260E"
          />
          <path
            id="Vector_21"
            d="M117.935 44.9925C117.481 45.8415 116.733 46.4961 115.833 46.8343C114.932 47.1726 113.939 47.1713 113.039 46.8312C112.139 46.491 111.394 45.835 110.941 44.985C110.488 44.1351 110.358 43.1492 110.577 42.2109"
            fill="#47220D"
          />
        </g>
        <g id="boba-label">
          <mask
            id="mask-1"
            maskUnits="userSpaceOnUse"
            x="48"
            y="65"
            width="57"
            height="57"
          >
            <circle
              id="circle-mask"
              cx="76.5"
              cy="93.5"
              r="28.5"
              fill="white"
            />
          </mask>
          <g mask="url(#mask-1)">
            {props.image ? (
              <image
                href={props.image}
                x="48"
                y="65"
                height="57"
                width="57"
                transform={`rotate(${DEFAULT_ANGLE})`}
                transform-origin="76.5 93.5"
                preserveAspectRatio="xMidYMid slice"
              />
            ) : (
              <></>
            )}
          </g>
        </g>
      </g>
    </svg>
  )
}
