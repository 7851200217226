import { defineMessages } from 'react-intl'

export default defineMessages({
  text: {
    defaultMessage: 'Text',
  },
  fontSize: {
    defaultMessage: 'Font Size',
  },
  font: {
    defaultMessage: 'Font',
  },
  fontStyle: {
    defaultMessage: 'Font Style',
  },
  fontWeight: {
    defaultMessage: 'Font Weight',
  },
  color: {
    defaultMessage: 'Color',
  },
  '38pt': {
    defaultMessage: '38pt',
  },
  '30pt': {
    defaultMessage: '30pt',
  },
  '24pt': {
    defaultMessage: '24pt',
  },
  '20pt': {
    defaultMessage: '20pt',
  },
  '16pt': {
    defaultMessage: '16pt',
  },
  normal: {
    defaultMessage: 'Normal',
  },
  italic: {
    defaultMessage: 'Italic',
  },
  bold: {
    defaultMessage: 'Bold',
  },
  light: {
    defaultMessage: 'Light',
  },
})
