import * as React from 'react'

import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import messages from './messages'
import { Avatar, Form, FormProps, Input, message, Select, Upload } from 'antd'
import { AvatarWidgetConfigSchema } from '../types'
import { AvatarWidgetConfigDefaults, AVATAR_WIDGET_ID } from '../constants'
import {
  withWidgetConfig,
  Spin,
  useWidget,
  usePageBuilder,
  useImageUrlChecker,
} from '@anyonelab/common'
import { UserOutlined } from '@ant-design/icons'
import { BASE_API_URL } from '../../../../../public-page/src/config/index'
import { apiClient } from '../../../../../frontend/src/utils/api-client'
import { AvatarWidget } from '@anyonelab/frontend/models/Widget'

export interface AvatarWidgetConfigProps {
  data: AvatarWidget
}

export const AvatarWidgetConfig = withWidgetConfig<AvatarWidgetConfigProps>(
  {
    widgetId: AVATAR_WIDGET_ID,
  },
  ({ data = AvatarWidgetConfigDefaults, onChange }) => {
    const intl = useIntl()

    const { isWaitingThumbnail } = useImageUrlChecker(data.imageUrl)

    const [isUploading, setIsUploading] = React.useState<boolean>(false)

    const [form] = Form.useForm<AvatarWidget>()

    const initialValues: AvatarWidget = {
      ...data,
    }

    const onValuesChange: FormProps<AvatarWidget>['onValuesChange'] = (
      _,
      formValues,
    ) => {
      onChange(formValues)
    }

    const beforeUpload = () => {
      setIsUploading(true)
    }

    const customUploadAction = async (e) => {
      const formData = new FormData()

      formData.append(e.filename, e.file)

      try {
        const response = await apiClient.post(
          `${BASE_API_URL}/image-upload?type=avatar`,
          formData,
        )
        const result = response.data
        onChange({
          imageUrl: result.image_name,
        })
        setIsUploading(false)
      } catch (err) {
        setIsUploading(false)
        if (err.response?.data?.message === 'File too large') {
          message.error(
            intl.formatMessage(messages.pleaseMakeSureYourImageIsUnder3Mb),
          )
        } else {
          if (
            err.response?.data?.message ===
            'We only support the following image types: jpeg, jpg, png, gif.'
          ) {
            message.error(
              intl.formatMessage(messages.weOnlySupportImageTypeMessage),
            )
          } else {
            message.error(intl.formatMessage(messages.uploadFail))
          }
        }
      }
    }

    return (
      <AvatarConfigStyled>
        <Form
          form={form}
          initialValues={initialValues}
          onValuesChange={onValuesChange}
          layout="vertical"
        >
          <Form.Item name="imageUrl" hidden>
            <Input />
          </Form.Item>
          <Form.Item label={intl.formatMessage(messages.imageSource)}>
            <Upload
              accept="image/png, image/gif, image/jpeg"
              name="uri"
              listType="picture-card"
              showUploadList={false}
              beforeUpload={beforeUpload}
              customRequest={customUploadAction}
            >
              {data.imageUrl && !isUploading ? (
                <Avatar
                  src={
                    isWaitingThumbnail
                      ? data.imageUrl.replace('thumbnail', 'original')
                      : data.imageUrl
                  }
                  size={100}
                  icon={<UserOutlined />}
                  style={{ backgroundColor: 'white' }}
                />
              ) : (
                <Avatar
                  size={64}
                  icon={isUploading ? <Spin /> : <UserOutlined />}
                  style={{ backgroundColor: 'white' }}
                />
              )}
            </Upload>
          </Form.Item>
        </Form>
      </AvatarConfigStyled>
    )
  },
)
const AvatarConfigStyled = styled.div``
