import React from 'react'

type BarCodeProps = {
  strokeColor?: string
} & React.SVGProps<SVGSVGElement>

export const BarCode = (props: BarCodeProps) => {
  return (
    <svg
      width="267"
      height="44"
      viewBox="0 0 267 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame" clip-path="url(#clip0_1040_2384)">
        <g id="Group 4534692">
          <path
            id="Vector"
            d="M23.7603 0V43.8298H14.4062V0H23.7603Z"
            fill={props.strokeColor || '#CCA78C'}
          />
          <path
            id="Vector_2"
            d="M64.0567 0V43.8298H54.7031V0H64.0567Z"
            fill={props.strokeColor || '#CCA78C'}
          />
          <path
            id="Vector_3"
            d="M82.7637 0V43.8298H73.4102V0H82.7637Z"
            fill={props.strokeColor || '#CCA78C'}
          />
          <path
            id="Vector_4"
            d="M95.7298 0V43.8298H86.3555V0H95.7298Z"
            fill={props.strokeColor || '#CCA78C'}
          />
          <path
            id="Vector_5"
            d="M141.749 0V43.8298H132.395V0H141.749Z"
            fill={props.strokeColor || '#CCA78C'}
          />
          <path
            id="Vector_6"
            d="M167.679 0V43.8298H158.325V0H167.679Z"
            fill={props.strokeColor || '#CCA78C'}
          />
          <path
            id="Vector_7"
            d="M228.145 0V43.8298H218.852V0H228.145Z"
            fill={props.strokeColor || '#CCA78C'}
          />
          <path
            id="Vector_8"
            d="M246.852 0V43.8298H237.498V0H246.852Z"
            fill={props.strokeColor || '#CCA78C'}
          />
          <path
            id="Vector_9"
            d="M259.797 0V43.8298H250.443V0H259.797Z"
            fill={props.strokeColor || '#CCA78C'}
          />
          <path
            id="Vector_10"
            d="M3.61173 0V43.8662L0 43.9757V0H3.61173Z"
            fill={props.strokeColor || '#CCA78C'}
          />
          <path
            id="Vector_11"
            d="M10.7945 0V43.8419L7.20312 44V0H10.7945Z"
            fill={props.strokeColor || '#CCA78C'}
          />
          <path
            id="Vector_12"
            d="M30.9425 0V43.8419L27.3516 44V0H30.9425Z"
            fill={props.strokeColor || '#CCA78C'}
          />
          <path
            id="Vector_13"
            d="M43.9086 0V43.8419L40.2969 44V0H43.9086Z"
            fill={props.strokeColor || '#CCA78C'}
          />
          <path
            id="Vector_14"
            d="M51.1117 0V43.8419L47.5 44V0H51.1117Z"
            fill={props.strokeColor || '#CCA78C'}
          />
          <path
            id="Vector_15"
            d="M102.913 0V43.8419L99.3213 44V0H102.913Z"
            fill={props.strokeColor || '#CCA78C'}
          />
          <path
            id="Vector_16"
            d="M115.877 0V43.8419L112.266 44V0H115.877Z"
            fill={props.strokeColor || '#CCA78C'}
          />
          <path
            id="Vector_17"
            d="M123.06 0V43.8419L119.469 44V0H123.06Z"
            fill={props.strokeColor || '#CCA78C'}
          />
          <path
            id="Vector_18"
            d="M148.971 0V43.8419L145.38 44V0H148.971Z"
            fill={props.strokeColor || '#CCA78C'}
          />
          <path
            id="Vector_19"
            d="M180.645 0V43.8419L177.033 44V0H180.645Z"
            fill={props.strokeColor || '#CCA78C'}
          />
          <path
            id="Vector_20"
            d="M187.828 0V43.8419L184.236 44V0H187.828Z"
            fill={props.strokeColor || '#CCA78C'}
          />
          <path
            id="Vector_21"
            d="M195.031 0V43.8419L191.439 44V0H195.031Z"
            fill={props.strokeColor || '#CCA78C'}
          />
          <path
            id="Vector_22"
            d="M207.996 0V43.8419L204.385 44V0H207.996Z"
            fill={props.strokeColor || '#CCA78C'}
          />
          <path
            id="Vector_23"
            d="M215.178 0V43.8419L211.587 44V0H215.178Z"
            fill={props.strokeColor || '#CCA78C'}
          />
          <path
            id="Vector_24"
            d="M267 0V43.8419L263.408 44V0H267Z"
            fill={props.strokeColor || '#CCA78C'}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1040_2384">
          <rect width="267" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
