import * as React from 'react'
import styled, { css, keyframes } from 'styled-components/macro'
import { Helmet } from 'react-helmet-async'
import { AnimatePresence, motion } from 'framer-motion'
import { LogoStyled } from '@anyonelab/common'

const GET_STARTED_URL = 'https://getstarted.anyonelab.com/'
const DEFAULT_ANIMATION_DURATION = 0.5
const DEFAULT_ANIMATIONS = {
  fadeIn: { opacity: [0, 1] },
  slideIn: { translateY: [40, 0] },
}

export const NotFoundPage = ({ username, ...props }) => {
  return (
    <>
      <AnimatePresence>
        <Wrapper
          animate={['fadeIn', 'slideIn']}
          variants={DEFAULT_ANIMATIONS}
          transition={{ duration: DEFAULT_ANIMATION_DURATION }}
        >
          <MessageWrapper>
            <Title>@{username}</Title>
            <Title>&nbsp;&nbsp;&nbsp;does not exist yet&nbsp;😢</Title>
          </MessageWrapper>
          <LogoWrapper
            animate={['fadeIn']}
            variants={DEFAULT_ANIMATIONS}
            transition={{
              duration: DEFAULT_ANIMATION_DURATION,
              delay: DEFAULT_ANIMATION_DURATION,
            }}
            onClick={(event) => {
              event.preventDefault()
              window.location.href = GET_STARTED_URL
            }}
          >
            <Logo />
          </LogoWrapper>
        </Wrapper>
      </AnimatePresence>
    </>
  )
}

// TODO: Global theme CSS could not be implemented at the moment, will refactor in cool down period.

const centeringCSS = css`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Wrapper = styled(motion.div)`
  height: 100vh;
  display: flex;
  min-height: 320px;
  padding-top: 30vh;
  padding-bottom: 10vh;
  background: white;
  ${centeringCSS}
  justify-content: space-between;
`

const MessageWrapper = styled(motion.div)`
  ${centeringCSS}
`

const Title = styled.div`
  font-weight: bold;
  text-align: center;
  box-sizing: border-box;
  padding: 0 1rem;
  color: black;
  font-size: 1.5rem;
`

const LogoWrapper = styled(motion.div)`
  ${centeringCSS}
`

const Logo = styled(LogoStyled)`
  width: 35%;
  cursor: pointer;
`
