import { defineMessages } from 'react-intl'

export default defineMessages({
  email: {
    defaultMessage: 'Email',
  },
  facebook: {
    defaultMessage: 'Facebook',
  },
  instagram: {
    defaultMessage: 'Instagram',
  },
  tiktok: {
    defaultMessage: 'TikTok',
  },
  twitter: {
    defaultMessage: 'Twitter',
  },
  youtube: {
    defaultMessage: 'YouTube',
  },
  handle: {
    defaultMessage: 'handle',
  },
  color: {
    defaultMessage: 'Color',
  },
})
