import React, { HTMLAttributes } from 'react'
import styled from 'styled-components/macro'
import { PrimaryButton } from '../../PrimaryButton'
import { SocialGroupComponent } from '../../SocialGroupComponent'
import {
  DownloadIcon,
  useResponsive,
  BobaDonationReceipt,
  captureElement,
  BobaDonationDetail,
  BobaCurrency,
  AnalyticsContext,
  BobaMePublicPageEvent,
  track,
} from '@anyonelab/common'
import { useIntl } from 'react-intl'
import messages from '../messages'

type PaymentSucceedNotificationProps = {
  name: string
  note: string
  currency: BobaCurrency
  fullName: string
  boba_donation_details: BobaDonationDetail[]
  is_public_by_supporter: boolean
  donated_amount: number
} & HTMLAttributes<HTMLDivElement>

function PaymentSucceedNotification(props: PaymentSucceedNotificationProps) {
  const intl = useIntl()
  const { isMobileLayout } = useResponsive()

  const { amplitudeInit } = React.useContext(AnalyticsContext)

  const downloadOnClick = () => {
    const ELEMENT_SELECTOR = '.payment-receipt-container>.receipt'
    const ANIMATED_RECEIPT_IMAGE_ELEMENT_SELECTOR =
      '.payment-receipt-container .animated-receipt-image'
    const STATIC_RECEIPT_IMAGE_ELEMENT_SELECTOR =
      '.payment-receipt-container .static-receipt-image'

    const element = document.querySelector(ELEMENT_SELECTOR) as HTMLElement
    const animatedReceiptImageElement = document.querySelector(
      ANIMATED_RECEIPT_IMAGE_ELEMENT_SELECTOR,
    ) as HTMLElement
    const staticReceiptImageElement = document.querySelector(
      STATIC_RECEIPT_IMAGE_ELEMENT_SELECTOR,
    ) as HTMLElement

    if (!element) return
    element.classList.remove('shadow')
    animatedReceiptImageElement.classList.add('hide')
    staticReceiptImageElement.classList.remove('hide')
    captureElement(element, undefined, () => {
      element.classList.add('shadow')
      animatedReceiptImageElement.classList.remove('hide')
      staticReceiptImageElement.classList.add('hide')
      amplitudeInit && track(BobaMePublicPageEvent.downloadReceiptClick, {})
    })
  }

  return (
    <Container
      className={`${
        isMobileLayout ? 'mobile' : 'desktop'
      } payment-receipt-container`}
    >
      <Title
        className="title"
        children={intl.formatMessage(messages.title, {
          br: <br />,
          creator: props.fullName,
        })}
      />
      <Subtitle
        className="subtitle"
        children={intl.formatMessage(messages.subtitle, { br: <br /> })}
      />
      <BobaDonationReceipt
        className="receipt shadow"
        boba_donation_details={props.boba_donation_details}
        donated_amount={props.donated_amount}
        is_public_by_supporter={props.is_public_by_supporter}
        donor_name={props.name}
        donor_note={props.note}
        currency={props.currency}
      />
      <StyledPrimaryButton
        className="button"
        onClick={downloadOnClick}
        content={
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: isMobileLayout ? '12px 24px' : '14px 30px',
            }}
          >
            <DownloadIcon
              style={{
                marginRight: '12px',
              }}
              filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
            />
            {intl.formatMessage(messages.downloadPicture)}
          </div>
        }
      />
      {/* NOTE : No need for social group component for current sprint */}
      {/* <DesktopSocialGroupComponent /> */}
    </Container>
  )
}

export default PaymentSucceedNotification

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  &.mobile {
    .receipt {
      transform: scale(0.8);
      margin: -72px 0 -36px;
    }
    .button {
      width: auto;
    }
  }
  &.desktop {
    .receipt {
      margin-bottom: 24px;
    }
    .button {
      width: 100%;
    }
  }
`

const Title = styled.div`
  font-family: '${({ theme }) => theme.bobaFontFamily.title}';
  font-weight: 700;
  font-size: 28px;
  color: ${({ theme }) => theme.bobaColor.textPrimary};
  line-height: 34px;
`

const Subtitle = styled.div`
  font-family: 'Roboto';
  font-size: ${({ theme }) => theme.fontSize[20]};
  color: #929292;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 30px 0px;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  width: auto;
`
