import { createSlice } from '@anyonelab/frontend/utils/@reduxjs/toolkit'
import {
  useInjectReducer,
  useInjectSaga,
} from '@anyonelab/frontend/utils/redux-injectors'
import { linkPortalSaga } from './saga'
import { PayloadAction } from '@reduxjs/toolkit'
import { LinkPortalState } from './types'
import {
  BobameWidgetConfigDefaults,
  LeadFormWidgetConfigDefaults,
  LinkBlockWidgetConfigDefaults,
  ProfileSplashWidgetConfigDefaults,
  WidgetConfigDefaults,
} from './defaultWidget'
import {
  IWidget,
  PageWidget,
  ProfileSplashWidget,
  WidgetType,
} from '@anyonelab/frontend/models/Widget'
import { extractLastButton } from './utils'
import { uuidv4 } from '@anyonelab/frontend/utils/uuid'
import { DefaultLinkPortalWidgetObject } from '../constants'

export const initialState: LinkPortalState = {
  savedState: true,
  id: null,
  url: null,
  isPublished: false,
  profileSplash: ProfileSplashWidgetConfigDefaults,
  widgetList: WidgetConfigDefaults,
}

export const initialSavedState = {
  savedState: false,
}

const slice = createSlice({
  name: 'linkPortal',
  initialState,
  reducers: {
    updateState(state, action: PayloadAction<Partial<LinkPortalState>>) {
      return {
        ...state,
        ...action.payload,
      }
    },
    updateWidgetState<T extends keyof WidgetType>(
      state,
      action: PayloadAction<{ index: number; data: WidgetType[T] }>,
    ) {
      const { index, data } = action.payload
      state.widgetList[index].data = data
    },
    updateProfileSplash(state, action: PayloadAction<ProfileSplashWidget>) {
      state.profileSplash = {
        ...action.payload,
      }
    },
    addWidget<T extends keyof WidgetType>(
      state,
      action: PayloadAction<{ data: Pick<IWidget<T>, 'data' | 'type'> }>,
    ) {
      const { data, type } = action.payload.data
      state.widgetList.push({
        type,
        data,
        id: uuidv4(),
      })
    },
    addLeadForm(state) {
      state.widgetList.push({
        type: 'leadForm',
        data: LeadFormWidgetConfigDefaults,
        id: uuidv4(),
      })
    },
    addBobameWidget(state) {
      state.widgetList.push({
        type: 'bobame',
        data: BobameWidgetConfigDefaults,
        id: uuidv4(),
      })
    },
    addLink(state) {
      const lastButtonData = extractLastButton(state.widgetList)
      const newLinkButton = lastButtonData
        ? { ...lastButtonData, title: '', url: '' }
        : {
            ...LinkBlockWidgetConfigDefaults,
          }
      state.widgetList.push({
        type: 'linkButton',
        data: newLinkButton,
        id: uuidv4(),
      })
    },
    removeWidgetElement(state, action: PayloadAction<{ index: number }>) {
      const { index } = action.payload
      state.widgetList.splice(index, 1)
    },
    promoteWidgetElement(state, action: PayloadAction<{ index: number }>) {
      const { index } = action.payload

      const temp = state.widgetList[index - 1]
      state.widgetList[index - 1] = state.widgetList[index]
      state.widgetList[index] = temp
    },
    demoteWidgetElement(state, action: PayloadAction<{ index: number }>) {
      const { index } = action.payload

      const temp = state.widgetList[index + 1]
      state.widgetList[index + 1] = state.widgetList[index]
      state.widgetList[index] = temp
    },
    updateSavedState(state, action: PayloadAction<{ state: boolean }>) {
      state.savedState = action.payload.state
    },
    onLinkPortalThemeSave(state, action: PayloadAction<PageWidget>) {
      const { profileSplash, widgetList } = action.payload
      state.widgetList = widgetList
      state.profileSplash = profileSplash
    },
  },
})

export const { actions: linkPortalActions } = slice

export const useLinkPortalSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer })
  useInjectSaga({ key: slice.name, saga: linkPortalSaga })
  return { actions: slice.actions }
}
