import React, { HTMLAttributes } from 'react'
import styled from 'styled-components/macro'
import { BobaDefaultIcons, MenuOutlined, useImageUrlChecker } from '../..'
import { ProfilePicPlaceholder } from '../ProfilePicPlaceholder'
import { useBobaUser } from '../../../../frontend/src/app/providers/BobaUserProvider/BobaUserProvider'
import { Spin } from 'antd'
import clsx from 'clsx'
import { GetBobaUserAccountResponse } from '../schema/boba-response.schema'

export type ProfilePictureProps = {
  source?: string
  size?: number
  isLoading?: boolean
  publicPageBobaUser?: GetBobaUserAccountResponse
} & React.HTMLAttributes<HTMLDivElement>

const DEFAULT_SIZE = 35

export const ProfilePicture = ({
  isLoading,
  source,
  publicPageBobaUser,
  ...props
}: ProfilePictureProps) => {
  const { isWaitingThumbnail } = useImageUrlChecker(source)
  const { snackBioAvatarImageUrl, bobaUserAccount } = useBobaUser()

  const containerStyle = clsx({
    onClick: props.onClick !== undefined,
  })

  if (isLoading) {
    return (
      <Container
        className={containerStyle}
        size={props.size || DEFAULT_SIZE}
        {...props}
      >
        <Spin />
      </Container>
    )
  }

  return (
    <Container
      className={containerStyle}
      size={props.size || DEFAULT_SIZE}
      {...props}
    >
      {source ? (
        <Image
          src={
            isWaitingThumbnail
              ? source?.replace('thumbnail', 'original')
              : source
          }
        />
      ) : bobaUserAccount?.is_using_default_boba_avatar ||
        publicPageBobaUser?.is_using_default_boba_avatar ? (
        <BobaDefaultAvatar
          children={
            BobaDefaultIcons[
              bobaUserAccount?.default_boba_avatar_key ||
                publicPageBobaUser?.default_boba_avatar_key ||
                'boba-avatar-0'
            ]
          }
        />
      ) : bobaUserAccount?.avatar_image_url ||
        publicPageBobaUser?.avatar_image_url ? (
        <Image
          src={
            bobaUserAccount?.avatar_image_url ||
            publicPageBobaUser?.avatar_image_url
          }
        />
      ) : snackBioAvatarImageUrl ? (
        <Image src={snackBioAvatarImageUrl} />
      ) : (
        <ProfilePicPlaceholder size={props.size} />
      )}
      {props.children}
    </Container>
  )
}

const Container = styled.div<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  background: #dec9af;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const BobaDefaultAvatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 100%;
    height: 100%;
  }
`
