import { defineMessages } from 'react-intl'

export default defineMessages({
  title: { defaultMessage: 'Cashout History' },
  requestDate: { defaultMessage: 'Request Date' },
  status: { defaultMessage: 'Status' },
  receivedDate: { defaultMessage: 'Received Date' },
  amount: { defaultMessage: 'Amount' },
  pending: { defaultMessage: 'Pending' },
  paid: { defaultMessage: 'Paid' },
  totalReceived: { defaultMessage: 'Total received money' },
  emptyMessage: { defaultMessage: 'Currently no Cashout History, good luck!' },
  usd: { defaultMessage: 'USD' },
  hkd: { defaultMessage: 'HKD' },
  twd: { defaultMessage: 'TWD' },
})
