import * as React from 'react'

import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import messages from './messages'
import { Typography } from 'antd'
import { useWidget, withWidget } from '@anyonelab/common'
import { TEXT_WIDGET_ID } from '../constants'
import { TextWidgetConfig } from '../config'
import { LabelWidget } from '@anyonelab/frontend/src/models/Widget'
import { capitalCase } from 'change-case'
import { FontFamily } from '../enum'

export interface TextWidgetCreatorProps {
  data: LabelWidget
}

export const filterSpacesFromString = (input: string) =>
  input.replace(/\s\s/g, '\u00A0\u00A0')

export const SpanWithBreak = (text: LabelWidget['text']) => {
  return (
    <>
      {text}
      <br />
    </>
  )
}

export const TextWidgetCreator = withWidget(
  {
    widgetId: TEXT_WIDGET_ID,
    configComponent: TextWidgetConfig,
  },
  ({ data }: TextWidgetCreatorProps) => {
    const intl = useIntl()

    const { selectWidget } = useWidget()

    // NOTE: Split the original data.content with '\n'.
    const dataContentWithBreak: LabelWidget['text'][] = data.text.split('\n')

    return (
      <TextWidgetStyled onClick={selectWidget}>
        <Typography.Text
          style={{
            display: 'inline-block',
            fontFamily: capitalCase(data.fontFamily || FontFamily.Roboto),
            color: data.fontColor,
            fontSize: data.fontSize,
            fontWeight: data.fontWeight as any,
            fontStyle: data.fontStyle,
            textAlign: 'center',
          }}
        >
          {data.text ? (
            // NOTE: Map out the content with <br /> in order to present the line breaks.
            dataContentWithBreak.map((text, index) => (
              <span key={index}>
                {SpanWithBreak(filterSpacesFromString(text))}
              </span>
            ))
          ) : (
            <span className="placeholder">
              {intl.formatMessage(messages.placeholder)}
            </span>
          )}
        </Typography.Text>
      </TextWidgetStyled>
    )
  },
)

const TextWidgetStyled = styled.div`
  .placeholder {
  }
`
