/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 */

import enTranslationMessages from './compiled/en.json'
import zhTranslationMessages from './compiled/zh.json'

export const DEFAULT_LOCALE = 'en'
export const DEFAULT_FULL_LOCALE = 'en-us'

export const translationMessages = {
  en: enTranslationMessages,
  zh: zhTranslationMessages,
}
