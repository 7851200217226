import React from 'react'
import styled from 'styled-components/macro'
import { MenuOutlined } from '../..'

export const MenuWrapper = ({ children, ...props }) => {
  return (
    <Container {...props}>
      <MenuOutlined style={{ color: 'white' }} />
      {children}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: #cca78c;
  padding: 6px 12px;
  gap: 8px;
  border-radius: 32px;
  cursor: pointer;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`
