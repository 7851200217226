import { useGetBobaUserAccount } from '@anyonelab/frontend/api/boba-api/hook/useGetBobaData'
import { GetBobaUserAccountResponse } from '@anyonelab/frontend/api/boba-api/schema/boba-response.schema'
import { useGetPageList } from '@anyonelab/frontend/api/hooks/Page/useGetPageList'
import { Page } from '@anyonelab/frontend/models/Page'
import React, { PropsWithChildren } from 'react'

interface BobaUserContextProps {
  bobaUserAccount?: GetBobaUserAccountResponse | null
  setBobaUserAccount: React.Dispatch<
    React.SetStateAction<GetBobaUserAccountResponse | null>
  >
  refetchBobaUser: () => Promise<any>
  isLoading: boolean
  snackBioPage?: Page
  snackBioAvatarImageUrl: string | null
  isNewUser: boolean
  setIsNewUser: React.Dispatch<React.SetStateAction<boolean>>
}

export const BobaUserContext = React.createContext<BobaUserContextProps>({
  bobaUserAccount: null,
  setBobaUserAccount: () => undefined,
  refetchBobaUser: async () => undefined,
  isLoading: false,
  snackBioPage: undefined,
  snackBioAvatarImageUrl: null,
  isNewUser: false,
  setIsNewUser: () => undefined,
})
export const useBobaUser = () => React.useContext(BobaUserContext)

interface BobaUserProviderProps {}

function BobaUserProvider({
  children,
}: PropsWithChildren<BobaUserProviderProps>) {
  const [isNewUser, setIsNewUser] = React.useState(false)
  const [
    bobaUserAccount,
    setBobaUserAccount,
  ] = React.useState<GetBobaUserAccountResponse | null>(null)
  const {
    data,
    isLoading: bobaUserAccountIsLoading,
    refetch: refetchBobaUser,
  } = useGetBobaUserAccount()
  const { data: page, isLoading: pageIsLoading } = useGetPageList()

  const extractSnackBioPageAvatarImageUrl = (page): string | null => {
    if (!page || !page.data[0]) return null

    let url: string | null = null

    page.data[0].widget_object.widgetList.forEach((widget) => {
      if (widget.type === 'avatar') {
        url = widget.data.imageUrl
      }
    })

    return url
  }

  React.useEffect(() => {
    if (data) {
      setBobaUserAccount(data?.data[0])
    }
  }, [data])

  return (
    <BobaUserContext.Provider
      value={{
        bobaUserAccount: bobaUserAccount || data?.data[0],
        setBobaUserAccount,
        refetchBobaUser,
        isLoading: bobaUserAccountIsLoading || pageIsLoading,
        snackBioPage: page?.data[0],
        snackBioAvatarImageUrl: extractSnackBioPageAvatarImageUrl(page),
        isNewUser: isNewUser,
        setIsNewUser: setIsNewUser,
      }}
    >
      {children}
    </BobaUserContext.Provider>
  )
}

export default BobaUserProvider
