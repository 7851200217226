import React from 'react'
import { BobaItem } from '@anyonelab/common'
import {
  BobaResponsiveModal,
  BobaResponsiveModalProps,
} from './BobaResponsiveModal'
import PaymentContentForm from './PopupContentComponent/PaymentContentForm'
import PaymentSucceedNotification from './PopupContentComponent/PaymentSucceedNotification'
import { BobaCurrency, BobaDonationDetail } from '../type'
import {
  ThankYouPageContent,
  ThankYouPageContentProps,
} from './PopupContentComponent/ThankYouPageContent'
import { GetBobaUserAccountResponse } from '../schema/boba-response.schema'

interface BobaPaymentPopupModalProps extends BobaResponsiveModalProps {
  publicPageBobaUser?: GetBobaUserAccountResponse
  fullName: string
  currency: BobaCurrency
  name?: string
  note?: string
  is_public_by_supporter?: boolean
  domain: string
  boba_donation_details: BobaDonationDetail[]
  handleCreatePaymentApiCall: (data) => Promise<any>
  totalDonatedAmount: number
  avatar: ThankYouPageContentProps['avatar']
  message: ThankYouPageContentProps['message']
  isDefaultImage: ThankYouPageContentProps['isDefaultImage']
  image: ThankYouPageContentProps['image']
}

function BobaPaymentPopupModal({
  publicPageBobaUser,
  fullName,
  currency,
  name,
  note,
  is_public_by_supporter,
  domain,
  boba_donation_details,
  handleCreatePaymentApiCall,
  totalDonatedAmount,
  avatar,
  message,
  isDefaultImage,
  image,
  ...props
}: BobaPaymentPopupModalProps) {
  const [paymentIsSuccess, setPaymentIsSuccess] = React.useState(false)
  const [hasReadThankYouPage, setHasReadThankyouPage] = React.useState(false)
  const [showCloseIcon, setShowCloseIcon] = React.useState(true)

  const _onClose = () => {
    setPaymentIsSuccess(false)
    setHasReadThankyouPage(false)
    setShowCloseIcon(true)
    props.onClose()
  }

  React.useEffect(() => {
    paymentIsSuccess && !hasReadThankYouPage
      ? setShowCloseIcon(false)
      : setShowCloseIcon(true)
  }, [paymentIsSuccess, hasReadThankYouPage])

  const goToNextPage = () => setHasReadThankyouPage(true)

  return (
    <BobaResponsiveModal
      onClose={_onClose}
      maskClosable={false}
      closeIconVisible={showCloseIcon}
      {...props}
    >
      {!paymentIsSuccess ? (
        <PaymentContentForm
          donorBobaUserId={publicPageBobaUser?.id}
          email={publicPageBobaUser?.email}
          currency={currency}
          name={name}
          fullName={fullName}
          note={note}
          is_public_by_supporter={is_public_by_supporter}
          domain={domain}
          boba_donation_details={boba_donation_details}
          handleCreatePaymentApiCall={handleCreatePaymentApiCall}
          totalDonatedAmount={totalDonatedAmount}
          setPaymentIsSuccess={setPaymentIsSuccess}
        />
      ) : paymentIsSuccess && !hasReadThankYouPage ? (
        <ThankYouPageContent
          avatar={avatar}
          message={message}
          isDefaultImage={isDefaultImage}
          image={image}
          onClick={goToNextPage}
        />
      ) : (
        <PaymentSucceedNotification
          name={name || ''}
          note={note || ''}
          fullName={fullName}
          boba_donation_details={boba_donation_details}
          is_public_by_supporter={is_public_by_supporter || false}
          donated_amount={totalDonatedAmount}
          currency={currency}
        />
      )}
    </BobaResponsiveModal>
  )
}

export default BobaPaymentPopupModal
