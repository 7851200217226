import React, { HTMLAttributes } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import {
  BobaBoardContainer,
  convertCurrencyToSymbol,
  DesktopOnly,
  MobileOnly,
} from '../..'
import messages from './messages'
import CountUp from 'react-countup'
import moment from 'moment'

type WeeklyStatusBoardProps = {
  past_seven_days_view_times?: number
  weeklyBobaDonation?: number
} & HTMLAttributes<HTMLDivElement>

export const WeeklyStatusBoard = (props: WeeklyStatusBoardProps) => {
  const intl = useIntl()
  return (
    <>
      <MobileOnly>
        <BobaBoardContainer
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Title children={intl.formatMessage(messages.title)} />
          <Subtitle
            children={`${moment()
              .subtract(8, 'day')
              .format('YYYY/M/D')} - ${moment()
              .subtract(1, 'day')
              .format('YYYY/M/D')} `}
          />
          <ItemContainer style={{ marginBottom: '24px' }}>
            <Number
              style={{ marginBottom: '8px' }}
              useEasing
              formattingFn={(value) => `${value.toLocaleString()}`}
              end={props.past_seven_days_view_times || 0}
            />
            <Description children={intl.formatMessage(messages.pageViews)} />
          </ItemContainer>
          <ItemContainer>
            <Number
              style={{ marginBottom: '8px' }}
              useEasing
              end={props.weeklyBobaDonation || 0}
            />
            <Description
              children={intl.formatMessage(messages.receivedBobas)}
            />
          </ItemContainer>
        </BobaBoardContainer>
      </MobileOnly>
      <DesktopOnly>
        <BobaBoardContainer style={{ minWidth: 'unset' }}>
          <Title children={intl.formatMessage(messages.title)} />
          <Subtitle
            children={`${moment()
              .subtract(8, 'day')
              .format('YYYY/M/D')} - ${moment()
              .subtract(1, 'day')
              .format('YYYY/M/D')} `}
          />
          <DesktopItemContainer>
            <ItemContainer style={{ flex: 1, justifyContent: 'end' }}>
              <Number
                style={{ marginBottom: '12px' }}
                useEasing
                formattingFn={(value) => `${value.toLocaleString()}`}
                end={props.past_seven_days_view_times || 0}
              />
              <Description children={intl.formatMessage(messages.pageViews)} />
            </ItemContainer>
            <ItemContainer style={{ flex: 1, justifyContent: 'end' }}>
              <Number
                style={{ marginBottom: '12px' }}
                useEasing
                end={props.weeklyBobaDonation || 0}
              />
              <Description
                children={intl.formatMessage(messages.receivedBobas)}
              />
            </ItemContainer>
          </DesktopItemContainer>
        </BobaBoardContainer>
      </DesktopOnly>
    </>
  )
}

const DesktopItemContainer = styled.div`
  margin-top: 16px;
  display: flex;
`

const Title = styled.div`
  color: #4a1e11;
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 4px;
`

const Subtitle = styled.div`
  color: #999;
  font-size: 12px;
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  font-style: normal;
  text-align: center;
  margin-bottom: 12px;
`

const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Number = styled(CountUp)`
  color: #ad5138;
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
`

const Description = styled.div`
  color: #929292;
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
`
