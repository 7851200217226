import { defineMessages } from 'react-intl'

export default defineMessages({
  buySomeoneABoba: { defaultMessage: 'Your Boba order!' },
  total: { defaultMessage: 'total' },
  namePlaceholder: { defaultMessage: '@your instagram or Name' },
  messagePlaceholder: { defaultMessage: 'Say something' },
  checkbox1Message: {
    defaultMessage:
      'Too shy to public, make the message privacy. PS. Boba bar owner will love your shoutout! 😘',
  },
  submit: { defaultMessage: 'Support' },
  login: { defaultMessage: 'Register/ Login' },
  activate: { defaultMessage: 'Activate' },
})
