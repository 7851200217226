import {
  Button,
  usePageBuilder,
  useWidget,
  withWidget,
} from '@anyonelab/common'
import { LeadFormWidget } from '@anyonelab/frontend/models/Widget'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { LeadFormWidgetConfig } from '../config'
import { defaultSanitizeOptions, LEAD_FORM_WIDGET_ID } from '../const'
import DOMPurify from 'dompurify'
import messages from './messages'

export interface LeadFormWidgetCreatorProps {
  data: LeadFormWidget
}

export const LeadFormWidgetCreator = withWidget<LeadFormWidgetCreatorProps>(
  {
    widgetId: LEAD_FORM_WIDGET_ID,
    configComponent: LeadFormWidgetConfig,
  },
  ({ data, instanceId }) => {
    const intl = useIntl()

    const { selectWidget, displayMode } = useWidget()

    React.useEffect(() => {
      if (window.instgrm && window.instgrm.Embeds) {
        window.instgrm.Embeds.process()
      }
      if (window.twttr && window.twttr.widgets) {
        window.twttr.widgets.load()
      }
    }, [data])

    return (
      <LinkBlockWidgetStyled onClick={selectWidget}>
        {!data.htmlTag ? (
          <PlaceholderBlock
            children={intl.formatMessage(messages.htmlEmbedEmptyTitle)}
          />
        ) : (
          <></>
        )}
        <section
          className="not-found-controller"
          dangerouslySetInnerHTML={{
            __html: data?.htmlTag,
          }}
        />
        {!displayMode && <div id="blocker" />}
      </LinkBlockWidgetStyled>
    )
  },
)

const LinkBlockWidgetStyled = styled.div`
  width: 100%;
  display: grid;
  place-items: center;

  position: relative;
  #blocker {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  section {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  iframe {
    width: 100%;
  }
`

const PlaceholderBlock = styled.div`
  width: 100%;
  background-color: white;
  padding: 20px;
  text-align: center;
  border-radius: 0.75rem;
  color: #ccc;
  font-style: italic;
`
