import { defineMessages } from 'react-intl'

export default defineMessages({
  pasteTheCode: { defaultMessage: 'Paste the code' },
  nameTheElement: { defaultMessage: 'Name this element' },
  delete: { defaultMessage: 'Delete' },
  addMoreEmbedRequestMessage: {
    defaultMessage: 'Hey code master! Want more embed elements?',
  },
  addMoreEmbedMessageTitle: { defaultMessage: 'Thank you!' },
  addMoreEmbedMessageDescription: {
    defaultMessage: 'We will notify you when new embed is on.',
  },
  addMoreEmbedMessageAction: { defaultMessage: 'Got it' },
})
