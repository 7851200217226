import { bobaShaker } from '@anyonelab/common/'
import React, { HTMLAttributes } from 'react'
import Lottie, { LottieComponentProps } from 'lottie-react'

type BobaBartenderAnimatedProps = {
  lottieProps?: LottieComponentProps
} & HTMLAttributes<HTMLDivElement>

export const BobaBartenderAnimated = ({
  lottieProps,
  ...rest
}: BobaBartenderAnimatedProps) => {
  return (
    <div {...rest}>
      <Lottie
        animationData={bobaShaker}
        style={{ margin: 'none', width: 120 }}
        {...lottieProps}
      />
    </div>
  )
}
