import React, { HTMLAttributes } from 'react'
import { useIntl } from 'react-intl'
import styled, { useTheme } from 'styled-components/macro'
import { BobaBoardContainer } from '../BobaBoardContainer'
import messages from './messages'
import { useTable } from 'react-table'
import { columnTitles } from './const'
import {
  BobaCurrency,
  DesktopOnly,
  MobileOnly,
  BobaPagination,
  PaginationType,
  BobaBoardTitleMobile,
  BobaBoardTitleDesktop,
  useResponsive,
} from '../..'
import { GetBobaPayoutResponse } from '../schema/boba-response.schema'
import moment from 'moment'

type CashoutHistoryBoardProps = {
  cashoutHistoryData: GetBobaPayoutResponse[]
  currency: BobaCurrency
  isLoading: boolean
  pagination: PaginationType
} & HTMLAttributes<HTMLDivElement>

const StatusCell = ({ value }) => {
  const intl = useIntl()
  const status = intl.formatMessage(messages[value])
  return (
    <div
      style={{
        color: value === 'pending' ? '#cca78c' : '#c07c62',
        fontWeight: 'bold',
      }}
    >
      {status}
    </div>
  )
}

const RequestDateCell = ({ value }) => {
  return <div style={{ color: '#929292' }}>{value}</div>
}

const ReceivedDateCell = ({ value }) => {
  return <div style={{ color: '#929292' }}>{value || '---'}</div>
}

const AmountCell = ({ value, row }) => {
  const intl = useIntl()

  return (
    <div
      style={{
        fontWeight: 'bold',
        color: row.values.status === 'pending' ? '#cca78c' : '#929292',
      }}
    >
      {`${intl.formatMessage(
        messages[value.currency],
      )} ${value.amount.toLocaleString()}`}
    </div>
  )
}

const getCell = (
  item: 'requestDate' | 'status' | 'receivedDate' | 'amount',
): ((param: any) => JSX.Element) => {
  switch (item) {
    case 'requestDate':
      return RequestDateCell
    case 'status':
      return StatusCell
    case 'receivedDate':
      return ReceivedDateCell
    case 'amount':
      return AmountCell
  }
}

const EmptyCashoutHistoryMessage = () => {
  const intl = useIntl()
  const { isMobileLayout } = useResponsive()
  return (
    <div
      style={{
        fontSize: isMobileLayout ? 12 : 16,
        textAlign: 'center',
        color: '#4a1e11',
        marginBottom: 12,
      }}
    >
      {intl.formatMessage(messages.emptyMessage)}
    </div>
  )
}

export const CashoutHistoryBoard = (props: CashoutHistoryBoardProps) => {
  const intl = useIntl()
  const theme = useTheme()

  const data = React.useMemo(() => {
    return props.cashoutHistoryData.map((item) => {
      return {
        requestDate: moment(item.createdAt).format('YYYY.M.DD'),
        status: item.status,
        receivedDate: item.paid_at
          ? moment(item.paid_at).format('YYYY.M.DD')
          : null,
        amount: { amount: item.cashout_amount, currency: props.currency },
      }
    })
  }, [props.cashoutHistoryData])

  const columns = React.useMemo(() => {
    return columnTitles.map((title) => {
      return {
        Header: intl.formatMessage(messages[title]),
        accessor: title,
        Cell: getCell(
          title as 'requestDate' | 'status' | 'receivedDate' | 'amount',
        ),
      }
    })
  }, [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  return (
    <BobaBoardContainer>
      <MobileOnly>
        <BobaBoardTitleMobile children={intl.formatMessage(messages.title)} />
        {props.cashoutHistoryData.length === 0 ? (
          <EmptyCashoutHistoryMessage />
        ) : (
          <TableContainer className="mobile">
            <table
              {...getTableProps()}
              style={{
                width: '100%',
                tableLayout: 'fixed',
              }}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        style={{
                          color: '#794e2e',
                          fontFamily: theme.bobaFontFamily.primary,
                          fontWeight: 600,
                          fontSize: 12,
                          textAlign: 'start',
                          backgroundColor: theme.bobaColor.backgroundWhite,
                          paddingBottom: '12px',
                          borderBottom: '#ad5138 solid 1px',
                        }}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, rowIndex, rowArray) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, index, array) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              fontSize: 12,
                              padding: '14px 10px',
                              backgroundColor: theme.bobaColor.backgroundWhite,
                              borderBottom:
                                parseInt(rowIndex) !== rowArray.length - 1
                                  ? '#CCA78C solid 0.5px'
                                  : 'none',
                            }}
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </TableContainer>
        )}
        <BobaPagination
          current={props.pagination.current}
          showSizeChanger={false}
          defaultCurrent={props.pagination.defaultCurrent}
          pageSize={props.pagination.pageSize}
          total={props.pagination.total}
          onChange={props.pagination.onChange}
        />
      </MobileOnly>
      <DesktopOnly>
        <BobaBoardTitleDesktop children={intl.formatMessage(messages.title)} />
        {props.cashoutHistoryData.length === 0 ? (
          <EmptyCashoutHistoryMessage />
        ) : (
          <TableContainer className="desktop">
            <table
              {...getTableProps()}
              style={{
                width: '100%',
                tableLayout: 'fixed',
              }}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        style={{
                          color: '#794e2e',
                          fontFamily: theme.bobaFontFamily.primary,
                          fontWeight: 600,
                          fontSize: 12,
                          textAlign: 'start',
                          backgroundColor: theme.bobaColor.backgroundWhite,
                          paddingBottom: '20px',
                          borderBottom: '#ad5138 solid 1px',
                        }}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row, rowIndex, rowArray) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, index, array) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{
                              fontSize: 12,
                              padding: '14px 10px',
                              backgroundColor: theme.bobaColor.backgroundWhite,
                              borderBottom:
                                parseInt(rowIndex) !== rowArray.length - 1
                                  ? '#CCA78C solid 0.5px'
                                  : 'none',
                            }}
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </TableContainer>
        )}
        <BobaPagination
          current={props.pagination.current}
          showSizeChanger={false}
          defaultCurrent={props.pagination.defaultCurrent}
          pageSize={props.pagination.pageSize}
          total={props.pagination.total}
          onChange={props.pagination.onChange}
        />
      </DesktopOnly>
    </BobaBoardContainer>
  )
}

const TableContainer = styled.div`
  display: block;
  &.desktop {
    min-width: 800px;
  }
  &.mobile {
  }
  margin-bottom: 32px;
  border-bottom: 1px solid #ad5138;
`
