import React from 'react'

type LeftArrowProps = {
  stroke?: string
  strokeWidth?: string
} & React.SVGProps<SVGSVGElement>

export const LeftArrow = (props: LeftArrowProps) => {
  return (
    <svg
      width="15"
      height="25"
      viewBox="0 0 15 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        id="Vector 57"
        d="M13 2L2 12.5L13 23"
        stroke={props.stroke || '#929292'}
        stroke-width={props.strokeWidth || '3'}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
