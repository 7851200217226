/**
 * index.tsx
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import * as React from 'react'
import * as ReactDOM from 'react-dom'

// Use consistent styling
import 'sanitize.css/sanitize.css'

// Import root app
import { App } from '../src/app/App'

import { HelmetProvider } from 'react-helmet-async'

import 'antd/dist/antd.css'

const MOUNT_NODE = document.getElementById('root') as HTMLElement

ReactDOM.hydrate(
  <HelmetProvider>
    <React.StrictMode>
      <App
        data={(window as any).PUBLICDATA}
        username={(window as any).USER}
        isAnyonelab={(window as any).IS_ANYONELAB}
      />
    </React.StrictMode>
  </HelmetProvider>,
  MOUNT_NODE,
)
