import { rgba } from 'polished'
import * as React from 'react'
import styled, { css } from 'styled-components/macro'
import { useWidget } from '.'
import {
  getInstanceName,
  LinkPortalEvent,
  ProfileSplashWidgetInstanceIds,
  PROFILE_SPLASH_WIDGET_ID,
  PublicPageEvent,
  track,
  usePageBuilder,
} from '@anyonelab/common'
import { WidgetContext } from './WidgetContext'
import { DEFAULT_BORDER_OFFSET } from './styles'

export interface WithWidgetOptions {
  widgetId: string
  configComponent: React.ComponentType<any>
  disableDefaultInteractions?: boolean
}

export interface WithWidgetComponentProps<T> {
  instanceId: string
  instanceLabel: string
  data: T
  displayMode?: boolean
}

export const withWidget = <
  WidgetComponentProps extends {
    data: unknown
  }
>(
  { widgetId, configComponent, disableDefaultInteractions }: WithWidgetOptions,
  WidgetComponent: React.ComponentType<
    WithWidgetComponentProps<WidgetComponentProps['data']> &
      WidgetComponentProps
  >,
) => {
  const WithWidgetComponentWrapper = (
    props: WidgetComponentProps &
      WithWidgetComponentProps<WidgetComponentProps['data']>,
  ) => {
    const {
      instanceId: parentinstanceId,
      displayMode: parentDisplayMode,
    } = useWidget()
    const { instanceId, instanceLabel, data, displayMode } = props
    const {
      activeWidget,
      selectWidget: selectWidgetOnPage,
      hoverWidget,
      hoveredInstanceId,
      activeInstanceId,
      registerWidgetConfig,
      updateConfigData,
      isPreviewMode,
      configPanelContent,
      setConfigPanelContent,
    } = usePageBuilder()

    const isDisplayMode = parentDisplayMode || displayMode || isPreviewMode

    const currentInstanceId = `${
      parentinstanceId ? `${parentinstanceId}.` : ''
    }${instanceId}` // if not parentInstanceId then is 'linkportal-profilesplash'

    const selectWidget = (
      customWidgetId?: string,
      customCurrentInstanceId?: string,
      customInstanceId?: string,
    ) => {
      let selectedWidgetId = widgetId
      let selectedCurrentInstanceId = currentInstanceId
      let selectedInstanceId = instanceId

      if (customWidgetId && customInstanceId && customInstanceId) {
        selectedWidgetId = customWidgetId
        selectedCurrentInstanceId = customCurrentInstanceId
        selectedInstanceId = customInstanceId
      }

      // widget on click then call this function
      setConfigPanelContent('')
      if (isDisplayMode) return
      selectWidgetOnPage(selectedWidgetId, selectedCurrentInstanceId) // this function set the active widget and active instance
      switch (getInstanceName(selectedInstanceId)) {
        case ProfileSplashWidgetInstanceIds.AVATAR:
          track(LinkPortalEvent.avatarClick)
          break
        case ProfileSplashWidgetInstanceIds.TITLE:
          track(LinkPortalEvent.titleClick)
          break
        case ProfileSplashWidgetInstanceIds.SUBTITLE:
          track(LinkPortalEvent.subtitleClick)
          break
        case ProfileSplashWidgetInstanceIds.LINKS:
          track(LinkPortalEvent.linkBlockClick)
          break
        case PROFILE_SPLASH_WIDGET_ID:
          track(LinkPortalEvent.profileSplashClick)
          break
        // TODO: Add Social Media Click
        // TODO: Add Lead form Click
        // TODO: Add BobameClick
        default:
          break
      }
    }

    React.useEffect(() => {
      if (!configComponent) return
      registerWidgetConfig({
        widgetId,
        instanceLabel,
        instanceId: currentInstanceId,
        component: configComponent,
      })
    }, [registerWidgetConfig, currentInstanceId, instanceLabel])

    React.useEffect(() => {
      if (configPanelContent === 'theme') return
      updateConfigData(currentInstanceId, data)
    }, [updateConfigData, data, currentInstanceId])

    const isActive = activeInstanceId === currentInstanceId
    const isHovered = hoveredInstanceId === currentInstanceId

    const onWidgetMouseOver: React.MouseEventHandler<HTMLDivElement> = (e) => {
      e.stopPropagation()

      if (disableDefaultInteractions || isDisplayMode) return

      hoverWidget(widgetId, currentInstanceId)
    }

    const onWidgetMouseOut: React.MouseEventHandler<HTMLDivElement> = (e) => {
      e.stopPropagation()

      if (disableDefaultInteractions || isDisplayMode) return

      hoverWidget('', '')
    }

    return (
      <WidgetContext.Provider
        value={{
          widgetId,
          instanceId: currentInstanceId,
          hovered: isHovered,
          active: isActive,
          selectWidget,
          displayMode: displayMode || isDisplayMode,
        }}
      >
        <BaseWidgetStyled
          active={isActive}
          hovered={isHovered}
          isPreviewMode={isDisplayMode}
          className={`widget-${widgetId} widget ${isActive ? 'selected' : ''}`}
          onMouseOver={onWidgetMouseOver}
          onMouseOut={onWidgetMouseOut}
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <WidgetComponent {...props} />

          {!disableDefaultInteractions && (
            <div className="hover-border">
              <div className="widget-label">{instanceLabel}</div>
            </div>
          )}
        </BaseWidgetStyled>
      </WidgetContext.Provider>
    )
  }

  return WithWidgetComponentWrapper
}

export interface BaseWidgetStyledProps {
  hovered?: boolean
  active?: boolean
  isPreviewMode?: boolean
}

export const BaseWidgetStyled = styled.div<BaseWidgetStyledProps>`
  position: relative;

  transition: transform 0.15s ease;

  .hover-border {
    opacity: 0;

    position: absolute;
    z-index: ${({ theme }) => theme.zIndex[100]};

    top: ${DEFAULT_BORDER_OFFSET};
    left: ${DEFAULT_BORDER_OFFSET};
    right: ${DEFAULT_BORDER_OFFSET};
    bottom: ${DEFAULT_BORDER_OFFSET};

    border: 2px solid ${({ theme }) => theme.colors.widgetBlue};

    pointer-events: none;

    .widget-label {
      padding: 2px 4px;
      background-color: ${({ theme }) => theme.colors.widgetBlue};
      color: ${({ theme }) => theme.colors.white};
      font-size: ${({ theme }) => theme.fontSize[10]};

      position: absolute;
      top: 0;
      left: -2px;
      transform: translateY(-100%);

      white-space: nowrap;
    }
  }

  ${(props) =>
    props.isPreviewMode
      ? css`
          cursor: default;
        `
      : css`
          cursor: pointer;
          user-select: none;

          &:hover {
            > .hover-border {
              opacity: 0.7;
              transition: border 0.15s, opacity 0.15s;
            }
          }

          ${props.active &&
          css`
            && {
              > .hover-border {
                opacity: ${({ theme }) => theme.opacity.full};
              }
            }
          `}
        `}
`
