import { MenuWrapper, ProfilePicture, useResponsive } from '@anyonelab/common'
import React from 'react'
import styled from 'styled-components/macro'
import { ReactComponent as SnackBioAppIconOutlined } from '../../../assets/snack-bio-app-icon-outlined.svg'
import { ReactComponent as SettingIcon } from '../../../assets/boba-tools/settingIcon.svg'
import { ReactComponent as HomeIcon } from '../../../assets/boba-tools/homeIcon.svg'
import { ReactComponent as TranslationIcon } from '../../../assets/boba-tools/languageIcon.svg'
import { ReactComponent as ShareIcon } from '../../../assets/boba-tools/externalLinkIcon.svg'
import { ReactComponent as HtmlEmbedIcon } from '../../../assets/boba-tools/htmlTagIcon.svg'
import { useHistory } from 'react-router-dom'
import { GetBobaUserAccountResponse } from '../../schema/boba-response.schema'

interface HomePageNavBarProps {
  className?: string
  changeLocale?: () => void
  shareModalOpenToggle: () => void
  embedModalOpenToggle?: () => void
  openSnackBioTab: () => void
  showSnackBioButton: boolean
  displayMode?: boolean
  isPublicPageAuthenticated?: boolean
  publicPageBobaUser?: GetBobaUserAccountResponse
  isLoggedInCheck?: boolean
}

export function HomePageNavBar({
  className,
  changeLocale,
  shareModalOpenToggle,
  embedModalOpenToggle,
  openSnackBioTab,
  showSnackBioButton,
  displayMode,
  isPublicPageAuthenticated,
  publicPageBobaUser,
  isLoggedInCheck,
}: HomePageNavBarProps) {
  const { isMobileLayout } = useResponsive()
  const history = useHistory()

  const openBobaSettingPageTab = () => {
    const targetOrigin =
      process.env.REACT_APP_ENV === 'development'
        ? 'https://localhost:3000'
        : 'https://app.anyonelab.com'
    const snackBioUrl = targetOrigin + '/boba/setting'
    window.open(snackBioUrl, '_blank')
  }

  return (
    <Container className={className} isMobileLayout={isMobileLayout}>
      <AccountButtonContainer>
        <>
          <Button>
            <HomeIcon
              fill="#CCA78C"
              onClick={() => !displayMode && history.push('/dasboard')}
            />
          </Button>
          <Button>
            <TranslationIcon fill={'#CCA78C'} onClick={changeLocale} />
          </Button>
        </>
      </AccountButtonContainer>

      <FunctionalButtonContainer>
        {displayMode ? (
          <>
            <StyledButton onClick={shareModalOpenToggle}>
              <ShareIcon />
              {/* // Todo add tab function */}
            </StyledButton>
            {showSnackBioButton && (
              <StyledButton onClick={openSnackBioTab}>
                {/* // Todo add share function */}
                <SnackBioAppIconOutlined />
              </StyledButton>
            )}
            {isLoggedInCheck && // * is public page and already check log in status and is already logged in
              isPublicPageAuthenticated && ( // Todo Need add avatar or boba icon
                <MenuWrapper
                  onClick={openBobaSettingPageTab}
                  children={
                    <ProfilePicture
                      size={24}
                      publicPageBobaUser={publicPageBobaUser}
                    />
                  }
                />
              )}
          </>
        ) : (
          <>
            <StyledButton onClick={embedModalOpenToggle}>
              {/* // Todo add share function */}
              <HtmlEmbedIcon />
            </StyledButton>
            <StyledButton onClick={shareModalOpenToggle}>
              {/* // Todo add share function */}
              <ShareIcon />
            </StyledButton>
            <MenuWrapper
              onClick={() => history.push('/boba/setting')}
              children={
                <ProfilePicture
                  size={24}
                  publicPageBobaUser={publicPageBobaUser}
                />
              }
            />
          </>
        )}
      </FunctionalButtonContainer>
    </Container>
  )
}

const Container = styled.div<{ isMobileLayout: boolean }>`
  padding: ${(props) => (props.isMobileLayout ? '16px' : '24px')};
  width: 100%;
  position: absolute;
  top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 900;
`

const Title = styled.div`
  color: ${({ theme }) => theme.bobaColor.backgroundWhite};
  font-family: '${({ theme }) => theme.bobaFontFamily.title}';
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSize[24]};
`

const AccountButtonContainer = styled.div`
  display: flex;
  > div {
    margin-right: 12px;
  }
`

const FunctionalButtonContainer = styled.div`
  justify-self: end;
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 12px;
`

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

const StyledButton = styled(Button)`
  background-color: #cca78c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  height: 40px;
  width: 50px;
`
