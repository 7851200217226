import amplitude, { AmplitudeClient } from 'amplitude-js'
import { UserPropertiesInterface, EventName, EventProperty } from './types'

export const initAnalytics = (cb?: (client: AmplitudeClient) => void) => {
  amplitude
    .getInstance()
    .init(
      process.env.REACT_APP_AMPLITUDE_STAGING_KEY!!,
      undefined,
      undefined,
      cb,
    )
}

export const setAnalyticsUserId = (userId: string | null) => {
  amplitude.getInstance().setUserId(userId)
}

export const setAnalyticsUserProperties = (
  properties: UserPropertiesInterface,
) => {
  amplitude.getInstance().setUserProperties(properties)
}

export const clearAnalyticsUserProperties = () => {
  amplitude.getInstance().clearUserProperties()
}

export const editAnalyticsUserProperty = (
  property: keyof Partial<UserPropertiesInterface>,
  value: any,
) => {
  const identify = new amplitude.Identify().set(property, value)
  amplitude.getInstance().identify(identify)
}

export const track = (
  event: EventName,
  eventProperties: EventProperty = {},
  callback?: () => void,
): void => {
  amplitude.getInstance().logEvent(event, eventProperties)
  callback && callback()
}
