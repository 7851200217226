import moment from 'moment'
import React, { HTMLAttributes, ReactNode } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { ReactComponent as TalkingBubble } from '../../assets/talking-bubble-icon.svg'
import { ReactComponent as ShareIcon } from '../../assets/share-icon.svg'
import {
  BobaBoardContainer,
  BobaBoardTitleDesktop,
  BobaBoardTitleMobile,
  BobaIcon,
  BobaPagination,
  DEFAULT_BOBA_AVATAR_KEY,
  DesktopOnly,
  Divider,
  DoneIcon,
  EmptyCircleIcon,
  MobileOnly,
} from '../..'
import { GetBobaDontaionWithDonorInfoResponse } from '../schema/boba-response.schema'
import messages from './messages'
import { DONATION_ID_PREFIX } from '../MessageBoard/const'

type DonationHistoryBoardProps = {
  donationHistoryData: GetBobaDontaionWithDonorInfoResponse[]
  displayOnClick: (isDisplay: boolean, donationId: number) => void
  repostOnClick: (data: GetBobaDontaionWithDonorInfoResponse) => void
  replyOnClick: (sectionId: string) => void
  pagination: {
    current: number
    defaultCurrent: number
    pageSize: number
    total: number
    onChange: (page: number, pageSize?: number | undefined) => void
  }
}

const MessageCell = (props: {
  donor_name: string
  donor_note: string
  donor_email: string
  donor_is_using_default_boba_avatar: boolean
  donor_default_boba_avatar_key: string
  donor_avatar_image_url: string
  created_date: Date
  boba_count: number
  donated_amount: number
}) => {
  const intl = useIntl()
  return (
    <>
      <MobileOnly>
        <MessageCellContainer data-mobile className="message-cell-container">
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '8px',
            }}
          >
            <BobaIcon
              isUsingDefaultBobaAvatar={
                props.donor_is_using_default_boba_avatar
              }
              defaultBobaAvatarKey={props.donor_default_boba_avatar_key}
              imageUrl={props.donor_avatar_image_url}
              size={20}
              style={{ marginRight: '8px' }}
            />
            <NameComponent data-mobile children={`@${props.donor_name}`} />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <DateComponent
                data-mobile
                children={moment(props.created_date).format('YYYY.M.DD')}
              />
              <Price
                data-mobile
                children={`${intl.formatMessage(messages.cup, {
                  itemCount: props.boba_count,
                })} | $${props.donated_amount.toLocaleString()}`}
              />
            </div>
          </div>
          <Message
            data-mobile
            className={props.donor_note ? '' : 'empty-message'}
            children={
              props.donor_note ||
              intl.formatMessage(messages.emptyMessagePlaceholder1)
            }
          />
        </MessageCellContainer>
      </MobileOnly>
      <DesktopOnly>
        <MessageCellContainer data-desktop className="message-cell-container">
          <BobaIcon
            isUsingDefaultBobaAvatar={props.donor_is_using_default_boba_avatar}
            defaultBobaAvatarKey={props.donor_default_boba_avatar_key}
            imageUrl={props.donor_avatar_image_url}
            size={40}
            style={{ marginRight: '12px' }}
          />
          <MessageContentContainer>
            <div style={{ display: 'flex', marginBottom: '12px' }}>
              <NameComponent data-desktop children={`@${props.donor_name}`} />
              <Price
                data-desktop
                children={`${intl.formatMessage(messages.cup, {
                  itemCount: props.boba_count,
                })} | $${props.donated_amount.toLocaleString()}`}
              />
              <DateComponent
                data-desktop
                children={moment(props.created_date).format('YYYY.M.DD')}
              />
            </div>
            <Message
              data-desktop
              className={props.donor_note ? '' : 'empty-message'}
              children={
                props.donor_note ||
                intl.formatMessage(messages.emptyMessagePlaceholder1)
              }
            />
          </MessageContentContainer>
        </MessageCellContainer>
      </DesktopOnly>
    </>
  )
}

const DisplayCell = (props: {
  donationId: number
  is_display_by_user: boolean
  is_public_by_supporter: boolean
  onClick: (isDisplay: boolean, donationId: number) => void
}) => {
  const intl = useIntl()
  return (
    <DisplayCellContainer className="display-cell-container">
      {!props.is_public_by_supporter ? (
        intl.formatMessage(messages.supporterMessageIsPrivate)
      ) : props.is_display_by_user ? (
        <DoneIcon
          className={'pointer'}
          onClick={() => props.onClick(false, props.donationId)}
        />
      ) : (
        <EmptyCircleIcon
          onClick={() => props.onClick(true, props.donationId)}
        />
      )}
    </DisplayCellContainer>
  )
}

const RepostCell = (
  props: HTMLAttributes<HTMLDivElement> & {
    repostOnClick: () => void
  },
) => {
  return (
    <RepostCellContainer className="repost-cell-container" {...props}>
      <ShareIcon className="pointer" onClick={props.repostOnClick} />
    </RepostCellContainer>
  )
}

const ReplyCell = (
  props: HTMLAttributes<HTMLDivElement> & {
    isActive?: boolean
    redirectClick: () => void
  },
) => {
  const { isActive, redirectClick } = props
  return (
    <ReplyCellContainer
      className={`reply-cell-container ${isActive && 'active'}`}
      {...props}
    >
      <TalkingBubble className={'pointer'} onClick={() => redirectClick()} />
    </ReplyCellContainer>
  )
}

export const DonationHistoryBoard = (props: DonationHistoryBoardProps) => {
  const intl = useIntl()
  const NUMBER_OF_COLUMNS = 4

  return (
    <StyledBobaBoardContainer>
      <MobileOnly>
        <BobaBoardTitleMobile
          className="title"
          children={intl.formatMessage(messages.title)}
        />
        <VerticalTableContainer>
          <div style={{ color: '#4A1E11', fontSize: '16px', fontWeight: 600 }}>
            {intl.formatMessage(messages.message)}
          </div>
          <StyledDivider style={{ borderColor: '#AD5138', borderWidth: 1 }} />
          {props.donationHistoryData.map((item, index, array) => {
            return (
              <>
                <MessageCell
                  donor_is_using_default_boba_avatar={
                    item.donor_boba_user_account.is_using_default_boba_avatar ??
                    true
                  }
                  donor_default_boba_avatar_key={
                    item.donor_boba_user_account.default_boba_avatar_key ??
                    DEFAULT_BOBA_AVATAR_KEY
                  }
                  donor_avatar_image_url={
                    item.donor_boba_user_account.avatar_image_url ?? ''
                  }
                  data-mobile
                  donor_name={item.donor_name || ''}
                  donor_note={item.donor_note || ''}
                  donor_email={item.donor_email || ''}
                  created_date={item.createdAt}
                  boba_count={item.boba_count}
                  donated_amount={item.donated_amount}
                />
                <div style={{ display: 'flex' }}>
                  <SubItemContainer>
                    <SubItemTitle
                      children={intl.formatMessage(messages.display)}
                    />
                    <DisplayCell
                      donationId={item.id}
                      data-mobile
                      onClick={props.displayOnClick}
                      is_display_by_user={item.is_display_by_user}
                      is_public_by_supporter={item.is_public_by_supporter}
                    />
                  </SubItemContainer>
                  <Divider
                    type="vertical"
                    style={{ borderColor: '#b3b3b3', height: 'auto' }}
                  />
                  <SubItemContainer>
                    <SubItemTitle
                      children={intl.formatMessage(messages.repost)}
                    />
                    <RepostCell
                      style={{ flex: 1 }}
                      data-mobile
                      repostOnClick={() => {
                        props.repostOnClick(item)
                      }}
                    />
                  </SubItemContainer>
                  <Divider
                    type="vertical"
                    style={{ borderColor: '#b3b3b3', height: 'auto' }}
                  />
                  <SubItemContainer>
                    <SubItemTitle
                      children={intl.formatMessage(messages.reply)}
                    />
                    <ReplyCell
                      style={{ flex: 1 }}
                      data-mobile
                      isActive={
                        (item.boba_donation_comments.is_active &&
                          Boolean(item.boba_donation_comments.message)) ||
                        false
                      }
                      redirectClick={() => {
                        props.replyOnClick(`${DONATION_ID_PREFIX}${item.id}`)
                      }}
                    />
                  </SubItemContainer>
                </div>
                {index !== array.length - 1 ? (
                  <StyledDivider />
                ) : (
                  <StyledDivider
                    style={{ borderColor: '#AD5138', borderWidth: 1 }}
                  />
                )}
              </>
            )
          })}
        </VerticalTableContainer>
      </MobileOnly>
      <DesktopOnly>
        <BobaBoardTitleDesktop
          className="title"
          children={intl.formatMessage(messages.title)}
        />
        <TableContainer>
          <Topic
            style={{ textAlign: 'start' }}
            children={intl.formatMessage(messages.message)}
          />
          <Topic children={intl.formatMessage(messages.display)} />
          <Topic children={intl.formatMessage(messages.repost)} />
          <Topic children={intl.formatMessage(messages.reply)} />
          <>
            {[...Array(NUMBER_OF_COLUMNS)].map((item) => (
              <StyledDivider
                style={{ borderColor: '#AD5138', borderWidth: 1 }}
              />
            ))}
          </>
          {props.donationHistoryData.map((item, index, array) => {
            return (
              <>
                <MessageCell
                  donor_is_using_default_boba_avatar={
                    item.donor_boba_user_account.is_using_default_boba_avatar ??
                    true
                  }
                  donor_default_boba_avatar_key={
                    item.donor_boba_user_account.default_boba_avatar_key ??
                    DEFAULT_BOBA_AVATAR_KEY
                  }
                  donor_avatar_image_url={
                    item.donor_boba_user_account.avatar_image_url ?? ''
                  }
                  donor_name={item.donor_name || ''}
                  donor_note={item.donor_note || ''}
                  donor_email={item.donor_email || ''}
                  created_date={item.createdAt}
                  boba_count={item.boba_count}
                  donated_amount={item.donated_amount}
                />
                <DisplayCell
                  donationId={item.id}
                  onClick={props.displayOnClick}
                  is_display_by_user={item.is_display_by_user}
                  is_public_by_supporter={item.is_public_by_supporter}
                />
                <RepostCell
                  repostOnClick={() => {
                    props.repostOnClick(item)
                  }}
                />
                <ReplyCell
                  isActive={
                    (item.boba_donation_comments.is_active &&
                      Boolean(item.boba_donation_comments.message)) ||
                    false
                  }
                  redirectClick={() => {
                    props.replyOnClick(`${DONATION_ID_PREFIX}${item.id}`)
                  }}
                />
                {/* *NOTE: Need refactor. */}

                {index === array.length - 1 ? (
                  <>
                    {[...Array(NUMBER_OF_COLUMNS)].map((item) => (
                      <Divider style={{ margin: '6px', border: 'none' }} />
                    ))}
                  </>
                ) : (
                  <>
                    {[...Array(NUMBER_OF_COLUMNS)].map((item) => (
                      <StyledDivider />
                    ))}
                  </>
                )}
              </>
            )
          })}
        </TableContainer>
      </DesktopOnly>
      <BobaPagination
        current={props.pagination.current}
        showSizeChanger={false}
        defaultCurrent={props.pagination.defaultCurrent}
        pageSize={props.pagination.pageSize}
        size={'small'}
        total={props.pagination.total}
        onChange={props.pagination.onChange}
      />
    </StyledBobaBoardContainer>
  )
}

const StyledBobaBoardContainer = styled((props) => (
  <BobaBoardContainer {...props} />
))`
  &[data-desktop] {
    .display-cell-container {
      font-size: 12px;
    }
  }
  &[data-mobile] {
    .display-cell-container {
      font-size: 12px;
    }
  }
`

const VerticalTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 36px;
`

const SubItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`

const SubItemTitle = styled.div`
  color: #4a1e11;
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 8px;
`

const TableContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr 1fr 1fr;
  border-bottom: #ad5138 solid 1px;
  margin-bottom: 32px;
`

const Topic = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  color: #4a1e11;
  margin: 0 20px;
`

const MessageCellContainer = styled.div`
  background-color: #f2f2f2;
  border-radius: 12px;
  &[data-desktop] {
    display: flex;
    padding: 12px;
    margin-left: 20px;
  }
  &[data-mobile] {
    display: flex;
    flex-direction: column;
    padding: 8px 12px 16px;
    margin-bottom: 12px;
  }
`

const MessageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const NameComponent = styled.div`
  flex: 1;
  color: #8b8b8b;
  font-size: 14px;
`

const Price = styled.div`
  color: #a55a24;
  font-weight: 600;
  &[data-desktop] {
    font-size: 12px;
    margin-right: 16px;
  }
  &[data-mobile] {
    align-self: end;
    font-size: 12px;
  }
`

const DateComponent = styled.div`
  color: #929292;
  font-weight: 500;
  &[data-desktop] {
    font-size: 12px;
  }
  &[data-mobile] {
    align-self: end;
    font-size: 10px;
  }
`

const Message = styled.div`
  color: #ab5337;
  border: 1px #ab5337 solid;
  &.empty-message {
    color: #979594;
  }
  &[data-desktop] {
    min-height: 44px;
    font-size: 16px;
    border-radius: 12px;
    padding: 8px 20px;
  }
  &[data-mobile] {
    font-size: 12px;
    border-radius: 24px;
    padding: 12px;
  }
`

const DisplayCellContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #bab9b9;
  text-align: center;
  margin: 0 20px;
  & .pointer {
    cursor: pointer;
  }
`

const RepostCellContainer = styled((props) => (
  <DisplayCellContainer {...props} />
))`
  color: #cca78c;
  margin: 0 20px;
`

const ReplyCellContainer = styled((props) => (
  <DisplayCellContainer {...props} />
))`
  color: #cccccc;
  &.active {
    color: #cca78c;
  }
  margin: 0 20px;
`

const StyledDivider = styled(Divider)`
  border-color: #8b8b8b;
  border-width: 0.5;
  margin: 12px 0;
`
