import React from 'react'
import { MobileSnackbioButton } from '../MobileSnackbioButton'
import styled from 'styled-components'
import { HomeFilled } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'

interface HomeNavButtonProps {
  className?: string
}

export function HomeNavButton({ className }: HomeNavButtonProps) {
  const history = useHistory()

  return (
    <StyledMobileSnackbioButton
      className={className}
      onClick={() => {
        history.push('/')
      }}
    >
      <HomeFilled style={{ color: '#999999' }} size={24} />
    </StyledMobileSnackbioButton>
  )
}

const StyledMobileSnackbioButton = styled(MobileSnackbioButton)``
