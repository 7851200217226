import React from 'react'
import styled from 'styled-components/macro'
import { Pagination, PaginationProps } from '../..'

export const BobaPagination = (props: PaginationProps) => {
  return <StyledPagination {...props} />
}

export type PaginationType = {
  current: number
  defaultCurrent: number
  pageSize: number
  total: number
  onChange: (page: number, pageSize?: number | undefined) => void
}

const StyledPagination = styled(Pagination)`
  display: flex;
  justify-content: center;

  > li.ant-pagination-prev,
  > li.ant-pagination-next {
    > button {
      border: none;
      background: none;
    }
  }

  > li.ant-pagination-item-active {
    font-weight: bold;
  }

  > li.ant-pagination-item-active:hover a,
  > li.ant-pagination-item:hover a,
  > li.ant-pagination-item-active:focus-visible a,
  > li.ant-pagination-item:focus-visible a {
    color: #929292;
    border-color: #929292;
  }

  > li.ant-pagination-item-active::selection {
    background: unset;
  }

  > li {
    background: unset;
    border: none;
  }

  > li > a {
    color: #929292;
  }
`
