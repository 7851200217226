import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { BobaBarWithCharacter } from '../BobaBarWithCharacter'
import { PrimaryButton } from '../PrimaryButton'
import messages from './messages'

export const BobaDonatorWelcomeModalContent = (props) => {
  const intl = useIntl()
  const { onClick } = props
  return (
    <Container {...props}>
      <Title children={intl.formatMessage(messages.welcomeTitle)} />
      <Subtitle
        children={intl.formatMessage(messages.welcomeSubtitle, { br: <br /> })}
      />
      <BobaBarWithCharacter
        style={{ width: '200px' }}
        fullName={intl.formatMessage(messages.welcomeCheering)}
      />
      <Remarks children={intl.formatMessage(messages.welcomeRemarks)} />
      <PrimaryButton
        onClick={onClick}
        content={intl.formatMessage(messages.okay)}
      />
    </Container>
  )
}

const Container = styled.div`
  text-align: center;
  display: grid;
  gap: 24px;
  place-items: center;
  font-family: '${({ theme }) => theme.bobaFontFamily.primary}';
  color: #8b8888;
`

const Title = styled.div`
  color: #ab5337;
  font-size: 24px;
  font-weight: 700;
`

const Subtitle = styled.div`
  font-size: 16px;
`

const Remarks = styled.div`
  font-size: 12px;
`
