import React from 'react'
import styled from 'styled-components/macro'
import { PrimaryButton, PrimaryButtonProps } from '../../PrimaryButton'
import { ReactComponent as CopyIcon } from '../../../assets/boba-tools/copyIcon.svg'
import { message } from 'antd'
import ReactDOMServer from 'react-dom/server'
import { ServerStyleSheet } from 'styled-components/macro'
import { useIntl } from 'react-intl'
import {
  BobaLoaderSVG,
  useResponsive,
  primaryColorToTextColorConvertor,
} from '@anyonelab/common'
import { motion } from 'framer-motion'
import messages from '../messages'

interface BobaPillIconProps {
  backgroundColor: string
  content: string | React.ReactNode
  domain?: string
}

const BobaPillIcon = ({
  backgroundColor,
  content,
  domain,
}: BobaPillIconProps) => {
  return (
    <BobaButton
      style={{
        backgroundColor,
        color: primaryColorToTextColorConvertor(backgroundColor),
        border: backgroundColor === '#FFFFFF' ? '1px solid #C4C4C4' : 'none',
      }}
      href={`https://${domain}.bobaboba.me`}
      target="_blank"
    >
      <img
        height="100%"
        src="https://s3.ap-southeast-1.amazonaws.com/media.anyonelab.com/images/boba/boba-embed-icon.png"
        alt="boba-icon"
      />
      <div style={{ flex: 1, textAlign: 'center' }} children={content} />
    </BobaButton>
  )
}

const BobaButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 164px;
  height: 40px;
  padding: 8px 16px;
  backdrop-filter: blur(20px);
  border-radius: 12px;
  color: white;
  text-decoration: none !important;

  &:hover {
    text-decoration: none !important;
  }
`

const sheet = new ServerStyleSheet()

const buttonBackground = ['#AD5138', '#C07C62', '#CCA78C', '#A0ADA6', '#FFFFFF']
const buttonContent = ['Give me a Boba!', '請我喝珍奶！']

interface EmbedContentProps {
  domain: string
}

function EmbedContent({ domain }: EmbedContentProps) {
  const intl = useIntl()
  const { isMobileLayout } = useResponsive()
  const [embedCode, setEmbedCode] = React.useState('')
  const [selectedBackgroundColor, setSelectedBackgroundColor] = React.useState(
    buttonBackground[0],
  )
  const [selectedContent, setSelectedContent] = React.useState(buttonContent[0])

  const copyEmbedCode = () => {
    navigator.clipboard.writeText(embedCode)
    message.success('copiedEmbedCode')
  }

  React.useLayoutEffect(() => {
    if (selectedBackgroundColor && selectedContent) {
      const htmlString = ReactDOMServer.renderToString(
        sheet.collectStyles(
          <BobaPillIcon
            backgroundColor={selectedBackgroundColor}
            content={selectedContent}
            domain={domain}
          />,
        ),
      )
      const styleTags = sheet.getStyleTags()
      setEmbedCode(`<blockquote>${styleTags + htmlString}</blockquote>`)
    }
  }, [selectedContent, selectedBackgroundColor])

  return (
    <Container className={isMobileLayout ? 'mobile' : 'desktop'}>
      <Title
        className="title"
        children={intl.formatMessage(messages.embededContentTitle)}
      />
      <BobaButtonContainer className="button-container">
        {buttonContent.map((content, i) => {
          return buttonBackground.map((backgroundColor, j) => {
            const isSelected =
              content === selectedContent &&
              backgroundColor === selectedBackgroundColor

            return (
              <motion.div
                whileTap={{ scale: 0.9 }}
                children={
                  <BobaButtonBox
                    className={isSelected ? 'is-selected' : ''}
                    key={backgroundColor + content}
                    onClick={() => {
                      setSelectedContent(content)
                      setSelectedBackgroundColor(backgroundColor)
                    }}
                  >
                    <BobaPillIcon
                      backgroundColor={backgroundColor}
                      content={content}
                    />
                    <div className="blocker" />
                  </BobaButtonBox>
                }
              />
            )
          })
        })}
      </BobaButtonContainer>
      <EmbedCodeContainer>{embedCode}</EmbedCodeContainer>
      <motion.div
        whileTap={{ scale: 0.9 }}
        children={
          <StyledPrimaryButton
            className="button"
            content={
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CopyIcon fill="#FFFFFF" />
                {intl.formatMessage(messages.copyCode)}
              </div>
            }
            onClick={copyEmbedCode}
          />
        }
      />
    </Container>
  )
}

export default EmbedContent

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  &.mobile {
    .title {
      margin-bottom: 32px;
    }
    .button-container {
      grid-template-columns: repeat(2, 1fr);
      font-size: 10px;
    }
  }

  &.desktop {
    .title {
      margin-bottom: 24px;
    }
    min-width: 450px;
    max-width: 550px;
    .button-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }
`

const Title = styled.div`
  font-family: '${({ theme }) => theme.bobaFontFamily.title}';
  font-weight: 700;
  font-size: 28px;
  color: ${({ theme }) => theme.bobaColor.textPrimary};
  text-align: center;
  line-height: 33px;
`

const BobaButtonContainer = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
`

const BobaButtonBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10px;
  cursor: pointer;

  .blocker {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &.is-selected {
    outline: 1.75px solid #ad5138;
    border-radius: 16px;
  }
`

const EmbedCodeContainer = styled.div`
  width: 100%;
  max-height: 120px;
  overflow-y: scroll;
  border: 3.5px solid #ab5337;
  border-radius: 24px;
  margin: 24px;
  padding: 8px 24px;
`

const StyledPrimaryButton = styled((props: PrimaryButtonProps) => (
  <PrimaryButton padding="0" {...props} />
))`
  flex: 1;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  width: 240px;
  padding: 10px;
`
