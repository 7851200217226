import { LeftSquareOutlined } from '@ant-design/icons'
import { adjustHue } from 'polished'
import {
  ProfileSplashWidgetBackgroundColors,
  ProfileSplashWidgetConfigSchema,
  GradientColorType,
} from './types'

export const getNonNullableKey = (
  obj: ProfileSplashWidgetConfigSchema,
): string => {
  const index = Object.entries(obj).findIndex((item) => item[1])
  const key = Object.keys(obj)[index]

  return key
}

export const convertBackgroundToStyle = (
  data: ProfileSplashWidgetConfigSchema,
  isMobileLayout: boolean,
  isWaitingThumbnail: boolean,
): object => {
  if (data.backgroundDesktopImageURL) {
    if (isWaitingThumbnail) {
      const url = data.backgroundDesktopImageURL.replace(
        'thumbnail',
        'original',
      )
      return {
        backgroundImage: `url(${url})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }
    } else {
      const url = data.backgroundDesktopImageURL
      return {
        backgroundImage: `url(${url})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }
    }
  }

  const key = getNonNullableKey(data)
  const color = data[key]

  switch (key) {
    case 'backgroundColor':
      let hexColor = color
      if (color[0] !== '#') {
        hexColor = '#' + color // previous background color no "#" but only number
      }
      return {
        backgroundImage: `linear-gradient(151.99deg, ${hexColor} 15.42%, ${adjustHue(
          -30,
          hexColor,
        )} 80.63%)`,
      }
    case 'singleColor':
      return {
        backgroundColor: `${color}`,
      }
    case 'gradientColor':
      return {
        backgroundImage: `linear-gradient(151deg, ${color.reduce(
          (prev: GradientColorType | null, curr: GradientColorType) => {
            if (!prev) {
              return `${curr.color} ${curr.offset * 100}%`
            } else {
              return `${prev} , ${curr.color} ${curr.offset * 100}%`
            }
          },
          null,
        )})`,
      }
    default:
      return {
        backgroundColor: '#FFFFFF',
      }
  }
}
