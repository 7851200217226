import { LinkButtonWidget } from '@anyonelab/frontend/models/Widget'
import { WidgetConfigUpdate } from '@anyonelab/frontend/types/Widget'
import { FontFamily } from '../TextWidget/enum'
import { LINK_BLOCK_WIDGET_ID } from './constants'
import { LinkButtonType } from './enum'

export const availableLinkButtonUrlTypes = [
  'external',
  // 'internal',
  'email',
  'whatsapp',
  'phoneNumber',
] as const

export type LinkButtonUrlType = typeof availableLinkButtonUrlTypes[number]

export type PhoneNumberType = Extract<
  LinkButtonUrlType,
  'whatsapp' | 'phoneNumber'
>

export interface LinkBlockWidgetConfigSchema {
  id: string
  type: LinkButtonUrlType
  buttonType: LinkButtonType
  fontFamily: FontFamily
  fontSize: string
  fontStyle: string
  fontWeight: string
  fontColor: string
  backgroundColor: string
  externalUrl: string
  buttonText: string
}

export type LinkBlockWidgetConfigUpdate<
  InstanceIdType = string
> = WidgetConfigUpdate<
  typeof LINK_BLOCK_WIDGET_ID,
  InstanceIdType,
  LinkButtonWidget
>
