import {
  AnalyticsContext,
  ProfileSplashCreatorWidget,
  PublicPageEvent,
  track,
} from '@anyonelab/common'
import { Page } from '@anyonelab/public-page/models/Page'
import * as React from 'react'
import { useIntl } from 'react-intl'

import styled from 'styled-components/macro'
import { NotFoundPage } from '../NotFoundPage/NotFoundPage'
import messages from './messages'
import _ from 'lodash'

function convertPageToLinkPageSchema(_page: Page): any {
  const page = _.cloneDeep(_page)
  page.widget_object.widgetList.forEach((widget) => {
    switch (widget.type) {
      case 'socialMediaButtonGroup': {
        // handle some old version social media data widget data and hardcode assign a color to them
        Object.keys(widget.data).forEach((socialType) => {
          if (typeof widget.data[socialType] === 'string') {
            widget.data[socialType.replace('Url', '')] = {
              // in case some is using old version facebookUrl, tiktokUrl change to new version facebook , tiktok
              url: widget.data[socialType],
              color: '#FFFFFF',
            }
            if (socialType !== 'email') {
              delete widget.data[socialType]
            }
          }
        })
        break
      }
      case 'linkButton': {
        // handle some old version button without type data and hardcode assign a type to them
        widget.data.type = widget.data.type ? widget.data.type : 'external'
        break
      }
    }
  })

  return {
    id: page.id,
    url: page.url,
    isPublished: page.is_published,
    profileSplash: page.widget_object.profileSplash,
    widgetList: page.widget_object.widgetList,
  }
}

export interface PublicPageProps {
  username: string
  data: Page
}

export const PublicPage = ({ username, data }: PublicPageProps) => {
  const { amplitudeInit } = React.useContext(AnalyticsContext)
  React.useEffect(() => {
    amplitudeInit &&
      track(PublicPageEvent.pageView, {
        url: data.url,
        userId: data.user_id,
        pageId: data.id,
      })
  }, [amplitudeInit, data.id, data.url, data.user_id])

  return (
    <DashboardPageStyled>
      <ProfileSplashCreatorWidget
        domain={username}
        instanceId="profile"
        instanceLabel="profile"
        data={data.widget_object.profileSplash}
        elementData={convertPageToLinkPageSchema(data).widgetList as any}
        displayMode={true}
      />
    </DashboardPageStyled>
  )
}

const DashboardPageStyled = styled.div``
