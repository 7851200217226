import React from 'react'
import styled from 'styled-components/macro'
import { PrimaryTextInput, PrimaryTextInputProps } from '../PrimaryTextInput'

export const PrimaryTextInputOutlined = (props: PrimaryTextInputProps) => {
  return <OutlineInput {...props} />
}

const OutlineInput = styled(PrimaryTextInput)`
  border-color: ${({ borderColor }) => borderColor};
  font-weight: 400;
  font-size: ${({ theme }) => theme.fontSize['12']};
  color: #929292;
  background-color: #fafafa;
  border-radius: 12px;
  box-sizing: border-box;
  box-shadow: unset;
  overflow: hidden;
  &.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: #ab5337;
  }
  > .ant-input {
    font-size: ${({ theme }) => theme.fontSize['12']};
    color: #929292;
    background-color: #fafafa;
  }
`
