import { Form, message, Modal } from 'antd'
import React from 'react'
import styled, { css } from 'styled-components'
import { PrimaryButton } from '../../PrimaryButton'
import { PrimaryTextInput } from '../../PrimaryTextInput'
import {
  AnalyticsContext,
  BobaCharacterDisplayDeadEye,
  BobaItem,
  BobaLoader,
  BobaMePublicPageEvent,
  openInNewTab,
  track,
} from '@anyonelab/common'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import { BobaCurrency, BobaDonationDetail } from '../../type'
import { useIntl } from 'react-intl'
import messages from '../messages'

interface PaymentContentFormProps {
  donorBobaUserId?: number
  email?: string
  name?: string
  note?: string
  currency?: BobaCurrency
  is_public_by_supporter?: boolean
  fullName?: string
  domain: string
  boba_donation_details: BobaDonationDetail[]
  handleCreatePaymentApiCall: (data) => Promise<any>
  totalDonatedAmount: number
  setPaymentIsSuccess: React.Dispatch<React.SetStateAction<boolean>>
}

function PaymentContentForm({
  donorBobaUserId,
  email,
  name,
  fullName,
  note,
  currency,
  is_public_by_supporter,
  domain,
  boba_donation_details,
  handleCreatePaymentApiCall,
  totalDonatedAmount,
  setPaymentIsSuccess,
}: PaymentContentFormProps) {
  const [paymentFormValues, setPaymentFormValues] = React.useState({
    email,
  })

  const onValuesChange = async (changedValues: any, formValues: any) => {
    setPaymentFormValues({
      ...formValues,
      ...changedValues,
    })
  }

  const stripe = useStripe()
  const elements = useElements()
  const intl = useIntl()
  const TERMS_AND_POLICY_LINK = 'https://bobaboba.me/privacy-policy'
  const [isLoading, setIsLoading] = React.useState(false)
  const [isPaymentSuccessful, setIsPaymentSuccessful] = React.useState<
    boolean | null
  >(null)

  const { amplitudeInit } = React.useContext(AnalyticsContext)

  const handleStripePayment = async (ev) => {
    ev.preventDefault()
    setIsLoading(true)
    setIsPaymentSuccessful(null)
    amplitudeInit && track(BobaMePublicPageEvent.confirmPaymentClick, {})
    if (!elements || !stripe || isLoading) return
    try {
      const cardNumber = elements.getElement('cardNumber')
      const cardExpiry = elements.getElement('cardExpiry')
      const cardCvc = elements.getElement('cardCvc')
      if (cardNumber && cardExpiry && cardCvc) {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardNumber,
        })

        if (error) {
          console.error('create payment method fail')
          message.error('payment fail')
          amplitudeInit && track(BobaMePublicPageEvent.paymentFailed, {})
        }
        if (!paymentMethod) return

        const result = await handleCreatePaymentApiCall({
          donor_boba_user_account_id: donorBobaUserId,
          email: paymentFormValues.email,
          token: paymentMethod.id,
          name,
          note,
          domain,
          boba_donation_details: boba_donation_details,
          is_public_by_supporter,
        })

        if (result?.actionRequired) {
          // * action mean 3D secure authentication
          console.log('payment action required')

          const { paymentIntent, error } = await stripe.confirmCardPayment(
            result?.clientSecret,
          )

          await handleCreatePaymentApiCall({
            paymentData: result.data.paymentData,
            donorData: result.data.donorData,
            domain,
            paymentIntentId: result.id,
          })
        }

        setPaymentIsSuccess(true)
        setIsPaymentSuccessful(true)
        amplitudeInit && track(BobaMePublicPageEvent.paymentSuccess, {})
      }
    } catch (err) {
      console.error(err)
      setIsPaymentSuccessful(false)
    }
    setIsLoading(false)
  }

  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'absolute',
            zIndex: 1000,
            margin: 'auto',
            justifyContent: 'center',
            backgroundColor: '#fafafa',
            padding: '24px 120px 60px',
            borderRadius: '64px',
          }}
        >
          <div
            children={intl.formatMessage(messages.paymentProcessingMessage)}
          />
          <BobaLoader
            style={{
              position: 'static',
              transform: 'none',
              height: '90px',
              marginTop: '40px',
            }}
          />
        </div>
      ) : (
        <></>
      )}
      {isPaymentSuccessful === false ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            position: 'absolute',
            zIndex: 1000,
            margin: 'auto',
            justifyContent: 'center',
            backgroundColor: '#fafafa',
            padding: '24px 120px 60px',
            borderRadius: '64px',
          }}
        >
          <div children={intl.formatMessage(messages.paymentFailedMessage)} />
          <BobaCharacterDisplayDeadEye
            style={{
              height: '90px',
              marginTop: '40px',
            }}
          />
        </div>
      ) : (
        <></>
      )}
      <Title
        children={intl.formatMessage(messages.supportCreator, {
          creator: fullName,
        })}
      />
      <Subtitle
        children={intl.formatMessage(messages.totalCharged, {
          currency: currency?.toUpperCase(),
          amount: totalDonatedAmount,
        })}
      />
      <Form
        style={{ width: '100%' }}
        className="form"
        name="nest-messages"
        layout="vertical"
        initialValues={paymentFormValues}
        onValuesChange={onValuesChange}
      >
        <StyledFormItem
          name={'email'}
          label={intl.formatMessage(messages.email)}
        >
          <StyledPrimaryTextInput
            disabled={Boolean(email)}
            placeholder={intl.formatMessage(messages.email).toLowerCase()}
            value={'email'}
          />
        </StyledFormItem>
      </Form>
      <Subtitle children={intl.formatMessage(messages.description)} />
      <form style={{ width: '100%' }}>
        <StyledCardNumberElement />
        <div style={{ display: 'flex', gap: '16px' }}>
          <StyledCardExpiryElement />
          <StyledCardCvcElement />
        </div>
        <PrimaryButton
          content={intl.formatMessage(messages.submit)}
          onClick={handleStripePayment}
        />
        <Remark
          children={intl.formatMessage(messages.remark, {
            br: <br />,
            u: (text) => (
              <div
                style={{ textDecoration: 'underline' }}
                onClick={() => openInNewTab(TERMS_AND_POLICY_LINK)}
                children={text}
              />
            ),
          })}
        />
      </form>
    </>
  )
}

export default PaymentContentForm

const Title = styled.div`
  font-family: '${({ theme }) => theme.bobaFontFamily.title}';
  font-weight: 800;
  font-size: 28px;
  color: ${({ theme }) => theme.bobaColor.textPrimary};
  white-space: nowrap;
`

const Subtitle = styled.div`
  font-family: 'Roboto';
  font-size: ${({ theme }) => theme.fontSize[20]};
  color: #929292;
  display: flex;
  justify-content: center;
  align-items: center;
`

const inputCss = css`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize[16]};
  font-weight: 400;
  box-sizing: border-box;
  border-radius: 24px;
  height: 60px;
  padding: 16px 24px;
  color: #929292;
  box-shadow: unset;
  border: unset;
  background: #f4f4f4;
  margin: 8px 0px;
`

const StyledCardNumberElement = styled(CardNumberElement)`
  ${inputCss}
`

const StyledCardExpiryElement = styled(CardExpiryElement)`
  ${inputCss}
`

const StyledCardCvcElement = styled(CardCvcElement)`
  ${inputCss}
`

const Remark = styled.div`
  color: #908f8f;
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  text-align: center;
`

const StyledFormItem = styled(Form.Item)`
  .ant-form-item-label > label {
    font-family: ${({ theme }) => theme.bobaFontFamily.secondary};
    font-size: ${({ theme }) => theme.fontSize[16]};
    font-weight: 600;
    color: ${({ theme }) => theme.bobaColor.textPrimary};
  }
`

const StyledPrimaryTextInput = styled(PrimaryTextInput)`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize[16]};
  font-weight: 400;
  box-sizing: border-box;
  border-radius: 24px;
  height: 60px;
  padding: 16px 24px;
  color: #929292;
  box-shadow: unset;
  border: unset;
  background: #f4f4f4;
  > .ant-input {
    background: #f4f4f4;
    font-size: inherit;
    width: 100%;
  }
`
