import * as React from 'react'

import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import messages from './messages'
import {
  useWidget,
  withWidget,
  usePageBuilder,
  openInNewTab,
  track,
  SocialMediaLinkButtonConfigurationEvent,
  LinkPortalEvent,
  PublicPageEvent,
} from '@anyonelab/common'
import {
  SocialMediaLinkButtonWidgetConfigSchema,
  SocialMediaType,
} from '../types'
import {
  SocialMediaLinkButtonWidgetConfigDefaults,
  SOCIAL_MEDIA_LINK_BUTTON_WIDGET_ID,
} from '../constants'
import { SocialMediaLinkButtonWidgetConfig } from '../config'
import {
  FacebookOutlined,
  InstagramOutlined,
  YoutubeFilled,
  YoutubeOutlined,
  TwitterOutlined,
  LinkedinOutlined,
} from '@ant-design/icons'
import { FaTiktok } from 'react-icons/fa'
import { AiOutlineMail } from 'react-icons/ai'
import { theme } from '@anyonelab/common/theme'
import {
  SocialMediaButtonGroupWidget,
  SocialMediaButtonType,
  WidgetType,
} from '@anyonelab/frontend/models/Widget'
export interface SocialMediaLinkButtonWidgetCreatorProps {
  data: SocialMediaButtonGroupWidget
}

export const SocialMediaLinkButtonWidgetCreator = withWidget<
  SocialMediaLinkButtonWidgetCreatorProps
>(
  {
    widgetId: SOCIAL_MEDIA_LINK_BUTTON_WIDGET_ID,
    configComponent: SocialMediaLinkButtonWidgetConfig,
  },
  ({ data = SocialMediaLinkButtonWidgetConfigDefaults }) => {
    // NOTE: Use Dom to remove the <div className="ant-space-item"/> that does not contain any <a> element within, because the ones without <a> are the component that don't have user input.
    // NOTE: This is bad way to solve the issue but we will implement this for temporary fix, refactor later.
    // React.useEffect(() => {
    //   if (!data) return

    //   const elements = document.querySelector(
    //     `.widget-SOCIAL_MEDIA_LINK_BUTTON .${data.type}`,
    //   )
    //   if (!elements) return

    //   if (elements.parentElement && elements.parentElement.parentElement) {
    //     elements.parentElement.parentElement.hidden = !!!data.url
    //   }
    // }, [data])

    const DEFAULT_SOCIAL_ICON_SIZE = 24

    const intl = useIntl()

    const { selectWidget, displayMode } = useWidget()

    const renderIcon = (type: SocialMediaButtonType) => {
      switch (type) {
        case 'facebook':
          return <FacebookOutlined size={DEFAULT_SOCIAL_ICON_SIZE} />
        case 'instagram':
          return <InstagramOutlined size={DEFAULT_SOCIAL_ICON_SIZE} />
        case 'youtube':
          return <YoutubeOutlined size={DEFAULT_SOCIAL_ICON_SIZE} />
        case 'twitter':
          return <TwitterOutlined size={DEFAULT_SOCIAL_ICON_SIZE} />
        case 'tiktok':
          return <FaTiktok size={DEFAULT_SOCIAL_ICON_SIZE} />
        case 'linkedin':
          return <LinkedinOutlined size={DEFAULT_SOCIAL_ICON_SIZE} />
        case 'email':
          return <AiOutlineMail size={DEFAULT_SOCIAL_ICON_SIZE} />
      }
    }

    const getSocialLink = (type: SocialMediaButtonType, data: string) => {
      switch (type) {
        case 'facebook':
          return `https://www.facebook.com/${data}`
        case 'instagram':
          return `https://www.instagram.com/${data}`
        case 'youtube':
          return `https://www.youtube.com/${data}`
        case 'twitter':
          return `https://www.twitter.com/${data}`
        case 'tiktok':
          return `https://www.tiktok.com/${data}`
        case 'linkedin':
          return `https://www.linkedin.com/in/${data}`
        case 'email':
          return `mailto:${data}`
      }
    }

    const getEventName = (type: SocialMediaButtonType) => {
      switch (type) {
        case 'facebook':
          return 'public_page_social_media_link_button_facebook_click'
        case 'instagram':
          return 'public_page_social_media_link_button_instagram_click'
        case 'youtube':
          return 'public_page_social_media_link_button_youtube_click'
        case 'twitter':
          return 'public_page_social_media_link_button_twitter_click'
        case 'tiktok':
          return 'public_page_social_media_link_button_tiktok_click'
        case 'email':
          return 'public_page_social_media_link_button_email_click'
        case 'linkedin':
          return 'public_page_social_media_link_button_linkedin_click'
        default:
          break
      }
    }

    return (
      <SocialMediaLinkButtonWidgetContainer onClick={selectWidget}>
        {(Object.keys(data) as Array<keyof SocialMediaButtonGroupWidget>).map(
          (item) => {
            return (
              <SocialMediaLinkButtonWidgetStyled
                key={item}
                className={item}
                displayMode={displayMode}
                displayButton={Boolean(data[item]?.url)}
                onClick={() => {
                  if (displayMode) {
                    openInNewTab(getSocialLink(item, data[item].url))
                    const subdomain =
                      window.location.pathname.replaceAll('/', '') ||
                      window.location.hostname.split('.')[0]
                    const eventName = getEventName(item)
                    eventName && track(eventName, { username: subdomain })
                  } else {
                    track(LinkPortalEvent.socialMediaLinkButtonClick)
                  }
                }}
                color={data[item].color || 'white'}
              >
                {renderIcon(item)}
              </SocialMediaLinkButtonWidgetStyled>
            )
          },
        )}
      </SocialMediaLinkButtonWidgetContainer>
    )
  },
)

const SocialMediaLinkButtonWidgetContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`

const SocialMediaLinkButtonWidgetStyled = styled.a<{
  displayMode: boolean
  displayButton: boolean
  color: string
}>`
  display: ${({ displayButton }) => (displayButton ? 'flex' : 'none')};
  width: 40px;
  height: 40px;
  text-align: center;
  justify-content: center;
  align-items: center;
  line-height: 40px;
  color: white;
  position: relative;
  transition: ${({ displayMode }) =>
    displayMode ? 'all 0.2s ease 0s' : undefined};

  .anticon {
    font-size: 24px;
  }

  svg {
    color: ${({ color }) => color};
  }

  &:hover,
  &:active {
    color: white;
    transform: ${({ displayMode }) =>
      displayMode ? 'scale(1.06) translate(0px, -4px)' : undefined};
  }
`
