import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { BASE_API_URL } from '../config'
import { getCookie } from '../utils/cookies'

export const apiClient = axios.create({
  baseURL: BASE_API_URL,
})

// const getToken = () => {
//   try {
//     const accessToken = localStorage.getItem('JWT_TOKEN')
//     return accessToken
//   } catch (err) {
//     console.log(err)
//   }
// }

// const setAuthorizationHeader = (
//   config: AxiosRequestConfig,
// ): AxiosRequestConfig => {
//   try {
//     if (!config) {
//       config = {}
//     }
//     if (!config.headers) {
//       config.headers = {}
//     }

//     config.headers.Authorization = `Bearer ${getToken()}`

//     return config
//   } catch (err) {
//     console.log(err)
//     return config
//   }
// }

// apiClient.interceptors.request.use(setAuthorizationHeader)

export function setApiClientAccessToken(
  apiClient: AxiosInstance,
  accessToken: string,
) {
  const setAuthorizationHeader = (
    config: AxiosRequestConfig,
  ): AxiosRequestConfig => {
    try {
      if (!config) {
        config = {}
      }
      if (!config.headers) {
        config.headers = {}
      }

      config.headers.Authorization = `Bearer ${accessToken}`

      return config
    } catch (err) {
      console.log(err)
      return config
    }
  }

  apiClient.interceptors.request.use(setAuthorizationHeader)
}
