import { BobaCurrency } from '../boba-components'

export const convertCurrencyToSymbol = (input: BobaCurrency): string => {
  switch (input) {
    case 'hkd':
    case 'twd':
    case 'usd':
    default:
      return '$'
  }
}
