import React from 'react'
import styled, { useTheme } from 'styled-components'
import { ReactComponent as CaretUp } from '@anyonelab/common/src/assets/CaretUp.svg'
import { ReactComponent as CaretDown } from '@anyonelab/common/src/assets/CaretDown.svg'
import { ReactComponent as BackgroundIcon } from '@anyonelab/common/src/assets/background-icon.svg'
import { ReactComponent as TitleIcon } from '@anyonelab/common/src/assets/title-icon.svg'
import { ReactComponent as SubtitleIcon } from '@anyonelab/common/src/assets/subtitle-icon.svg'
import { ReactComponent as SocialMediaIcon } from '@anyonelab/common/src/assets/social-media-icon.svg'
import { ReactComponent as AvatarIcon } from '@anyonelab/common/src/assets/avatar-icon.svg'
import { ReactComponent as ThemeIcon } from '@anyonelab/common/src/assets/theme-icon.svg'
import { ReactComponent as HTMLEmbedIcon } from '@anyonelab/common/src/assets/html-embed-icon.svg'
import { WidgetType } from '@anyonelab/frontend/models/Widget'
import { LinkOutlined } from '@ant-design/icons'
import { BobaCircleIcon } from '@anyonelab/common/'

export type BlockType = 'theme' | keyof WidgetType

export type ConfigPanelBlockProps = {
  title?: string
  blockType: BlockType
  onClick?: () => void
  promoteOnClick?: () => void
  demoteOnClick?: () => void
} & React.HTMLProps<HTMLDivElement>

const ConfigPanelBlockIcon = (props: { type: BlockType }) => {
  switch (props.type) {
    case 'avatar':
      return <AvatarIcon />
    case 'titleLabel':
      return <TitleIcon />
    case 'subtitleLabel':
      return <SubtitleIcon />
    case 'linkButton':
      return <LinkOutlined />
    case 'socialMediaButtonGroup':
      return <SocialMediaIcon />
    case 'leadForm':
      return <HTMLEmbedIcon />
    case 'profileSplash':
      return <BackgroundIcon />
    case 'theme':
      return <ThemeIcon />
    case 'bobame':
      return <BobaCircleIcon size={24} />
    default:
      return <></>
  }
}

export const ConfigPanelBlock = (props: ConfigPanelBlockProps) => {
  const { title, onClick, promoteOnClick, demoteOnClick, blockType } = props
  return title ? (
    <BlockContainer
      blockType={props.blockType}
      onClick={(event) => onClick && onClick()}
    >
      <IconContainer
        children={<ConfigPanelBlockIcon type={props.blockType} />}
      />
      <span>{title}</span>
      <ButtonContainer>
        {promoteOnClick ? (
          <ButtonWrapper
            onClick={(event) => {
              event.stopPropagation()
              promoteOnClick()
            }}
            children={<CaretUp />}
          />
        ) : (
          <></>
        )}
        {demoteOnClick ? (
          <ButtonWrapper
            onClick={(event) => {
              event.stopPropagation()
              demoteOnClick()
            }}
            children={<CaretDown />}
          />
        ) : (
          <></>
        )}
      </ButtonContainer>
    </BlockContainer>
  ) : (
    <></>
  )
}

const BlockContainer = styled.div<{ blockType: BlockType }>`
  background-color: ${({ blockType, theme }) =>
    blockType === 'bobame' ? '#F8E0BE' : theme.colors.background};
  color: ${({ blockType, theme }) =>
    blockType === 'bobame' ? '#AD5138' : 'black'};
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius[16]};
  font-family: 'Roboto';
  font-weight: 700;
  line-height: 24px;
  font-size: 16px;
  box-sizing: border-box;
  position: relative;
  padding: 12px 32px;
  user-select: none;
  cursor: pointer;
  > span {
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 12px;
  }
`

const IconContainer = styled.div`
  position: absolute;
  left: 16px;
  top: 0;
  height: 100%;
  display: grid;
  place-items: center;
  width: 24px;
`

const ButtonContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  cursor: pointer;
`
