import axios from 'axios'
import feathers from '@feathersjs/client'
import socketio from '@feathersjs/socketio-client'
import io from 'socket.io-client'
import { message } from '@anyonelab/common/'
import { BASE_API_URL } from '../config'
import { auth } from '../app/services/auth/firebase'

// TODO: set this up in @anyonelab/frontend/config
const socket = io(BASE_API_URL)

// Setup the transport (Rest, Socket, etc.) here
const client: any = feathers()
client.configure(socketio(socket))
client.configure(feathers.authentication())

export const apiClient = axios.create({
  // if api is on the same domain, we do not need it here

  // TODO: set this up in @anyonelab/frontend/config
  baseURL: BASE_API_URL,
})

// TODO: better solution to cache token
let apiToken

export const getToken = async () => {
  try {
    if (apiToken) return apiToken
    const token = await auth.currentUser?.getIdToken()

    let authenticatedUser = await client.authenticate({
      strategy: 'firebase',
      access_token: token,
    })

    return authenticatedUser.accessToken
  } catch (err) {
    console.log(err)
  }
}

apiClient.interceptors.request.use(async (config) => {
  config.headers.Authorization = `Bearer ${await getToken()}`

  return config
})

apiClient.interceptors.response.use(undefined, function (error) {
  if (error.name === 'Timeout' && error.code === 408) {
    message.warning('Timeout! please login again')
    auth.signOut()
    return Promise.reject(error)
  }
})
