import {
  BobaDefaulticonList,
  Radio,
  useImageUrlChecker,
} from '@anyonelab/common/'
import { apiClient } from '@anyonelab/frontend/utils/api-client'
import React from 'react'
import { useIntl } from 'react-intl'
import messages from '../messages'
import styled from 'styled-components'
import { Form, message, Spin } from 'antd'
import clsx from 'clsx'
import { useResponsive } from '../../../providers/ResponsiveProvider/ResponsiveContext'

export const IconRadioGroup = ({ updateOnClick, imageUrl, defaultIconKey }) => {
  const { isMobileLayout } = useResponsive()
  const inputFile = React.useRef<HTMLInputElement | null>(null)
  const [selectedDefaultIconKey, setSelectedDefaultIconKey] = React.useState(
    defaultIconKey,
  )
  const [isWaitingImageUpload, setIsWaitingImageUpload] = React.useState(false)
  const { isWaitingThumbnail } = useImageUrlChecker(imageUrl)
  const intl = useIntl()

  const containerStyle = clsx({
    mobile: isMobileLayout,
    desktop: !isMobileLayout,
  })

  return (
    <>
      <input
        ref={inputFile}
        name="avatar_image_url"
        type="file"
        accept=".jpg,.jpeg,.png"
        style={{ display: 'none' }}
        onChange={async (e) => {
          if (!e.target || !e.target.files || e.target.files.length === 0)
            return

          setIsWaitingImageUpload(true)
          try {
            const formData = new FormData()
            formData.append('uri', e.target.files[0])
            const response = await apiClient.post(
              `/image-upload?type=bobaAvatar`,
              formData,
            )

            updateOnClick({
              avatar_image_url: response.data.image_name,
            })

            setIsWaitingImageUpload(false)
          } catch (err) {
            console.error(err)
            setIsWaitingImageUpload(false)
            message.error(intl.formatMessage(messages.uploadFailed))
          }
        }}
      />
      <StyledRadio className={containerStyle} value={false}>
        <Title>{intl.formatMessage(messages.photoUpload)}</Title>
        <div
          onClick={() => {
            if (inputFile && inputFile.current) {
              inputFile.current.click()
            }
          }}
          style={{
            cursor: 'pointer',
            width: '90px',
            height: '90px',
            borderRadius: '50%',
            backgroundColor: '#E4E4E4',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '4px',
          }}
        >
          {isWaitingImageUpload ? (
            <Spin />
          ) : !imageUrl ? (
            <div className="text">
              {intl.formatMessage(messages.imageLimit, { br: <br /> })}
            </div>
          ) : (
            <img
              src={
                isWaitingThumbnail
                  ? imageUrl.replace('thumbnail', 'original')
                  : imageUrl
              }
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '50%',
              }}
              alt="avatar"
            />
          )}
        </div>
      </StyledRadio>
      <StyledRadio value={true} className={containerStyle}>
        <Title>{intl.formatMessage(messages.defaultBobaAvatar)}</Title>
        <div
          style={{
            marginTop: '4px',
          }}
        >
          <Form.Item // ! Missing selected border styling
            name="default_boba_avatar_key"
            required
          >
            <Radio.Group
              defaultValue={defaultIconKey}
              style={{
                display: 'flex',
                flexWrap: 'wrap',
              }}
              onChange={(e) => setSelectedDefaultIconKey(e.target.value)}
            >
              {BobaDefaulticonList.map(({ icon, key }, index) => {
                const style = clsx({
                  border: true,
                  isSelected: selectedDefaultIconKey === key,
                })
                return (
                  <StyledRadioButton
                    className={style}
                    key={index}
                    value={key}
                    children={<div className={style}>{icon}</div>}
                  />
                )
              })}
            </Radio.Group>
          </Form.Item>
        </div>
      </StyledRadio>
    </>
  )
}

const StyledRadio = styled(Radio)`
  &.desktop {
    margin: 0px 16px;
  }
  &.mobile {
    margin: 4px 0px;
  }

  .ant-radio-inner:after {
    background: #ad5138 !important;
  }

  .ant-radio-checked .ant-radio-inner,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-wrapper:hover,
  .ant-radio-input:focus .ant-radio-inner {
    border-color: #ad5138 !important;
  }

  .text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #9f9f9f;
  }

  .ant-radio-checked::after {
    border: 1px solid #ad5138;
  }
`

const StyledRadioButton = styled(Radio)`
  padding: 0px;
  margin: 0px;

  .ant-radio {
    display: none;
  }

  .border {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border-width: 8px;
    border-style: solid;
    border-color: transparent;
  }
  .isSelected {
    border-color: #d6d6d6;
  }
`

const Title = styled.div`
  color: #62463e;
  font-weight: 500;
  font-family: '${({ theme }) => theme.bobaFontFamily.primary}';
`
