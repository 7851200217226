import React, { HTMLAttributes, useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import moment from 'moment'
import { ReactComponent as CopyIcon } from './../../assets/copy-button.svg'
import { Divider, Spin } from 'antd'
import {
  AppLogo,
  getDailyColor,
  getDailyEmoji,
  LoadingOutlined,
  useResponsive,
} from '../..'
import Lottie from 'lottie-react'
import BobaWatcherSvg from '../../lottie/boba-watcher.json'
import { motion } from 'framer-motion'

export type QuoteBoardProps = {
  quote?: string
  quoteIsLoading?: boolean
  author?: string
  type?: 'vertical' | 'horizontal'
} & HTMLAttributes<HTMLDivElement>

export const QuoteBoard = (props: QuoteBoardProps) => {
  const { quote, quoteIsLoading, author, type } = props
  const intl = useIntl()
  const { responsiveClassName } = useResponsive()
  const [dateTime, setDateTime] = useState(moment(new Date()))
  const weekday = dateTime.format('ddd')
  const { primary: primaryColor, secondary: secondaryColor } = getDailyColor(
    weekday,
  )
  const dailyEmoji = getDailyEmoji(weekday)
  const copyOnClick = () => {
    navigator.clipboard.writeText(quote || '')
  }
  React.useEffect(() => {
    const timeout = setInterval(() => {
      const date = new Date()
      setDateTime(moment(date))
    }, 1000)
    return () => clearInterval(timeout)
  }, [])

  if (type === 'horizontal') {
    return (
      <Container
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        className={`${type} container ${responsiveClassName}`}
      >
        <ClockContainer className="clock">
          <DateComponent className="date" children={dateTime.format('MM.DD')} />
          <Divider
            className="divider"
            style={{ margin: '8px 0', borderColor: primaryColor }}
          />
          <YearComponent
            className="year"
            children={`${weekday.toUpperCase()} ${dateTime
              .format('YYYY')
              .toUpperCase()}`}
          />
          <TimeComponent
            className="time"
            children={dateTime.format('A hh:mm')}
          />
        </ClockContainer>
        <QuoteContainer className="quote-container">
          {quoteIsLoading ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'grid',
                placeItems: 'center',
              }}
            >
              <Spin indicator={<LoadingOutlined spin />} />
            </div>
          ) : quote && author ? (
            <>
              <Quote className="quote" children={`"${quote}"`} />
              <Author className="author" children={`—— ${author}`} />
            </>
          ) : (
            <></>
          )}
        </QuoteContainer>
        <EmotionContainer className="emotion">
          <BobaWatcher
            className="boba-watcher"
            children={
              <Lottie
                animationData={BobaWatcherSvg}
                loop
                style={{ margin: 'none', width: '100%' }}
              />
            }
          />
        </EmotionContainer>
        <CopyButton
          className="copy-button"
          children={
            <motion.div
              whileTap={{ scale: 0.75 }}
              children={<CopyIcon onClick={copyOnClick} />}
            />
          }
        />
      </Container>
    )
  } else if (type === 'vertical') {
    return (
      <Container
        primaryColor={primaryColor}
        secondaryColor={secondaryColor}
        className={`${type} container ${responsiveClassName}`}
      >
        <ClockContainer className="clock">
          <DateComponent className="date" children={dateTime.format('MM.DD')} />
          <Divider
            className="divider"
            style={{ margin: '8px 0', borderColor: primaryColor }}
          />
          <YearComponent
            className="year"
            children={`${weekday.toUpperCase()} ${dateTime
              .format('YYYY')
              .toUpperCase()}`}
          />
          <TimeComponent
            className="time"
            children={dateTime.format('A hh:mm')}
          />
        </ClockContainer>
        <EmotionContainer className="emotion">
          <BobaWatcher
            className="boba-watcher"
            children={
              <Lottie
                animationData={BobaWatcherSvg}
                loop
                style={{ margin: 'none', width: '100%' }}
              />
            }
          />
          <Emoji className="emoji" children={dailyEmoji} />
        </EmotionContainer>
        <QuoteContainer className="quote-container">
          {quoteIsLoading ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'grid',
                placeItems: 'center',
              }}
            >
              <Spin indicator={<LoadingOutlined spin />} />
            </div>
          ) : quote && author ? (
            <>
              <Quote className="quote" children={`"${quote}"`} />
              <Author className="author" children={`—— ${author}`} />
            </>
          ) : (
            <></>
          )}
        </QuoteContainer>
        <AppLogo />
        <CopyButton
          className="copy-button"
          children={
            <motion.div
              whileTap={{ scale: 0.75 }}
              children={<CopyIcon onClick={copyOnClick} />}
            />
          }
        />
      </Container>
    )
  } else {
    return <></>
  }
}

const Container = styled.div<{ primaryColor: string; secondaryColor: string }>`
  width: 100%;
  border-radius: 12px;
  position: relative;
  background-color: ${({ secondaryColor }) => secondaryColor};
  color: ${({ primaryColor }) => primaryColor};
  display: grid;
  &.horizontal {
    grid-template-columns: 80px 1fr;
    grid-template-rows: 1fr auto;
    grid-gap: 20px;
    padding: 24px 24px 4px;
    .copy-button {
      justify-self: end;
      align-self: end;
      margin-bottom: 20px;
    }
    .boba-watcher {
      margin-bottom: -26px;
    }
    &.tablet-small {
      .date {
        font-size: 28px;
        line-height: 28px;
      }
      .year,
      .time {
        font-size: 16px;
      }
    }
    &.tablet-big,
    &.desktop {
      .date {
        font-size: 32px;
        line-height: 32px;
      }
      .year,
      .time {
        font-size: 18px;
      }
    }
  }
  &.vertical {
    padding: 48px 42px 20px;
    grid-gap: 48px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto 1fr auto;
    .quote-container {
      grid-column: span 2;
    }
    .emotion {
      display: flex;
      width: 100%;
      flex-direction: column;
      .boba-watcher {
        flex: 1;
        display: flex;
        justify-content: center;
      }
    }
    .copy-button {
      justify-self: end;
    }
    .date {
      font-size: 10vw;
    }
    .year,
    .time {
      font-size: 6vw;
    }
    .emoji {
      font-size: 6vw;
      display: grid;
      place-items: center;
    }
  }
`

const ClockContainer = styled.div`
  align-self: start;
  display: grid;
  width: max-content;
  text-align: center;
  gap: 4px;
`

const DateComponent = styled.div``

const YearComponent = styled.div``

const TimeComponent = styled.div``

const EmotionContainer = styled.div`
  width: max-content;
`

const Emoji = styled.div``

const BobaWatcher = styled.div``

const QuoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`

const Quote = styled.div`
  margin-bottom: 12px;
`

const Author = styled.div``

const CopyButton = styled.div`
  color: #90959a;
  > * {
    cursor: pointer;
  }
`
