// NOTE: The purpose of this function is to validate whether the input string is a valid url, return true or false if it is valid or invalid.
// NOTE: In order to be valid, the inputUrl must starts with http:// or https:// and have proper domain name or ip (v4) address, optional port, query string or fragment locator.
// NOTE: modified from https://stackoverflow.com/a/5717133/6122411

export const isValidUrl = (inputUrl: string): boolean => {
  let pattern = new RegExp(
    // NOTE: Protocol
    '^(https?:\\/\\/)' +
      // NOTE: Domain name
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' +
      // OR ip (v4) address
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      // Port and path
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+@\\u4e00-\\u9fa5]*)*' +
      // Query string
      '(\\?[;&a-z\\u4e00-\\u9fa5\\d%_.~+=-]*)?' +
      // OPTIONAL fragment locator
      '(\\#[-a-z\\d_]*)?$',
    'i',
  )
  return !!pattern.test(inputUrl)
}
