import { MotionProps } from 'framer-motion'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { WideRoundedButton } from '../../../../frontend/src/app/components/WideRoundedButton'
import messages from './messages'

export const SaveButton = (
  props: MotionProps & React.HTMLAttributes<HTMLDivElement>,
) => {
  const intl = useIntl()
  return (
    <StyledWideRoundedButton {...props}>
      {intl.formatMessage(messages.save)}
    </StyledWideRoundedButton>
  )
}

const StyledWideRoundedButton = styled(WideRoundedButton)`
  background-color: ${({ theme }) => theme.colors.pinkLight};
  color: ${({ theme }) => theme.colors.pinkDark};
  user-select: none;
`
