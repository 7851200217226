import { defineMessages } from 'react-intl'

export default defineMessages({
  title: {
    defaultMessage: 'Successful payment 😋 {br}Thanks for your support.',
  },
  subtitle: {
    defaultMessage:
      'Download the pic and repost on your instagram. {br}Share happiness with your friends!',
  },
  downloadPicture: { defaultMessage: 'Download Pic' },
  supportCreator: {
    defaultMessage: 'Support {creator}',
  },
  email: {
    defaultMessage: 'Email',
  },
  totalCharged: {
    defaultMessage: 'Total charged {currency} ${amount}.',
  },
  description: {
    defaultMessage: 'Enter your credit card information:',
  },
  submit: {
    defaultMessage: 'Confirm payment',
  },
  remark: {
    defaultMessage:
      'After payment，you’ll receive a special receipt. By continuing, you agree to our <u>terms and privacy policy</u>.',
  },
  bobaBarTitle: {
    defaultMessage: 'Your Boba Bar is open!',
  },
  bobaBarDescription: { defaultMessage: 'Copy link and share it' },
  bobaBarSubmit: {
    defaultMessage: 'Shake your own boba',
  },
  bobaShakerTitle: {
    defaultMessage: 'Hey Boba Shaker!',
  },
  bobaShakerDescription: {
    defaultMessage: 'You can edit Boba information in the “setting”.',
  },
  bobaShakerSubmit: {
    defaultMessage: 'Got it!',
  },
  paymentProcessingMessage: {
    defaultMessage: 'Payment processing...',
  },
  paymentFailedMessage: {
    defaultMessage: 'Payment Failed!',
  },
  copyCode: {
    defaultMessage: 'Copy Code',
  },
  embededContentTitle: {
    defaultMessage: 'Embed Boba in your website',
  },
  paymentSuccessMessage: {
    defaultMessage: 'Successful payment!{br}Thanks for support 🥺',
  },
  thankyouDefaultMessage1: {
    defaultMessage:
      'Thank you so much! A cup of your donation, give me 100% of my creative energy.',
  },
  seeReceiptMessage: {
    defaultMessage: 'Look your special receipt',
  },
  welcomeTitle: { defaultMessage: 'Welcome to BobaMe😋' },
  welcomeSubtitle: {
    defaultMessage:
      "You haven't opened your boba bar yet!{br}Open your bar now to receive others love🧡",
  },
  welcomeRemarks: {
    defaultMessage:
      'P.S. You can click the menu button to view the purchase history.',
  },
  okay: { defaultMessage: 'Okay' },
  welcomeCheering: { defaultMessage: 'Hooray!' },
})
