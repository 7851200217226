import * as React from 'react'

import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import messages from './messages'
import {
  Form,
  Input,
  FormProps,
  Select,
  Divider,
  Button,
  isValidUrl,
  isValidEmail,
  addHttpsPrefix,
  hasHttpPrefix,
  track,
  LinkBlockConfigurationEvent,
  ArrowDownOutlined,
  ArrowUpOutlined,
  usePageBuilder,
} from '@anyonelab/common'
import { LINK_BLOCK_WIDGET_ID } from '../constants'
import {
  availableLinkButtonUrlTypes,
  LinkBlockWidgetConfigSchema,
} from '../types'
import { withWidgetConfig } from '@anyonelab/common'
import { camelCase, capitalCase, sentenceCase } from 'change-case'
import { LinkButtonType } from '../enum'
import { FontFamily } from '../../TextWidget/enum'
import { notification, Slider } from 'antd'
import { getPlaceholder } from '../utils/placeholderGenerator'
import { getCountryLocalisationLabels } from '../utils/countryLocalisationLabelsGetter'
import 'react-phone-number-input/style.css'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'
import { E164Number } from 'libphonenumber-js/min'
import { LinkButtonWidget } from '@anyonelab/frontend/models/Widget'

export interface LinkBlockWidgetConfigProps {
  data: LinkButtonWidget
}

export const LinkBlockWidgetConfig = withWidgetConfig<
  LinkBlockWidgetConfigProps
>(
  {
    widgetId: LINK_BLOCK_WIDGET_ID,
  },
  ({ data, onChange, onRemove, onPromote, onDemote, onDuplicate }) => {
    const intl = useIntl()

    const [phoneNumber, setPhoneNumber] = React.useState<
      E164Number | undefined
    >('')

    const [form] = Form.useForm<LinkButtonWidget>()

    const initialValues: LinkButtonWidget = {
      ...data,
    }

    const onValuesChange: FormProps<LinkButtonWidget>['onValuesChange'] = (
      changedValues: Partial<LinkButtonWidget>,
      formValues,
    ) => {
      const resetExternalUrl = () => {
        form.setFieldsValue({ ...formValues, url: '' })
        formValues.url = ''
      }

      const isSwitchingBetweenUrlAndPhoneNumber = (): boolean => {
        // NOTE: Should refactor in a more scalable way.
        return Boolean(
          ((initialValues.type === 'external' ||
            initialValues.type === 'email') &&
            (changedValues.type === 'phoneNumber' ||
              changedValues.type === 'whatsapp')) ||
            ((initialValues.type === 'phoneNumber' ||
              initialValues.type === 'whatsapp') &&
              (changedValues.type === 'external' ||
                changedValues.type === 'email')),
        )
      }

      changedValues.hasOwnProperty('type') &&
        isSwitchingBetweenUrlAndPhoneNumber() &&
        resetExternalUrl()

      onChange({
        ...data,
        ...formValues,
      })
    }

    const checkUrl = (event: React.FocusEvent<HTMLInputElement>): void => {
      const input = event.currentTarget.value
      let resultIsValid = true
      switch (data.type) {
        case 'email':
          resultIsValid = isValidEmail(input)
          break
        case 'phoneNumber':
        case 'whatsapp':
          resultIsValid = isPossiblePhoneNumber(input)
          break
        case 'external':
        default:
          const parsedUrl = hasHttpPrefix(input) ? input : addHttpsPrefix(input)
          resultIsValid = isValidUrl(parsedUrl)
          break
      }
      // NOTE: Show error message.
      if (!resultIsValid) {
        notification.error({
          key: 'link_block_invalid_link',
          message: intl.formatMessage(messages.oops),
          description: intl.formatMessage(messages.pleaseCheckForTyposMessage, {
            link: event.currentTarget.value,
            urlType: intl.formatMessage(messages[data.type]),
          }),
        })
      } else {
        // Clear the notification if the link is valid again.
        notification.close('link_block_invalid_link')
      }
    }

    const externalUrlOnFocus = (): void => {
      track(LinkBlockConfigurationEvent.externalUrlInputOnChange)
    }

    const contentOnFocus = (): void => {
      track(LinkBlockConfigurationEvent.textInputOnChange)
    }

    const backgroundColorPickerOnFocus = (): void => {
      track(LinkBlockConfigurationEvent.backgroundColorOnChange)
    }

    const backgroundColorOpacityOnFocus = (): void => {
      track(LinkBlockConfigurationEvent.backgroundColorOpacityOnChange)
    }

    const fontColorPickerOnFocus = (): void => {
      track(LinkBlockConfigurationEvent.fontColorOnChange)
    }

    return (
      <LinkBlockWidgetConfigStyled>
        <Form
          form={form}
          initialValues={initialValues}
          onValuesChange={onValuesChange}
          layout="vertical"
        >
          <Form.Item label={intl.formatMessage(messages.linkType)} name="type">
            <Select>
              {availableLinkButtonUrlTypes.map((item, index) => {
                return (
                  <Select.Option key={item + 'link-type-option'} value={item}>
                    {intl.formatMessage(messages[item])}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label={intl.formatMessage(messages[data.type])}
            name="url"
            required
          >
            {data.type === 'phoneNumber' || data.type === 'whatsapp' ? (
              <PhoneInput
                international
                labels={getCountryLocalisationLabels(intl.locale)}
                placeholder={getPlaceholder(data.type)}
                value={phoneNumber}
                onChange={setPhoneNumber}
                onFocus={externalUrlOnFocus}
                onBlur={checkUrl}
              />
            ) : (
              <Input
                type={data.type === 'email' ? 'email' : 'url'}
                placeholder={getPlaceholder(data.type)}
                onFocus={externalUrlOnFocus}
                onBlur={checkUrl}
              />
            )}
          </Form.Item>

          <Form.Item
            label={intl.formatMessage(messages.linkButtonText)}
            name="title"
            required
          >
            <Input.TextArea
              onFocus={contentOnFocus}
              autoSize={{ minRows: 3, maxRows: 5 }}
            />
          </Form.Item>

          <Divider />

          <Form.Item
            label={intl.formatMessage(messages.buttonType)}
            name="buttonType"
          >
            <Select defaultActiveFirstOption>
              {Object.keys(LinkButtonType).map((button) => {
                return (
                  <Select.Option key={button} value={LinkButtonType[button]}>
                    {intl.formatMessage(messages[camelCase(button)])}
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label={intl.formatMessage(messages.font)}
            name="fontFamily"
          >
            <Select value={data.fontFamily}>
              {Object.keys(FontFamily).map((type) => {
                return (
                  <Select.Option value={FontFamily[type]}>
                    <span
                      style={{
                        fontFamily: capitalCase(FontFamily[type]),
                      }}
                    >
                      {capitalCase(type, { stripRegexp: /[\p{L}]/gi })}
                    </span>
                  </Select.Option>
                )
              })}
            </Select>
          </Form.Item>

          <Form.Item
            label={intl.formatMessage(messages.fontSize)}
            name="fontSize"
          >
            <Select value={data.fontSize}>
              <Select.Option value={'38px'}>
                {intl.formatMessage(messages['38pt'])}
              </Select.Option>
              <Select.Option value={'30px'}>
                {intl.formatMessage(messages['30pt'])}
              </Select.Option>
              <Select.Option value={'24px'}>
                {intl.formatMessage(messages['24pt'])}
              </Select.Option>
              <Select.Option value={'20px'}>
                {intl.formatMessage(messages['20pt'])}
              </Select.Option>
              <Select.Option value={'16px'}>
                {intl.formatMessage(messages['16pt'])}
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={intl.formatMessage(messages.fontStyle)}
            name="fontStyle"
          >
            <Select value={data.fontStyle}>
              <Select.Option value={'normal'}>
                {intl.formatMessage(messages.normal)}
              </Select.Option>
              <Select.Option value={'italic'}>
                {intl.formatMessage(messages.italic)}
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={intl.formatMessage(messages.fontWeight)}
            name="fontWeight"
          >
            <Select value={data.fontWeight}>
              <Select.Option value={'normal'}>
                {intl.formatMessage(messages.normal)}
              </Select.Option>
              <Select.Option value={'bold'}>
                {intl.formatMessage(messages.bold)}
              </Select.Option>
              <Select.Option value={'lighter'}>
                {intl.formatMessage(messages.light)}
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={intl.formatMessage(messages.fontColor)}
            name="fontColor"
          >
            <Input
              onFocus={fontColorPickerOnFocus}
              value={data.fontColor}
              type="color"
            />
          </Form.Item>

          <Form.Item
            label={intl.formatMessage(messages.backgroundColor)}
            name="backgroundColor"
          >
            <Input
              onFocus={backgroundColorPickerOnFocus}
              value={data.backgroundColor}
              type="color"
            />
          </Form.Item>

          <Form.Item
            label={intl.formatMessage(messages.backgroundColorOpacity)}
            name="backgroundColorOpacity"
          >
            <Slider
              value={data.backgroundColorOpacity}
              tipFormatter={(value) => {
                return value + '%'
              }}
              onChange={backgroundColorOpacityOnFocus}
              defaultValue={100}
              max={100}
              min={0}
            />
          </Form.Item>
        </Form>

        <Divider />
        <Button
          onClick={() => onRemove()}
          children={intl.formatMessage(messages.delete)}
        />
        <Button
          onClick={() => {
            onDuplicate()
          }}
          children={intl.formatMessage(messages.duplicate)}
        />
      </LinkBlockWidgetConfigStyled>
    )
  },
)

const LinkBlockWidgetConfigStyled = styled.div``
