import React from 'react'
import styled from 'styled-components/macro'
import { Modal, ModalProps } from '../..'
import { LoginPopUpContext } from './components'

interface LoginPopUpModalProps extends ModalProps {
  targetOrigin: string
  onClose: () => void
  visible: boolean
}

export function LoginPopUpModal({
  targetOrigin,
  ...props
}: LoginPopUpModalProps) {
  return (
    <StyledBobaResponsiveModal {...props}>
      <LoginPopUpContext targetOrigin={targetOrigin} />
    </StyledBobaResponsiveModal>
  )
}

const StyledBobaResponsiveModal = styled((props) => (
  <Modal
    centered
    width={1000}
    visible={props.visible}
    onCancel={props.onClose}
    footer={null}
    {...props}
  />
))`
  .ant-modal-content {
    border-radius: 20px;
  }
`
