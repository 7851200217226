/**
 *
 * PageBuilderWorkspace
 *
 */
import * as React from 'react'

import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import messages from './messages'

export interface PageBuilderWorkspaceProps {}

export const PageBuilderWorkspace = ({
  children,
}: React.PropsWithChildren<PageBuilderWorkspaceProps>) => {
  const intl = useIntl()

  return <PageBuilderWorkspaceStyled>{children}</PageBuilderWorkspaceStyled>
}

const PageBuilderWorkspaceStyled = styled.div``
