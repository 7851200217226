import axios from 'axios'
import { apiClient } from '../apiClient'
import { BobaPaymentRequest } from './schema/boba-request.schema'

// ! Public API
export const createDonationPayment = (data: BobaPaymentRequest) => {
  try {
    return apiClient.post(`/boba-payment`, data).then((res) => res.data)
  } catch (err) {
    throw err
  }
}
