import { FontFamily } from '../TextWidget/enum'
import { LinkButtonType } from './enum'
import { WidgetType } from '@anyonelab/frontend/src/models/Widget'

export const LINK_BLOCK_WIDGET_ID = 'LINK_BLOCK'

export const LinkBlockWidgetConfigDefaults: WidgetType['linkButton'] = {
  type: 'external',
  url: '',
  title: '',
  fontSize: '16px',
  fontColor: '#000',
  fontStyle: 'normal',
  buttonType: LinkButtonType.FillPill,
  fontFamily: FontFamily.Arial,
  fontWeight: 'normal',
  titleEmoji: '',
  shouldDisplay: true,
  backgroundColor: '#ffffff',
  backgroundColorOpacity: 100,
  hasRoundedCorners: true,
}
