import { defineMessages } from 'react-intl'

export default defineMessages({
  title: { defaultMessage: 'Purchase History' },
  time: { defaultMessage: 'Time' },
  creator: { defaultMessage: 'Creator' },
  event: { defaultMessage: 'Event' },
  message: { defaultMessage: 'Message' },
  reply: { defaultMessage: 'Reply' },
  twd: { defaultMessage: 'TWD' },
  hkd: { defaultMessage: 'HKD' },
  usd: { defaultMessage: 'USD' },
  cup: {
    defaultMessage:
      '{itemCount, plural, =0 {0 Cups} =1 {# Cup} other {# Cups}}',
  },
  emptyMessage: { defaultMessage: 'Currently no donation history' },
})
