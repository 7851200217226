import styled from 'styled-components/macro'

export const BobaBoardTitle = styled.div`
  color: #4a1e11;
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
`

export const BobaBoardTitleDesktop = styled((props) => (
  <BobaBoardTitle className="title" {...props} />
))`
  font-size: 20px;
  text-align: start;
  margin-bottom: 20px;
`

export const BobaBoardTitleMobile = styled((props) => (
  <BobaBoardTitle className="title" {...props} />
))`
  font-size: 16px;
  text-align: center;
  margin-bottom: 16px;
`
