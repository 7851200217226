import { PageWidgetType } from '@anyonelab/frontend/models/Widget'

export const isEmptySocialMediaButtonGroup = (item: PageWidgetType) => {
  if (
    item.type === 'socialMediaButtonGroup' &&
    Object.values(item.data).every((value) => value.url === '')
  ) {
    return true
  } else {
    return false
  }
}
