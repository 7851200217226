import React from 'react'
import styled from 'styled-components/macro'

export const DashboardTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight[700]};
  font-family: '${({ theme }) => theme.bobaFontFamily.primary}';
  color: #929292;
  font-size: ${({ theme }) => theme.fontSize[16]};
  margin: ${({ theme }) => theme.padding[16]} 0;
`
