export const LEAD_FORM_WIDGET_ID = 'LEAD_FORM_BUTTON'
export const defaultSanitizeOptions = {
  // USE_PROFILES: {
  //   svg: true,
  //   svgFilters: true,
  //   html: true,
  //   mathMl: true,
  // },
  ADD_TAGS: ['iframe'],
  // ALLOWED_TAGS: [
  //   'b',
  //   'i',
  //   'em',
  //   'strong',
  //   'a',
  //   '',
  //   'iframe',
  //   'style',
  //   'html',
  //   'body',
  //   'blockquote',
  //   'center',
  //   'p',
  //   'br',
  //   'div',
  //   'svg',
  // ],
  // ALLOWED_ATTR: [
  //   'href',
  //   'lang',
  //   'dir',
  //   'class',
  //   'src',
  //   'width',
  //   'height',
  //   'style',
  //   'scrolling',
  //   'background',
  //   'frameborder',
  //   'target',
  //   'liquidHeightPercentage',
  //   'size',
  // ],
  // FORBID_ATTR: [],
  // FORBID_CONTENTS: [],
  FORBID_TAGS: ['script', 'img'],
}
