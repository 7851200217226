import React from 'react'
import styled from 'styled-components/macro'
import { ReactComponent as DesktopHeaderSVG } from '../../assets/boba-header/boba-navbar-desktop.svg'
import { ReactComponent as MobileHeaderSVG } from '../../assets/boba-header/boba-navbar-mobile.svg'
import {
  BillSummary,
  BobaMenu,
  DesktopOnly,
  MessageBoard,
  MobileOnly,
  ProfilePicture,
  useResponsive,
  BobaItem,
  Message as MessageType,
  calculateTotalDonation,
  BobaCharacterDisplay,
  BobaCharacterDisplayProps,
  BobaHomepageRibbonDesktop,
  BobaHomepageRibbonMobile,
  AnalyticsContext,
  BobaMePublicPageEvent,
  track,
  BobaEmbedPopupModal,
  PoweredByFooter,
} from '@anyonelab/common'
import { HomePageNavBar } from './HomePageNavBar'
import {
  GetBobaPublicUserAccountResponse,
  GetBobaUserAccountResponse,
} from '../schema/boba-response.schema'
import { BobaPaymentRequest } from '../schema/boba-request.schema'
import BobaPaymentPopupModal from '../BobaPopUpModal/BobaPaymentPopupModal'
import { BobaDonationDetail } from '../type'
import BobaSharePopupModal from '../BobaPopUpModal/BobaSharePopupModal'
import desktopTopRibbon from '../../assets/boba-desktop-homepage-top-ribbon.svg'
import mobileTopRibbon from '../../assets/boba-mobile-homepage-top-ribbon.svg'

type BobaPaymentRequestForm = Pick<
  BobaPaymentRequest,
  'name' | 'note' | 'is_public_by_supporter'
>

interface HomeProfileLayoutProps {
  BobaUserInformation:
    | GetBobaUserAccountResponse
    | GetBobaPublicUserAccountResponse
  bobaItems: BobaDonationDetail[] | null
  setBobaItems: React.Dispatch<React.SetStateAction<BobaDonationDetail[]>>
  bobaDonationsMessage: MessageType[] | null
  changeLocale?: () => void
  handleCreatePaymentApiCall?: (data) => Promise<any>
  displayMode?: boolean // *is public page
  isPublicPageAuthenticated?: boolean // *is public page logged in ? not relateed to snackbio or bobame
  loginPopClick?: () => void // *bobame donator login
  publicPageBobaUser?: GetBobaUserAccountResponse //* public page logged in and get boba user
  isLoggedInCheck?: boolean // *initial public page log in checking process is finished?
  postComment: (
    data: any,
    callback?: () => void,
    failCallback?: () => void,
  ) => void
  editComment: (
    id: number,
    data: any,
    callback?: () => void,
    failCallback?: () => void,
  ) => void
  removeComment: (
    id: number,
    data: any,
    callback?: () => void,
    failCallback?: () => void,
  ) => void
  donationMessageNextPage: () => void
}

export function HomeProfileLayout({
  BobaUserInformation,
  bobaItems,
  setBobaItems,
  bobaDonationsMessage,
  handleCreatePaymentApiCall = async () => undefined,
  changeLocale,
  displayMode = false, // from public page
  isPublicPageAuthenticated = false,
  publicPageBobaUser,
  loginPopClick,
  isLoggedInCheck,
  postComment,
  editComment,
  removeComment,
  donationMessageNextPage,
}: HomeProfileLayoutProps) {
  const { isMobileLayout } = useResponsive()
  const [popupModalIsOpen, setPopupModalIsOpen] = React.useState(false)
  const [sharePopUpModalIsOpen, setSharePopUpModalIsOpen] = React.useState(
    false,
  )
  const [embedPopUpModalIsOpen, setEmbedPopUpModalIsOpen] = React.useState(
    false,
  )
  const [paymentSupporterData, setPaymentSupporterData] = React.useState<
    BobaPaymentRequestForm
  >({
    name: '',
    note: '',
    is_public_by_supporter: true,
  })
  const [totalDonatedAmount, setTotalDonatedAmount] = React.useState(0)

  const { amplitudeInit } = React.useContext(AnalyticsContext)

  React.useEffect(() => {
    if (typeof window === 'undefined') return
    const { hash } = window.location
    if (hash === '') {
      window.scrollTo(0, 0)
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '')
        const element = document.getElementById(id)
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' })
          element.focus({ preventScroll: true })
        }
      }, 0)
    }
  }, [])
  // }, [pathname, hash, key]) // do this on route change

  const shareModalOpenToggle = () => {
    setSharePopUpModalIsOpen((prev) => !prev)
    amplitudeInit && track(BobaMePublicPageEvent.shareButtonClick)
  }

  const embedModalOpenToggle = () => {
    setEmbedPopUpModalIsOpen((prev) => !prev)
    // amplitudeInit && track(BobaMePublicPageEvent.shareButtonClick) // Todo @Louie add amplitude track
  }

  const modalOpenToggle = () => {
    setPopupModalIsOpen((prev) => !prev)
  }

  const openSnackBioTab = () => {
    const snackBioUrl = `https://${BobaUserInformation.domain}.anyonelab.com`
    window.open(snackBioUrl, '_blank')
    amplitudeInit && track(BobaMePublicPageEvent.snackBioButtonClick)
  }

  React.useEffect(() => {
    if (bobaItems) {
      setTotalDonatedAmount(calculateTotalDonation(bobaItems))
    }
  }, [bobaItems])

  return (
    <>
      <HomePageNavBar
        isLoggedInCheck={isLoggedInCheck}
        displayMode={displayMode}
        isPublicPageAuthenticated={isPublicPageAuthenticated}
        publicPageBobaUser={publicPageBobaUser}
        changeLocale={changeLocale}
        shareModalOpenToggle={shareModalOpenToggle}
        embedModalOpenToggle={embedModalOpenToggle}
        openSnackBioTab={openSnackBioTab}
        showSnackBioButton={BobaUserInformation.is_snack_bio_display}
      />
      <Container className={isMobileLayout ? 'mobile' : 'desktop'}>
        <DesktopOnly>
          <ShopTitleBar className="shop-title-bar">
            <StyledBobaCharacterDisplay
              direction="left"
              image={BobaUserInformation.avatar_image_url}
            />
            <ShopInfoContainer className="shop-info-container">
              <ShopName
                className="shop-name"
                children={BobaUserInformation?.full_name}
              />
              <ShopDescription
                className="shop-description"
                children={BobaUserInformation?.about_me}
              />
            </ShopInfoContainer>
            <StyledBobaCharacterDisplay
              className="boba-character"
              image={BobaUserInformation.avatar_image_url}
            />
          </ShopTitleBar>
          <div
            className="ribbon-container"
            children={<BobaHomepageRibbonDesktop className="ribbon" />}
          />
        </DesktopOnly>
        <MobileOnly>
          <ShopTitleBar className="shop-title-bar">
            <ShopName
              className="shop-name"
              children={BobaUserInformation?.full_name}
            />
            <ShopDescription
              className="shop-description"
              children={BobaUserInformation?.about_me}
            />
            <StyledBobaCharacterDisplay
              className="boba-character"
              filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
              image={BobaUserInformation.avatar_image_url}
            />
          </ShopTitleBar>
          <div
            className="ribbon-container"
            children={<BobaHomepageRibbonMobile className="ribbon" />}
          />
        </MobileOnly>
        <BobaContent className="boba-content">
          <BobaBarContent className="bobabar-content">
            {bobaItems && (
              <BobaMenu
                className="boba-menu"
                fullName={BobaUserInformation.full_name}
                currency={BobaUserInformation.currency}
                updateItems={setBobaItems}
                items={bobaItems}
              />
            )}
            {bobaItems && (
              <BillSummary
                isLoggedInCheck={isLoggedInCheck}
                isPublicPageAuthenticated={isPublicPageAuthenticated}
                publicPageBobaUser={publicPageBobaUser}
                loginPopClick={loginPopClick}
                name={BobaUserInformation.full_name}
                currency={BobaUserInformation.currency}
                totalDonatedAmount={totalDonatedAmount}
                bobaItems={bobaItems}
                paymentSupporterData={paymentSupporterData}
                setPaymentSupporterData={setPaymentSupporterData}
                displayMode={displayMode}
                supportOnClick={() => {
                  if (displayMode) {
                    modalOpenToggle()
                    track(BobaMePublicPageEvent.makeOrderClick, {
                      name: paymentSupporterData.name,
                      message: paymentSupporterData.note,
                      isPrivate: paymentSupporterData.is_public_by_supporter,
                      donationNumber: bobaItems,
                    })
                  }
                }}
              />
            )}
          </BobaBarContent>
          {bobaDonationsMessage && (
            <MessageBoard
              donationMessageNextPage={donationMessageNextPage}
              postComment={postComment}
              editComment={editComment}
              removeComment={removeComment}
              displayMode={displayMode}
              messages={bobaDonationsMessage}
            />
          )}
        </BobaContent>
        {displayMode ? <PoweredByFooter footerType="bobame" /> : <></>}
      </Container>

      <BobaSharePopupModal
        fullName={BobaUserInformation.full_name}
        avatarImageUrl={BobaUserInformation.avatar_image_url}
        domain={BobaUserInformation.domain}
        aboutMe={BobaUserInformation.about_me}
        visible={sharePopUpModalIsOpen}
        onClose={shareModalOpenToggle}
        displayMode={displayMode}
      />

      <BobaEmbedPopupModal
        visible={embedPopUpModalIsOpen}
        onClose={embedModalOpenToggle}
        domain={BobaUserInformation.domain}
      />

      {bobaItems &&
        displayMode && ( // * is modal only show if it is in public page
          <BobaPaymentPopupModal
            publicPageBobaUser={publicPageBobaUser}
            currency={BobaUserInformation.currency}
            fullName={BobaUserInformation.full_name}
            totalDonatedAmount={totalDonatedAmount}
            onClose={modalOpenToggle}
            visible={popupModalIsOpen}
            name={paymentSupporterData.name}
            note={paymentSupporterData.note}
            is_public_by_supporter={paymentSupporterData.is_public_by_supporter}
            domain={BobaUserInformation.domain}
            boba_donation_details={bobaItems}
            handleCreatePaymentApiCall={handleCreatePaymentApiCall}
            avatar={BobaUserInformation.avatar_image_url}
            message={BobaUserInformation.thank_you_message}
            isDefaultImage={
              BobaUserInformation.is_using_default_thank_you_image
            }
            image={BobaUserInformation.thank_you_image_url}
          />
        )}
    </>
  )
}

const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.bobaColor.backgroundPrimary};
  display: flex;
  flex-direction: column;
  align-items: center;

  &.mobile {
    padding-bottom: 70px;

    .shop-title-bar {
      background-color: #ad5138;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding-top: 60px;
      position: relative;
    }

    .boba-character {
      position: absolute;
      bottom: -80px;
      left: 52%;
      transform: translateX(-50%);
      z-index: 500;
    }

    .shop-name {
      font-size: 30px;
      font-weight: 600;
      padding: 8px 16px;
    }

    .shop-description {
      font-size: 12px;
      margin-bottom: 12px;
      padding: 16px;
    }

    .ribbon-container {
      position: relative;
      top: -1px;
      width: 100%;
      height: 64px;
    }

    .ribbon {
      width: 100%;
      height: auto;
      position: absolute;
    }

    .boba-content {
      width: 100%;
      padding: 24px;

      .bobabar-content {
        .boba-menu {
          margin-bottom: 16px;
        }
      }
    }
  }

  &.desktop {
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 100vh;
    min-width: fit-content;
    padding-bottom: 60px;

    .shop-title-bar {
      background-color: #ad5138;
      padding-top: 36px;
    }

    .ribbon-container {
      position: relative;
      top: -1px;
      width: 100%;
      height: 120px;
    }

    .ribbon {
      width: 100%;
      height: auto;
      position: absolute;
      top: -1px;
    }

    .shop-info-container {
      padding-top: 40px;
      max-width: 800px;

      .shop-name {
        margin-bottom: 24px;
        -webkit-line-clamp: 1; /* number of lines to show */
        line-clamp: 1;
        -webkit-box-orient: vertical;
      }

      .shop-description {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
        line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    .boba-content {
      padding: 0px 70px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .bobabar-content {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: stretch;

        .boba-menu {
          max-width: 640px;
          margin-right: 50px;
        }

        .bill {
          position: relative;
          min-width: 400px;
          max-width: 420px;
        }
      }

      .message-board {
        display: flex;
        width: 100%;
        max-width: 640px;
        padding: 20px;
        justify-content: center;
        align-self: start;
      }
    }
  }
`

const ShopTitleBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const ShopInfoContainer = styled.div``

const ShopName = styled.div`
  font-size: 54px;
  font-weight: ${({ theme }) => theme.fontWeight[700]};
  font-family: '${({ theme }) => theme.bobaFontFamily.title}';
  color: ${({ theme }) => theme.bobaColor.backgroundPrimary};
  letter-spacing: 0.04em;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`

const ShopDescription = styled.div`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fontWeight[400]};
  font-family: ${({ theme }) => theme.bobaFontFamily.secondary};
  color: white;
  white-space: wrap;
`

const BobaContent = styled.div``
const BobaBarContent = styled.div``

const StyledBobaCharacterDisplay = styled(
  (props: BobaCharacterDisplayProps) => (
    <BobaCharacterDisplay
      size={102}
      filter="drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))"
      {...props}
    />
  ),
)``
