import { CarouselRef } from 'antd/lib/carousel'
import _ from 'lodash'
import React, { HTMLAttributes } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import InfiniteScroll from 'react-infinite-scroller'
import {
  BobaDisplay,
  Carousel,
  LeftArrow,
  RightArrow,
  useResponsive,
} from '../..'
import { Message as MessageType } from '../type'
import { Message as MessageComponent } from './components/Message'
import messages from './messages'

type MessageBoardProps = {
  className?: string
  ownerName?: string
  messages: MessageType[]
  displayMode: boolean
  donationMessageNextPage: () => void
  postComment: (
    data: any,
    callback?: () => void,
    failCallback?: () => void,
  ) => void
  editComment: (
    id: number,
    data: any,
    callback?: () => void,
    failCallback?: () => void,
  ) => void
  removeComment: (
    id: number,
    data: any,
    callback?: () => void,
    failCallback?: () => void,
  ) => void
} & HTMLAttributes<HTMLDivElement>

// TODO: Update reply onclick

export const MessageBoard = (props: MessageBoardProps) => {
  const intl = useIntl()
  const { isMobileLayout, responsiveClassName } = useResponsive()
  const carouselRef = React.useRef<CarouselRef>(null)
  const {
    displayMode,
    messages: donationMessages,
    className,
    ownerName,
    postComment,
    editComment,
    removeComment,
    donationMessageNextPage,
  } = props

  if (donationMessages.length <= 0) return <></>

  return (
    <Container
      className={`${isMobileLayout ? 'mobile' : 'desktop'} message-board`}
    >
      <Title
        className="title"
        children={
          <div
            style={
              isMobileLayout
                ? {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }
                : {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }
            }
          >
            <BobaDisplay size={35} series="peach" />
            {intl.formatMessage(messages.title, {
              someone: ownerName || 'their',
            })}
          </div>
        }
      />

      {/* *NOTE: Disabled infinite scroll*/}
      <MessagesContainer
        className={`message-container ${responsiveClassName}`}
        pageStart={0}
        loadMore={donationMessageNextPage}
        hasMore={false}
      >
        {donationMessages.map((message) => {
          return (
            <StyledMessageComponent
              postComment={postComment}
              editComment={editComment}
              removeComment={removeComment}
              displayMode={displayMode}
              donationId={message.donationId}
              content={message.content}
              name={message.name}
              count={message.count}
              value={message.value}
              currency={message.currency}
              donor_boba_user_account={message?.donor_boba_user_account}
              creator_boba_user_account={message?.creator_boba_user_account}
              boba_donation_comments={message?.boba_donation_comments}
              isReplyable={true}
            />
          )
        })}
      </MessagesContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  &.mobile {
    font-size: ${({ theme }) => theme.fontSize[16]};
  }
  &.desktop {
    font-size: ${({ theme }) => theme.fontSize[24]};
  }
`

const Title = styled.div`
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  font-weight: bold;
  color: #4a1e11;
  text-align: center;
  padding: 20px 0px;
`

const MessagesContainer = styled((props) => <InfiniteScroll {...props} />)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 36px;
  margin-right: 10%;
  &.mobile {
    margin-right: unset;
  }
`

const CarouselContainer = styled.div`
  width: 88%;
`

const MobileMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`

const MobileMessageGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    margin: 0 auto 32px;
  }
`

const ArrowContainer = styled.div`
  width: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`

const LeftArrowContainer = styled(ArrowContainer)``
const RightArrowContainer = styled(ArrowContainer)``

const StyledMessageComponent = styled(MessageComponent)`
  width: 100%;
`
