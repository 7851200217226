import { BobameWidget, LeadFormWidget } from './../../../../models/Widget'
import {
  FontFamily,
  LabelWidget,
  LinkButtonWidget,
  LinkButtonType,
  ProfileSplashWidget,
  PageWidgetType,
  SocialMediaButtonGroupWidget,
  AvatarWidget,
} from '@anyonelab/frontend/models/Widget'

export const AvatarWidgetConfigDefaults: AvatarWidget = {
  imageUrl: '',
}

export const TitleWidgetConfigDefaults: LabelWidget = {
  text: 'Your Name!',
  fontSize: '38px',
  fontColor: '#8690ac',
  fontStyle: 'normal',
  fontFamily: FontFamily.Montserrat,
  fontWeight: 'bold',
}

export const SubtitleWidgetConfigDefaults: LabelWidget = {
  text: 'Introduce your cool identity (⁎⁍̴̛ᴗ⁍̴̛⁎)',
  fontSize: '16px',
  fontColor: '#808599',
  fontStyle: 'normal',
  fontFamily: FontFamily.Montserrat,
  fontWeight: 'normal',
}

export const LinkBlockWidgetConfigDefaults: LinkButtonWidget = {
  url: '',
  type: 'external',
  title: '',
  fontSize: '16px',
  fontColor: '#48637f',
  fontStyle: 'normal',
  buttonType: LinkButtonType.FillRounded,
  fontFamily: FontFamily.Raleway,
  fontWeight: 'normal',
  backgroundColor: '#eff2fb',
  backgroundColorOpacity: 100,
  titleEmoji: '',
  shouldDisplay: true,
  hasRoundedCorners: true,
}

const getLinkBlockWidgetConfigDefaults = (index: number): LinkButtonWidget => {
  const indexIsOdd = Boolean(index % 2)
  return {
    ...LinkBlockWidgetConfigDefaults,
    title: `Link ${index}`,
    backgroundColor: indexIsOdd ? '#eff2fb' : '#ccd3e5',
  }
}

export const ProfileSplashWidgetConfigDefaults: ProfileSplashWidget = {
  gradientColor: [
    {
      id: 1,
      color: 'rgb(230, 235, 251)',
      active: false,
      offset: '0.000',
      opacity: 1,
    },
    {
      id: 2,
      color: 'rgb(210, 218, 224)',
      active: true,
      offset: '1.000',
      opacity: 1,
    },
  ],
}

export const LeadFormWidgetConfigDefaults: LeadFormWidget = {
  htmlTag: '',
  name: '',
}

export const BobameWidgetConfigDefaults: BobameWidget = {
  primaryButton: {
    backgroundColor: '#AD5138',
    content: '',
  },
  secondaryButton: {
    backgroundColor: '#BF7C62',
    content: 'Give me a Boba!',
  },
}

export const SocialMediaWidgetConfigDefaults: SocialMediaButtonGroupWidget = {
  email: {
    url: 'enteryourmail',
    color: '#8890aa',
  },
  tiktok: {
    url: '',
    color: '#8890aa',
  },
  twitter: {
    url: '',
    color: '#8890aa',
  },
  youtube: {
    url: '',
    color: '#8890aa',
  },
  facebook: {
    url: 'enteryouraccount',
    color: '#8890aa',
  },
  instagram: {
    url: 'enteryouraccount',
    color: '#8890aa',
  },
}

export const WidgetConfigDefaults: PageWidgetType[] = [
  {
    type: 'avatar',
    data: AvatarWidgetConfigDefaults,
    id: 'b667c111-7728-49d6-82b3-edab18ec248c',
  },
  {
    type: 'titleLabel',
    data: TitleWidgetConfigDefaults,
    id: '2da91608-b7a3-438f-886b-0c196d42fa11',
  },
  {
    type: 'subtitleLabel',
    data: SubtitleWidgetConfigDefaults,
    id: '1e69ad7c-a8f6-4395-bd5b-66444e80c4fc',
  },
  {
    type: 'socialMediaButtonGroup',
    data: SocialMediaWidgetConfigDefaults,
    id: '57241765-5482-4c9a-9c75-fbbe631cb6a3',
  },
  {
    type: 'linkButton',
    data: getLinkBlockWidgetConfigDefaults(1),
    id: 'e1f1b7cb-fed2-4259-a2b1-d8dc32f5b2e8',
  },
  {
    type: 'linkButton',
    data: getLinkBlockWidgetConfigDefaults(2),
    id: '4da796da-c508-42f3-8d67-85252c01ac6c',
  },
  {
    type: 'linkButton',
    data: getLinkBlockWidgetConfigDefaults(3),
    id: 'bed051f0-8673-4ec9-afe6-f869036d0f37',
  },
]
