import { defineMessages } from 'react-intl'

export default defineMessages({
  avatar: {
    defaultMessage: 'Avatar',
  },
  title: {
    defaultMessage: 'Title',
  },
  subtitle: {
    defaultMessage: 'Subtitle',
  },
  linkBlock: {
    defaultMessage: 'Link Block',
  },
  socialMedia: {
    defaultMessage: 'Social Media',
  },
  facebook: {
    defaultMessage: 'Facebook',
  },
  instagram: {
    defaultMessage: 'Instagram',
  },
  youtube: {
    defaultMessage: 'YouTube',
  },
  twitter: {
    defaultMessage: 'Twitter',
  },
  tiktok: {
    defaultMessage: 'TikTok',
  },
  email: {
    defaultMessage: 'Email',
  },
  addNewLink: {
    defaultMessage: '+ Link',
  },
  addSocialMedia: {
    defaultMessage: '+ Social media',
  },
  addHtmlEmbed: {
    defaultMessage: '+ HTML Embed',
  },
  htmlEmbed: {
    defaultMessage: 'Html embed',
  },
  bobame: {
    defaultMessage: 'BobaMe Embed',
  },
  bobameConfigTitle: {
    defaultMessage: '+ Embed your BobaMe',
  },
})
