import { defineMessages } from 'react-intl'

export default defineMessages({
  numberOfCup: {
    defaultMessage: '{number, plural, =0 {0 cups} =1 {# cup} other {# cups}}',
  },
  emptyMessageReplacer: {
    defaultMessage: '(As light as the wind, leaving no trace.)',
  },
  reply: { defaultMessage: 'Reply' },
  cancel: { defaultMessage: 'Cancel' },
  postSucceeded: { defaultMessage: 'Post succeeded' },
  editSucceeded: { defaultMessage: 'Revise succeeded' },
  removeSucceeded: { defaultMessage: 'Remove succeeded' },
  postFailed: { defaultMessage: 'Post failed' },
  editFailed: { defaultMessage: 'Revise failed' },
  removeFailed: { defaultMessage: 'Remove failed' },
})
