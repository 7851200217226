import React from 'react'
import styled from 'styled-components/macro'
import { Switch, SwitchProps } from '../..'

export const BobaSwitch = (props: SwitchProps) => {
  return <StyledSwitch {...props} />
}

const StyledSwitch = styled((props) => <Switch {...props} />)`
  --antd-wave-shadow-color: #ab5337;
  &.ant-switch-checked {
    background-color: #ab5337;
  }
`
