import { CircleLogoStyled, LogoStyled } from '@anyonelab/common/'
import React from 'react'
import styled from 'styled-components/macro'

type AppLogoProps = {} & React.HTMLAttributes<HTMLDivElement>

export const AppLogo = (props: AppLogoProps) => {
  return (
    <LogoContainer {...props}>
      <CircleLogoStyled />
      <LogoStyled />
    </LogoContainer>
  )
}

const LogoContainer = styled.div`
  display: grid;
  grid-template-columns: min-content min-content;
  gap: 8px;
`
