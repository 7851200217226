import * as React from 'react'
import styled, { keyframes } from 'styled-components/macro'
import { ReactComponent as SpinnerLogo } from '../../assets/SpinnerLogo.svg'
import { ReactComponent as SpinnerAnimated } from '../../assets/Spinner.svg'

export const Spinner = () => {
  return (
    <SpinnerStyled>
      <SpinnerAnimatedStyled />
      <SpinnerLogoStyled />
    </SpinnerStyled>
  )
}

const SpinnerStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const SpinnerAnimatedStyled = styled(SpinnerAnimated)`
  animation: ${rotate} infinite 20s linear;
  display: block;
  margin: auto;
  position: absolute;
`

const SpinnerLogoStyled = styled(SpinnerLogo)`
  margin: 20px;
`
