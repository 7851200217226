import {
  LinkButtonWidget,
  PageWidgetType,
} from '@anyonelab/frontend/models/Widget'

export function extractLastButton(
  data: PageWidgetType[],
): LinkButtonWidget | null {
  for (let i = data.length - 1; i > 0; i--) {
    if (data[i].type === 'linkButton') {
      return data[i].data as LinkButtonWidget
    }
  }
  return null
}
