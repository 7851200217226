import { defineMessages } from 'react-intl'

export default defineMessages({
  title: { defaultMessage: 'Settings' },
  avatar: { defaultMessage: 'Avatar' },
  upload: { defaultMessage: 'Upload' },
  barName: { defaultMessage: 'Boba Bar Title' },
  yourName: { defaultMessage: 'Name' },
  email: { defaultMessage: 'Email' },
  about: { defaultMessage: 'Introduction' },
  displaySnackBioLinkButton: {
    defaultMessage: 'Display Snack Bio link button',
  },
  bobaPriceAndName: {
    defaultMessage: 'Boba Name & Price',
  },
  name: { defaultMessage: 'Name' },
  price: { defaultMessage: 'Price' },
  save: { defaultMessage: 'Save' },
  sthIsRequired: { defaultMessage: '{sth} is required!' },
  sthMustWithin: { defaultMessage: '{label} must be between {min} and {max}' },
  loading: { defaultMessage: 'Uploading' },
  uploadFailed: { defaultMessage: 'Please upload image under 3MB' },
  thankYouMessage: { defaultMessage: 'Thank Donator’s Page: Text' },
  thankYouImage: { defaultMessage: 'Thank Donator’s Page: Pic' },
  default: { defaultMessage: 'Default' },
  yourPic: { defaultMessage: 'Your Pic' },
  imageLimit: { defaultMessage: 'Upload{br} 2 MB Limited' },
  defaultBobaAvatar: { defaultMessage: 'Pick a bubble' },
  photoUpload: { defaultMessage: 'Photo Upload' },
})
