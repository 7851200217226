import React from 'react'
import styled from 'styled-components/macro'
import { BobaAvatarKeys, BobaDefaultIconComponent, BobaDefaultIcons } from '..'

type BobaIconProps = {
  size?: number
  isUsingDefaultBobaAvatar?: boolean
  defaultBobaAvatarKey?: string
  imageUrl?: string
} & React.SVGProps<SVGSVGElement>

export const BobaIcon = (props: BobaIconProps) => {
  const { isUsingDefaultBobaAvatar, defaultBobaAvatarKey, imageUrl } = props
  switch (true) {
    case isUsingDefaultBobaAvatar === undefined &&
      defaultBobaAvatarKey === undefined &&
      imageUrl === undefined:
      return (
        <svg
          width={props.size || '22'}
          height={props.size || '22'}
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g id="Group 4534701">
            <path
              id="Vector"
              d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
              fill="#C07C62"
            />
            <path
              id="Vector_2"
              d="M3.95049 12.2564C5.13774 12.2564 6.10019 11.294 6.10019 10.1067C6.10019 8.91949 5.13774 7.95703 3.95049 7.95703C2.76324 7.95703 1.80078 8.91949 1.80078 10.1067C1.80078 11.294 2.76324 12.2564 3.95049 12.2564Z"
              fill="white"
            />
            <path
              id="Vector_3"
              d="M10.8138 8.67832C12.001 8.67832 12.9635 7.71586 12.9635 6.52861C12.9635 5.34136 12.001 4.37891 10.8138 4.37891C9.62652 4.37891 8.66406 5.34136 8.66406 6.52861C8.66406 7.71586 9.62652 8.67832 10.8138 8.67832Z"
              fill="white"
            />
            <path
              id="Vector_4"
              d="M11.6466 6.78391C11.8704 5.95733 11.3818 5.10577 10.5552 4.88191C9.72867 4.65804 8.87711 5.14664 8.65325 5.97322C8.42938 6.7998 8.91798 7.65135 9.74456 7.87522C10.5711 8.09909 11.4227 7.61049 11.6466 6.78391Z"
              fill="#D79B7A"
            />
            <path
              id="Vector_5"
              d="M4.79292 10.4293C5.01925 9.59853 4.52928 8.74161 3.69854 8.51528C2.8678 8.28895 2.01086 8.77891 1.78453 9.60966C1.5582 10.4404 2.04818 11.2973 2.87893 11.5237C3.70967 11.75 4.56659 11.26 4.79292 10.4293Z"
              fill="#D79B7A"
            />
            <path
              id="Vector_6"
              d="M19.7783 13.5488C20.1726 13.5488 20.4922 13.2292 20.4922 12.835C20.4922 12.4407 20.1726 12.1211 19.7783 12.1211C19.3841 12.1211 19.0645 12.4407 19.0645 12.835C19.0645 13.2292 19.3841 13.5488 19.7783 13.5488Z"
              fill="white"
            />
            <path
              id="Vector_7"
              d="M18.976 11.0311C19.049 10.3014 18.8367 9.57177 18.3838 8.99501L17.0534 6.75607C16.8996 6.56055 16.8244 6.31464 16.8425 6.06655C16.8962 5.91308 17.0043 5.78459 17.1463 5.70538C17.2883 5.62616 17.4543 5.6017 17.6131 5.63661C17.9269 5.72139 18.2089 5.89624 18.4243 6.13955C18.9887 6.68683 19.46 7.32251 19.8196 8.02155C20.1294 8.54552 20.2763 9.14983 20.2415 9.75754C20.2164 10.0594 20.0961 10.3455 19.8977 10.5745C19.6994 10.8034 19.4334 10.9633 19.1382 11.0311H18.976Z"
              fill="white"
            />
          </g>
        </svg>
      )
    case !isUsingDefaultBobaAvatar && Boolean(imageUrl):
      return (
        <Container {...props}>
          <img
            alt={'user-avatar'}
            style={{
              width: props.size || '22',
              height: props.size || '22',
              borderRadius: '50%',
            }}
            src={imageUrl}
          />
        </Container>
      )
    default:
      return (
        <Container {...props}>
          <BobaDefaultIconComponent
            defaultBobaAvatarKey={defaultBobaAvatarKey || ''}
            containerProps={{
              style: {
                width: props.size || '22',
                height: props.size || '22',
              },
            }}
          />
        </Container>
      )
  }
}

const Container = styled.div``
