import React from 'react'

export const Peach = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="53"
      height="81"
      viewBox="0 0 53 81"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Group 4534646" filter="url(#filter0_d_1038_1827)">
        <path
          id="Vector"
          d="M5.75932 27.9875L11.811 71.2408C11.8725 71.5685 12.0474 71.8641 12.3052 72.076C12.563 72.2879 12.8871 72.4025 13.221 72.3997H38.6879C39.0209 72.4045 39.3447 72.2904 39.6009 72.0779C39.8571 71.8654 40.0289 71.5686 40.0854 71.2408L46.1121 27.9875C46.1376 27.7902 46.1198 27.5897 46.0599 27.4C46 27.2103 45.8995 27.0359 45.7654 26.8889C45.6312 26.7419 45.4666 26.6257 45.283 26.5486C45.0994 26.4715 44.9012 26.4352 44.7022 26.4423H7.16931C6.97026 26.4352 6.77205 26.4715 6.58845 26.5486C6.40485 26.6257 6.24026 26.7419 6.10611 26.8889C5.97195 27.0359 5.87143 27.2103 5.81155 27.4C5.75166 27.5897 5.73385 27.7902 5.75932 27.9875Z"
          fill="#F2F2F2"
        />
        <path
          id="Vector_2"
          d="M28.2064 1.8692C28.2064 0.83687 27.3685 0 26.3348 0C25.3011 0 24.4631 0.83687 24.4631 1.8692V39.2781C24.4631 40.3105 25.3011 41.1473 26.3348 41.1473C27.3685 41.1473 28.2064 40.3105 28.2064 39.2781V1.8692Z"
          fill="#CED8ED"
        />
        <path
          id="Vector_3"
          d="M46.3492 26.4453C46.3492 21.0384 44.1985 15.853 40.3702 12.0297C36.5419 8.20648 31.3497 6.0586 25.9357 6.0586C23.2545 6.05696 20.5993 6.58317 18.1219 7.60712C15.6444 8.63107 13.3934 10.1327 11.4975 12.0261C9.60164 13.9195 8.09807 16.1676 7.07277 18.6417C6.04748 21.1159 5.52058 23.7677 5.52222 26.4453H46.3492Z"
          fill="#E3E7F5"
        />
        <path
          id="Vector_4"
          d="M46.2619 23.9219H6.2959C5.02791 23.9219 4 24.9484 4 26.2148C4 27.4811 5.02791 28.5076 6.2959 28.5076H46.2619C47.5299 28.5076 48.5578 27.4811 48.5578 26.2148C48.5578 24.9484 47.5299 23.9219 46.2619 23.9219Z"
          fill="#CBD3E8"
        />
        <path
          id="Vector_5"
          d="M44.4402 39.1531L40.0855 71.241C40.029 71.5688 39.8572 71.8657 39.601 72.0781C39.3448 72.2906 39.021 72.4047 38.688 72.3999H13.221C12.8872 72.4027 12.563 72.2881 12.3053 72.0763C12.0475 71.8644 11.8725 71.5687 11.811 71.241L7.31909 39.1406L44.4402 39.1531Z"
          fill="#CC7E43"
        />
        <path
          id="Vector_6"
          d="M13.7947 63.4166C13.744 63.1118 13.719 62.8034 13.7198 62.4945L13.5576 62.5942C13.0927 63.0365 12.8164 63.6408 12.7862 64.2813C12.756 64.9219 12.9742 65.5495 13.3954 66.0335C13.6965 66.6023 14.2062 67.0328 14.8179 67.2348C15.4295 67.4369 16.0957 67.3949 16.677 67.1176H16.7519C16.7468 67.0153 16.7258 66.9144 16.6895 66.8186C16.5509 66.482 16.3441 66.1776 16.0821 65.9246C15.8202 65.6715 15.5087 65.4753 15.1672 65.3481C14.796 65.1651 14.4761 64.8928 14.2365 64.5556C13.9969 64.2184 13.8451 63.827 13.7947 63.4166ZM14.1191 62.3449C14.0557 62.851 14.1397 63.3646 14.361 63.8243C14.5823 64.284 14.9315 64.6702 15.3669 64.9369C15.7547 65.0893 16.1079 65.3179 16.4058 65.6089C16.7037 65.9 16.9402 66.2478 17.1013 66.6316C17.1013 66.6316 17.1013 66.6316 17.1013 66.7064C17.3583 66.2312 17.4705 65.6913 17.424 65.1532C17.3774 64.6151 17.1741 64.1024 16.8393 63.6783C16.5645 63.2162 16.161 62.844 15.6779 62.6072C15.1949 62.3704 14.6532 62.2793 14.1191 62.3449Z"
          fill="#E09874"
        />
        <path
          id="Vector_7"
          d="M36.4546 48.451C36.3946 48.1516 36.3653 47.8468 36.3673 47.5414L36.2051 47.641C35.7402 48.0834 35.4639 48.6877 35.4337 49.3282C35.4035 49.9688 35.6217 50.5963 36.0429 51.0804C36.344 51.6492 36.8537 52.0796 37.4653 52.2817C38.077 52.4838 38.7431 52.4418 39.3245 52.1645H39.3994C39.3932 52.0583 39.3721 51.9534 39.337 51.853C39.1957 51.5194 38.9878 51.2182 38.7261 50.9675C38.4644 50.7169 38.1543 50.522 37.8147 50.395C37.4441 50.2093 37.1258 49.9343 36.8883 49.5949C36.6508 49.2555 36.5018 48.8624 36.4546 48.451ZM36.7666 47.3918C36.7048 47.8978 36.7895 48.4108 37.0106 48.8702C37.2317 49.3295 37.5801 49.716 38.0143 49.9838C38.4013 50.1342 38.7542 50.3606 39.0521 50.6495C39.35 50.9384 39.5869 51.2841 39.7487 51.6661C39.7503 51.6951 39.7503 51.7242 39.7487 51.7533C40.0074 51.2785 40.1205 50.7383 40.0739 50.1999C40.0273 49.6614 39.8231 49.1485 39.4867 48.7252C39.212 48.2631 38.8084 47.8909 38.3254 47.6541C37.8423 47.4173 37.3007 47.3262 36.7666 47.3918Z"
          fill="#E09874"
        />
        <path
          id="Vector_8"
          d="M26.3725 58.8019C26.6257 58.6232 26.8976 58.4728 27.1836 58.3533L27.0214 58.2411C26.4303 58.0019 25.77 57.9977 25.1759 58.2294C24.5819 58.4612 24.0993 58.9112 23.8271 59.4872C23.4391 59.9957 23.2616 60.6336 23.3312 61.2691C23.4009 61.9046 23.7124 62.4891 24.2014 62.9017H24.3012C24.3909 62.8534 24.4747 62.7948 24.5508 62.7272C24.7946 62.4562 24.9811 62.1388 25.099 61.794C25.2169 61.4493 25.2639 61.0843 25.2371 60.7209C25.2642 60.335 25.3809 59.9606 25.578 59.6276C25.7751 59.2945 26.0471 59.0118 26.3725 58.8019ZM27.4706 58.6523C27.0588 58.8143 25.6488 59.4499 25.6239 60.8331C25.6527 61.2465 25.5955 61.6615 25.4559 62.0518C25.3164 62.4421 25.0974 62.7993 24.8128 63.101L24.7504 63.1633C25.2914 63.2026 25.8318 63.0801 26.3029 62.8116C26.774 62.5431 27.1544 62.1407 27.3957 61.6555C27.6995 61.2165 27.8684 60.6985 27.8817 60.1651C27.895 59.6316 27.7521 59.1059 27.4706 58.6523Z"
          fill="#E09874"
        />
        <path
          id="Vector_9"
          d="M34.7451 67.8666C34.439 67.8344 34.1376 67.7675 33.8467 67.6672C33.8411 67.7294 33.8411 67.792 33.8467 67.8542C34.1191 68.4393 34.6081 68.8961 35.2108 69.1285C35.8135 69.3609 36.483 69.3508 37.0784 69.1003C37.7102 68.9872 38.2741 68.6353 38.6529 68.1178C39.0316 67.6003 39.1962 66.9569 39.1123 66.3214V66.2217C39.0088 66.2072 38.9038 66.2072 38.8003 66.2217C38.0713 66.2992 37.4027 66.6622 36.9412 67.2311C36.6523 67.5041 36.3023 67.7043 35.9203 67.8148C35.5383 67.9254 35.1354 67.9431 34.7451 67.8666ZM33.8342 67.2436C34.29 67.4635 34.7995 67.5475 35.3019 67.4856C35.8042 67.4237 36.2781 67.2186 36.6667 66.8946C36.9298 66.5714 37.2555 66.3044 37.6242 66.1096C37.993 65.9148 38.3972 65.7961 38.8128 65.7607H38.9002C38.5265 65.3689 38.046 65.0952 37.5182 64.9734C36.9904 64.8516 36.4384 64.887 35.9305 65.0753C35.4099 65.1985 34.9357 65.4682 34.5641 65.8526C34.1926 66.2369 33.9392 66.7197 33.8342 67.2436Z"
          fill="#E09874"
        />
        <path
          id="Vector_10"
          d="M19.1351 62.5447C20.3756 62.5447 21.3811 61.5404 21.3811 60.3016C21.3811 59.0628 20.3756 58.0586 19.1351 58.0586C17.8947 58.0586 16.8892 59.0628 16.8892 60.3016C16.8892 61.5404 17.8947 62.5447 19.1351 62.5447Z"
          fill="#42210B"
        />
        <path
          id="Vector_11"
          d="M23.1413 55.2166C24.3817 55.2166 25.3872 54.2123 25.3872 52.9735C25.3872 51.7347 24.3817 50.7305 23.1413 50.7305C21.9008 50.7305 20.8953 51.7347 20.8953 52.9735C20.8953 54.2123 21.9008 55.2166 23.1413 55.2166Z"
          fill="#42210B"
        />
        <path
          id="Vector_12"
          d="M32.549 54.9041C33.7894 54.9041 34.7949 53.8998 34.7949 52.661C34.7949 51.4222 33.7894 50.418 32.549 50.418C31.3085 50.418 30.303 51.4222 30.303 52.661C30.303 53.8998 31.3085 54.9041 32.549 54.9041Z"
          fill="#42210B"
        />
        <path
          id="Vector_13"
          d="M38.4132 61.197C39.6536 61.197 40.6592 60.1928 40.6592 58.954C40.6592 57.7152 39.6536 56.7109 38.4132 56.7109C37.1728 56.7109 36.1672 57.7152 36.1672 58.954C36.1672 60.1928 37.1728 61.197 38.4132 61.197Z"
          fill="#42210B"
        />
        <path
          id="Vector_14"
          d="M31.9374 63.3025C33.1778 63.3025 34.1834 62.2982 34.1834 61.0594C34.1834 59.8206 33.1778 58.8164 31.9374 58.8164C30.697 58.8164 29.6914 59.8206 29.6914 61.0594C29.6914 62.2982 30.697 63.3025 31.9374 63.3025Z"
          fill="#42210B"
        />
        <path
          id="Vector_15"
          d="M30.0036 71.1775C31.244 71.1775 32.2495 70.1732 32.2495 68.9345C32.2495 67.6957 31.244 66.6914 30.0036 66.6914C28.7631 66.6914 27.7576 67.6957 27.7576 68.9345C27.7576 70.1732 28.7631 71.1775 30.0036 71.1775Z"
          fill="#42210B"
        />
        <path
          id="Vector_16"
          d="M13.8827 56.7361C15.1231 56.7361 16.1287 55.7318 16.1287 54.493C16.1287 53.2542 15.1231 52.25 13.8827 52.25C12.6423 52.25 11.6367 53.2542 11.6367 54.493C11.6367 55.7318 12.6423 56.7361 13.8827 56.7361Z"
          fill="#42210B"
        />
        <path
          id="Vector_17"
          d="M21.0072 70.3806C22.2476 70.3806 23.2532 69.3764 23.2532 68.1376C23.2532 66.8988 22.2476 65.8945 21.0072 65.8945C19.7668 65.8945 18.7612 66.8988 18.7612 68.1376C18.7612 69.3764 19.7668 70.3806 21.0072 70.3806Z"
          fill="#42210B"
        />
        <g id="Group" opacity="0.28">
          <path
            id="Vector_18"
            opacity="0.28"
            d="M46.6369 26.4935C46.6386 24.166 46.1807 21.861 45.2896 19.7104C44.3985 17.5597 43.0916 15.6056 41.4437 13.9599C39.7958 12.3141 37.8391 11.0089 35.6857 10.119C33.5322 9.22903 31.2242 8.7718 28.8937 8.77344C24.1879 8.77344 19.6748 10.6404 16.3473 13.9635C13.0198 17.2867 11.1504 21.7938 11.1504 26.4935H46.6369Z"
            fill="#E3E7F5"
          />
          <path
            id="Vector_19"
            opacity="0.28"
            d="M11.004 27.3458L16.2696 64.9417C16.3279 65.2274 16.4847 65.4836 16.7127 65.6658C16.9407 65.8479 17.2254 65.9445 17.5173 65.9386H39.6403C39.9322 65.9445 40.2169 65.8479 40.4449 65.6658C40.6729 65.4836 40.8297 65.2274 40.888 64.9417L46.1162 27.3458C46.13 27.174 46.108 27.0012 46.0515 26.8383C45.995 26.6754 45.9053 26.526 45.788 26.3995C45.6707 26.273 45.5285 26.1722 45.3702 26.1034C45.2119 26.0347 45.041 25.9995 44.8684 26H12.2518C12.0792 25.9995 11.9083 26.0347 11.75 26.1034C11.5918 26.1722 11.4495 26.273 11.3322 26.3995C11.2149 26.526 11.1252 26.6754 11.0687 26.8383C11.0122 27.0012 10.9902 27.174 11.004 27.3458Z"
            fill="#F2F2F2"
          />
          <path
            id="Vector_20"
            opacity="0.28"
            d="M46.5619 24.3008H11.8241C10.7215 24.3008 9.82764 25.1934 9.82764 26.2946C9.82764 27.3957 10.7215 28.2884 11.8241 28.2884H46.5619C47.6645 28.2884 48.5584 27.3957 48.5584 26.2946C48.5584 25.1934 47.6645 24.3008 46.5619 24.3008Z"
            fill="#CBD3E8"
          />
        </g>
        <g id="Group 4534645">
          <path
            id="Vector_21"
            d="M17.9755 16.2805L19.3356 16.754C19.3898 16.7744 19.4388 16.8067 19.4788 16.8486C19.5189 16.8904 19.549 16.9407 19.567 16.9957C19.5849 17.0508 19.5903 17.1091 19.5826 17.1665C19.575 17.2239 19.5545 17.2788 19.5228 17.3272C19.3349 17.6528 19.05 17.9118 18.7079 18.0682C18.3657 18.2245 17.9832 18.2704 17.6137 18.1995C17.1038 18.1026 16.6528 17.8088 16.3584 17.3819C16.0641 16.955 15.9502 16.4294 16.0415 15.9191C16.1412 15.4096 16.4385 14.9601 16.8685 14.6684C17.2984 14.3767 17.8264 14.2664 18.3374 14.3614C18.6537 14.4183 18.9499 14.5561 19.1971 14.7614C19.4442 14.9667 19.6338 15.2325 19.7473 15.5328C19.7685 15.5874 19.7773 15.6461 19.7732 15.7045C19.7691 15.7629 19.7522 15.8198 19.7236 15.8709C19.695 15.9221 19.6555 15.9663 19.6078 16.0005C19.5602 16.0347 19.5056 16.0579 19.4479 16.0686L17.9755 16.2805Z"
            fill="#42210B"
          />
          <path
            id="Vector_22"
            d="M36.0309 16.2787L37.3785 16.7522C37.4338 16.7713 37.4841 16.8026 37.5255 16.8439C37.5669 16.8851 37.5984 16.9353 37.6176 16.9904C37.6368 17.0456 37.6433 17.1044 37.6364 17.1624C37.6296 17.2205 37.6097 17.2762 37.5782 17.3254C37.3903 17.651 37.1054 17.91 36.7633 18.0664C36.4211 18.2227 36.0386 18.2686 35.6691 18.1977C35.1592 18.1008 34.7082 17.807 34.4138 17.3801C34.1195 16.9532 34.0056 16.4276 34.0969 15.9173C34.1448 15.6642 34.2422 15.4231 34.3836 15.2078C34.5251 14.9924 34.7077 14.8071 34.921 14.6623C35.1343 14.5176 35.3741 14.4164 35.6267 14.3644C35.8792 14.3125 36.1396 14.3109 36.3928 14.3596C36.7091 14.4165 37.0053 14.5543 37.2525 14.7596C37.4996 14.9649 37.6892 15.2307 37.8028 15.531C37.8239 15.5856 37.8327 15.6443 37.8286 15.7027C37.8245 15.7611 37.8076 15.818 37.779 15.8691C37.7504 15.9203 37.7109 15.9645 37.6632 15.9987C37.6156 16.0328 37.561 16.0561 37.5033 16.0668L36.0309 16.2787Z"
            fill="#42210B"
          />
          <path
            id="Vector_23"
            d="M27.9452 17.4871C27.5469 17.4402 27.1813 17.2443 26.922 16.9388C26.7674 17.1114 26.5729 17.2436 26.3554 17.324C26.138 17.4044 25.9041 17.4304 25.6742 17.3999C25.5081 17.3655 25.3524 17.2921 25.2201 17.186C25.0878 17.0799 24.9826 16.9439 24.9131 16.7892C24.8987 16.76 24.8906 16.7281 24.8891 16.6955C24.8877 16.663 24.893 16.6305 24.9047 16.6001C24.9164 16.5697 24.9343 16.542 24.9572 16.5188C24.9801 16.4956 25.0076 16.4774 25.0379 16.4653C25.0666 16.4529 25.0974 16.4464 25.1286 16.446C25.1598 16.4455 25.1908 16.4513 25.2198 16.4629C25.2488 16.4745 25.2752 16.4916 25.2975 16.5134C25.3198 16.5352 25.3376 16.5612 25.3498 16.5899C25.3498 16.5899 25.4996 16.8765 25.7616 16.9263C26.0236 16.9762 26.348 16.7893 26.7224 16.4154C26.7456 16.3893 26.7746 16.3689 26.8071 16.3559C26.8396 16.3429 26.8747 16.3377 26.9095 16.3406C26.9463 16.3416 26.9824 16.3511 27.0149 16.3684C27.0474 16.3857 27.0754 16.4103 27.0967 16.4403C27.1866 16.5839 27.3049 16.7077 27.4444 16.8041C27.5838 16.9006 27.7415 16.9676 27.9078 17.0011C28.0147 16.9922 28.1184 16.9596 28.2112 16.9057C28.304 16.8518 28.3836 16.778 28.4443 16.6896C28.4654 16.6657 28.4913 16.6466 28.5203 16.6335C28.5494 16.6204 28.5809 16.6136 28.6127 16.6136C28.6446 16.6136 28.6761 16.6204 28.7051 16.6335C28.7342 16.6466 28.7601 16.6657 28.7812 16.6896C28.8034 16.7116 28.8211 16.7378 28.8332 16.7667C28.8452 16.7955 28.8514 16.8265 28.8514 16.8578C28.8514 16.8891 28.8452 16.92 28.8332 16.9489C28.8211 16.9778 28.8034 17.004 28.7812 17.026C28.6831 17.1583 28.5577 17.2679 28.4135 17.3475C28.2692 17.4271 28.1095 17.4746 27.9452 17.4871Z"
            fill="#42210B"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1038_1827"
          x="0"
          y="0"
          width="52.5583"
          height="80.3984"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1038_1827"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1038_1827"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
