/**
 *
 * ProfileSplashCreator
 *
 */
import React, { useCallback, useEffect, useMemo, useRef } from 'react'

import styled, { useTheme } from 'styled-components/macro'
import { useIntl } from 'react-intl'
import messages from './messages'
import {
  Button,
  Space,
  Row,
  Col,
  Spacer,
  useResponsive,
  usePageBuilder,
  track,
  LinkPortalEvent,
  PageBuilderContext,
  useImageUrlChecker,
  LinkPortalThemeCreator,
  isEmptySocialMediaButtonGroup,
  LeadFormWidgetCreator,
  LEAD_FORM_WIDGET_ID,
  BobameEmbedIcon,
  LinkOutlined,
  BobaBarWithCharacter,
  BobaPrimaryIconWidget,
  openInNewTab,
  getBobaMePublicUrl,
  Drawer,
  MobileOnly,
  DesktopOnly,
  Modal,
  BobaAccountNotFoundRedirectMessage,
  HomeNavButton,
} from '@anyonelab/common'
import { ReactComponent as SocialMediaIcon } from '@anyonelab/common/src/assets/social-media-icon.svg'
import { ReactComponent as HTMLEmbedIcon } from '@anyonelab/common/src/assets/html-embed-icon.svg'
import { useWidget, withWidget } from '@anyonelab/common'
import { AvatarCreatorWidget } from '../../Avatar'
import { TextWidgetCreator } from '../../TextWidget/creator'
import {
  ProfileSplashWidgetConfigDefaults,
  ProfileSplashWidgetInstanceIds,
  PROFILE_SPLASH_WIDGET_ID,
  WidgetConfigDefaults,
} from '../constants'
import {
  ProfileSplashElementWidgetConfigSchema,
  ProfileSplashWidgetConfigSchema,
} from '../types'
import { ProfileSplashConfig } from '../config'
import { adjustHue } from 'polished'
import { LinkBlockWidgetCreator, LINK_BLOCK_WIDGET_ID } from '../../LinkBlock'
import { AnimatePresence, motion } from 'framer-motion'
import {
  SocialMediaLinkButtonWidgetCreator,
  SOCIAL_MEDIA_LINK_BUTTON_WIDGET_ID,
} from '../../SocialMediaLinkButton'
import { convertBackgroundToStyle } from '../utils'
import { PoweredByFooter } from '@anyonelab/common'
import {
  IWidget,
  PageWidgetType,
  ProfileSplashWidget,
} from '@anyonelab/frontend/models/Widget'
import { linkBlockAnimation } from '../../styles'
import { LINK_PORTAL_SPLASH_INSTANCE_ID } from '@anyonelab/frontend/app/pages/LinkTreePage/constants'
import {
  BobameWidgetCreator,
  BOBAME_WIDGET_ID,
  primaryColorToSecondaryColorConvertor,
} from '../../Bobame'
import { useGetBobaUserAccount } from '@anyonelab/frontend/api/boba-api/hook/useGetBobaData'
import { useHistory } from 'react-router-dom'

export interface ProfileSplashCreatorWidgetProps {
  data: ProfileSplashWidget
  domain: string
  elementData: PageWidgetType[]
  onAddLink?(): void
  onAddLeadForm?(): void
  onAddBobame?(): void
  isPreviewComponent?: boolean
  isAdmin?: boolean | undefined | null
}

export const ProfileSplashCreatorWidget = withWidget<
  ProfileSplashCreatorWidgetProps
>(
  {
    widgetId: PROFILE_SPLASH_WIDGET_ID,
    configComponent: ProfileSplashConfig,
    disableDefaultInteractions: true,
  },
  ({
    domain,
    data = ProfileSplashWidgetConfigDefaults,
    elementData = WidgetConfigDefaults,
    onAddLink = () => undefined,
    onAddLeadForm = () => undefined,
    onAddBobame = () => undefined,
    isPreviewComponent,
    isAdmin = false,
  }) => {
    const intl = useIntl()
    const { isMobileLayout } = useResponsive()
    const {
      previewViewMode,
      isPreviewMode,
      configPanelContent,
      setConfigPanelContent,
      activeInstanceId,
      activeWidgetId,
      selectWidget,
    } = usePageBuilder()
    const theme = useTheme()
    const { data: bobaUserData } = useGetBobaUserAccount()
    const history = useHistory()

    const { isWaitingThumbnail } = useImageUrlChecker(
      data?.backgroundDesktopImageURL,
    )

    const [backgroundStyle, setBackgroundStyle] = React.useState<object>({})
    const {
      hovered,
      selectWidget: selectSplash,
      displayMode,
      active,
    } = useWidget()

    const containerRef = useRef<HTMLDivElement>(null)

    const item = {
      initial: {
        scale: 0.5,
        opacity: 0,
      },
      enter: {
        scale: 1,
        opacity: 1,
      },
      exit: {
        scale: 0.5,
        opacity: 0,
      },
    }

    const numberOfLinkBlocks = useRef<number>(0)

    const getTitleColor = (data: PageWidgetType[]) => {
      const title = data.find((elem) => elem.type === 'titleLabel') as IWidget<
        'titleLabel'
      >
      return title.data.fontColor
    }

    // NOTE: Prevent tapping on the preview component.
    useEffect(() => {
      containerRef?.current?.addEventListener('click', (event: Event) => {
        isPreviewComponent && event.stopImmediatePropagation()
      })

      return () => {}
    }, [numberOfLinkBlocks.current])

    // NOTE: Select the last block when adding new blocks.
    /** Original approach
     *  using ref as a global variable, when click add button,
     * array length add one and compare to the ref to determine whether this is new added button
     * and to set this button as the selected widget for changing config
     * */

    /** New approach
     * using ref as a global variable and use length elementData as the initial value,
     * when some add button is clicked, for now 20220323 , there are only link button,
     * then the elementData length will increase, and we know that something is added,
     * then we can check the last elementData[elementData.length-1].type to check the widget type
     */

    // ! need modify
    // useEffect(() => {
    //   const linkBlockDataDoesNotExist = Boolean(elementData?.links.length < 1)
    //   const firstInitLinkBlock = Boolean(numberOfLinkBlocks.current < 1)
    //   const isAddingNewBlock = Boolean(
    //     elementData.links.length > numberOfLinkBlocks.current,
    //   )
    //   if (linkBlockDataDoesNotExist) return
    //   if (firstInitLinkBlock) {
    //     numberOfLinkBlocks.current = elementData.links.length
    //     return
    //   }
    //   if (isAddingNewBlock) {
    //     onSelectWidget(
    //       LINK_BLOCK_WIDGET_ID,
    //       'linkportal-profilesplash.' +
    //         elementData.links[elementData.links.length - 1].id,
    //     )
    //   }
    //   return () => {
    //     numberOfLinkBlocks.current = elementData.links.length
    //   }
    // }, [elementData.links.length])

    // ! check lead form is exist
    const socialMediaData = elementData.find(
      (item) => item.type === 'socialMediaButtonGroup',
    )
    const socialMediaIsExist =
      socialMediaData && !isEmptySocialMediaButtonGroup(socialMediaData)
    const leadFormData = elementData.find((item) => item.type === 'leadForm')
    const leadFormIsExist = Boolean(leadFormData)
    const bobameData = elementData.find((item) => item.type === 'bobame')
    const bobameIsExist = Boolean(bobameData)
    const [showInfoModal, setShowInfoModal] = React.useState(false)

    React.useEffect(() => {
      setBackgroundStyle(
        convertBackgroundToStyle(
          data,
          isPreviewMode ? previewViewMode === 'mobile' : isMobileLayout,
          isWaitingThumbnail,
        ),
      )
    }, [data, isWaitingThumbnail])

    const bobameWidgetActivated = activeWidgetId === 'BOBAME_BUTTON'

    const profileSplashChildrenActivated =
      activeInstanceId !== 'linkportal-profilesplash'

    const profileSplashChildrenInsidePreviewComponent =
      isPreviewComponent &&
      profileSplashChildrenActivated &&
      !configPanelContent

    // ! need modify
    // const hasSocialMediaData =
    //   Boolean(elementData.facebook.url) ||
    //   Boolean(elementData.instagram.url) ||
    //   Boolean(elementData.youtube.url) ||
    //   Boolean(elementData.twitter.url) ||
    //   Boolean(elementData.tiktok.url) ||
    //   Boolean(elementData.email.url)

    const DEFAULT_MARGIN = 1

    const WidgetWrapper = React.useCallback(
      (props: React.HTMLProps<HTMLDivElement> & { space?: boolean }) => {
        return (
          <React.Fragment>
            <Space
              align="center"
              style={{
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {props.children}
            </Space>
            <Spacer
              space={
                profileSplashChildrenInsidePreviewComponent ||
                props?.space === false
                  ? 0
                  : DEFAULT_MARGIN
              }
            />
          </React.Fragment>
        )
      },
      [],
    )

    const getWidget = React.useCallback(
      (widget: PageWidgetType, index: number): JSX.Element => {
        const readableIndex = index + 1
        switch (widget.type) {
          case 'avatar':
            return (
              <WidgetWrapper key={index + 'avatar'}>
                <AvatarCreatorWidget
                  data={widget.data}
                  instanceId={`${
                    ProfileSplashWidgetInstanceIds.AVATAR
                  }.${index.toString()}`}
                  instanceLabel={intl.formatMessage(messages.avatar)}
                />
              </WidgetWrapper>
            )
          case 'titleLabel':
            return (
              <WidgetWrapper space={false} key={index + 'title'}>
                <TextWidgetCreator
                  data={widget.data}
                  instanceId={`${
                    ProfileSplashWidgetInstanceIds.TITLE
                  }.${index.toString()}`}
                  instanceLabel={intl.formatMessage(messages.title)}
                />
              </WidgetWrapper>
            )
          case 'subtitleLabel':
            return (
              <WidgetWrapper key={index + 'subtitle'}>
                <TextWidgetCreator
                  data={widget.data}
                  instanceId={`${
                    ProfileSplashWidgetInstanceIds.SUBTITLE
                  }.${index.toString()}`}
                  instanceLabel={intl.formatMessage(messages.subtitle)}
                />
              </WidgetWrapper>
            )
          case 'linkButton':
            return (
              <>
                <LinkBlockWidgetCreator
                  data={widget.data}
                  instanceId={`${
                    ProfileSplashWidgetInstanceIds.LINKS
                  }.${index.toString()}`}
                  instanceLabel={intl.formatMessage(messages.linkBlock)}
                />
                <Spacer
                  space={
                    profileSplashChildrenInsidePreviewComponent
                      ? 0
                      : DEFAULT_MARGIN
                  }
                />
              </>
            )
          case 'socialMediaButtonGroup':
            return (
              <WidgetWrapper key={index + 'socialMedia'}>
                <SocialMediaLinkButtonWidgetCreator
                  data={widget.data}
                  instanceId={`${
                    ProfileSplashWidgetInstanceIds.SOCIAL_MEDIA
                  }.${index.toString()}`}
                  instanceLabel={intl.formatMessage(messages.socialMedia)}
                />
              </WidgetWrapper>
            )
          case 'leadForm':
            return (
              <WidgetWrapper key={index + 'leadForm'}>
                <LeadFormWidgetCreator
                  data={widget.data}
                  instanceId={`${
                    ProfileSplashWidgetInstanceIds.LEAD_FORM
                  }.${index.toString()}`}
                  instanceLabel={intl.formatMessage(messages.htmlEmbed)}
                />
              </WidgetWrapper>
            )
          case 'bobame':
            return (
              <WidgetWrapper key={index + 'bobame'}>
                <BobameWidgetCreator
                  onClick={() => {
                    if (!displayMode) return
                    openInNewTab(getBobaMePublicUrl(domain))
                  }}
                  data={widget.data}
                  instanceId={`${
                    ProfileSplashWidgetInstanceIds.BOBAME
                  }.${index.toString()}`}
                  instanceLabel={intl.formatMessage(messages.bobame)}
                  style={{ minWidth: '32%', cursor: 'pointer' }}
                />
              </WidgetWrapper>
            )
          default:
            return <React.Fragment key={index + 'fragment'}></React.Fragment>
        }
      },
      [],
    )

    return (
      <ProfileSplashCreatorWidgetStyled
        ref={containerRef}
        style={backgroundStyle}
        active={hovered}
        onClick={() => selectSplash()}
        isPreviewComponent={isPreviewComponent ?? false}
        hasActiveInstance={profileSplashChildrenActivated}
        configPanelContent={configPanelContent}
      >
        <Wrapper
          needExtraPadding={
            isPreviewComponent && !active && !bobameWidgetActivated
          }
          shrink={
            isPreviewComponent &&
            (!profileSplashChildrenActivated || configPanelContent)
          }
          className="wrapper"
        >
          {elementData.map((item, index) => {
            // NOTE: Remove social media creator if all its values are empty string.
            if (isEmptySocialMediaButtonGroup(item)) {
              return (
                <div style={{ height: 0, width: 0, visibility: 'hidden' }}>
                  {getWidget(item, index)}
                </div>
              )
            } else
              return isPreviewComponent ? (
                getWidget(item, index)
              ) : (
                <motion.div
                  layout
                  variants={linkBlockAnimation}
                  initial={'initial'}
                  animate={'enter'}
                  exit={'exit'}
                  key={item.id}
                >
                  {getWidget(item, index)}
                </motion.div>
              )
          })}
          {!displayMode && !isPreviewComponent && (
            <motion.div
              layout
              variants={linkBlockAnimation}
              initial={'initial'}
              animate={'enter'}
              exit={'exit'}
            >
              <AddLinkButtonStyled
                onClick={(e) => {
                  e.stopPropagation()
                  onAddLink()
                  selectWidget(
                    LINK_BLOCK_WIDGET_ID,
                    `${LINK_PORTAL_SPLASH_INSTANCE_ID}.${ProfileSplashWidgetInstanceIds.LINKS}.${elementData.length}`,
                  )
                  track(LinkPortalEvent.addLinkBlockClick)
                }}
              >
                {intl.formatMessage(messages.addNewLink)}
                <LinkButtonIconContainer children={<LinkOutlined />} />
              </AddLinkButtonStyled>
              <Spacer
                space={
                  profileSplashChildrenInsidePreviewComponent
                    ? 0
                    : DEFAULT_MARGIN
                }
              />
            </motion.div>
          )}
          {!displayMode && !isPreviewComponent && !socialMediaIsExist && (
            <motion.div
              layout
              variants={linkBlockAnimation}
              initial={'initial'}
              animate={'enter'}
              exit={'exit'}
            >
              <AddLinkButtonStyled
                onClick={(e) => {
                  e.stopPropagation()
                  const index = elementData.findIndex((element) => {
                    return element.type === 'socialMediaButtonGroup'
                  })
                  selectWidget(
                    SOCIAL_MEDIA_LINK_BUTTON_WIDGET_ID,
                    `${LINK_PORTAL_SPLASH_INSTANCE_ID}.${ProfileSplashWidgetInstanceIds.SOCIAL_MEDIA}.${index}`,
                  )
                  track(LinkPortalEvent.addSocialMediaLinkButtonClick)
                }}
              >
                <LinkButtonIconContainer
                  children={<SocialMediaIcon fill="white" />}
                />
                {intl.formatMessage(messages.addSocialMedia)}
              </AddLinkButtonStyled>
              <Spacer
                space={
                  profileSplashChildrenInsidePreviewComponent
                    ? 0
                    : DEFAULT_MARGIN
                }
              />
            </motion.div>
          )}
          {!displayMode && !isPreviewComponent && !leadFormIsExist && (
            <motion.div
              layout
              variants={linkBlockAnimation}
              initial={'initial'}
              animate={'enter'}
              exit={'exit'}
            >
              <AddLinkButtonStyled
                onClick={(e) => {
                  e.stopPropagation()

                  onAddLeadForm()

                  selectWidget(
                    LEAD_FORM_WIDGET_ID,
                    `${LINK_PORTAL_SPLASH_INSTANCE_ID}.${ProfileSplashWidgetInstanceIds.LEAD_FORM}.${elementData.length}`,
                  )

                  // Todo @Louie add track
                }}
              >
                <LinkButtonIconContainer
                  children={<HTMLEmbedIcon fill="white" />}
                />
                {intl.formatMessage(messages.addHtmlEmbed)}
              </AddLinkButtonStyled>
              <Spacer
                space={
                  profileSplashChildrenInsidePreviewComponent
                    ? 0
                    : DEFAULT_MARGIN
                }
              />
            </motion.div>
          )}
          {!displayMode && !isPreviewComponent && !bobameIsExist && (
            <motion.div
              layout
              variants={linkBlockAnimation}
              initial={'initial'}
              animate={'enter'}
              exit={'exit'}
            >
              {/* TODO: Add animation. */}
              <AddBobaMeButton
                onClick={(e) => {
                  e.stopPropagation()
                  const isBobaUser = Boolean(bobaUserData?.data?.[0])
                  if (isBobaUser) {
                    onAddBobame()
                    selectWidget(
                      BOBAME_WIDGET_ID,
                      `${LINK_PORTAL_SPLASH_INSTANCE_ID}.${ProfileSplashWidgetInstanceIds.BOBAME}.${elementData.length}`,
                    )
                    // Todo @Louie add track
                  } else {
                    setShowInfoModal(true)
                  }
                }}
              >
                <div>
                  <div
                    children={intl.formatMessage(messages.bobameConfigTitle)}
                  />
                  <BobaBarWithCharacter
                    primaryColor="#BF7C62"
                    secondaryColor={primaryColorToSecondaryColorConvertor(
                      '#BF7C62',
                    )}
                    fullName=""
                    style={{ width: 120, margin: '8px auto 0' }}
                  />
                </div>
              </AddBobaMeButton>
              <Spacer
                space={
                  profileSplashChildrenInsidePreviewComponent
                    ? 0
                    : DEFAULT_MARGIN
                }
              />
            </motion.div>
          )}
          {displayMode && <PoweredByFooter footerType="snackbio" />}

          {bobameIsExist ? (
            <motion.div
              variants={linkBlockAnimation}
              initial={'initial'}
              animate={'enter'}
              exit={'exit'}
              style={{
                position: 'absolute',
                top: isPreviewComponent ? -12 : -48,
                right: 12,
                width: 100,
                display:
                  isPreviewComponent && !active && !bobameWidgetActivated
                    ? // isPreviewComponent && (!bobameWidgetActivated || !active)
                      'none'
                    : 'flex',
                justifyContent: 'end',
              }}
            >
              <BobaPrimaryIconWidget
                onClick={() => {
                  if (!displayMode) return
                  openInNewTab(getBobaMePublicUrl(domain))
                }}
                size={48}
                data={bobameData?.data?.primaryButton}
              />
            </motion.div>
          ) : (
            <></>
          )}
        </Wrapper>
        <DesktopOnly>
          <Modal
            width={theme.DesktopModalWidth}
            wrapClassName="desktop-notification-wrapper"
            visible={showInfoModal}
            onCancel={() => setShowInfoModal(false)}
            closable={false}
          >
            <BobaAccountNotFoundRedirectMessage
              onClose={() => {
                setShowInfoModal(false)
              }}
              redirectOnClick={() => {
                history.push('/boba/onboard')
              }}
            />
          </Modal>
        </DesktopOnly>
        <MobileOnly>
          <Drawer
            bodyStyle={{ padding: 0, overflowY: 'hidden', zIndex: 1 }}
            visible={showInfoModal}
            placement="bottom"
            destroyOnClose
            closable={false}
            onClose={(e) => {
              e.stopPropagation()
              setShowInfoModal(false)
            }}
            height="100%"
          >
            <BobaAccountNotFoundRedirectMessage
              onClose={() => setShowInfoModal(false)}
              redirectOnClick={() => {
                history.push('/boba/onboard')
              }}
            />
          </Drawer>
        </MobileOnly>

        {!displayMode && !isPreviewComponent && isMobileLayout && (
          <BottomRightButtonContainer>
            <LinkPortalThemeCreator
              onClick={(e) => {
                e.stopPropagation()
                setConfigPanelContent('customize')
              }}
            />
            <HomeNavButton />
          </BottomRightButtonContainer>
        )}
      </ProfileSplashCreatorWidgetStyled>
    )
  },
)

const ProfileSplashCreatorWidgetStyled = styled.div<any>`
  .ant-space-item {
    width: 100%;
    display: flex;
    justify-content: center;

    & > div {
      width: 100%;
      display: flex;
      justify-content: center;
    }
  }

  display: flex;
  overflow-y: hidden;

  background: ${(props) => props.backgroundColorCSS};

  padding: ${({ isPreviewComponent }) =>
    isPreviewComponent ? '32px 16px' : '80px 32px'};

  min-height: ${({ isPreviewComponent }) =>
    isPreviewComponent ? 'unset' : '100vh'};

  > .wrapper {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    ${({ isPreviewComponent }) =>
      isPreviewComponent &&
      `
    display:flex;
    flex-direction: column;
    `};
  }

  ${({ isPreviewComponent }) =>
    isPreviewComponent &&
    `
    max-height: 360px;
    * {
      cursor:default;
    }
    *.hover-border{
      display:none;
    }
    .link-block-button span{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      width: 100%;
    }
  `};

  ${({ isPreviewComponent, hasActiveInstance, configPanelContent }) =>
    isPreviewComponent &&
    hasActiveInstance &&
    !configPanelContent &&
    `
    .widget:not(.selected){
     display:none; 
    }`};
`
const AddLinkButtonStyled = styled(Button)`
  width: 100%;
  font-size: 16px;
  height: auto;
  padding: 16px;
  color: #fff;
  position: relative;

  background: none;
  border: 1px dashed #d9d9d9;

  border-radius: 12px;
  outline: none;

  &:hover,
  &:active,
  &:focus {
    background: rgba(255, 255, 255, 0.1);
    border: 1px dashed #d9d9d9;
    color: #fff;
    outline: none;
  }
`

const AddBobaMeButton = styled(AddLinkButtonStyled)`
  background-color: #f8e0be;
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  font-weight: bold;
  color: #ad5138;
  border: 3px dashed #ad5138;
  &:hover,
  &:active,
  &:focus {
    color: #ad5138;
    background-color: #fae8d0;
    border: 3px dashed #ad5138;
    outline: none;
  }
`

const Wrapper = styled.div<any>`
  position: relative;
  ${({ shrink, needExtraPadding }) =>
    shrink &&
    `
    position: relative;
    transform: scale(0.5) translate(-50%, -60%);
    transform-origin: center center;
    left: 25%;
    padding-top:24px;
  `};
`

const MiddleSpacer = styled(Space)`
  width: '100%';
  justify-content: 'center';
  align-items: 'center';
`

const LinkButtonIconContainer = styled.div`
  position: absolute;
  top: 0;
  left: 16px;
  height: 100%;
  display: grid;
  place-items: center;
`

const BottomRightButtonContainer = styled.div`
  position: fixed;
  right: 0px;
  bottom: 36px;
`
