import Lottie, {
  LottieRef,
  LottieRefCurrentProps,
  useLottie,
} from 'lottie-react'
import React, { HTMLAttributes, useEffect } from 'react'
import styled from 'styled-components/macro'
import { matchaReceipt, peachReceipt, sunsetReceipt } from '../..'
import { BobaSeries } from '../type'
import { Sunset, Peach, Matcha } from './presets/'

export type BobaReceiptDisplayProps = {
  series?: BobaSeries
} & HTMLAttributes<HTMLDivElement>

export const BobaReceiptDisplayAnimated = (props: BobaReceiptDisplayProps) => {
  const lottieRef = React.useRef<LottieRefCurrentProps | null>(null)

  React.useEffect(() => {
    lottieRef?.current?.setSpeed(0.35)
  }, [])

  return (
    <Container {...props}>
      {props.series === 'sunset' ? (
        <Lottie
          lottieRef={lottieRef}
          animationData={sunsetReceipt}
          loop={true}
          style={{ height: '100%', width: '100%' }}
        />
      ) : props.series === 'peach' ? (
        <Lottie
          lottieRef={lottieRef}
          animationData={peachReceipt}
          loop={true}
          style={{ height: '100%', width: '100%' }}
        />
      ) : props.series === 'matcha' ? (
        <Lottie
          lottieRef={lottieRef}
          animationData={matchaReceipt}
          loop={true}
          style={{ height: '100%', width: '100%' }}
        />
      ) : (
        <Sunset />
      )}
    </Container>
  )
}

const Container = styled.div`
  border-radius: 40px;
  overflow: hidden;

  &.hide {
    display: none;
  }
`
