import html2canvas from 'html2canvas'
import { downloadImage } from './imageDownloader'

export const BOBA_RECEIPT_FILE_NAME = 'bobame receipt'

export const captureElement = async (
  element: HTMLElement,
  fileName: string = BOBA_RECEIPT_FILE_NAME,
  callback: () => void = () => {},
) => {
  try {
    const canvas = await html2canvas(element, {
      useCORS: true,
      backgroundColor: 'none',
    })
    const image = canvas.toDataURL('image/png', 1.0)
    downloadImage(image, `${fileName}.png`)
    callback()
  } catch (error) {
    console.log(error)
  }
}
