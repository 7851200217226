import {
  BobaBarWithCharacter,
  BobaCircleIcon,
  BobaPillIcon,
  BobaPrimaryIconWidget,
  Button,
  Form,
  FormProps,
  Radio,
  withWidgetConfig,
} from '@anyonelab/common/'
import { BobameWidget } from '@anyonelab/frontend/models/Widget'
import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { BOBAME_WIDGET_ID } from '../const'
import { primaryColorToSecondaryColorConvertor } from '../utils'
import messages from './messages'

export const primaryButtonBackgroundColorList = [
  '#AD5138',
  '#C07C62',
  '#A0ADA6',
  '#CCA78C',
  '#FFFFFF',
]
const primaryButtonContentList = ['', 'BobaMe', '請我喝珍奶']

export const secondaryButtonBackgroundColorList = [
  '#BF7C62',
  '#A0ADA6',
  '#CCA78C',
]
const secondaryButtonContentList = ['Give me a Boba!', '請我喝珍奶！']

export interface BobameButtonWidgetConfigProps {
  data: BobameWidget
}

export const BobameWidgetConfig = withWidgetConfig<
  BobameButtonWidgetConfigProps
>(
  {
    widgetId: BOBAME_WIDGET_ID,
  },
  ({ data, onChange, onRemove }) => {
    const intl = useIntl()
    const [form] = Form.useForm<BobameWidget>()

    const initialValues: BobameWidget = {
      ...data,
    }

    const contentOnFocus = (): void => {
      // Todo @louie add track
    }

    const onValuesChange: FormProps<BobameWidget>['onValuesChange'] = (
      changedValues: Partial<BobameWidget>,
      formValues,
    ) => {
      console.log(formValues)
      onChange({
        ...data,
        ...formValues,
      })
    }

    return (
      <BobameWidgetConfigStyled>
        <div
          style={{ fontSize: '14px', marginBottom: '16px' }}
          children={intl.formatMessage(messages.choose)}
        />
        <Form
          form={form}
          initialValues={initialValues}
          onValuesChange={onValuesChange}
          layout="vertical"
        >
          <Form.Item
            label={intl.formatMessage(messages.instruction1)}
            name="primaryButton"
            required
          >
            <PrimaryButtonGroup defaultValue={initialValues.primaryButton}>
              {primaryButtonContentList.map((content) => {
                return primaryButtonBackgroundColorList.map(
                  (backgroundColor) => (
                    <StyledRadioButton value={{ backgroundColor, content }}>
                      <BobaPrimaryIconWidget
                        data={{
                          backgroundColor: backgroundColor,
                          content: content,
                        }}
                      />
                    </StyledRadioButton>
                  ),
                )
              })}
            </PrimaryButtonGroup>
          </Form.Item>
          <Form.Item
            label={intl.formatMessage(messages.instruction2, { br: <br /> })}
            name="secondaryButton"
            required
          >
            <Radio.Group
              defaultValue={initialValues.secondaryButton}
              style={{
                display: 'grid',
                gridTemplateColumns: 'repeat(3, 1fr)',
                gap: '8px',
                paddingTop: '16px',
              }}
            >
              {secondaryButtonContentList.map((content) => {
                return secondaryButtonBackgroundColorList.map(
                  (backgroundColor) => {
                    return (
                      <StyledRadioButton
                        value={{ backgroundColor, content }}
                        children={
                          <BobaBarWithCharacter
                            animation="none"
                            fullName={content}
                            primaryColor={backgroundColor}
                          />
                        }
                      />
                    )
                  },
                )
              })}
            </Radio.Group>
          </Form.Item>
          <div
            style={{ fontSize: '10px', marginBottom: '16px', color: '#6C7B8A' }}
            children={intl.formatMessage(messages.remark)}
          />
        </Form>
        <Button onClick={() => onRemove()}>
          {intl.formatMessage(messages.delete)}
        </Button>
      </BobameWidgetConfigStyled>
    )
  },
)

const BobameWidgetConfigStyled = styled.div`
  padding-bottom: 80px;
`

const StyledRadioButton = styled(Radio.Button)`
  height: unset;
`

const PrimaryButtonGroup = styled(Radio.Group)`
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  column-gap: 4px;
  row-gap: 12px;
  > * {
    padding: 0;
  }
  > *:nth-child(-n + 5) {
    grid-column: span 3;
    div:first-of-type {
      margin: auto;
    }
  }
  > *:nth-child(n + 6) {
    grid-column: span 5;
  }
`
