import { Checkbox, CheckboxProps } from 'antd'
import React from 'react'
import styled from 'styled-components/macro'

export type PrimaryCheckBoxProps = {} & CheckboxProps

export const PrimaryCheckBox = (props: PrimaryCheckBoxProps) => {
  return <StyledCheckBox {...props} />
}

const StyledCheckBox = styled(Checkbox)`
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.bobaColor.primary};
    border-color: ${({ theme }) => theme.bobaColor.primary};
  }
  & .ant-checkbox-checked::after,
  & .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${({ theme }) => theme.bobaColor.primary};
  }
`
