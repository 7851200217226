export const downloadImage = (element: any, fileName: string) => {
  const tempoLink = window.document.createElement('a')
  tempoLink.style = 'display:none;'
  tempoLink.download = fileName

  tempoLink.href = element

  document.body.appendChild(tempoLink)
  tempoLink.click()
  document.body.removeChild(tempoLink)

  tempoLink.remove()
}
