import { defineMessages } from 'react-intl'

export default defineMessages({
  desktopView: {
    defaultMessage: 'Desktop View',
  },
  mobileView: {
    defaultMessage: 'Mobile View',
  },
  exitPreview: {
    defaultMessage: 'Exit Preview',
  },
})
