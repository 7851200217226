import { initializeApp } from 'firebase/app'
import {
  initializeAuth,
  browserLocalPersistence,
  browserPopupRedirectResolver,
} from 'firebase/auth'

export const firebaseConfig = {
  apiKey: 'AIzaSyBNPAmw4ObdQ5Q5sCwcSNE1cO4B059vlTA',
  authDomain: 'anyonelab.firebaseapp.com',
  projectId: 'anyonelab',
  storageBucket: 'anyonelab.appspot.com',
  messagingSenderId: '805297304240',
  appId: '1:805297304240:web:90461f1a9fdf3483de0175',
  measurementId: 'G-5XPN14PQ8D',
}

const app = initializeApp(firebaseConfig)

export const auth = initializeAuth(app, {
  persistence: browserLocalPersistence,
  popupRedirectResolver: browserPopupRedirectResolver,
})
