import { defineMessages } from 'react-intl'

export default defineMessages({
  title: { defaultMessage: 'Share with everyone!' },
  subtitle: {
    defaultMessage:
      'Tell everyone your Boba Bar is open! {br}Download pic or copy link to post on your social media.',
  },
  description: {
    defaultMessage:
      'BobaMe is a donation platform. Support your favorite creator by sending special Bobas!',
  },
  downloadPicture: { defaultMessage: 'Download Pic' },
  copyLink: { defaultMessage: 'Copy Link' },
})
