import * as React from 'react'

import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import messages from './messages'
import { Avatar, Space } from 'antd'
import { useImageUrlChecker, useWidget, withWidget } from '@anyonelab/common'
import { AvatarWidgetConfigSchema } from '../types'
import { AvatarWidgetConfigDefaults, AVATAR_WIDGET_ID } from '../constants'
import { AvatarWidgetConfig } from '../config'
import { WidgetType } from '@anyonelab/frontend/src/models/Widget'
import SnackBioAvatarPlaceholder from '../../../assets/snack-bio-avatar-placeholder.png'

export interface AvatarCreatorWidgetProps {
  data: WidgetType['avatar']
}

export const AvatarCreatorWidget = withWidget<AvatarCreatorWidgetProps>(
  {
    widgetId: AVATAR_WIDGET_ID,
    configComponent: AvatarWidgetConfig,
  },
  ({ data = AvatarWidgetConfigDefaults }) => {
    const intl = useIntl()

    const { selectWidget } = useWidget()

    const { isWaitingThumbnail } = useImageUrlChecker(data.imageUrl)

    return (
      <AvatarCreatorStyled onClick={selectWidget}>
        <Space align="center" direction="vertical">
          <AvatarStyled
            draggable={false}
            size={128}
            shape={'circle'}
            src={
              isWaitingThumbnail
                ? data.imageUrl.replace('thumbnail', 'original')
                : data.imageUrl || SnackBioAvatarPlaceholder
            }
          >
            {intl.formatMessage(messages.profileImage)}
          </AvatarStyled>
        </Space>
      </AvatarCreatorStyled>
    )
  },
)

const AvatarCreatorStyled = styled.div`
  .ant-avatar-string {
    color: #9a9a9a;
  }
`

const AvatarStyled = styled(Avatar)``
