import * as React from 'react'

import styled, { css } from 'styled-components/macro'
import { useIntl } from 'react-intl'
import messages from './messages'
import {
  hasHttpPrefix,
  addHttpsPrefix,
  isValidUrl,
  isValidEmail,
  filterSpacesFromString,
  useWidget,
  withWidget,
  notification,
  openInNewTab,
  openEmail,
  openPhoneCall,
  track,
  PublicPageEvent,
  useResponsive,
  message,
} from '@anyonelab/common'
import { LinkBlockWidgetConfigSchema } from '../types'
import {
  LinkBlockWidgetConfigDefaults,
  LINK_BLOCK_WIDGET_ID,
} from '../constants'
import { LinkBlockWidgetConfig } from '../config'
import { Button } from '@anyonelab/common'
import { getButtonColors, getButtonTypeCSS } from '../style'
import { capitalCase } from 'change-case'
import { FontFamily } from '../../TextWidget/enum'
import { isPossiblePhoneNumber } from 'react-phone-number-input'
import { isIOS } from 'react-device-detect'
import { WidgetType } from '@anyonelab/frontend/src/models/Widget'

export interface LinkBlockWidgetCreatorProps {
  data: WidgetType['linkButton']
}

export const LinkBlockWidgetCreator = withWidget<LinkBlockWidgetCreatorProps>(
  {
    widgetId: LINK_BLOCK_WIDGET_ID,
    configComponent: LinkBlockWidgetConfig,
  },
  ({ data = LinkBlockWidgetConfigDefaults, instanceId }) => {
    const intl = useIntl()

    const url = data.url

    const { selectWidget, displayMode } = useWidget()

    const { isMobileLayout } = useResponsive()

    const buttonTextWithBreak = data.title.split('\n')

    const isIOSDevicePhoneCall = isIOS && Boolean(data.type === 'phoneNumber')

    const LinkButtonRedirect = (event: React.MouseEvent): void => {
      event.preventDefault()
      if (!displayMode) return

      let urlIsValid = true

      switch (data.type) {
        case 'external':
          let parsedUrl = hasHttpPrefix(url) ? url : addHttpsPrefix(url)
          // Add https if it is missing the protocol, otherwise use the original url.
          if (isValidUrl(parsedUrl)) {
            openInNewTab(parsedUrl)
          } else {
            urlIsValid = false
          }
          break
        case 'email':
          if (isValidEmail(url)) {
            // NOTE: could add customized subject & body here.
            openEmail({ email: url, subject: '', body: '' })
          } else {
            urlIsValid = false
          }
          break
        case 'phoneNumber':
          if (isPossiblePhoneNumber(url)) {
            if (isIOS) break
            if (isMobileLayout) {
              openPhoneCall(url)
            } else {
              navigator.clipboard.writeText(url)
              message.success(intl.formatMessage(messages.copiedPhoneNumber))
            }
          } else {
            urlIsValid = false
          }
          break
        case 'whatsapp':
          if (isPossiblePhoneNumber(url)) {
            const whatsappPrefix = 'https://wa.me/'
            // NOTE: To remove the '+' from the phone number since whatsapp will handle it automatically.
            const parsedPhoneNumber = url.substring(1)
            openInNewTab(whatsappPrefix + parsedPhoneNumber)
          } else {
            urlIsValid = false
          }
          break
        default:
          break
      }

      urlIsValid ||
        notification.error({
          message: intl.formatMessage(messages.Oops),
          description: intl.formatMessage(
            messages.thisLinkDoesntTakeYouAnywhere,
          ),
        })

      const subdomain =
        window.location.pathname.replaceAll('/', '') ||
        window.location.hostname.split('.')[0]

      const linkPosition = Number(instanceId) + 1

      track(PublicPageEvent.linkBlockClick, {
        username: subdomain,
        link_title: data.title,
        link_type: data.type,
        link_url: url,
        link_position: linkPosition,
      })
    }

    return (
      <LinkBlockWidgetStyled onClick={selectWidget}>
        <LinkButtonTypeStyled
          className="link-block-button"
          displayMode={displayMode}
          href={
            displayMode && isIOSDevicePhoneCall ? `tel:${data.url}` : undefined
          }
          onClick={isIOSDevicePhoneCall ? undefined : LinkButtonRedirect}
          buttonType={data.buttonType}
          fontSize={data.fontSize}
          fontFamily={capitalCase(data.fontFamily || FontFamily.Roboto)}
          fontWeight={data.fontWeight}
          fontStyle={data.fontStyle}
          fontColor={data.fontColor}
          backgroundColor={
            data.backgroundColor +
            (data.backgroundColorOpacity !== undefined
              ? data.backgroundColorOpacity < 7
                ? '0' +
                  Math.floor(
                    (data.backgroundColorOpacity / 100) * 255,
                  ).toString(16)
                : Math.floor(
                    (data.backgroundColorOpacity / 100) * 255,
                  ).toString(16)
              : 'ff')
          }
        >
          {(data.title &&
            buttonTextWithBreak.map((text, i) => (
              <span key={i}>{filterSpacesFromString(text)}</span>
            ))) || (
            <span
              style={{
                fontStyle: 'italic',
                color: '#ccc',
                fontWeight: 'lighter',
              }}
            >
              {intl.formatMessage(messages.buttonTextIsRequired)}
            </span>
          )}
        </LinkButtonTypeStyled>
      </LinkBlockWidgetStyled>
    )
  },
)

const LinkBlockWidgetStyled = styled.div``

const LinkButtonTypeStyled = styled((props) => {
  const {
    children,
    displayMode,
    buttonType,
    fontSize,
    fontFamily,
    fontWeight,
    fontStyle,
    fontColor,
    backgroundColor,
    ...htmlProps
  } = props

  return <Button {...htmlProps}>{children}</Button>
})<{
  displayMode: boolean
  buttonType: LinkBlockWidgetConfigSchema['buttonType']
  fontSize: LinkBlockWidgetConfigSchema['fontSize']
  fontFamily: LinkBlockWidgetConfigSchema['fontFamily']
  fontWeight: LinkBlockWidgetConfigSchema['fontWeight']
  fontStyle: LinkBlockWidgetConfigSchema['fontStyle']
  fontColor: LinkBlockWidgetConfigSchema['fontColor']
  backgroundColor: LinkBlockWidgetConfigSchema['backgroundColor']
}>`
  padding: 20px;
  width: 100%;
  height: auto;
  border-radius: 12px;
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: ${({ fontSize }) => fontSize || '16px'};
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  font-style: ${({ fontStyle }) => fontStyle || 'normal'};
  color: ${({ fontColor }) => fontColor || 'black'};
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: normal;
  transition: all 0.3 ease 0s;

  a&& {
    padding: 20px !important;
  }

  :hover,
  :focus,
  :active {
    color: ${({ fontColor }) => fontColor || 'black'};

    ${({ buttonType }) => getButtonTypeCSS(buttonType)};

    ${({ buttonType, backgroundColor }) =>
      getButtonColors(buttonType, backgroundColor)};

    transform: ${({ displayMode }) =>
      displayMode ? `scale(1.02) translate(0px, -2px)` : ''};
  }

  ${({ buttonType }) => getButtonTypeCSS(buttonType)};

  ${({ buttonType, backgroundColor }) =>
    getButtonColors(buttonType, backgroundColor)};

  & span:empty:before {
    content: '\u200B';
  }
`
