import React from 'react'
import styled from 'styled-components/macro'
import {
  MobileOnly,
  DesktopOnly,
  BobaPriceNameDisplay,
} from '@anyonelab/common'
import { PrimaryButton } from '../../PrimaryButton'
import { ReactComponent as BobaShaker } from '../../../assets/boba/shaker.svg'
import { ReactComponent as SettingIcon } from '../../../assets/boba-tools/settingIcon.svg'
import { useBobaUser } from '../../../../../frontend/src/app/providers/BobaUserProvider/BobaUserProvider'
import { useIntl } from 'react-intl'
import messages from '../messages'

export function WelcomeShakerNotification({ onClose }) {
  const { bobaUserAccount } = useBobaUser()
  const intl = useIntl()
  const title = 'Hey Boba Shaker!'
  const subtitle = `You can edit the bobatea name. and price in the “setting”`
  const content = 'Got it!'

  if (!bobaUserAccount) {
    return null
  }

  return (
    <>
      <DesktopOnly>
        <Title
          style={{ marginBottom: '16px' }}
          children={intl.formatMessage(messages.bobaShakerTitle)}
        />
        <BobaShaker style={{ marginBottom: '24px' }} />
        <Subtitle
          style={{
            position: 'relative',
            textAlign: 'start',
            marginBottom: '24px',
            display: 'inline',
          }}
        >
          {intl.formatMessage(messages.bobaShakerDescription)}
          <StyledIconContainer style={{ display: 'inline-flex' }}>
            <SettingIcon />
          </StyledIconContainer>
        </Subtitle>
        <BobaPriceNameDisplayContainer>
          <BobaPriceNameDisplay
            style={{
              background: '#f0f0f0',
              borderRadius: '24px',
              padding: '20px',
              transform: 'scale(0.7)',
            }}
            bobaDisplayProps={{
              style: { width: '64px', height: '64px' },
              padding: 8,
            }}
            currency={bobaUserAccount.currency}
            boba={bobaUserAccount?.boba_menu[0]}
            index={0}
            isDisplayMode={true}
          />
        </BobaPriceNameDisplayContainer>
        <DesktopPrimaryButton content={content} onClick={onClose} />
      </DesktopOnly>
      <MobileOnly>
        <Title
          style={{ marginBottom: '12px' }}
          children={intl.formatMessage(messages.bobaShakerTitle)}
        />
        <BobaShaker style={{ marginBottom: '12px' }} />
        <Subtitle
          style={{
            position: 'relative',
            display: 'inline',
            textAlign: 'start',
          }}
        >
          {intl.formatMessage(messages.bobaShakerDescription)}
          <StyledIconContainer style={{ display: 'inline-flex' }}>
            <SettingIcon />
          </StyledIconContainer>
        </Subtitle>
        <BobaPriceNameDisplayContainer>
          <BobaPriceNameDisplay
            className="price-name-display"
            style={{
              background: '#f0f0f0',
              borderRadius: '24px',
              padding: '20px',
              transform: 'scale(0.7)',
            }}
            bobaDisplayProps={{
              style: { width: '64px', height: '64px' },
              padding: 8,
            }}
            currency={bobaUserAccount.currency}
            boba={bobaUserAccount?.boba_menu[0]}
            index={0}
            isDisplayMode={true}
          />
        </BobaPriceNameDisplayContainer>
        <MobilePrimaryButton content={content} onClick={onClose} />
      </MobileOnly>
    </>
  )
}

const BobaPriceNameDisplayContainer = styled.div`
  .ant-col.ant-form-item-label {
    display: none;
  }
`

const Title = styled.div`
  font-family: '${({ theme }) => theme.bobaFontFamily.title}';
  font-weight: 700;
  font-size: 28px;
  color: ${({ theme }) => theme.bobaColor.textPrimary};
  text-align: center;
  line-height: 33px;
`

const Subtitle = styled.div`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize[20]};
  color: #656565;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  text-align: center;
`

const StyledPrimaryButton = styled(PrimaryButton)`
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: center;
  white-space: pre-wrap;
  height: 60px;
  width: 100%;
  font-size: 18px;
  font-size: 18px;
`

const DesktopPrimaryButton = styled(StyledPrimaryButton)`
  min-width: 400px;
`

const MobilePrimaryButton = styled(StyledPrimaryButton)``

const StyledIconContainer = styled.div`
  width: 28px;
  height: 20px;
  background: #cca78c;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 16px;
    height: 16px;
  }
`
