import React, { HTMLAttributes } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { BobaDisplay, DEFAULT_SIZE } from '../BobaDisplay'
import messages from './messages'
import { Form, Input, MAX_BOBA_COUNT, useResponsive } from '@anyonelab/common/'
import { BobaCurrency, BobaDonationDetail, BobaItem, BobaType } from '../type'

type BobaCounterProps = {
  count: BobaDonationDetail['count']
  changeValue: (number) => void
  increaseOnClick: () => void
  decreaseOnClick: () => void
} & HTMLAttributes<HTMLDivElement>

const countParser = (input: number): number => {
  if (isNaN(input) || input < 0) {
    return 0
  } else {
    return input
  }
}

const BobaCounter = (props: BobaCounterProps) => {
  return (
    <CounterContainer>
      <CounterButton onClick={props.decreaseOnClick} children={'-'} />
      <CounterInput
        inputMode="numeric"
        type="text"
        min={0}
        minLength={1}
        defaultValue={0}
        value={props.count}
        onChange={(event) => {
          const newValue = countParser(parseInt(event.currentTarget.value))
          return props.changeValue(newValue)
        }}
      />
      <CounterButton onClick={props.increaseOnClick} children={'+'} />
    </CounterContainer>
  )
}

type BobaMenuItemProps = {
  index: number
  currency: BobaCurrency
  updateCount: (count: number) => void
} & BobaDonationDetail &
  HTMLAttributes<HTMLDivElement>

export const BobaMenuItem = (props: BobaMenuItemProps) => {
  const intl = useIntl()
  const increaseOnClick = () => props.updateCount(countParser(props.count + 1))
  const decreaseOnClick = () => props.updateCount(countParser(props.count - 1))
  const { isMobileLayout } = useResponsive()

  return (
    // * this padding to prevent wrapped boba overlapping
    <div style={{ paddingTop: isMobileLayout ? 0 : DEFAULT_SIZE / 2 }}>
      <Container className={isMobileLayout ? 'mobile' : 'desktop'} {...props}>
        <StyledBobaDisplay
          backgroundColor={props.boba_type.background_color}
          series={props.boba_type.series}
        />
        <BobaTitle className="boba-title" children={props.name} />
        <BobaPrice
          children={intl.formatMessage(messages.price, {
            currency: intl.formatMessage(messages[props.currency]),
            value: props.value,
          })}
        />
        <BobaCounter
          count={props.count}
          changeValue={(value) => props.updateCount(value)}
          increaseOnClick={increaseOnClick}
          decreaseOnClick={decreaseOnClick}
        />
      </Container>
    </div>
  )
}

const Container = styled.div`
  background-color: white;
  width: 170px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: ${DEFAULT_SIZE / 1.75}px 12px 12px;
  min-width: 170px;
  border-radius: 24px;
  box-sizing: border-box;

  .boba-title {
    width: 100%;
    overflow: hidden;
  }
  &.desktop {
    .boba-title {
    }
  }
  &.mobile {
    .boba-title {
    }
  }
`

const StyledBobaDisplay = styled(BobaDisplay)`
  position: absolute;
  top: -${DEFAULT_SIZE / 2}px;
  padding: 10px;
`

const BobaTitle = styled.div`
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fontWeight[600]};
  font-family: '${({ theme }) => theme.bobaFontFamily.primary}';
  color: #4a1e11;
  margin-bottom: 6px;
  text-align: center;
`

const BobaPrice = styled.div`
  color: #929292;
  font-size: 11px;
  font-weight: ${({ theme }) => theme.fontWeight[500]};
  font-family: '${({ theme }) => theme.bobaFontFamily.primary}';
  margin-bottom: 6px;
`

const CounterButton = styled.div`
  color: ${({ theme }) => theme.bobaColor.backgroundSecondary};
  font-size: 20px;
  font-weight: ${({ theme }) => theme.fontWeight[600]};
  font-family: '${({ theme }) => theme.bobaFontFamily.primary}';
  padding: 0 12px;
  cursor: pointer;
  user-select: none;
`

const CounterInput = styled(Input)`
  width: 60px;
  border-radius: 60px;
  border: unset;
  color: ${({ theme }) => theme.bobaColor.primary};
  background-color: ${({ theme }) => theme.bobaColor.backgroundPrimary};
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize[14]};
  font-weight: ${({ theme }) => theme.fontWeight[600]};
  font-family: '${({ theme }) => theme.bobaFontFamily.primary}';
`

const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
