import * as React from 'react'

export const BobaComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="108"
    height="156"
    viewBox="0 0 108 156"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Frame" clip-path="url(#clip0_1286_9119)">
      <path
        id="Vector"
        d="M103.092 68.82C107.015 57.94 106.517 45.943 101.706 35.4302C96.8947 24.9174 88.1587 16.7363 77.3921 12.6607C66.6255 8.58519 54.6963 8.94396 44.1907 13.6589C33.6852 18.3738 25.4501 27.065 21.2715 37.848L103.092 68.82Z"
        fill="#E3E7F5"
      />
      <path
        id="Vector_2"
        d="M20.6297 41.1675L0.0942412 133.077C-0.0317631 133.774 0.0878744 134.493 0.432471 135.11C0.777068 135.727 1.325 136.203 1.98169 136.457L52.9429 155.789C53.6062 156.055 54.3425 156.073 55.0179 155.84C55.6933 155.607 56.2629 155.137 56.6233 154.517L101.337 71.7598C101.535 71.3808 101.649 70.9635 101.672 70.5363C101.694 70.109 101.626 69.6817 101.47 69.2836C101.314 68.8855 101.075 68.5258 100.769 68.2289C100.463 67.932 100.096 67.705 99.6951 67.5631L24.4802 39.0787C24.0918 38.9392 23.6786 38.8833 23.2674 38.9148C22.8561 38.9462 22.456 39.0642 22.093 39.2611C21.73 39.458 21.4122 39.7294 21.1601 40.0578C20.908 40.3862 20.7273 40.7643 20.6297 41.1675Z"
        fill="#F2F2F2"
      />
      <path
        id="Vector_3"
        d="M82.9934 0.252578L82.9758 0.245884C80.9074 -0.537125 78.5998 0.515063 77.8216 2.59602L48.9735 79.7314C48.1953 81.8123 49.2411 84.134 51.3094 84.917L51.3271 84.9237C53.3954 85.7067 55.703 84.6545 56.4813 82.5736L85.3293 5.43823C86.1076 3.35727 85.0618 1.03559 82.9934 0.252578Z"
        fill="#CED8ED"
      />
      <path
        id="Vector_4"
        d="M104.866 63.6606L24.7538 33.3326C22.2172 32.3723 19.3869 33.6626 18.4324 36.2147L18.4258 36.2325C17.4713 38.7846 18.7541 41.632 21.2907 42.5923L101.403 72.9204C103.94 73.8806 106.77 72.5902 107.724 70.0381L107.731 70.0203C108.685 67.4682 107.403 64.6209 104.866 63.6606Z"
        fill="#CBD3E8"
      />
      <path
        id="Vector_5"
        d="M89.6538 93.0834L56.7178 154.572C56.3574 155.192 55.7878 155.662 55.1124 155.895C54.437 156.129 53.7006 156.111 53.0374 155.844L2.07618 136.513C1.41948 136.259 0.87155 135.782 0.526954 135.165C0.182357 134.548 0.0627193 133.829 0.188724 133.133L15.4204 64.9219L89.6538 93.0834Z"
        fill="#F8E0BE"
      />
      <path
        id="Vector_6"
        d="M74.3088 11.125C77.2135 11.8219 79.9953 12.9619 82.5572 14.5052L69.2128 50.1868L60.2285 46.7876C60.2285 46.7876 74.6297 11.2579 74.3088 11.125Z"
        fill="#E3E7F5"
      />
      <path
        id="Vector_7"
        d="M15.1374 125.272C17.7851 125.272 19.9315 123.112 19.9315 120.448C19.9315 117.784 17.7851 115.625 15.1374 115.625C12.4897 115.625 10.3433 117.784 10.3433 120.448C10.3433 123.112 12.4897 125.272 15.1374 125.272Z"
        fill="#D79B7A"
      />
      <path
        id="Vector_8"
        d="M15.3261 108.756C17.9738 108.756 20.1202 106.597 20.1202 103.933C20.1202 101.269 17.9738 99.1094 15.3261 99.1094C12.6784 99.1094 10.532 101.269 10.532 103.933C10.532 106.597 12.6784 108.756 15.3261 108.756Z"
        fill="#D79B7A"
      />
      <path
        id="Vector_9"
        d="M23.0837 141.303C25.7314 141.303 27.8778 139.143 27.8778 136.48C27.8778 133.816 25.7314 131.656 23.0837 131.656C20.436 131.656 18.2896 133.816 18.2896 136.48C18.2896 139.143 20.436 141.303 23.0837 141.303Z"
        fill="#D79B7A"
      />
      <path
        id="Vector_10"
        d="M58.9829 119.678C61.6306 119.678 63.777 117.518 63.777 114.855C63.777 112.191 61.6306 110.031 58.9829 110.031C56.3351 110.031 54.1887 112.191 54.1887 114.855C54.1887 117.518 56.3351 119.678 58.9829 119.678Z"
        fill="#D79B7A"
      />
      <path
        id="Vector_11"
        d="M59.9643 138.022C62.612 138.022 64.7584 135.862 64.7584 133.198C64.7584 130.534 62.612 128.375 59.9643 128.375C57.3166 128.375 55.1702 130.534 55.1702 133.198C55.1702 135.862 57.3166 138.022 59.9643 138.022Z"
        fill="#D79B7A"
      />
      <path
        id="Vector_12"
        d="M41.3351 136.631C43.9829 136.631 46.1293 134.472 46.1293 131.808C46.1293 129.144 43.9829 126.984 41.3351 126.984C38.6874 126.984 36.541 129.144 36.541 131.808C36.541 134.472 38.6874 136.631 41.3351 136.631Z"
        fill="#D79B7A"
      />
      <path
        id="Vector_13"
        d="M50.5837 150.701C53.2314 150.701 55.3778 148.542 55.3778 145.878C55.3778 143.214 53.2314 141.055 50.5837 141.055C47.936 141.055 45.7896 143.214 45.7896 145.878C45.7896 148.542 47.936 150.701 50.5837 150.701Z"
        fill="#D79B7A"
      />
      <path
        id="Vector_14"
        d="M35.7106 121.381C38.3584 121.381 40.5048 119.222 40.5048 116.558C40.5048 113.894 38.3584 111.734 35.7106 111.734C33.0629 111.734 30.9165 113.894 30.9165 116.558C30.9165 119.222 33.0629 121.381 35.7106 121.381Z"
        fill="#D79B7A"
      />
      <path
        id="Vector_15"
        d="M68.8354 37.6381C67.5686 37.1053 66.5083 36.1721 65.8153 34.9795C65.7527 34.8551 65.7394 34.7115 65.778 34.5775C65.8165 34.4436 65.9042 34.3294 66.0231 34.2579C66.1435 34.1884 66.2861 34.1695 66.4203 34.205C66.5545 34.2405 66.6695 34.3277 66.7404 34.4477C66.7404 34.5996 68.7787 37.9797 72.0251 36.4985C72.0885 36.4533 72.1611 36.4229 72.2375 36.409C72.314 36.3952 72.3924 36.3984 72.4675 36.4185C72.5425 36.4387 72.6122 36.4755 72.6716 36.5259C72.731 36.5762 72.7785 36.6392 72.811 36.7102C72.8435 36.7812 72.86 36.8585 72.8594 36.9366C72.8588 37.0148 72.8409 37.0917 72.8073 37.1622C72.7737 37.2326 72.7251 37.2947 72.6649 37.3442C72.6047 37.3936 72.5345 37.429 72.4592 37.448C71.8991 37.7239 71.2894 37.8829 70.6666 37.9156C70.0439 37.9482 69.421 37.8539 68.8354 37.6381Z"
        fill="#42210B"
      />
      <path
        id="Vector_16"
        d="M52.3767 34.5824C55.8688 34.5824 58.6996 31.7343 58.6996 28.2209C58.6996 24.7075 55.8688 21.8594 52.3767 21.8594C48.8846 21.8594 46.0537 24.7075 46.0537 28.2209C46.0537 31.7343 48.8846 34.5824 52.3767 34.5824Z"
        fill="#F2F2F2"
      />
      <path
        id="Vector_17"
        d="M46.3936 25.979C46.6783 25.1869 47.1165 24.4595 47.6829 23.8392C48.2493 23.2188 48.9326 22.7179 49.6929 22.3653C50.4533 22.0128 51.2757 21.8156 52.1124 21.7854C52.949 21.7551 53.7833 21.8922 54.5668 22.1889C55.3504 22.4857 56.0677 22.9361 56.6768 23.5139C57.286 24.0918 57.7751 24.7855 58.1157 25.555C58.4563 26.3245 58.6416 27.1543 58.6608 27.9964C58.6801 28.8385 58.5329 29.6761 58.2279 30.4605"
        fill="#51260E"
      />
      <path
        id="Vector_18"
        d="M53.4147 28.5224C53.2968 29.0523 53.0675 29.5504 52.742 29.9835C52.4164 30.4166 52.0024 30.7745 51.5277 31.0329C51.0531 31.2913 50.5289 31.4443 49.9905 31.4817C49.4521 31.519 48.9121 31.4396 48.4067 31.2492C47.9014 31.0587 47.4424 30.7614 47.0609 30.3773C46.6795 29.9933 46.3841 29.5315 46.1951 29.0229C46.0061 28.5143 45.9277 27.9709 45.9652 27.4292C46.0026 26.8875 46.1552 26.3602 46.4124 25.8828"
        fill="#47220D"
      />
      <path
        id="Vector_19"
        d="M87.4079 47.8559C90.9 47.8559 93.7309 45.0077 93.7309 41.4943C93.7309 37.981 90.9 35.1328 87.4079 35.1328C83.9159 35.1328 81.085 37.981 81.085 41.4943C81.085 45.0077 83.9159 47.8559 87.4079 47.8559Z"
        fill="#F2F2F2"
      />
      <path
        id="Vector_20"
        d="M81.4246 39.2313C82.0304 37.6734 83.222 36.4183 84.741 35.7378C86.26 35.0572 87.9843 35.0059 89.5405 35.5952C91.0967 36.1845 92.3595 37.3671 93.0555 38.8864C93.7515 40.4057 93.8245 42.1397 93.2589 43.7129"
        fill="#51260E"
      />
      <path
        id="Vector_21"
        d="M88.446 41.8148C88.0132 42.6263 87.302 43.2519 86.445 43.5752C85.5879 43.8985 84.6434 43.8975 83.787 43.5724C82.9307 43.2473 82.2208 42.62 81.7897 41.8077C81.3586 40.9953 81.2355 40.0531 81.4434 39.1562"
        fill="#47220D"
      />
    </g>
    <defs>
      <clipPath id="clip0_1286_9119">
        <rect width="108" height="156" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
