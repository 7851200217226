import React from 'react'

export const Matcha = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="217"
      height="380"
      viewBox="0 0 217 380"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="PIC_1">
        <g id="Mask group">
          <mask
            id="mask0_1232_1125"
            // style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="217"
            height="380"
          >
            <rect
              id="Rectangle 3460"
              width="217"
              height="380"
              rx="32"
              fill="#D9D9D9"
            />
          </mask>
          <g mask="url(#mask0_1232_1125)">
            <g id="Group">
              <path
                id="Vector"
                d="M184.11 9.75H33.0397C14.9137 9.75 0.219727 24.3289 0.219727 42.3129V346.164C0.219727 364.148 14.9137 378.727 33.0397 378.727H184.11C202.236 378.727 216.93 364.148 216.93 346.164V42.3129C216.93 24.3289 202.236 9.75 184.11 9.75Z"
                fill="white"
              />
              <path
                id="Vector_2"
                d="M29.9994 1.40326e-05H185.399C193.839 1.40326e-05 201.932 3.32621 207.9 9.24688C213.867 15.1675 217.219 23.1977 217.219 31.5708V216.431H-1.85059V31.5708C-1.85059 27.4223 -1.02655 23.3146 0.574434 19.4823C2.17541 15.65 4.52194 12.1683 7.47988 9.23634C10.4378 6.30434 13.9492 3.97947 17.8132 2.39465C21.6773 0.809819 25.8182 -0.00389713 29.9994 1.40326e-05V1.40326e-05Z"
                fill="#BCAF96"
              />
              <path
                id="Vector_3"
                d="M193.099 206.719H22.2694C8.94831 206.719 -1.85059 217.433 -1.85059 230.65V356.03C-1.85059 369.247 8.94831 379.961 22.2694 379.961H193.099C206.421 379.961 217.219 369.247 217.219 356.03V230.65C217.219 217.433 206.421 206.719 193.099 206.719Z"
                fill="#E6CDB4"
              />
              <path
                id="Vector_4"
                d="M88.1495 254.681C140.71 254.681 183.32 212.406 183.32 160.257C183.32 108.107 140.71 65.832 88.1495 65.832C35.5886 65.832 -7.02051 108.107 -7.02051 160.257C-7.02051 212.406 35.5886 254.681 88.1495 254.681Z"
                fill="#DCA87F"
              />
              <path
                id="Vector_5"
                d="M157.9 97.3025C152.925 95.5133 147.67 94.6163 142.38 94.6534C142.38 94.6534 131.45 96.0326 130.77 100.785C131.323 101.315 132.045 101.64 132.812 101.703C133.579 101.766 134.345 101.565 134.98 101.132C137.59 99.7036 147.07 100.388 151.4 109.238C155.73 118.088 171.17 114.368 168.83 113.078C159.49 107.978 157.9 97.3025 157.9 97.3025Z"
                fill="#E0D7CC"
              />
              <path
                id="Vector_6"
                d="M159.95 88.0556C159.95 88.0556 123.79 71.4765 154.95 45.7099C154.95 45.7099 187.05 17.0562 198.47 47.5951C198.47 47.5951 203.62 60.1956 201.87 62.3486C201.419 62.9475 200.762 63.36 200.023 63.5065C199.285 63.6531 198.518 63.5233 197.87 63.1423C197.87 63.1423 196.57 44.3904 187.16 40.1736C177.75 35.9569 151.27 46.1266 147.38 64.9084C147.38 64.9084 144.33 71.6253 154.63 80.3961C154.63 80.3961 160.84 85.2776 161.3 85.1486C161.76 85.0196 162.11 87.4405 159.95 88.0556Z"
                fill="#E0D7CC"
              />
              <path
                id="Vector_7"
                d="M162.78 77.7079C162.78 77.7079 150.779 93.394 159.079 104.615C159.079 104.615 163.24 118.684 180 116.68C196.76 114.676 214 100.428 220 92.1737C223.64 87.2128 223.54 74.4337 216.69 66.4368C210.18 58.8071 196.69 57.299 189.09 60.8609L162.78 77.7079Z"
                fill="#EFE9E4"
              />
              <path
                id="Vector_8"
                d="M162.28 79.7028C162.28 79.7028 158.33 73.0553 169.28 64.9493C169.28 64.9493 158.01 60.3953 167.13 54.8193C167.13 54.8193 174.85 49.5509 175.54 61.3279C175.54 61.3279 184.28 54.7201 191.03 59.7801C191.03 59.7801 185.49 71.9342 167.83 77.8574L162.28 79.7028Z"
                fill="#E0D7CC"
              />
              <path
                id="Vector_9"
                d="M217.22 203.344H-3.2002V323.118H217.22V203.344Z"
                fill="#FAF6EE"
              />
              <path
                id="Vector_10"
                d="M6.73966 265.653L45.6197 265.594C45.5623 265.807 45.4819 266.013 45.3797 266.209C43.5297 269.007 41.2497 271.467 39.2297 274.146C37.6097 276.309 35.8997 278.293 34.1497 280.397C31.9397 283.105 29.7097 285.804 27.6197 288.612C26.9597 289.495 26.4497 290.19 25.2097 290.19C13.1297 290.19 1.03966 290.19 -11.0403 290.19C-11.3803 290.19 -11.8403 290.348 -12.0403 289.971C-12.2403 289.594 -11.8903 289.227 -11.6403 288.92C-9.99035 286.935 -8.51034 284.891 -6.87034 282.967C-3.50034 278.889 -0.250344 274.712 3.02966 270.564C4.3743 269.011 5.61368 267.37 6.73966 265.653Z"
                fill="#E4DED4"
              />
              <path
                id="Vector_11"
                d="M6.52966 294.544C12.6363 294.544 18.7463 294.544 24.8597 294.544C25.1797 294.544 25.6597 294.326 25.8597 294.752C26.0597 295.179 25.6197 295.387 25.3997 295.665C22.7397 298.979 20.0997 302.313 17.3997 305.587C14.5197 309.189 11.6097 312.77 8.73966 316.382C8.56136 316.582 8.3346 316.733 8.08086 316.821C7.82711 316.91 7.55478 316.933 7.28966 316.888L-28.7103 316.937C-29.1503 316.937 -29.7103 317.066 -29.9403 316.59C-30.1703 316.114 -29.7003 315.737 -29.4503 315.419C-26.8603 312.155 -24.2303 308.921 -21.6303 305.656C-19.0303 302.392 -16.6303 299.356 -14.1403 296.161C-13.7898 295.622 -13.3017 295.185 -12.7255 294.894C-12.1494 294.603 -11.5058 294.469 -10.8603 294.504C-5.10034 294.614 0.739659 294.554 6.52966 294.544Z"
                fill="#E4DED4"
              />
              <path
                id="Vector_12"
                d="M53.1195 265.653L91.9895 265.594C91.9321 265.807 91.8517 266.013 91.7495 266.209C89.9095 269.007 87.6295 271.467 85.5995 274.146C83.9895 276.279 82.2195 278.323 80.5195 280.417C78.3195 283.125 76.0895 285.824 73.9895 288.632C73.3295 289.515 72.8195 290.209 71.5795 290.209C59.4995 290.209 47.4195 290.209 35.3395 290.209C34.9895 290.209 34.5295 290.368 34.3395 289.991C34.1495 289.614 34.4895 289.247 34.7495 288.939C36.3895 286.955 37.8795 284.911 39.5095 282.986C42.8895 278.909 46.1395 274.732 49.4195 270.584C50.7551 269.019 51.9908 267.372 53.1195 265.653V265.653Z"
                fill="#E4DED4"
              />
              <path
                id="Vector_13"
                d="M53.3296 294.515C59.4496 294.515 65.5596 294.515 71.6696 294.515C71.9996 294.515 72.4696 294.297 72.6696 294.724C72.8696 295.15 72.4296 295.359 72.1996 295.637C69.5329 298.944 66.8663 302.251 64.1996 305.558C61.3196 309.16 58.4096 312.751 55.5296 316.363C55.3519 316.564 55.1252 316.715 54.8713 316.804C54.6174 316.893 54.3447 316.915 54.0796 316.869H18.0796C17.6396 316.869 17.0796 316.998 16.8596 316.522C16.6396 316.045 17.0896 315.668 17.3396 315.351C19.9296 312.087 22.5696 308.842 25.1696 305.588C27.7696 302.334 30.1696 299.288 32.6596 296.083C33.0106 295.545 33.4991 295.109 34.0754 294.82C34.6516 294.531 35.2949 294.398 35.9396 294.436C41.7396 294.595 47.5396 294.525 53.3296 294.515Z"
                fill="#E4DED4"
              />
              <path
                id="Vector_14"
                d="M101.09 265.653L139.96 265.594C139.911 265.808 139.833 266.015 139.73 266.209C137.88 269.007 135.6 271.467 133.58 274.146C131.96 276.279 130.19 278.323 128.5 280.417C126.29 283.125 124.06 285.824 121.97 288.632C121.3 289.515 120.8 290.209 119.56 290.209C107.47 290.209 95.3901 290.209 83.3101 290.209C82.9701 290.209 82.5101 290.368 82.3101 289.991C82.1101 289.614 82.4701 289.247 82.7201 288.939C84.3701 286.955 85.8501 284.911 87.4801 282.986C90.8601 278.909 94.1101 274.732 97.3901 270.584C98.7289 269.021 99.9648 267.374 101.09 265.653Z"
                fill="#E4DED4"
              />
              <path
                id="Vector_15"
                d="M101.31 294.515C107.42 294.515 113.53 294.515 119.64 294.515C119.97 294.515 120.44 294.297 120.64 294.723C120.84 295.15 120.4 295.358 120.18 295.636C117.513 298.943 114.847 302.251 112.18 305.558C109.29 309.159 106.38 312.751 103.51 316.363C103.332 316.562 103.105 316.714 102.851 316.802C102.598 316.891 102.325 316.914 102.06 316.869H66.0601C65.6201 316.869 65.0601 316.998 64.8301 316.521C64.6001 316.045 65.0601 315.668 65.3201 315.351C67.9101 312.086 70.5401 308.842 73.1401 305.588C75.7401 302.333 78.1401 299.287 80.6301 296.083C80.9818 295.546 81.4705 295.11 82.0465 294.821C82.6226 294.532 83.2655 294.399 83.9101 294.436C89.7201 294.594 95.5101 294.525 101.31 294.515Z"
                fill="#E4DED4"
              />
              <path
                id="Vector_16"
                d="M147.459 265.653L186.339 265.594C186.282 265.807 186.202 266.013 186.099 266.209C184.259 269.007 181.969 271.467 179.949 274.146C178.329 276.279 176.569 278.323 174.869 280.417C172.659 283.125 170.429 285.824 168.339 288.632C167.679 289.515 167.169 290.209 165.929 290.209C153.849 290.209 141.769 290.209 129.679 290.209C129.339 290.209 128.879 290.368 128.679 289.991C128.479 289.614 128.829 289.247 129.089 288.939C130.729 286.955 132.219 284.911 133.849 282.986C137.229 278.909 140.469 274.732 143.749 270.584C145.095 269.024 146.334 267.377 147.459 265.653V265.653Z"
                fill="#E4DED4"
              />
              <path
                id="Vector_17"
                d="M147.68 294.515C153.79 294.515 159.91 294.515 166.02 294.515C166.35 294.515 166.82 294.297 167.02 294.723C167.22 295.15 166.78 295.358 166.56 295.636C163.893 298.943 161.227 302.251 158.56 305.558C155.67 309.159 152.76 312.751 149.89 316.363C149.712 316.563 149.486 316.715 149.232 316.804C148.978 316.892 148.705 316.915 148.44 316.869H112.44C112 316.869 111.44 316.998 111.21 316.521C110.98 316.045 111.45 315.668 111.7 315.351C114.29 312.086 116.93 308.842 119.53 305.588C122.13 302.333 124.53 299.287 127.01 296.083C127.364 295.545 127.855 295.11 128.432 294.821C129.01 294.532 129.654 294.399 130.3 294.436C136.09 294.594 141.9 294.525 147.68 294.515Z"
                fill="#E4DED4"
              />
              <path
                id="Vector_18"
                d="M47.43 208.476L86.3 208.406C86.241 208.622 86.1606 208.831 86.06 209.031C84.22 211.819 81.94 214.28 79.91 216.969C78.29 219.092 76.53 221.146 74.83 223.229C72.63 225.948 70.39 228.646 68.3 231.444C67.64 232.327 67.13 233.022 65.89 233.022C53.81 233.022 41.73 233.022 29.65 233.022C29.3 233.022 28.84 233.181 28.65 232.814C28.46 232.446 28.8 232.06 29.06 231.762C30.7 229.778 32.19 227.724 33.82 225.759C37.2 221.691 40.45 217.504 43.73 213.357C45.0619 211.806 46.2975 210.176 47.43 208.476V208.476Z"
                fill="#E4DED4"
              />
              <path
                id="Vector_19"
                d="M47.6401 237.336H65.9802C66.3102 237.336 66.7802 237.128 66.9802 237.554C67.1802 237.981 66.7402 238.189 66.5102 238.457C63.8435 241.764 61.1768 245.088 58.5102 248.428C55.6302 252.03 52.7202 255.612 49.8402 259.223C49.6637 259.425 49.4371 259.578 49.1828 259.667C48.9285 259.756 48.6553 259.777 48.3901 259.729L12.3902 259.779C11.9502 259.779 11.3901 259.918 11.1701 259.432C10.9501 258.945 11.4001 258.588 11.6501 258.271C14.2401 254.997 16.8802 251.762 19.4802 248.498C22.0802 245.234 24.4801 242.198 26.9601 238.993C27.3142 238.456 27.8048 238.021 28.3824 237.731C28.96 237.442 29.6042 237.309 30.2501 237.346C36.0501 237.405 41.9001 237.346 47.6401 237.336Z"
                fill="#E4DED4"
              />
              <path
                id="Vector_20"
                d="M94.3695 237.336H112.709C113.029 237.336 113.509 237.128 113.709 237.554C113.909 237.981 113.47 238.189 113.25 238.457C110.583 241.764 107.916 245.088 105.25 248.428C102.36 252.03 99.4495 255.612 96.5795 259.223C96.403 259.425 96.1765 259.578 95.9222 259.667C95.6679 259.756 95.3947 259.777 95.1295 259.729L59.1295 259.779C58.6895 259.779 58.1295 259.918 57.8995 259.432C57.6695 258.945 58.1395 258.588 58.3895 258.271C60.9795 254.997 63.6095 251.762 66.2195 248.498C68.8295 245.234 71.2195 242.198 73.6995 238.993C74.0528 238.455 74.5433 238.019 75.1211 237.73C75.6988 237.441 76.3434 237.308 76.9895 237.346C82.7795 237.405 88.5795 237.346 94.3695 237.336Z"
                fill="#E4DED4"
              />
              <path
                id="Vector_21"
                d="M103.899 220.548L138.449 220.488C138.402 220.678 138.331 220.861 138.239 221.034C136.599 223.524 134.579 225.717 132.779 228.078C131.339 229.973 129.779 231.789 128.259 233.644C126.259 236.065 124.319 238.456 122.459 240.947C121.869 241.731 121.459 242.346 120.309 242.346C109.569 242.346 98.8291 242.346 88.0891 242.346C87.7891 242.346 87.3791 242.495 87.2091 242.157C87.0391 241.82 87.3491 241.492 87.5691 241.225C89.0291 239.488 90.3491 237.643 91.8091 235.897C94.8091 232.275 97.6891 228.555 100.609 224.864C101.8 223.498 102.899 222.057 103.899 220.548V220.548Z"
                fill="#E4DED4"
              />
              <path
                id="Vector_22"
                d="M115.9 237.336H134.23C134.56 237.336 135.04 237.128 135.23 237.554C135.42 237.981 134.99 238.189 134.77 238.457C132.103 241.764 129.437 245.088 126.77 248.428C123.88 252.03 120.97 255.612 118.1 259.223C117.923 259.425 117.696 259.577 117.442 259.666C117.188 259.754 116.915 259.776 116.65 259.729L80.65 259.779C80.21 259.779 79.65 259.918 79.42 259.432C79.19 258.945 79.66 258.588 79.91 258.271C82.5 254.997 85.13 251.762 87.73 248.498C90.33 245.234 92.73 242.198 95.22 238.993C95.5734 238.455 96.0638 238.019 96.6416 237.73C97.2193 237.441 97.864 237.308 98.51 237.346C104.32 237.405 110.11 237.346 115.9 237.336Z"
                fill="#E4DED4"
              />
              <path
                id="Vector_23"
                d="M141.77 208.476L180.65 208.406C180.591 208.622 180.511 208.831 180.41 209.031C178.57 211.819 176.28 214.28 174.26 216.969C172.64 219.092 170.88 221.146 169.18 223.229C166.97 225.948 164.74 228.646 162.65 231.444C161.99 232.327 161.48 233.022 160.24 233.022C148.16 233.022 136.077 233.022 123.99 233.022C123.65 233.022 123.19 233.181 122.99 232.814C122.79 232.446 123.14 232.06 123.39 231.762C125.04 229.778 126.53 227.724 128.16 225.759C131.54 221.691 134.78 217.504 138.06 213.357C139.401 211.811 140.641 210.181 141.77 208.476V208.476Z"
                fill="#E4DED4"
              />
              <path
                id="Vector_24"
                d="M141.99 237.336H160.33C160.66 237.336 161.13 237.128 161.33 237.554C161.53 237.981 161.09 238.189 160.87 238.457C158.203 241.764 155.536 245.088 152.87 248.428C149.98 252.03 147.07 255.612 144.2 259.223C144.023 259.425 143.797 259.578 143.542 259.667C143.288 259.756 143.015 259.777 142.75 259.729L106.75 259.779C106.31 259.779 105.75 259.918 105.52 259.432C105.29 258.945 105.76 258.588 106.01 258.271C108.6 254.997 111.24 251.762 113.84 248.498C116.44 245.234 118.84 242.198 121.32 238.993C121.674 238.456 122.164 238.021 122.742 237.731C123.319 237.442 123.964 237.309 124.61 237.346C130.4 237.405 136.2 237.346 141.99 237.336Z"
                fill="#E4DED4"
              />
              <path
                id="Vector_25"
                d="M188.15 208.476L227.02 208.406C226.961 208.622 226.88 208.831 226.78 209.031C224.94 211.819 222.66 214.28 220.64 216.969C219.02 219.092 217.25 221.146 215.55 223.229C213.35 225.948 211.12 228.646 209.02 231.444C208.36 232.327 207.86 233.022 206.61 233.022C194.53 233.022 182.45 233.022 170.37 233.022C170.02 233.022 169.56 233.181 169.37 232.814C169.18 232.446 169.52 232.06 169.78 231.762C171.42 229.778 172.91 227.724 174.54 225.759C177.92 221.691 181.17 217.504 184.45 213.357C185.785 211.808 187.02 210.178 188.15 208.476V208.476Z"
                fill="#E4DED4"
              />
              <path
                id="Vector_26"
                d="M188.37 237.336H206.7C207.03 237.336 207.5 237.128 207.7 237.554C207.9 237.981 207.46 238.189 207.24 238.457C204.573 241.764 201.906 245.088 199.24 248.428C196.35 252.03 193.44 255.612 190.57 259.223C190.392 259.424 190.165 259.576 189.911 259.664C189.657 259.753 189.385 259.775 189.12 259.729L153.12 259.779C152.68 259.779 152.12 259.918 151.89 259.432C151.66 258.945 152.12 258.588 152.37 258.271C154.96 254.997 157.6 251.762 160.2 248.498C162.8 245.234 165.2 242.198 167.69 238.993C168.041 238.456 168.53 238.02 169.106 237.731C169.682 237.442 170.325 237.309 170.97 237.346C176.78 237.405 182.57 237.346 188.37 237.336Z"
                fill="#E4DED4"
              />
              <path
                id="Vector_27"
                d="M142.26 270.398H75.56C71.2245 270.398 67.71 273.885 67.71 278.187V280.042C67.71 284.344 71.2245 287.831 75.56 287.831H142.26C146.595 287.831 150.11 284.344 150.11 280.042V278.187C150.11 273.885 146.595 270.398 142.26 270.398Z"
                fill="#9A8C85"
              />
              <path
                id="Vector_28"
                d="M63.8097 173.598L77.1897 268.578C77.3184 269.299 77.7026 269.95 78.273 270.414C78.8434 270.878 79.5622 271.124 80.2997 271.108H136.59C137.325 271.122 138.042 270.874 138.61 270.41C139.178 269.947 139.561 269.297 139.69 268.578L152.99 173.598C153.054 173.163 153.02 172.72 152.891 172.3C152.762 171.88 152.541 171.494 152.244 171.169C151.946 170.843 151.58 170.587 151.172 170.419C150.763 170.251 150.322 170.174 149.88 170.195H66.8997C66.4593 170.177 66.0204 170.256 65.6142 170.426C65.2079 170.595 64.8444 170.852 64.5494 171.177C64.2544 171.501 64.0352 171.887 63.9073 172.305C63.7795 172.724 63.7461 173.165 63.8097 173.598V173.598Z"
                fill="#F2F2F2"
              />
              <path
                id="Vector_29"
                d="M111.9 112.133H106.63C105.801 112.133 105.13 112.799 105.13 113.621V201.001C105.13 201.823 105.801 202.489 106.63 202.489H111.9C112.728 202.489 113.4 201.823 113.4 201.001V113.621C113.4 112.799 112.728 112.133 111.9 112.133Z"
                fill="#CED8ED"
              />
              <path
                id="Vector_30"
                d="M153.53 170.198C153.53 158.323 148.775 146.934 140.311 138.537C131.848 130.139 120.369 125.422 108.4 125.422C96.4303 125.422 84.9513 130.139 76.4878 138.537C68.0243 146.934 63.2695 158.323 63.2695 170.198H153.53Z"
                fill="#E3E7F5"
              />
              <path
                id="Vector_31"
                d="M156.559 164.66H61.7394C60.7232 164.66 59.8994 165.477 59.8994 166.486V172.905C59.8994 173.913 60.7232 174.731 61.7394 174.731H156.559C157.576 174.731 158.399 173.913 158.399 172.905V166.486C158.399 165.477 157.576 164.66 156.559 164.66Z"
                fill="#CBD3E8"
              />
              <path
                id="Vector_32"
                d="M149.3 198.109L139.69 268.553C139.561 269.272 139.178 269.922 138.61 270.386C138.042 270.849 137.325 271.097 136.59 271.083H80.2998C79.5624 271.099 78.8435 270.853 78.2731 270.389C77.7028 269.925 77.3185 269.274 77.1898 268.553L67.2598 198.109H149.3Z"
                fill="#A5B39D"
              />
              <path
                id="Vector_33"
                d="M82.1797 152.654C82.2498 150.771 83.0533 148.987 84.4212 147.679C85.7891 146.371 87.6149 145.641 89.5147 145.641C91.4145 145.641 93.2403 146.371 94.6082 147.679C95.9761 148.987 96.7796 150.771 96.8497 152.654"
                fill="#42210B"
              />
              <path
                id="Vector_34"
                d="M89.6794 149.31L90.8494 149.152C91.0494 149.152 91.2094 149.449 91.1694 149.816C91.0494 150.898 90.7094 151.801 89.9994 152.158C89.2894 152.515 88.2894 151.553 88.1094 149.995C87.9294 148.437 88.4994 146.84 89.3594 146.473C89.9594 146.215 90.4394 146.592 90.7794 147.217C90.8521 147.362 90.8848 147.524 90.8743 147.686C90.8638 147.848 90.8103 148.005 90.7194 148.14L89.6794 149.31Z"
                fill="#F2F2F2"
              />
              <path
                id="Vector_35"
                d="M122.1 152.654C122.17 150.771 122.973 148.987 124.341 147.679C125.709 146.371 127.535 145.641 129.435 145.641C131.334 145.641 133.16 146.371 134.528 147.679C135.896 148.987 136.699 150.771 136.77 152.654"
                fill="#42210B"
              />
              <path
                id="Vector_36"
                d="M129.599 149.31L130.759 149.152C130.969 149.152 131.129 149.449 131.089 149.816C130.959 150.898 130.619 151.801 129.909 152.158C129.199 152.515 128.199 151.553 128.029 149.995C127.859 148.437 128.419 146.84 129.279 146.473C129.869 146.215 130.349 146.592 130.699 147.217C130.766 147.364 130.795 147.525 130.784 147.686C130.774 147.846 130.724 148.002 130.639 148.14L129.599 149.31Z"
                fill="#F2F2F2"
              />
              <path
                id="Vector_37"
                d="M109.74 152.429C108.324 152.39 106.962 151.884 105.87 150.99C105.812 150.946 105.764 150.891 105.729 150.829C105.694 150.766 105.671 150.697 105.663 150.625C105.655 150.554 105.662 150.481 105.683 150.412C105.704 150.344 105.739 150.28 105.785 150.224C105.831 150.169 105.888 150.123 105.953 150.09C106.017 150.058 106.088 150.038 106.16 150.032C106.232 150.027 106.305 150.036 106.374 150.059C106.442 150.082 106.505 150.119 106.56 150.167C106.7 150.276 109.81 152.786 112.42 150.167C112.521 150.067 112.657 150.012 112.8 150.012C112.942 150.012 113.078 150.067 113.18 150.167C113.28 150.269 113.337 150.406 113.337 150.549C113.337 150.692 113.28 150.829 113.18 150.931C112.739 151.399 112.206 151.773 111.615 152.031C111.023 152.288 110.385 152.424 109.74 152.429Z"
                fill="#42210B"
              />
              <path
                id="Vector_38"
                d="M108.59 262.022C111.329 262.022 113.55 259.819 113.55 257.101C113.55 254.383 111.329 252.18 108.59 252.18C105.851 252.18 103.63 254.383 103.63 257.101C103.63 259.819 105.851 262.022 108.59 262.022Z"
                fill="#42210B"
              />
              <path
                id="Vector_39"
                d="M138.85 240.877C141.589 240.877 143.81 238.674 143.81 235.956C143.81 233.238 141.589 231.035 138.85 231.035C136.11 231.035 133.89 233.238 133.89 235.956C133.89 238.674 136.11 240.877 138.85 240.877Z"
                fill="#42210B"
              />
              <path
                id="Vector_40"
                d="M134.47 254.709C137.209 254.709 139.43 252.506 139.43 249.788C139.43 247.07 137.209 244.867 134.47 244.867C131.73 244.867 129.51 247.07 129.51 249.788C129.51 252.506 131.73 254.709 134.47 254.709Z"
                fill="#42210B"
              />
              <path
                id="Vector_41"
                d="M121.17 249.432C123.909 249.432 126.13 247.229 126.13 244.511C126.13 241.793 123.909 239.59 121.17 239.59C118.431 239.59 116.21 241.793 116.21 244.511C116.21 247.229 118.431 249.432 121.17 249.432Z"
                fill="#42210B"
              />
              <path
                id="Vector_42"
                d="M123.79 266.159C126.529 266.159 128.75 263.955 128.75 261.238C128.75 258.52 126.529 256.316 123.79 256.316C121.051 256.316 118.83 258.52 118.83 261.238C118.83 263.955 121.051 266.159 123.79 266.159Z"
                fill="#42210B"
              />
              <path
                id="Vector_43"
                d="M84.5694 252.694C87.3087 252.694 89.5294 250.491 89.5294 247.773C89.5294 245.055 87.3087 242.852 84.5694 242.852C81.83 242.852 79.6094 245.055 79.6094 247.773C79.6094 250.491 81.83 252.694 84.5694 252.694Z"
                fill="#42210B"
              />
              <path
                id="Vector_44"
                d="M93.3399 268.768C96.0792 268.768 98.2999 266.565 98.2999 263.847C98.2999 261.129 96.0792 258.926 93.3399 258.926C90.6005 258.926 88.3799 261.129 88.3799 263.847C88.3799 266.565 90.6005 268.768 93.3399 268.768Z"
                fill="#42210B"
              />
              <path
                id="Vector_45"
                d="M69.6298 214.655C72.0698 213.484 77.0598 215.399 78.0898 217.473C79.1198 219.547 78.8098 221.878 79.3898 224.051C79.6904 225.279 80.3957 226.373 81.3938 227.158C82.392 227.944 83.6258 228.376 84.8998 228.387C85.7773 228.299 86.6271 228.032 87.3962 227.603C88.1652 227.175 88.8371 226.594 89.3698 225.896C90.9532 224.071 91.8133 221.736 91.7898 219.328C91.7898 216.967 90.9798 214.129 93.3198 212.621C95.4498 211.242 98.9198 212.125 100.55 213.871C102.18 215.618 102.46 217.84 102.69 220.023L103.93 231.73C104.2 234.221 104.71 237.068 107.39 238.457C107.842 238.71 108.339 238.871 108.854 238.932C109.369 238.994 109.891 238.954 110.39 238.814C110.96 238.596 111.463 238.233 111.85 237.763C115.37 233.992 115.64 228.942 114.85 224.319C114.6 222.801 114.27 221.174 115.12 219.775C115.97 218.376 118.55 217.542 119.98 218.614C122.1 220.201 119.35 223.684 121.72 225.053C123.44 226.045 125.88 224.597 126.51 223.009C127.14 221.422 126.86 219.646 127.8 218.157C128.74 216.669 131.92 215.915 132.85 217.413C133.19 217.959 133.11 218.663 133.61 219.13C134.71 220.122 136.84 218.892 137.22 217.602C137.6 216.312 137.29 214.715 137.76 213.326C138.054 212.553 138.624 211.915 139.361 211.532C140.099 211.15 140.953 211.05 141.76 211.252C142.46 211.51 143.03 212.075 143.81 212.056C144.59 212.036 147.05 211.321 147.31 210.647C148.64 207.204 148.47 202.213 149.31 198.086H67.2598L69.6298 214.655Z"
                fill="white"
              />
              <path
                id="Vector_46"
                d="M158.57 358.031C172.454 358.031 183.71 346.863 183.71 333.088C183.71 319.312 172.454 308.145 158.57 308.145C144.685 308.145 133.43 319.312 133.43 333.088C133.43 346.863 144.685 358.031 158.57 358.031Z"
                fill="#F2F2F2"
              />
              <path
                id="Vector_47"
                d="M141.499 337.059C144.548 337.059 147.02 334.607 147.02 331.582C147.02 328.558 144.548 326.105 141.499 326.105C138.451 326.105 135.979 328.558 135.979 331.582C135.979 334.607 138.451 337.059 141.499 337.059Z"
                fill="white"
              />
              <path
                id="Vector_48"
                d="M159.129 327.911C162.178 327.911 164.649 325.459 164.649 322.434C164.649 319.409 162.178 316.957 159.129 316.957C156.081 316.957 153.609 319.409 153.609 322.434C153.609 325.459 156.081 327.911 159.129 327.911Z"
                fill="white"
              />
              <path
                id="Vector_49"
                d="M179.01 336.047C179.904 336.047 180.63 335.327 180.63 334.439C180.63 333.552 179.904 332.832 179.01 332.832C178.115 332.832 177.39 333.552 177.39 334.439C177.39 335.327 178.115 336.047 179.01 336.047Z"
                fill="white"
              />
              <path
                id="Vector_50"
                d="M156.94 326.959C158.906 326.959 160.5 325.377 160.5 323.427C160.5 321.476 158.906 319.895 156.94 319.895C154.974 319.895 153.38 321.476 153.38 323.427C153.38 325.377 154.974 326.959 156.94 326.959Z"
                fill="#5B5A61"
              />
              <path
                id="Vector_51"
                d="M143.539 334.084C145.506 334.084 147.1 332.502 147.1 330.552C147.1 328.601 145.506 327.02 143.539 327.02C141.573 327.02 139.979 328.601 139.979 330.552C139.979 332.502 141.573 334.084 143.539 334.084Z"
                fill="#5B5A61"
              />
              <path
                id="Vector_52"
                d="M177.189 330.343C177.579 328.746 176.689 327.138 175.839 325.729L172.789 320.66C172.44 320.218 172.272 319.661 172.319 319.102C172.437 318.75 172.682 318.453 173.007 318.27C173.332 318.087 173.714 318.029 174.079 318.11C174.807 318.295 175.462 318.693 175.959 319.251C177.248 320.499 178.328 321.942 179.159 323.527C179.88 324.705 180.229 326.07 180.159 327.446C180.103 328.129 179.83 328.777 179.379 329.296C178.928 329.814 178.322 330.178 177.649 330.333L177.189 330.343Z"
                fill="white"
              />
              <path
                id="Vector_53"
                d="M43.3597 80.2573C57.2442 80.2573 68.4997 69.0899 68.4997 55.3142C68.4997 41.5385 57.2442 30.3711 43.3597 30.3711C29.4753 30.3711 18.2197 41.5385 18.2197 55.3142C18.2197 69.0899 29.4753 80.2573 43.3597 80.2573Z"
                fill="#F2F2F2"
              />
              <path
                id="Vector_54"
                d="M26.2895 59.2817C29.3381 59.2817 31.8095 56.8296 31.8095 53.8049C31.8095 50.7802 29.3381 48.3281 26.2895 48.3281C23.2409 48.3281 20.7695 50.7802 20.7695 53.8049C20.7695 56.8296 23.2409 59.2817 26.2895 59.2817Z"
                fill="white"
              />
              <path
                id="Vector_55"
                d="M43.9194 50.1332C46.968 50.1332 49.4394 47.6812 49.4394 44.6564C49.4394 41.6317 46.968 39.1797 43.9194 39.1797C40.8708 39.1797 38.3994 41.6317 38.3994 44.6564C38.3994 47.6812 40.8708 50.1332 43.9194 50.1332Z"
                fill="white"
              />
              <path
                id="Vector_56"
                d="M63.7997 58.2693C64.6944 58.2693 65.4197 57.5497 65.4197 56.662C65.4197 55.7743 64.6944 55.0547 63.7997 55.0547C62.905 55.0547 62.1797 55.7743 62.1797 56.662C62.1797 57.5497 62.905 58.2693 63.7997 58.2693Z"
                fill="white"
              />
              <path
                id="Vector_57"
                d="M41.7299 49.1814C43.6961 49.1814 45.2899 47.6 45.2899 45.6493C45.2899 43.6986 43.6961 42.1172 41.7299 42.1172C39.7638 42.1172 38.1699 43.6986 38.1699 45.6493C38.1699 47.6 39.7638 49.1814 41.7299 49.1814Z"
                fill="#5B5A61"
              />
              <path
                id="Vector_58"
                d="M28.3295 56.3025C30.2957 56.3025 31.8895 54.7211 31.8895 52.7704C31.8895 50.8197 30.2957 49.2383 28.3295 49.2383C26.3634 49.2383 24.7695 50.8197 24.7695 52.7704C24.7695 54.7211 26.3634 56.3025 28.3295 56.3025Z"
                fill="#5B5A61"
              />
              <path
                id="Vector_59"
                d="M61.9802 52.5657C62.3702 50.9683 61.4802 49.361 60.6302 47.9521L57.6302 42.8822C57.2808 42.4405 57.1129 41.884 57.1602 41.3245C57.2776 40.9722 57.5227 40.6757 57.8478 40.4925C58.1729 40.3092 58.5551 40.2521 58.9202 40.3323C59.646 40.5217 60.2997 40.9184 60.8002 41.4733C62.0884 42.7216 63.1685 44.165 64.0002 45.7495C64.7275 46.9428 65.076 48.3257 65.0002 49.7182C64.9435 50.3999 64.6698 51.0461 64.2186 51.5634C63.7674 52.0806 63.1621 52.442 62.4902 52.5955L61.9802 52.5657Z"
                fill="white"
              />
              <path
                id="Vector_60"
                d="M196.31 386.48C210.194 386.48 221.45 375.313 221.45 361.537C221.45 347.761 210.194 336.594 196.31 336.594C182.425 336.594 171.17 347.761 171.17 361.537C171.17 375.313 182.425 386.48 196.31 386.48Z"
                fill="#42210B"
              />
              <path
                id="Vector_61"
                d="M180.22 364.157C182.931 364.157 185.13 361.976 185.13 359.286C185.13 356.595 182.931 354.414 180.22 354.414C177.508 354.414 175.31 356.595 175.31 359.286C175.31 361.976 177.508 364.157 180.22 364.157Z"
                fill="white"
              />
              <path
                id="Vector_62"
                d="M195.9 356.259C198.612 356.259 200.81 354.078 200.81 351.387C200.81 348.697 198.612 346.516 195.9 346.516C193.189 346.516 190.99 348.697 190.99 351.387C190.99 354.078 193.189 356.259 195.9 356.259Z"
                fill="white"
              />
              <path
                id="Vector_63"
                d="M195.97 355.049C197.936 355.049 199.53 353.467 199.53 351.516C199.53 349.566 197.936 347.984 195.97 347.984C194.004 347.984 192.41 349.566 192.41 351.516C192.41 353.467 194.004 355.049 195.97 355.049Z"
                fill="#42210B"
              />
              <path
                id="Vector_64"
                d="M180.23 362.705C182.196 362.705 183.79 361.123 183.79 359.173C183.79 357.222 182.196 355.641 180.23 355.641C178.264 355.641 176.67 357.222 176.67 359.173C176.67 361.123 178.264 362.705 180.23 362.705Z"
                fill="#42210B"
              />
              <path
                id="Vector_65"
                d="M177.83 369.989L179.5 369.82C179.664 369.818 179.821 369.754 179.94 369.642C179.998 369.585 180.044 369.517 180.075 369.442C180.106 369.367 180.122 369.286 180.12 369.205C180.121 369.125 180.106 369.046 180.075 368.973C180.044 368.899 179.998 368.833 179.94 368.778C179.884 368.719 179.815 368.671 179.74 368.639C179.664 368.606 179.582 368.59 179.5 368.59L177.83 368.758C177.669 368.762 177.516 368.826 177.4 368.937C177.284 369.054 177.216 369.21 177.21 369.374C177.217 369.534 177.285 369.687 177.4 369.801C177.514 369.914 177.668 369.982 177.83 369.989Z"
                fill="white"
              />
              <path
                id="Vector_66"
                d="M180.299 372.923L181.059 371.286C181.138 371.14 181.16 370.97 181.119 370.81C181.075 370.657 180.975 370.527 180.839 370.443C180.692 370.364 180.521 370.343 180.359 370.383C180.204 370.425 180.072 370.524 179.989 370.661L179.229 372.308C179.15 372.454 179.129 372.624 179.169 372.784C179.214 372.937 179.314 373.067 179.449 373.151C179.596 373.23 179.768 373.251 179.929 373.211C180.007 373.19 180.08 373.154 180.144 373.104C180.208 373.055 180.26 372.993 180.299 372.923V372.923Z"
                fill="white"
              />
              <path
                id="Vector_67"
                d="M206.899 355.52L205.229 355.688C205.065 355.69 204.908 355.754 204.789 355.867C204.731 355.924 204.685 355.992 204.654 356.067C204.623 356.142 204.608 356.222 204.609 356.303C204.611 356.463 204.676 356.616 204.789 356.73C204.846 356.789 204.914 356.836 204.99 356.869C205.066 356.901 205.147 356.918 205.229 356.918L206.899 356.75C207.063 356.746 207.22 356.682 207.339 356.571C207.449 356.451 207.513 356.297 207.519 356.135C207.518 355.975 207.453 355.822 207.339 355.708C207.224 355.59 207.065 355.522 206.899 355.52V355.52Z"
                fill="white"
              />
              <path
                id="Vector_68"
                d="M204.409 352.585L203.649 354.222C203.57 354.368 203.549 354.538 203.589 354.698C203.635 354.853 203.739 354.985 203.879 355.065C204.023 355.144 204.191 355.165 204.349 355.125C204.427 355.106 204.499 355.071 204.563 355.024C204.627 354.976 204.68 354.916 204.719 354.847L205.479 353.2C205.559 353.055 205.581 352.884 205.539 352.724C205.495 352.571 205.395 352.441 205.259 352.357C205.112 352.278 204.941 352.257 204.779 352.297C204.623 352.342 204.491 352.445 204.409 352.585V352.585Z"
                fill="white"
              />
              <path
                id="Vector_69"
                d="M38.64 344.292C52.5244 344.292 63.78 333.125 63.78 319.349C63.78 305.574 52.5244 294.406 38.64 294.406C24.7556 294.406 13.5 305.574 13.5 319.349C13.5 333.125 24.7556 344.292 38.64 344.292Z"
                fill="#42210B"
              />
              <path
                id="Vector_70"
                d="M22.5399 321.958C25.2516 321.958 27.4499 319.777 27.4499 317.086C27.4499 314.396 25.2516 312.215 22.5399 312.215C19.8282 312.215 17.6299 314.396 17.6299 317.086C17.6299 319.777 19.8282 321.958 22.5399 321.958Z"
                fill="white"
              />
              <path
                id="Vector_71"
                d="M38.2293 314.071C40.9411 314.071 43.1393 311.89 43.1393 309.2C43.1393 306.509 40.9411 304.328 38.2293 304.328C35.5176 304.328 33.3193 306.509 33.3193 309.2C33.3193 311.89 35.5176 314.071 38.2293 314.071Z"
                fill="white"
              />
              <path
                id="Vector_72"
                d="M38.3002 312.861C40.2664 312.861 41.8602 311.28 41.8602 309.329C41.8602 307.378 40.2664 305.797 38.3002 305.797C36.3341 305.797 34.7402 307.378 34.7402 309.329C34.7402 311.28 36.3341 312.861 38.3002 312.861Z"
                fill="#42210B"
              />
              <path
                id="Vector_73"
                d="M22.56 320.517C24.5261 320.517 26.12 318.936 26.12 316.985C26.12 315.035 24.5261 313.453 22.56 313.453C20.5939 313.453 19 315.035 19 316.985C19 318.936 20.5939 320.517 22.56 320.517Z"
                fill="#42210B"
              />
              <path
                id="Vector_74"
                d="M20.1591 327.792L21.8291 327.633C21.9922 327.626 22.1479 327.563 22.2691 327.454C22.3265 327.397 22.372 327.329 22.4029 327.254C22.4338 327.179 22.4495 327.099 22.4491 327.018C22.4468 326.855 22.3825 326.699 22.2691 326.581C22.2119 326.523 22.1433 326.477 22.0676 326.447C21.992 326.416 21.9108 326.401 21.8291 326.402L20.1591 326.561C19.9942 326.569 19.8378 326.636 19.7191 326.75C19.6095 326.866 19.5457 327.017 19.5391 327.176C19.5414 327.339 19.6057 327.495 19.7191 327.613C19.777 327.67 19.8456 327.715 19.9211 327.746C19.9966 327.776 20.0775 327.792 20.1591 327.792V327.792Z"
                fill="white"
              />
              <path
                id="Vector_75"
                d="M22.6297 330.741L23.3897 329.094C23.4691 328.952 23.4906 328.785 23.4497 328.628C23.4288 328.551 23.3923 328.478 23.3425 328.415C23.2927 328.352 23.2305 328.3 23.1597 328.261C23.0188 328.181 22.8519 328.159 22.6948 328.2C22.5377 328.241 22.4031 328.341 22.3197 328.479L21.5597 330.126C21.4804 330.268 21.4589 330.435 21.4997 330.592C21.5417 330.746 21.642 330.878 21.7797 330.96C21.8507 331.003 21.9304 331.031 22.0132 331.041C22.096 331.052 22.1801 331.044 22.2597 331.019C22.4151 330.978 22.5481 330.879 22.6297 330.741V330.741Z"
                fill="white"
              />
              <path
                id="Vector_76"
                d="M49.21 313.336L47.54 313.495C47.3747 313.501 47.2177 313.568 47.1 313.683C47.0419 313.738 46.9959 313.804 46.9649 313.878C46.9339 313.951 46.9187 314.03 46.92 314.11C46.9185 314.191 46.9336 314.271 46.9646 314.347C46.9955 314.422 47.0416 314.49 47.1 314.546C47.1561 314.606 47.2244 314.653 47.3004 314.683C47.3763 314.714 47.4581 314.728 47.54 314.725L49.21 314.566C49.3699 314.558 49.5219 314.495 49.64 314.388C49.7547 314.27 49.8222 314.115 49.83 313.951C49.8297 313.869 49.8127 313.789 49.7801 313.714C49.7474 313.638 49.6998 313.571 49.64 313.515C49.5256 313.402 49.3713 313.338 49.21 313.336V313.336Z"
                fill="white"
              />
              <path
                id="Vector_77"
                d="M46.7397 310.389L45.9797 312.026C45.9003 312.168 45.8788 312.335 45.9197 312.493C45.9616 312.646 46.062 312.778 46.1997 312.86C46.2711 312.902 46.3503 312.93 46.4327 312.942C46.515 312.954 46.599 312.95 46.6797 312.929C46.7577 312.908 46.8307 312.872 46.8942 312.823C46.9578 312.773 47.0107 312.712 47.0497 312.641L47.8097 311.004C47.8887 310.859 47.9101 310.689 47.8697 310.528C47.825 310.376 47.7253 310.245 47.5897 310.161C47.5178 310.12 47.4382 310.093 47.3558 310.083C47.2733 310.073 47.1896 310.079 47.1097 310.101C47.032 310.12 46.9591 310.154 46.8954 310.202C46.8318 310.25 46.7787 310.31 46.7397 310.379V310.389Z"
                fill="white"
              />
              <path
                id="Vector_78"
                d="M73.3998 366.617C87.2842 366.617 98.5398 355.449 98.5398 341.674C98.5398 327.898 87.2842 316.73 73.3998 316.73C59.5153 316.73 48.2598 327.898 48.2598 341.674C48.2598 355.449 59.5153 366.617 73.3998 366.617Z"
                fill="#42210B"
              />
              <path
                id="Vector_79"
                d="M57.3094 344.282C60.0211 344.282 62.2194 342.101 62.2194 339.411C62.2194 336.72 60.0211 334.539 57.3094 334.539C54.5977 334.539 52.3994 336.72 52.3994 339.411C52.3994 342.101 54.5977 344.282 57.3094 344.282Z"
                fill="white"
              />
              <path
                id="Vector_80"
                d="M72.9901 336.384C75.7018 336.384 77.9001 334.203 77.9001 331.512C77.9001 328.822 75.7018 326.641 72.9901 326.641C70.2784 326.641 68.0801 328.822 68.0801 331.512C68.0801 334.203 70.2784 336.384 72.9901 336.384Z"
                fill="white"
              />
              <path
                id="Vector_81"
                d="M73.06 335.185C75.0261 335.185 76.62 333.604 76.62 331.653C76.62 329.702 75.0261 328.121 73.06 328.121C71.0939 328.121 69.5 329.702 69.5 331.653C69.5 333.604 71.0939 335.185 73.06 335.185Z"
                fill="#42210B"
              />
              <path
                id="Vector_82"
                d="M57.3198 342.834C59.2859 342.834 60.8798 341.252 60.8798 339.302C60.8798 337.351 59.2859 335.77 57.3198 335.77C55.3536 335.77 53.7598 337.351 53.7598 339.302C53.7598 341.252 55.3536 342.834 57.3198 342.834Z"
                fill="#42210B"
              />
              <path
                id="Vector_83"
                d="M54.8992 350.116L56.5792 349.957C56.7412 349.95 56.8946 349.883 57.0092 349.769C57.0673 349.714 57.1133 349.648 57.1443 349.574C57.1753 349.501 57.1905 349.422 57.1892 349.342C57.1907 349.261 57.1756 349.181 57.1446 349.105C57.1137 349.03 57.0676 348.962 57.0092 348.906C56.9551 348.846 56.8885 348.8 56.8142 348.769C56.7399 348.738 56.6597 348.724 56.5792 348.727L54.8992 348.886C54.7393 348.894 54.5873 348.957 54.4692 349.064C54.4117 349.122 54.3662 349.19 54.3353 349.265C54.3044 349.34 54.2888 349.42 54.2892 349.501C54.2876 349.582 54.3028 349.663 54.3337 349.738C54.3647 349.813 54.4108 349.881 54.4692 349.937C54.5836 350.05 54.7379 350.114 54.8992 350.116Z"
                fill="white"
              />
              <path
                id="Vector_84"
                d="M57.3902 353.066L58.1502 351.419C58.2296 351.277 58.2511 351.11 58.2102 350.952C58.1683 350.799 58.0679 350.667 57.9302 350.585C57.8604 350.543 57.7826 350.515 57.7016 350.503C57.6205 350.491 57.5378 350.495 57.4585 350.515C57.3792 350.536 57.3048 350.572 57.2398 350.621C57.1748 350.671 57.1205 350.733 57.0802 350.804L56.3202 352.441C56.2412 352.587 56.2198 352.756 56.2602 352.917C56.3055 353.072 56.4095 353.203 56.5502 353.284C56.6202 353.326 56.6981 353.352 56.779 353.363C56.8599 353.373 56.942 353.366 57.0202 353.344C57.0983 353.326 57.1718 353.292 57.2356 353.244C57.2994 353.197 57.3522 353.136 57.3902 353.066V353.066Z"
                fill="white"
              />
              <path
                id="Vector_85"
                d="M83.9697 335.66L82.2997 335.819C82.1365 335.826 81.9809 335.889 81.8597 335.998C81.8022 336.055 81.7567 336.123 81.7258 336.198C81.695 336.273 81.6793 336.353 81.6797 336.434C81.682 336.597 81.7463 336.753 81.8597 336.871C81.9794 336.982 82.136 337.045 82.2997 337.05L83.9697 336.891C84.1346 336.883 84.291 336.816 84.4097 336.702C84.5233 336.589 84.5879 336.436 84.5897 336.276C84.5874 336.113 84.5231 335.957 84.4097 335.839C84.3532 335.78 84.2848 335.734 84.209 335.703C84.1331 335.672 84.0516 335.658 83.9697 335.66Z"
                fill="white"
              />
              <path
                id="Vector_86"
                d="M81.4995 332.713L80.7495 334.35C80.6679 334.495 80.643 334.664 80.6795 334.826C80.7248 334.981 80.8287 335.113 80.9695 335.193C81.0394 335.235 81.1173 335.262 81.1982 335.272C81.2791 335.282 81.3612 335.276 81.4395 335.253C81.5176 335.235 81.591 335.202 81.6548 335.154C81.7187 335.106 81.7714 335.045 81.8095 334.975L82.5695 333.328C82.6489 333.186 82.6703 333.019 82.6295 332.862C82.585 332.705 82.4807 332.572 82.3388 332.49C82.1969 332.409 82.0285 332.385 81.8695 332.425C81.7152 332.474 81.5837 332.576 81.4995 332.713V332.713Z"
                fill="white"
              />
              <path
                id="Vector_87"
                d="M11.3392 385.042C25.2237 385.042 36.4792 373.875 36.4792 360.099C36.4792 346.324 25.2237 335.156 11.3392 335.156C-2.54522 335.156 -13.8008 346.324 -13.8008 360.099C-13.8008 373.875 -2.54522 385.042 11.3392 385.042Z"
                fill="#42210B"
              />
              <path
                id="Vector_88"
                d="M-4.75016 362.72C-2.03844 362.72 0.159843 360.539 0.159843 357.848C0.159843 355.158 -2.03844 352.977 -4.75016 352.977C-7.46187 352.977 -9.66016 355.158 -9.66016 357.848C-9.66016 360.539 -7.46187 362.72 -4.75016 362.72Z"
                fill="white"
              />
              <path
                id="Vector_89"
                d="M10.9295 354.821C13.6412 354.821 15.8395 352.64 15.8395 349.95C15.8395 347.259 13.6412 345.078 10.9295 345.078C8.21781 345.078 6.01953 347.259 6.01953 349.95C6.01953 352.64 8.21781 354.821 10.9295 354.821Z"
                fill="white"
              />
              <path
                id="Vector_90"
                d="M10.9995 353.611C12.9656 353.611 14.5595 352.03 14.5595 350.079C14.5595 348.128 12.9656 346.547 10.9995 346.547C9.03332 346.547 7.43945 348.128 7.43945 350.079C7.43945 352.03 9.03332 353.611 10.9995 353.611Z"
                fill="#42210B"
              />
              <path
                id="Vector_91"
                d="M21.8995 354.082L20.2295 354.251C20.0654 354.253 19.9082 354.317 19.7895 354.429C19.7311 354.486 19.685 354.554 19.654 354.629C19.6231 354.704 19.6079 354.785 19.6095 354.866C19.6113 355.026 19.6758 355.179 19.7895 355.292C19.8462 355.352 19.9144 355.399 19.99 355.431C20.0657 355.464 20.1471 355.481 20.2295 355.481L21.8995 355.312C22.0632 355.308 22.2198 355.245 22.3395 355.134C22.449 355.013 22.5127 354.859 22.5195 354.697C22.5177 354.537 22.4531 354.384 22.3395 354.271C22.2236 354.153 22.0655 354.085 21.8995 354.082V354.082Z"
                fill="white"
              />
              <path
                id="Vector_92"
                d="M19.4396 351.147L18.6796 352.784C18.6005 352.93 18.5791 353.1 18.6196 353.261C18.6649 353.416 18.7688 353.547 18.9096 353.628C19.0528 353.707 19.221 353.728 19.3796 353.687C19.457 353.668 19.5297 353.634 19.5933 353.586C19.6569 353.538 19.7101 353.478 19.7496 353.41L20.5096 351.763C20.5887 351.617 20.6101 351.447 20.5696 351.286C20.525 351.134 20.4253 351.003 20.2896 350.919C20.1427 350.841 19.9715 350.82 19.8096 350.86C19.6535 350.905 19.5209 351.008 19.4396 351.147V351.147Z"
                fill="white"
              />
              <g id="Group_2" opacity="0.28">
                <path
                  id="Vector_93"
                  opacity="0.28"
                  d="M153.29 170.551C153.123 159.965 148.767 149.869 141.163 142.442C133.558 135.014 123.316 130.852 112.645 130.852C101.974 130.852 91.7316 135.014 84.1274 142.442C76.5232 149.869 72.1674 159.965 72 170.551H153.29Z"
                  fill="#E3E7F5"
                />
                <path
                  id="Vector_94"
                  opacity="0.28"
                  d="M72.4898 173.618L84.4898 259.182C84.6082 259.828 84.9537 260.41 85.4646 260.826C85.9756 261.242 86.6189 261.465 87.2798 261.454H137.99C138.653 261.467 139.298 261.243 139.81 260.825C140.321 260.406 140.665 259.82 140.78 259.172L152.78 173.618C152.831 173.228 152.796 172.831 152.677 172.456C152.558 172.081 152.359 171.735 152.093 171.444C151.827 171.152 151.5 170.922 151.135 170.768C150.77 170.614 150.376 170.54 149.98 170.552H75.2798C74.8839 170.54 74.4901 170.614 74.1257 170.768C73.7613 170.922 73.435 171.152 73.1696 171.444C72.9041 171.736 72.7058 172.081 72.5884 172.457C72.4711 172.832 72.4374 173.228 72.4898 173.618Z"
                  fill="#F2F2F2"
                />
                <path
                  id="Vector_95"
                  opacity="0.28"
                  d="M156.08 165.57H70.56C69.6763 165.57 68.96 166.281 68.96 167.158V173.051C68.96 173.928 69.6763 174.639 70.56 174.639H156.08C156.964 174.639 157.68 173.928 157.68 173.051V167.158C157.68 166.281 156.964 165.57 156.08 165.57Z"
                  fill="#CBD3E8"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
