export enum LinkButtonType {
  FillRounded = 'fill_rounded',
  FillRectangle = 'fill_rectangle',
  FillPill = 'fill_pill',
  OutlineRounded = 'outline_rounded',
  OutlineRectangle = 'outline_rectangle',
  OutlinePill = 'outline_pill',
  SoftShadowRounded = 'soft_shadow_rounded',
  SoftShadowRectangle = 'soft_shadow_rectangle',
  SoftShadowPill = 'soft_shadow_pill',
  HardShadowRounded = 'hard_shadow_rounded',
  HardShadowRectangle = 'hard_shadow_rectangle',
  HardShadowPill = 'hard_shadow_pill',
}
