export enum LinkButtonType {
  FillRounded = 'fill_rounded',
  FillRectangle = 'fill_rectangle',
  FillPill = 'fill_pill',
  OutlineRounded = 'outline_rounded',
  OutlineRectangle = 'outline_rectangle',
  OutlinePill = 'outline_pill',
  SoftShadowRounded = 'soft_shadow_rounded',
  SoftShadowRectangle = 'soft_shadow_rectangle',
  SoftShadowPill = 'soft_shadow_pill',
  HardShadowRounded = 'hard_shadow_rounded',
  HardShadowRectangle = 'hard_shadow_rectangle',
  HardShadowPill = 'hard_shadow_pill',
}

export enum FontFamily {
  Arial = 'arial',
  Arvo = 'arvo',
  Bitter = 'bitter',
  CormorantGaramond = 'cormorant_garamond',
  CrimsonPro = 'crimson_pro',
  Lato = 'lato',
  LibreBaskerville = 'libre_baskerville',
  Lora = 'lora',
  Merriweather = 'merriweather',
  Montserrat = 'montserrat',
  NotoSans = 'noto_sans',
  NotoSerif = 'noto_serif',
  NunitoSans = 'nunito_sans',
  OpenSans = 'open_sans',
  Oswald = 'oswald',
  PlayfairDisplay = 'playfair_display',
  Poppins = 'poppins',
  PtSerif = 'pt_serif',
  Raleway = 'raleway',
  Roboto = 'roboto',
  SourceSansPro = 'source_sans_pro',
  Ubuntu = 'ubuntu',
  思源黑體 = 'noto_sans_tc',
  思源宋體 = 'noto_serif_tc',
}

export type FontStyle = 'normal' | 'italic'
export type FontWeight = 'normal' | 'bold' | 'light'

export interface AvatarWidget {
  imageUrl: string
}

export interface LabelWidget {
  text: string
  fontSize: string | number
  fontColor: string
  fontStyle: FontStyle
  fontFamily: FontFamily
  fontWeight: FontWeight
}

export const availableLinkButtonUrlTypes = [
  'external',
  // 'internal',
  'email',
  'whatsapp',
  'phoneNumber',
] as const

export type LinkButtonUrlType = typeof availableLinkButtonUrlTypes[number]

export type PhoneNumberType = Extract<
  LinkButtonUrlType,
  'whatsapp' | 'phoneNumber'
>

export interface LinkButtonWidget {
  url: string
  type: LinkButtonUrlType
  title: string
  fontSize: string | number
  fontColor: string
  fontStyle: FontStyle
  buttonType: LinkButtonType
  fontFamily: FontFamily
  fontWeight: FontWeight
  titleEmoji: string
  shouldDisplay: boolean
  backgroundColor: string
  backgroundColorOpacity: number
  hasRoundedCorners: boolean
}

export interface GradientColorType {
  color: string
  active: boolean
  id: number
  offset: number
}

export interface ProfileSplashWidget {
  backgroundDesktopImageURL?: string
  singleColor?: string
  gradientColor?: GradientColorType[]
}

export interface SocialMediaButtonGroupWidget {
  // updated using below
  facebook: { url: string; color: string }
  instagram: { url: string; color: string }
  youtube: { url: string; color: string }
  twitter: { url: string; color: string }
  tiktok: { url: string; color: string }
  email: { url: string; color: string }
}
export type SocialMediaButtonType = keyof SocialMediaButtonGroupWidget

export interface LeadFormWidget {
  htmlTag: string
  name: string
}

interface SecondaryButton {
  backgroundColor?: string
  content?: string
}
interface PrimaryButton {
  backgroundColor?: string
  content?: string
}

export interface BobameWidget {
  primaryButton: PrimaryButton
  secondaryButton: SecondaryButton
}

export interface WidgetType {
  avatar: AvatarWidget
  titleLabel: LabelWidget
  subtitleLabel: LabelWidget
  linkButton: LinkButtonWidget
  socialMediaButtonGroup: SocialMediaButtonGroupWidget
  profileSplash: ProfileSplashWidget
  leadForm: LeadFormWidget
  bobame: BobameWidget
}

export interface IWidget<T extends keyof WidgetType> {
  id: string
  type: T
  data: WidgetType[T]
}

export type PageWidgetType =
  | IWidget<'avatar'>
  | IWidget<'titleLabel'>
  | IWidget<'subtitleLabel'>
  | IWidget<'linkButton'>
  | IWidget<'socialMediaButtonGroup'>
  | IWidget<'leadForm'>
  | IWidget<'bobame'>

export interface PageWidget {
  profileSplash: ProfileSplashWidget
  widgetList: PageWidgetType[]
}
