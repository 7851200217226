import * as React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

export const queryClient = new QueryClient()

export interface ReactQueryProviderProps {}

export const ReactQueryProvider = ({
  children,
}: React.PropsWithChildren<ReactQueryProviderProps>) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}
