import { UseQueryResult, useQuery } from 'react-query'
import { getBobaUserAccounts } from '../BobaUserAccountAPI'
import { GetBobaUserAccountPaginatedResponse } from '../schema/boba-response.schema'

export const useGetBobaUserAccount = (): UseQueryResult<
  GetBobaUserAccountPaginatedResponse,
  Error
> => {
  return useQuery<GetBobaUserAccountPaginatedResponse, Error>(
    'boba-user-accounts',
    getBobaUserAccounts,
    {
      refetchOnWindowFocus: false,
      retry: false,
    },
  )
}
