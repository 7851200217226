import { rem } from 'polished'
import * as React from 'react'
import styled from 'styled-components/macro'

export interface SpacerProps {
  space?: number
}

export const SpacerStyled = styled.div<SpacerProps>`
  font-size: ${rem(16)};

  padding-top: ${(props) => `${props.space}em`};
  width: 100%;
`

export const Spacer = ({ space = 1 }: React.PropsWithChildren<SpacerProps>) => {
  return <SpacerStyled space={space} />
}

Spacer.displayName = 'Spacer'
