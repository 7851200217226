export const linkBlockAnimation = {
  initial: {
    scale: 0.5,
    opacity: 0,
  },
  enter: {
    scale: 1,
    opacity: 1,
  },
  exit: {
    scale: 0.5,
    opacity: 0,
  },
  whileTap: {
    opacity: 0.5,
  },
}
