export function replaceLottieColor(
  input: any,
  replaceKey: string,
  replaceValue: number[],
): object {
  input?.layers
    .filter((item) => item.nm.includes(replaceKey))
    .map((item) => {
      return item.shapes.map((item) => {
        return (item.it.find((item) => item.ty === 'fl').c.k = replaceValue)
      })
    })
  return input
}
