/**
 *
 * ProfileSplashConfig
 *
 */

import * as React from 'react'

import styled from 'styled-components/macro'
import { useIntl } from 'react-intl'
import {
  GradientColorDefault,
  ProfileSplashWidgetConfigDefaults,
  PROFILE_SPLASH_WIDGET_ID,
} from '../constants'
import {
  ProfileSplashConfigurationEvent,
  track,
  useImageUrlChecker,
  UserOutlined,
  withWidgetConfig,
} from '@anyonelab/common'
import {
  ProfileSplashWidgetBackgroundColors,
  ProfileSplashWidgetConfigSchema,
  GradientColorType,
} from '../types'
import {
  Form,
  FormProps,
  Space,
  Input,
  Upload,
  Avatar,
  Spin,
  message,
} from 'antd'
import { GradientPicker } from 'react-linear-gradient-picker'
import { SketchPicker } from 'react-color'
import 'react-linear-gradient-picker/dist/index.css'
import messages from './messages'
import { BASE_API_URL } from '../../../../../public-page/src/config/index'
import { apiClient } from '../../../../../frontend/src/utils/api-client'
import { GradientPresetButton } from '../../../../../frontend/src/app/components/GradientPresetButton'
import { gradientPreset } from './gradientPreset'
import { ProfileSplashWidget } from '@anyonelab/frontend/models/Widget'

export interface ProfileSplashConfigProps {
  data: ProfileSplashWidget
}

const gapBetweenGradientPresetButtonRow = 14

export const ProfileSplashConfig = withWidgetConfig<ProfileSplashConfigProps>(
  {
    widgetId: PROFILE_SPLASH_WIDGET_ID,
  },
  ({ data, onChange }) => {
    const intl = useIntl()

    const [form] = Form.useForm<ProfileSplashWidget>()

    const initialValues: ProfileSplashWidget = {
      ...data,
    }

    const { isWaitingThumbnail } = useImageUrlChecker(
      data?.backgroundDesktopImageURL,
    )

    const [isUploading, setIsUploading] = React.useState<boolean>(false)

    const [palette, setPalette] = React.useState(
      initialValues.gradientColor || GradientColorDefault,
    )

    const [showGradientPalette, setShowGradientPalette] = React.useState<
      boolean
    >(false)

    const beforeUpload = () => {
      setIsUploading(true)
    }

    // background image
    const customUploadAction = async (e) => {
      const formData = new FormData()
      formData.append(e.filename, e.file)
      try {
        const response = await apiClient.post(
          `${BASE_API_URL}/image-upload?type=background`,
          formData,
        )
        const result = response.data

        onChange({
          backgroundDesktopImageURL: result.image_name,
        })
        setIsUploading(false)
      } catch (err) {
        setIsUploading(false)
        if (err.response?.data?.message === 'File too large') {
          message.error(
            intl.formatMessage(messages.pleaseMakeSureYourImageIsUnder3Mb),
          )
        } else {
          if (
            err.response?.data?.message ===
            'We only support the following image types: jpeg, jpg, png, gif.'
          ) {
            message.error(
              intl.formatMessage(messages.weOnlySupportImageTypeMessage),
            )
          } else {
            message.error(intl.formatMessage(messages.uploadFail))
          }
        }
      }
    }

    // single color
    const onValuesChange: FormProps<ProfileSplashWidget>['onValuesChange'] = (
      changeValues,
      formValues,
    ) => {
      const updatedValues = {
        ...changeValues,
      }
      onChange(updatedValues)
    }

    // gradient color
    const onPaletteChange = (e: GradientColorType[]) => {
      setPalette(e)
      const updatedValues = {
        gradientColor: e,
      }
      onChange(updatedValues)
    }

    const singleColorPickerOnFocus = (): void => {
      track(ProfileSplashConfigurationEvent.singleColorOnChange)
    }

    const gradientColorPickerOnFocus = (): void => {
      track(ProfileSplashConfigurationEvent.gradientColorOnChange)
    }

    const rgbToRgba = (rgb, a = 1) =>
      rgb.replace('rgb(', 'rgba(').replace(')', `, ${a})`)

    const WrappedSketchPicker = ({ onSelect, ...rest }) => {
      console.log(onSelect)
      return (
        <SketchPicker
          {...rest}
          color={rgbToRgba(rest.color, rest.opacity)}
          onChange={(c) => {
            const { r, g, b, a } = c.rgb
            onSelect(`rgb(${r}, ${g}, ${b})`, a)
          }}
        />
      )
    }

    return (
      <ProfileSplashConfigStyled>
        <Form
          form={form}
          initialValues={initialValues}
          onValuesChange={onValuesChange}
          layout="vertical"
        >
          <Form.Item label={intl.formatMessage(messages.imageSource)}>
            <Upload
              accept="image/png, image/gif, image/jpeg"
              name="uri"
              listType="picture-card"
              showUploadList={false}
              beforeUpload={beforeUpload}
              customRequest={customUploadAction}
            >
              {data.backgroundDesktopImageURL && !isUploading ? (
                <Avatar
                  src={data.backgroundDesktopImageURL}
                  size={100}
                  icon={<UserOutlined />}
                  style={{ backgroundColor: 'white' }}
                />
              ) : (
                <Avatar
                  size={64}
                  icon={isUploading ? <Spin /> : <UserOutlined />}
                  style={{ backgroundColor: 'white' }}
                />
              )}
            </Upload>
          </Form.Item>
          <Form.Item
            label={intl.formatMessage(messages.singleColor)}
            name="singleColor"
          >
            <Input onFocus={singleColorPickerOnFocus} type="color" />
          </Form.Item>
          <Form.Item
            label={intl.formatMessage(messages.gradientColor)}
            name="gradientColor"
          >
            <GradientPaletteShownButtonStyled
              palette={palette}
              onClick={() => {
                gradientColorPickerOnFocus()
                setShowGradientPalette((isShown) => !isShown)
              }}
            >
              <div className="background-color" />
            </GradientPaletteShownButtonStyled>
            {showGradientPalette && (
              <GradientPicker
                {...{
                  paletteHeight: 32,
                  palette,
                  onPaletteChange: onPaletteChange,
                  maxStops: 10, // default
                  minStops: 2, //default
                }}
              >
                <WrappedSketchPicker onSelect={undefined} />
              </GradientPicker>
            )}
          </Form.Item>
          <Form.Item>
            <GradientPresetContainer
              children={gradientPreset.map((palette, index, array) => {
                return (
                  <GradientPresetButton
                    key={`gradient-preset-button ${index}`}
                    totalPresets={array.length}
                    onClick={() => onPaletteChange(palette)}
                    index={index}
                    palette={palette}
                  />
                )
              })}
            />
          </Form.Item>
        </Form>
      </ProfileSplashConfigStyled>
    )
  },
)

const ProfileSplashConfigStyled = styled.div``

const GradientPaletteShownButtonStyled = styled.div<{ palette: any }>`
  box-sizing: border-box;
  width: 100%;
  height: 30px;
  padding: 8px 12px;
  border: solid 1px #d9d9d9;
  cursor: pointer;

  .background-color {
    width: 100%;
    height: 100%;
    padding: 4px 10px;
    background: ${(props) =>
      `linear-gradient(151deg, ${props.palette.reduce(
        (prev: GradientColorType | null, curr: GradientColorType) => {
          if (!prev) {
            return `${curr.color} ${curr.offset * 100}%`
          } else {
            return `${prev} , ${curr.color} ${curr.offset * 100}%`
          }
        },
        null,
      )})`};
    border: solid 1px black;
  }

  &:hover {
    border: solid 1px blue;
  }
`

const ColorChoiceStyled = styled.div<{
  backgroundColor: string
  active: boolean
}>`
  display: inline-block;
  border-radius: 3px;

  border: 2px solid ${({ theme }) => theme.colors.grey95};

  :hover {
    border-color: ${({ active, theme }) =>
      active ? theme.colors.pinkDark : theme.colors.grey90};
  }

  border-color: ${({ active, theme }) =>
    active ? theme.colors.pinkDark : theme.colors.grey95};

  cursor: pointer;

  :before {
    display: block;
    content: '';
    width: 40px;
    height: 40px;

    background: ${({ backgroundColor }) => backgroundColor};

    border-radius: 3px;
    border: 4px solid white;
  }
`

const GradientPresetContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: ${gapBetweenGradientPresetButtonRow}px;
  justify-items: center;
`
