import React from 'react'

export const BobaCharacterDisplayDeadEye = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      width="172"
      height="246"
      viewBox="0 0 172 246"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame 14">
        <g id="Group 4534804">
          <path
            id="Vector"
            d="M161.441 109.468C167.584 92.4559 166.804 73.6968 159.269 57.2585C151.735 40.8201 138.053 28.0276 121.192 21.6549C104.33 15.2822 85.6475 15.8428 69.1947 23.2153C52.7419 30.5878 39.845 44.1781 33.3008 61.039L161.441 109.468Z"
            fill="#E3E7F5"
          />
          <path
            id="Vector_2"
            d="M32.2372 66.2355L0.0764746 209.95C-0.120861 211.039 0.0665035 212.163 0.606178 213.128C1.14585 214.093 2.00397 214.839 3.03242 215.236L82.843 245.464C83.8994 245.922 85.0869 245.972 86.1778 245.605C87.2688 245.238 88.1865 244.479 88.7549 243.474L158.782 114.071C159.091 113.479 159.269 112.826 159.305 112.158C159.341 111.49 159.233 110.822 158.989 110.199C158.745 109.577 158.371 109.014 157.891 108.55C157.411 108.086 156.838 107.731 156.21 107.509L38.415 62.969C37.7984 62.7257 37.137 62.6188 36.4756 62.6555C35.8141 62.6921 35.1682 62.8718 34.5819 63.1818C33.9957 63.4917 33.4829 63.9249 33.0783 64.4519C32.6737 64.9788 32.3867 65.5872 32.2372 66.2355Z"
            fill="#F2F2F2"
          />
          <path
            id="Vector_3"
            d="M129.938 2.22735L129.91 2.21688C126.671 0.992528 123.057 2.63778 121.838 5.89167L76.659 126.504C75.4401 129.758 77.078 133.389 80.3172 134.613L80.3449 134.623C83.5841 135.848 87.1981 134.203 88.4169 130.949L133.596 10.3359C134.815 7.08201 133.177 3.4517 129.938 2.22735Z"
            fill="#CED8ED"
          />
          <path
            id="Vector_4"
            d="M164.193 101.376L38.7285 53.9533C34.7559 52.4517 30.3234 54.4693 28.8286 58.46L28.8182 58.4878C27.3234 62.4784 29.3324 66.9307 33.305 68.4323L158.769 115.855C162.742 117.356 167.174 115.339 168.669 111.348L168.679 111.32C170.174 107.33 168.166 102.877 164.193 101.376Z"
            fill="#CBD3E8"
          />
          <path
            id="Vector_5"
            d="M140.395 147.332L88.8135 243.478C88.2451 244.483 87.3274 245.242 86.2364 245.609C85.1455 245.976 83.9579 245.926 82.9016 245.468L3.09102 215.24C2.06256 214.843 1.20445 214.098 0.664772 213.133C0.125097 212.168 -0.0622677 211.043 0.135068 209.955L23.9894 103.297L140.395 147.332Z"
            fill="#F8E0BE"
          />
          <path
            id="Vector_6"
            d="M116.364 19.2578C120.913 20.3475 125.269 22.13 129.281 24.5433L108.383 80.3369L94.3125 75.0217C94.3125 75.0217 116.866 19.4657 116.364 19.2578Z"
            fill="#E3E7F5"
          />
          <path
            id="Vector_7"
            d="M23.6937 197.74C27.8403 197.74 31.2018 194.364 31.2018 190.198C31.2018 186.033 27.8403 182.656 23.6937 182.656C19.547 182.656 16.1855 186.033 16.1855 190.198C16.1855 194.364 19.547 197.74 23.6937 197.74Z"
            fill="#D79B7A"
          />
          <path
            id="Vector_8"
            d="M23.9905 171.912C28.1371 171.912 31.4986 168.536 31.4986 164.37C31.4986 160.205 28.1371 156.828 23.9905 156.828C19.8439 156.828 16.4824 160.205 16.4824 164.37C16.4824 168.536 19.8439 171.912 23.9905 171.912Z"
            fill="#D79B7A"
          />
          <path
            id="Vector_9"
            d="M36.139 222.779C40.2856 222.779 43.6471 219.403 43.6471 215.237C43.6471 211.072 40.2856 207.695 36.139 207.695C31.9924 207.695 28.6309 211.072 28.6309 215.237C28.6309 219.403 31.9924 222.779 36.139 222.779Z"
            fill="#D79B7A"
          />
          <path
            id="Vector_10"
            d="M92.3597 188.983C96.5063 188.983 99.8678 185.606 99.8678 181.441C99.8678 177.275 96.5063 173.898 92.3597 173.898C88.2131 173.898 84.8516 177.275 84.8516 181.441C84.8516 185.606 88.2131 188.983 92.3597 188.983Z"
            fill="#D79B7A"
          />
          <path
            id="Vector_11"
            d="M93.8987 217.678C98.0454 217.678 101.407 214.301 101.407 210.136C101.407 205.97 98.0454 202.594 93.8987 202.594C89.7521 202.594 86.3906 205.97 86.3906 210.136C86.3906 214.301 89.7521 217.678 93.8987 217.678Z"
            fill="#D79B7A"
          />
          <path
            id="Vector_12"
            d="M64.723 215.506C68.8696 215.506 72.2311 212.129 72.2311 207.964C72.2311 203.799 68.8696 200.422 64.723 200.422C60.5763 200.422 57.2148 203.799 57.2148 207.964C57.2148 212.129 60.5763 215.506 64.723 215.506Z"
            fill="#D79B7A"
          />
          <path
            id="Vector_13"
            d="M79.2073 237.506C83.3539 237.506 86.7154 234.129 86.7154 229.964C86.7154 225.799 83.3539 222.422 79.2073 222.422C75.0607 222.422 71.6992 225.799 71.6992 229.964C71.6992 234.129 75.0607 237.506 79.2073 237.506Z"
            fill="#D79B7A"
          />
          <path
            id="Vector_14"
            d="M55.9144 191.623C60.061 191.623 63.4225 188.247 63.4225 184.081C63.4225 179.916 60.061 176.539 55.9144 176.539C51.7677 176.539 48.4062 179.916 48.4062 184.081C48.4062 188.247 51.7677 191.623 55.9144 191.623Z"
            fill="#D79B7A"
          />
          <path
            id="Vector_15"
            d="M109.16 55.7723C111.131 56.6219 112.766 58.1041 113.808 59.9868C113.865 60.0802 113.902 60.1844 113.917 60.2926C113.931 60.4008 113.924 60.5111 113.895 60.6164C113.866 60.7217 113.816 60.8198 113.748 60.905C113.68 60.9901 113.595 61.0604 113.499 61.1114C113.309 61.2177 113.085 61.2446 112.876 61.1864C112.666 61.1283 112.488 60.9899 112.379 60.8008C112.382 60.5633 109.255 55.2393 104.143 57.4928C104.044 57.5406 103.936 57.5671 103.826 57.5712C103.717 57.5753 103.607 57.5568 103.505 57.5165C103.403 57.4763 103.31 57.4153 103.233 57.3374C103.155 57.2595 103.094 57.1663 103.054 57.0637C103.011 56.9659 102.989 56.8605 102.987 56.7537C102.985 56.6468 103.005 56.5406 103.044 56.4415C103.084 56.3424 103.143 56.2523 103.218 56.1765C103.293 56.1007 103.383 56.0406 103.481 55.9999C104.364 55.5794 105.321 55.3424 106.297 55.3033C107.273 55.2643 108.247 55.4236 109.16 55.7723Z"
            fill="#42210B"
          />
          <path
            id="Vector_16"
            d="M82.0157 55.9022C87.4847 55.9022 91.9181 51.4487 91.9181 45.955C91.9181 40.4613 87.4847 36.0078 82.0157 36.0078C76.5467 36.0078 72.1133 40.4613 72.1133 45.955C72.1133 51.4487 76.5467 55.9022 82.0157 55.9022Z"
            fill="#F2F2F2"
          />
          <path
            id="Vector_17"
            d="M136.877 76.6601C142.346 76.6601 146.779 72.2065 146.779 66.7128C146.779 61.2191 142.346 56.7656 136.877 56.7656C131.408 56.7656 126.975 61.2191 126.975 66.7128C126.975 72.2065 131.408 76.6601 136.877 76.6601Z"
            fill="#F2F2F2"
          />
        </g>
        <g id="Group 4534805">
          <g id="Group 4534806">
            <line
              id="Line 204"
              x1="2"
              y1="-2"
              x2="12.9453"
              y2="-2"
              transform="matrix(0.462771 0.886478 -0.885029 0.465537 77.334 39.7344)"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
            />
            <line
              id="Line 205"
              x1="2"
              y1="-2"
              x2="12.9466"
              y2="-2"
              transform="matrix(-0.897869 0.440262 -0.437451 -0.899242 88.0762 41.0391)"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
            />
          </g>
          <g id="Group 4534807">
            <line
              id="Line 204_2"
              x1="2"
              y1="-2"
              x2="12.9453"
              y2="-2"
              transform="matrix(0.462771 0.886478 -0.885029 0.465537 132.322 60.8203)"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
            />
            <line
              id="Line 205_2"
              x1="2"
              y1="-2"
              x2="12.9466"
              y2="-2"
              transform="matrix(-0.897869 0.440262 -0.437451 -0.899242 143.064 62.125)"
              stroke="black"
              stroke-width="4"
              stroke-linecap="round"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
