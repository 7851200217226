import {
  BobaIcon,
  Button,
  DEFAULT_BOBA_AVATAR_KEY,
  Form,
  FormInstance,
  Input,
} from '@anyonelab/common/'
import clsx from 'clsx'
import React, { HTMLAttributes, useEffect } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import messages from './messages'
import { ReplyMessageMode } from './type'

type ReplyMessageProps = {
  displayMode?: boolean
  mode: ReplyMessageMode
  defaultContent?: string
  profileIcon?: string
  isDefaultBobaAvatar?: boolean
  defaultBobaAvatar?: string
  bobaDonationId: number
  commentIsActive?: boolean
  hasReplied?: boolean
  changeMode?: (mode: ReplyMessageMode) => void
  postOnClick?: (values: any) => void
  removeOnClick?: (values: any) => void
  editOnClick?: (values: any) => void
  containerProps?: HTMLAttributes<HTMLDivElement>
}

export const ReplyMessage = (props: ReplyMessageProps) => {
  const {
    displayMode,
    mode = 'view',
    defaultContent,
    containerProps,
    changeMode,
    postOnClick,
    editOnClick,
    removeOnClick,
    bobaDonationId,
    profileIcon,
    isDefaultBobaAvatar,
    defaultBobaAvatar,
    commentIsActive,
    hasReplied,
  } = props
  const intl = useIntl()
  const [showSubmitButton, setShowSubmitButton] = React.useState(false)
  const [savedContent, setSavedContent] = React.useState(defaultContent)
  const [content, setContent] = React.useState(defaultContent)
  const modeClassName = clsx({
    'create-mode': mode === 'create',
    'edit-mode': mode === 'edit',
    'view-mode': mode === 'view',
    'hide-mode': mode === 'hide',
  })
  const confirmButtonClassName = clsx({
    invisible: showSubmitButton === false,
  })

  return (
    <Container className={`container ${modeClassName}`} {...containerProps}>
      <NextMessageIndicator />
      <ReplyContainer>
        <IconContainer>
          <BobaIcon
            imageUrl={profileIcon ?? ''}
            defaultBobaAvatarKey={defaultBobaAvatar ?? DEFAULT_BOBA_AVATAR_KEY}
            isUsingDefaultBobaAvatar={isDefaultBobaAvatar ?? true}
            size={36}
            style={{ marginRight: '16px' }}
          />
        </IconContainer>
        {!displayMode && (mode === 'create' || mode === 'edit') ? (
          <MessageContentForm
            className={`message-content-form ${modeClassName}`}
            onFinish={(values: any) => {
              switch (mode) {
                case 'create':
                  hasReplied
                    ? editOnClick &&
                      editOnClick({
                        message: values.content,
                      })
                    : postOnClick &&
                      postOnClick({
                        message: values.content,
                        donationId: bobaDonationId,
                      })
                  break
                case 'edit':
                  editOnClick &&
                    editOnClick({
                      message: values.content,
                    })
                  break
                default:
                  console.error('Unexpected outcome')
                  break
              }
              setContent(values.content)
              setSavedContent(values.content)
            }}
            onValuesChange={(fields) => {
              setShowSubmitButton(Boolean(fields.content))
            }}
          >
            <StyledFormItem
              style={{ flex: 1 }}
              label=""
              name="content"
              children={
                <StyledInput
                  autoSize
                  className={`input ${modeClassName}`}
                  defaultValue={commentIsActive ? savedContent : ''}
                />
              }
            />
            <StyledFormItem
              children={
                <ConfirmButton
                  disabled={!showSubmitButton}
                  className={`confirm-button ${modeClassName} ${confirmButtonClassName}`}
                  type="text"
                  htmlType="submit"
                  children={intl.formatMessage(messages.send)}
                />
              }
            />
          </MessageContentForm>
        ) : mode === 'view' ? (
          <MessageContentForm
            as={'div'}
            className={`message-content-form ${modeClassName}`}
            children={
              <StyledInput
                as={'div'}
                className={`input ${modeClassName}`}
                children={content}
              />
            }
          />
        ) : (
          <></>
        )}
        {!displayMode && mode === 'view' ? (
          <FunctionKeyContainer
            className={`function-key-container ${modeClassName}`}
            children={
              <>
                <FunctionKeyButton
                  onClick={() => changeMode && changeMode('edit')}
                  children={intl.formatMessage(messages.edit)}
                />
                <FunctionKeyButton
                  onClick={() => {
                    removeOnClick &&
                      removeOnClick({ content: defaultContent || '' })
                    setContent('')
                  }}
                  children={intl.formatMessage(messages.remove)}
                />
              </>
            }
          />
        ) : (
          <></>
        )}
      </ReplyContainer>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  &.hide-mode {
    display: none;
  }
`

const NextMessageIndicator = styled.span`
  width: 4px;
  height: 48px;
  border-radius: 80px;
  background-color: ${({ theme }) => theme.bobaColor.backgroundSecondary};
  margin: 0 36px 0 20px;
  position: relative;
  &::after {
    content: '';
    border-radius: 80px;
    display: block;
    position: absolute;
    bottom: 0;
    height: 4px;
    width: 20px;
    background-color: ${({ theme }) => theme.bobaColor.backgroundSecondary};
  }
`

const ReplyContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: 32px;
  position: relative;
`

const IconContainer = styled.div`
  display: flex;
`

const MessageContentForm = styled(Form)`
  display: flex;
  border: ${({ theme }) => theme.bobaColor.primary} solid 1px;
  border-radius: 24px;
  padding: 8px 24px;
  flex: 1;
  height: min-content;
  min-height: 44px;
  position: relative;
  &.view-mode {
    background-color: #e8dbcf;
    border-color: transparent;
  }
`

const ConfirmButton = styled(Button)`
  color: #8b8b8b;
  font-size: 9px;
  &.invisible {
    opacity: 0;
  }
`

const StyledInput = styled(Input.TextArea)`
  font-weight: 500;
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  font-size: 12px;
  color: ${({ theme }) => theme.bobaColor.primary};
  border: none;
  background-color: transparent;
  &.view-mode {
    resize: none;
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    cursor: default;
  }
  &.ant-input {
    padding: 0;
    min-height: 26px;
  }
`

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
`

const FunctionKeyContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: -16px;
  left: 80px;
  font-size: 8px;
  color: #8b8b8b;
  > div {
    margin-right: 12px;
  }
`

const FunctionKeyButton = styled.div`
  cursor: pointer;
`
