import React, { HTMLAttributes } from 'react'
import styled, { css } from 'styled-components/macro'

export type CountingRowProps = {
  value?: number
  valuePrefix?: string
  count?: number | null
  currency?: 'hkd' | 'twd' | 'usd'
  subtotalStyle?: React.CSSProperties
  countStyle?: React.CSSProperties
} & HTMLAttributes<HTMLDivElement>

export const CountingRow = (props: CountingRowProps) => {
  return (
    <Container {...props}>
      <ChildrenContainer
        className="children-container"
        children={props.children}
      />
      {props.count != null && props.count >= 0 ? (
        <Count
          className="count"
          style={props.countStyle}
          children={`x${props.count.toLocaleString()}`}
        />
      ) : (
        <></>
      )}
      <Subtotal
        className="subtotal"
        style={props.subtotalStyle}
        children={`${props.valuePrefix || ''}${
          props.value && props.value < 0 ? '-' : ''
        }$${Math.abs(props.value || 0).toLocaleString() || '---'}`}
      />
    </Container>
  )
}

const Container = styled.div<CountingRowProps>`
  display: grid;
  grid-template-columns: ${({ count }) =>
    count != null && count >= 0
      ? '1fr minmax(30px, max-content) minmax(60px, max-content)'
      : '1fr minmax(64px,max-content)'};
  align-items: center;
  min-height: 30px;
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
`

const AccountTextCss = css`
  font-weight: ${({ theme }) => theme.fontWeight[600]};
`

const Count = styled.div`
  ${AccountTextCss}
`

const Subtotal = styled.div`
  ${AccountTextCss}
  text-align: end;
`

const ChildrenContainer = styled.div`
  flex: 1;
`
