import { defineMessages } from 'react-intl'

export default defineMessages({
  customize: {
    defaultMessage: 'Customize',
  },
  socialMediaButtonGroup: {
    defaultMessage: 'Social Media',
  },
  selectAnElementToConfigure: {
    defaultMessage: 'Select an element to configure',
  },
  theme: {
    defaultMessage: 'Theme',
  },
  background: {
    defaultMessage: 'Background',
  },
  avatar: {
    defaultMessage: 'Profile image',
  },
  titleLabel: {
    defaultMessage: 'Title',
  },
  subtitleLabel: {
    defaultMessage: 'Subtitle',
  },
  linkButton: {
    defaultMessage: 'Link',
  },
  leadForm: {
    defaultMessage: 'Html embed',
  },
  bobame: {
    defaultMessage: 'BobaMe Embed',
  },
})
