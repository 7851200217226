import { defineMessages } from 'react-intl'

const scope = 'WidgetCreator'

export default defineMessages({
  buttonTextIsRequired: {
    defaultMessage: 'Button text is required',
  },
  Oops: {
    defaultMessage: `Oops!`,
  },
  thisLinkDoesntTakeYouAnywhere: {
    defaultMessage: `This link doesn't take you anywhere.`,
  },
  copiedPhoneNumber: {
    defaultMessage: `Phone number has been copied!`,
  },
})
