import { CountryCode } from 'libphonenumber-js/min'
import zhHK from '../locale/zh_HK.json'
import enUS from '../locale/en_US.json'

export const getCountryLocalisationLabels = (
  locale: string,
): Partial<
  Record<CountryCode | 'ZZ' | 'ext' | 'country' | 'phone', string>
> => {
  switch (locale) {
    case 'zh-HK':
      return zhHK
    default:
      return enUS
  }
}
