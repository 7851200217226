import { defineMessages } from 'react-intl'

export default defineMessages({
  title: { defaultMessage: 'Received Bobas' },
  received: { defaultMessage: 'Recevied' },
  receivedMessage: { defaultMessage: 'Received Message' },
  message: { defaultMessage: 'Message' },
  display: { defaultMessage: 'Display' },
  repost: { defaultMessage: 'Repost' },
  reply: { defaultMessage: 'Your Reply' },
  cup: {
    defaultMessage:
      '{itemCount, plural, =0 {0 Cups} =1 {# Cup} other {# Cups}}',
  },
  emptyMessagePlaceholder1: {
    defaultMessage: '(As light as the wind, leaving no trace.)',
  },
  supporterMessageIsPrivate: {
    defaultMessage: 'Supporter’s message is private',
  },
})
