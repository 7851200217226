import { SocialMediaButtonGroupWidget } from '@anyonelab/frontend/models/Widget'

export const SOCIAL_MEDIA_LINK_BUTTON_WIDGET_ID = 'SOCIAL_MEDIA_LINK_BUTTON'

export const SocialMediaLinkButtonWidgetConfigDefaults: SocialMediaButtonGroupWidget = {
  facebook: { url: '', color: '#fff' },
  instagram: { url: '', color: '#fff' },
  youtube: { url: '', color: '#fff' },
  twitter: { url: '', color: '#fff' },
  tiktok: { url: '', color: '#fff' },
  email: { url: '', color: '#fff' },
}
