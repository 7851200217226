import React, { HTMLAttributes } from 'react'
import styled from 'styled-components/macro'
import { DesktopOnly, MobileOnly } from '../..'

export const BobaBoardContainer = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <>
      <MobileOnly>
        <MobileContainer data-mobile {...props} />
      </MobileOnly>
      <DesktopOnly>
        <DesktopContainer data-desktop {...props} />
      </DesktopOnly>
    </>
  )
}

const Container = styled.div`
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  border-radius: 24px;
  background: ${({ theme }) => theme.bobaColor.backgroundWhite};
`

const DesktopContainer = styled(Container)`
  padding: 24px 60px;
  width: auto;
  min-width: 800px;
`

const MobileContainer = styled(Container)`
  padding: 24px;
`
