import { MobileSnackbioButton } from '@anyonelab/common'
import React from 'react'
import { MdPalette } from 'react-icons/md'
import styled from 'styled-components'

interface LinkPortalThemeCreatorProps {
  className?: string
  onClick: (e) => void
}

export function LinkPortalThemeCreator({
  className,
  onClick,
}: LinkPortalThemeCreatorProps) {
  return (
    <StyledMobileSnackbioButton className={className} onClick={onClick}>
      <MdPalette size={24} color="#999999" />
    </StyledMobileSnackbioButton>
  )
}

const StyledMobileSnackbioButton = styled(MobileSnackbioButton)``
