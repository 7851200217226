import { GetBobaDonorDonationResponse } from '@anyonelab/frontend/api/boba-api/schema/boba-response.schema'
import moment from 'moment'
import React from 'react'
import { useIntl } from 'react-intl'
import { useTable } from 'react-table'
import styled, { useTheme } from 'styled-components/macro'
import {
  BobaPagination,
  DesktopOnly,
  MobileOnly,
  openInNewTab,
  PaginationType,
  useResponsive,
} from '../..'
import { ReactComponent as TalkingBubble } from '../../assets/talking-bubble-icon.svg'
import { BobaBoardContainer } from '../BobaBoardContainer'
import { BobaBoardTitle } from '../BobaBoardTitle'
import { DONATION_ID_PREFIX } from '../MessageBoard/const'
import { desktopColumnTitles, mobileColumnTitles } from './const'
import { EmptyDonorDonationHistoryMessage } from './EmptyDonorDonationHistoryMessage'
import messages from './messages'

type DonorDonationHistoryBoardProps = {
  pagination: PaginationType
  data?: GetBobaDonorDonationResponse[]
}

type CellType = 'time' | 'creator' | 'event' | 'message' | 'reply'

const TimeCell = ({ value }) => {
  return (
    <div className="time-cell" style={{ color: '#636363' }}>
      {value || '---'}
    </div>
  )
}

const CreatorCell = ({ value }) => {
  return (
    <div className="creator-cell" style={{ color: '#8B8B8B' }}>
      {value || '---'}
    </div>
  )
}

const EventCell = ({ value }) => {
  const intl = useIntl()
  return (
    <div
      className="event-cell"
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      {value.creator ? (
        <div
          style={{ color: '#8B8B8B', fontWeight: 500 }}
          children={value.creator}
        />
      ) : (
        <></>
      )}
      <div
        style={{ color: '#A55A24', fontWeight: 600 }}
        children={`${intl.formatMessage(messages[value.currency])} $${
          value.amount
        } | ${intl.formatMessage(messages.cup, {
          itemCount: value.count,
        })}`}
      />
    </div>
  )
}

const MessageCell = ({ value }) => {
  return (
    <div
      className="message-cell"
      style={{
        color: '#A55A24',
        backgroundColor: '#F2F2F2',
        padding: 8,
        margin: -8,
        borderRadius: 12,
        textAlign: 'justify',
      }}
    >
      {value}
    </div>
  )
}

const ReplyCell = ({ value }) => {
  return (
    <div
      className="reply-cell"
      onClick={() =>
        openInNewTab(
          `https://${value.domain}.bobaboba.me#${DONATION_ID_PREFIX}${value.id}`,
        )
      }
      style={{
        cursor: 'pointer',
        color: value?.isActive ? '#CCA78C' : '#CCCCCC',
      }}
      children={<TalkingBubble />}
    />
  )
}

const getCell = (item: CellType): ((param: any) => JSX.Element) => {
  switch (item) {
    case 'time':
      return TimeCell
    case 'creator':
      return CreatorCell
    case 'event':
      return EventCell
    case 'message':
      return MessageCell
    case 'reply':
      return ReplyCell
  }
}

export const DonorDonationHistoryBoard = (
  props: DonorDonationHistoryBoardProps,
) => {
  const { data } = props
  const intl = useIntl()
  const { responsiveClassName } = useResponsive()
  const theme = useTheme()

  const desktopTableData = React.useMemo(() => {
    if (!data) return
    return data.map((item) => {
      return {
        time: moment(item.donation_date).format('YYYY.M.DD'),
        creator: item.creator_full_name,
        event: {
          amount: item.amount,
          currency: item.currency,
          count: item.boba_count,
        },
        message: item.donor_note,
        reply: {
          domain: item.creator_domain,
          id: item.donation_id,
          isActive: item.isReplied,
        },
      }
    })
  }, [data])

  const mobileTableData = React.useMemo(() => {
    if (!data) return
    return data.map((item) => {
      return {
        time: moment(item.donation_date).format('YYYY.M.DD'),
        event: {
          creator: item.creator_full_name,
          amount: item.amount,
          currency: item.currency,
          count: item.boba_count,
        },
        reply: {
          domain: item.creator_domain,
          id: item.donation_id,
          isActive: item.isReplied,
        },
      }
    })
  }, [data])

  const desktopTableColumns = React.useMemo(() => {
    return desktopColumnTitles.map((title) => {
      return {
        Header: intl.formatMessage(messages[title]),
        accessor: title,
        Cell: getCell(title as CellType),
      }
    })
  }, [])

  const mobileTableColumns = React.useMemo(() => {
    return mobileColumnTitles.map((title) => {
      return {
        Header: intl.formatMessage(messages[title]),
        accessor: title,
        Cell: getCell(title as CellType),
      }
    })
  }, [])

  const {
    getTableProps: getDesktopTableProps,
    getTableBodyProps: getDesktopTableBodyProps,
    headerGroups: desktopHeaderGroups,
    rows: desktopRows,
    prepareRow: desktopPrepareRow,
  } = useTable({ columns: desktopTableColumns, data: desktopTableData })

  const {
    getTableProps: getMobileTableProps,
    getTableBodyProps: getMobileTableBodyProps,
    headerGroups: mobileHeaderGroups,
    rows: mobileRows,
    prepareRow: mobilePrepareRow,
  } = useTable({ columns: mobileTableColumns, data: mobileTableData })

  return (
    <StyledBobaBoardContainer className={`container ${responsiveClassName}`}>
      <BobaBoardTitle
        className="title"
        children={intl.formatMessage(messages.title)}
      />
      {!data?.length ? (
        <EmptyDonorDonationHistoryMessage />
      ) : (
        <>
          <MobileOnly>
            <table
              {...getMobileTableProps()}
              style={{
                width: '100%',
                tableLayout: 'auto',
              }}
            >
              <thead>
                {mobileHeaderGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        style={{
                          color: '#794e2e',
                          fontFamily: theme.bobaFontFamily.primary,
                          fontWeight: 600,
                          fontSize: 12,
                          textAlign: 'center',
                          backgroundColor: theme.bobaColor.backgroundWhite,
                          paddingBottom: '12px',
                          borderBottom: '#ad5138 solid 1px',
                        }}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getMobileTableBodyProps()}>
                {mobileRows.map((row, rowIndex, rowArray) => {
                  mobilePrepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, index, array) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={`${cell.column.id}`}
                            style={{
                              fontSize: 12,
                              padding: '14px 10px',
                              backgroundColor: theme.bobaColor.backgroundWhite,
                              borderBottom:
                                parseInt(rowIndex) !== rowArray.length - 1
                                  ? '#CCA78C solid 0.5px'
                                  : 'none',
                            }}
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </MobileOnly>
          <DesktopOnly>
            <table
              {...getDesktopTableProps()}
              style={{
                width: '100%',
                tableLayout: 'auto',
              }}
            >
              <thead>
                {desktopHeaderGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps()}
                        style={{
                          color: '#794e2e',
                          fontFamily: theme.bobaFontFamily.primary,
                          fontWeight: 600,
                          fontSize: 12,
                          textAlign: 'center',
                          backgroundColor: theme.bobaColor.backgroundWhite,
                          paddingBottom: '12px',
                          borderBottom: '#ad5138 solid 1px',
                        }}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getDesktopTableBodyProps()}>
                {desktopRows.map((row, rowIndex, rowArray) => {
                  desktopPrepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, index, array) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={`${cell.column.id}`}
                            style={{
                              fontSize: 12,
                              padding: '14px 10px',
                              backgroundColor: theme.bobaColor.backgroundWhite,
                              borderBottom:
                                parseInt(rowIndex) !== rowArray.length - 1
                                  ? '#CCA78C solid 0.5px'
                                  : 'none',
                            }}
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </DesktopOnly>
        </>
      )}
      <BobaPagination
        current={props.pagination.current}
        showSizeChanger={false}
        defaultCurrent={props.pagination.defaultCurrent}
        pageSize={props.pagination.pageSize}
        total={props.pagination.total}
        onChange={props.pagination.onChange}
      />
    </StyledBobaBoardContainer>
  )
}

const StyledBobaBoardContainer = styled(BobaBoardContainer)`
  &.mobile {
    .title {
      text-align: center;
      font-size: 16px;
      margin-bottom: 16px;
    }
    td > .event-cell {
      text-align: start;
    }
    td.time {
      min-width: 84px;
    }
  }
  &.desktop,
  &.tablet-big,
  &.tablet-small {
    .title {
      text-align: start;
      font-size: 20px;
      margin-bottom: 20px;
    }
    td {
      vertical-align: top;
    }
    td.time {
      min-width: 100px;
    }
    td.creator {
      min-width: 120px;
    }
    td.event {
      min-width: 160px;
    }
  }
  td > div {
    text-align: center;
  }
  td > .message-cell {
    text-align: start;
  }
`
