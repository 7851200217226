import { Divider } from 'antd'
import React, { HTMLAttributes } from 'react'
import { useIntl } from 'react-intl'
import styled, { useTheme } from 'styled-components/macro'
import { BobaDisplay, BobaDisplayProps } from '../BobaDisplay'
import { bobaBackgroundColor } from '../const'
import { BobaCurrency, BobaDonationDetail } from '../type'
import { CountingRow, CountingRowProps } from './CountingRow'
import messages from './messages'
import { TotalName } from './type'

type CountingTableProps = {
  sumValue?: number
  totalName?: TotalName
  currency: BobaCurrency
  bobaItems?: BobaDonationDetail[]
} & HTMLAttributes<HTMLDivElement>

type BobaRowProps = { currency: BobaCurrency } & BobaDonationDetail &
  HTMLAttributes<HTMLDivElement>

const BobaRow = (props: BobaRowProps) => {
  const { count, value, currency, style, boba_type, name, ...rest } = props
  return (
    <CountingRow
      count={count}
      value={value}
      currency={currency}
      style={{
        fontSize: '14px',
        color: '#929292',
        marginBottom: '8px',
        alignItems: 'start',
        ...style,
      }}
      children={
        <ItemContainer>
          <BobaDisplay
            padding={4}
            style={{ marginRight: '14px', alignSelf: 'start' }}
            series={boba_type.series}
            backgroundColor={boba_type.background_color}
            size={30}
          />
          <ItemTitle children={name} />
        </ItemContainer>
      }
      {...rest}
    />
  )
}

export const CountingTable = (props: CountingTableProps) => {
  const intl = useIntl()
  const theme = useTheme()
  return (
    <Container {...props}>
      <>
        {props.bobaItems?.map((bobaItem) => {
          return (
            <BobaRow
              boba_type={bobaItem.boba_type}
              name={bobaItem.name}
              currency={props.currency}
              value={bobaItem.value * bobaItem.count}
              count={bobaItem.count}
            />
          )
        })}
      </>
      <Divider
        style={{ borderColor: theme.bobaColor.primary, margin: '12px 0' }}
      />
      <CountingRow
        style={{
          color: theme.bobaColor.primary,
          marginBottom: '24px',
        }}
        value={props.sumValue}
        children={
          <Total
            children={`${
              props.totalName && messages[props.totalName]
                ? intl.formatMessage(messages[props.totalName])
                : props.totalName
            } ${intl.formatMessage(messages[props.currency])}`}
          />
        }
      />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Total = styled.div`
  flex: 1;
  font-weight: ${({ theme }) => theme.fontWeight[600]};
`

const ItemTitle = styled.div`
  width: 0px;
  margin-right: 16px;
  flex-grow: 1;
  font-weight: bold;
  color: #4a1e11;
`

const ItemContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`
