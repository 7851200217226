import React, { HTMLAttributes } from 'react'
import styled from 'styled-components/macro'
import { useWidget } from '../..'
import { BobaCharacterDisplay } from '../BobaCharacterDisplay'
import { BobaIcon } from '../BobaIcon'
import { BobaLoader, BobaLoaderSVG } from '../BobaLoader'

type BobaCircleIconProps = {
  backgroundColor?: string
  size?: number
} & HTMLAttributes<HTMLDivElement>

export const BobaCircleIcon = ({
  size,
  backgroundColor,
  ...rest
}: BobaCircleIconProps) => {
  const { displayMode } = useWidget()
  return (
    <Container
      size={size}
      displayMode={displayMode}
      backgroundColor={backgroundColor}
      {...rest}
    >
      <BobaLoaderSVG
        size={size ? size - 8 : displayMode ? 36 : 24}
        liquidHeightPercentage={80}
      />
    </Container>
  )
}

const Container = styled.div<{
  backgroundColor?: string
  size?: number
  displayMode?: boolean
}>`
  border-radius: 50%;
  width: ${({ size, displayMode }) =>
    size ? size + 'px' : displayMode ? '48px' : '36px'};
  height: ${({ size, displayMode }) =>
    size ? size + 'px' : displayMode ? '48px' : '36px'};
  display: grid;
  place-items: center;
  box-shadow: 0px 4px 4px rgba(128, 126, 126, 0.25);
  background-color: ${({ backgroundColor }) => backgroundColor || '#AD5138'};
`
