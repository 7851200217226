import React from 'react'
import {
  DesktopOnly,
  message,
  MobileOnly,
  BobaCharacterDisplay,
  openInNewTab,
  BobaLoaderSVG,
  getBobaMePublicUrl,
} from '@anyonelab/common'
import styled from 'styled-components/macro'
import { PrimaryButton } from '../../PrimaryButton'
import { ReactComponent as CopyIcon } from '../../../assets/boba-tools/copyIcon.svg'
import { ReactComponent as TriangleLogo } from '../../../assets/TriangleLogo.svg'
import { useIntl } from 'react-intl'
import messages from '../messages'

function WelcomeNotification({ domain, avatarImageUrl, buttonOnClick }) {
  const intl = useIntl()
  const bobaPublicPageUrl = getBobaMePublicUrl(domain)

  const copyUrl = () => {
    navigator.clipboard.writeText(bobaPublicPageUrl)
    message.success('copiedLink')
  }

  const ButtonContent = () => {
    return (
      <ButtonContentContainer>
        {intl.formatMessage(messages.bobaBarSubmit)}
        <TriangleLogo className="icon" />
      </ButtonContentContainer>
    )
  }
  return (
    <>
      <DesktopOnly>
        <Title children={intl.formatMessage(messages.bobaBarTitle)} />
        <Subtitle
          style={{ marginBottom: '20px' }}
          children={intl.formatMessage(messages.bobaBarDescription)}
        />
        <BobaLoaderSVG
          image={avatarImageUrl}
          style={{ marginBottom: '24px' }}
          liquidHeightPercentage={70}
          size={150}
          direction="right"
        />
        <LinkTag>
          <StyledUrl
            onClick={() => openInNewTab(bobaPublicPageUrl)}
            children={bobaPublicPageUrl}
          />
          <CopyIcon fill="#AD5138" onClick={copyUrl} />
        </LinkTag>
        <DesktopPrimaryButton
          content={<ButtonContent />}
          onClick={buttonOnClick}
        />
      </DesktopOnly>
      <MobileOnly>
        <MobileTitle children={intl.formatMessage(messages.bobaBarTitle)} />
        <Subtitle children={intl.formatMessage(messages.bobaBarDescription)} />
        <BobaLoaderSVG
          image={avatarImageUrl}
          style={{ marginBottom: '24px' }}
          liquidHeightPercentage={70}
          size={150}
          direction="right"
        />
        <LinkTag>
          <StyledUrl
            onClick={() => openInNewTab(bobaPublicPageUrl)}
            children={bobaPublicPageUrl}
          />
          <CopyIcon fill="#AD5138" onClick={copyUrl} />
        </LinkTag>
        <MobilePrimaryButton
          content={<ButtonContent />}
          onClick={buttonOnClick}
        />
      </MobileOnly>
    </>
  )
}

export default WelcomeNotification

const Title = styled.div`
  font-family: '${({ theme }) => theme.bobaFontFamily.title}';
  font-weight: 700;
  font-size: 28px;
  color: ${({ theme }) => theme.bobaColor.textPrimary};
  text-align: center;
  line-height: 33px;
  margin-bottom: 4px;
`

const Subtitle = styled.div`
  font-family: 'Montserrat';
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize[20]};
  color: #656565;
  display: flex;
  justify-content: center;
  align-items: center;
`

const BobaImageContainer = styled.div`
  width: 150px;
  height: 200px;
  background: #ab7b22;
  margin: 10px;
`

const LinkTag = styled.a`
  font-family: 'Roboto';
  font-size: ${({ theme }) => theme.fontSize[20]};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px 30px 0px;
  a {
    text-decoration: underline;
    color: #929292;
    margin-right: 10px;
  }
`

const StyledPrimaryButton = styled(PrimaryButton)`
  font-family: 'Montserrat';
  font-style: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: center;
  white-space: pre-wrap;
  height: 60px;
  width: 100%;
  font-size: 18px;
  font-size: 18px;
`
const DesktopPrimaryButton = styled(StyledPrimaryButton)`
  min-width: 400px;
`

const MobilePrimaryButton = styled(StyledPrimaryButton)``

const ButtonContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    margin-left: 10px;
  }
`

const MobileTitle = styled(Title)`
  margin-bottom: 24px;
  font-size: 24px;
`

const StyledUrl = styled.div`
  color: #929292;
  cursor: pointer;
  text-decoration: underline;
  margin-right: 12px;
`
