import { Input, InputProps } from 'antd'
import React, { HTMLAttributes } from 'react'
import styled from 'styled-components/macro'

export type PrimaryTextInputProps = {
  borderColor?: string
  inputWidth?: number
} & InputProps

export const PrimaryTextInput = (props: PrimaryTextInputProps) => {
  return <StyledInput {...props} />
}

const StyledInput = styled(Input)<{
  borderColor?: string
  inputWidth?: number
}>`
  letter-spacing: 0.04em;
  height: 64px;
  border-radius: 84px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  background: #fafafa;
  color: #929292;
  font-family: 'Roboto';
  font-size: ${({ theme }) => theme.fontSize[28]};
  font-weight: bold;
  overflow: hidden;
  &.ant-input:focus,
  &.ant-input:hover,
  &.ant-input-focused {
    border-color: ${({ borderColor }) => borderColor + '80'};
    box-shadow: 0 0 0 2px ${({ borderColor }) => borderColor + '80'};
  }
  > .ant-input {
    width: ${({ inputWidth }) => inputWidth}ch;
    font-family: 'Roboto';
    font-weight: lighter;
    font-size: ${({ theme }) => theme.fontSize[28]};
    color: #929292;
    background-color: #fafafa;
  }
`
