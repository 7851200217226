import React from 'react'

export const BobaHomepageRibbonDesktop = (
  props: React.SVGProps<SVGSVGElement>,
) => {
  return (
    <svg
      width="1453"
      height="186"
      viewBox="0 0 1453 186"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Frame 14" clip-path="url(#clip0_1855_285)">
        <path
          id="Vector 80"
          d="M489 54.106C221 100.892 84 58.9361 0 11.3885V-208H1453V0.841019C1367 109.481 1159.39 123.688 983 54.106C890.5 17.6157 795 54.106 807 123.688C813.434 160.995 773.82 187.303 741 185.95C703.554 184.406 665.563 153.78 674 123.688C702.402 22.3914 534.5 46.1628 489 54.106Z"
          fill="#AD5138"
        />
      </g>
      <defs>
        <clipPath id="clip0_1855_285">
          <rect width="1453" height="186" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
