/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react'

import { PublicPage } from './pages/PublicPage/PublicPage'
import { NotFoundPage } from './pages/NotFoundPage/NotFoundPage'
import { GlobalStyle } from '../styles/global-styles'
import { ReactQueryProvider } from './providers/ReactQueryProvider'
import { LanguageProvider } from './providers/LanguageProvider'
import { AnalyticsProvider, ResponsiveProvider } from '@anyonelab/common/'
import { ALThemeProvider as ThemeProvider } from '@anyonelab/common'
import { translationMessages } from '../i18n'
import { Page } from '@anyonelab/public-page/models/Page'
import { BobaPublicPage } from './pages/BobaPublicPage'
import { GetBobaPublicUserAccountResponse } from '../api/boba-api/schema/boba-response.schema'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { getCookie } from '../utils/cookies'
import { getUsersList } from '../api/UsersAPI'
import LoginPopUpProvider from './providers/LoginPopUpProvider/LoginPopUpProvider'

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '',
)

interface AppProps {
  data: Page | GetBobaPublicUserAccountResponse
  username: string
  isAnyonelab: boolean
}

export function App({ data, username, isAnyonelab }: AppProps) {
  return (
    <Elements stripe={stripePromise}>
      <LanguageProvider messages={translationMessages}>
        <ResponsiveProvider>
          <ReactQueryProvider>
            <LoginPopUpProvider>
              <AnalyticsProvider>
                <ThemeProvider>
                  {data ? (
                    isAnyonelab ? (
                      <PublicPage username={username} data={data as Page} />
                    ) : (
                      <BobaPublicPage
                        data={data as GetBobaPublicUserAccountResponse}
                      />
                    )
                  ) : (
                    <NotFoundPage username={username} />
                  )}
                  <GlobalStyle />
                </ThemeProvider>
              </AnalyticsProvider>
            </LoginPopUpProvider>
          </ReactQueryProvider>
        </ResponsiveProvider>
      </LanguageProvider>
    </Elements>
  )
}
