import React from 'react'
import { ReactComponent as BobaDefaultAvator0 } from '../../assets/boba-default-avatar/boba-avatar-0.svg'
import { ReactComponent as BobaDefaultAvator1 } from '../../assets/boba-default-avatar/boba-avatar-1.svg'
import { ReactComponent as BobaDefaultAvator2 } from '../../assets/boba-default-avatar/boba-avatar-2.svg'
import { ReactComponent as BobaDefaultAvator3 } from '../../assets/boba-default-avatar/boba-avatar-3.svg'
import { ReactComponent as BobaDefaultAvator4 } from '../../assets/boba-default-avatar/boba-avatar-4.svg'
import { ReactComponent as BobaDefaultAvator5 } from '../../assets/boba-default-avatar/boba-avatar-5.svg'
import { ReactComponent as BobaDefaultAvator6 } from '../../assets/boba-default-avatar/boba-avatar-6.svg'
import { ReactComponent as BobaDefaultAvator7 } from '../../assets/boba-default-avatar/boba-avatar-7.svg'
import { ReactComponent as BobaDefaultAvator8 } from '../../assets/boba-default-avatar/boba-avatar-8.svg'
import { ReactComponent as BobaDefaultAvator9 } from '../../assets/boba-default-avatar/boba-avatar-9.svg'

type BobaDefaultIconsProps = {
  defaultBobaAvatarKey: string
  containerProps?: React.SVGProps<SVGSVGElement>
}
export const BobaDefaultIconComponent = (props: BobaDefaultIconsProps) => {
  const { defaultBobaAvatarKey, containerProps } = props
  switch (true) {
    case defaultBobaAvatarKey === 'boba-avatar-1':
      return <BobaDefaultAvator1 {...containerProps} />
    case defaultBobaAvatarKey === 'boba-avatar-2':
      return <BobaDefaultAvator2 {...containerProps} />
    case defaultBobaAvatarKey === 'boba-avatar-3':
      return <BobaDefaultAvator3 {...containerProps} />
    case defaultBobaAvatarKey === 'boba-avatar-4':
      return <BobaDefaultAvator4 {...containerProps} />
    case defaultBobaAvatarKey === 'boba-avatar-5':
      return <BobaDefaultAvator5 {...containerProps} />
    case defaultBobaAvatarKey === 'boba-avatar-6':
      return <BobaDefaultAvator6 {...containerProps} />
    case defaultBobaAvatarKey === 'boba-avatar-7':
      return <BobaDefaultAvator7 {...containerProps} />
    case defaultBobaAvatarKey === 'boba-avatar-8':
      return <BobaDefaultAvator8 {...containerProps} />
    case defaultBobaAvatarKey === 'boba-avatar-9':
      return <BobaDefaultAvator9 {...containerProps} />
    case defaultBobaAvatarKey === 'boba-avatar-0':
    default:
      return <BobaDefaultAvator0 {...containerProps} />
  }
}
