import { defineMessages } from 'react-intl'

export default defineMessages({
  title: { defaultMessage: 'Boba Receipt' },
  total: { defaultMessage: 'Total' },
  emptyMessage: {
    defaultMessage: '(As light as the wind, leaving no trace.)',
  },
  emptyName: { defaultMessage: 'noname' },
})
