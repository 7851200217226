import React from 'react'
import { useIntl } from 'react-intl'
import { BobaBoardContainer } from '../BobaBoardContainer'
import styled from 'styled-components/macro'
import messages from './messages'
import {
  BobaSwitch,
  DesktopOnly,
  MobileOnly,
  PrimaryButton,
  PrimaryTextAreaOutlined,
  PrimaryTextAreaProps,
  PrimaryTextInputOutlined,
  PrimaryTextInputProps,
  ProfilePicture,
  BobaPriceNameDisplay,
  Form,
  useResponsive,
  ProfilePictureProps,
  BobaLoader,
  BAR_FULL_NAME_MAX_LENGTH,
  BAR_FULL_NAME_MIN_LENGTH,
  BAR_ABOUT_MAX_LENGTH,
  BAR_ABOUT_MIN_LENGTH,
  BAR_THANK_YOU_MESSAGE_MAX_LENGTH,
  BAR_THANK_YOU_MESSAGE_MIN_LENGTH,
  AnalyticsContext,
  track,
  BobaMeSettingPageEvent,
  BobaBoardTitleMobile,
  BobaBoardTitleDesktop,
} from '../..'
import { GetBobaUserAccountResponse } from '../schema/boba-response.schema'
import { Divider, Radio } from 'antd'
import { ThankYouImageRadioGroup } from './components/ThankYouImageRadioGroup'
import { IconRadioGroup } from './components/IconRadioGroup'

export type SettingBoardProps = {
  data: GetBobaUserAccountResponse | undefined
  uploadOnClick: (url: string) => void
  updateOnClick: (data: any) => void
  currentImageUrl: string
}

export const SettingBoard = (props: SettingBoardProps) => {
  const inputFile = React.useRef<HTMLInputElement | null>(null)
  const [isWaitingImageUpload, setIsWaitingImageUpload] = React.useState(false)
  const intl = useIntl()
  const { isMobileLayout } = useResponsive()

  const { amplitudeInit } = React.useContext(AnalyticsContext)

  const onFinish = (data) => {
    const newData = {
      ...data,
    }
    props.updateOnClick(newData)
    const bobaMenuArrayForTracking = newData.boba_menu.map((item) => {
      return { name: item.name, value: item.value }
    })
    amplitudeInit &&
      track(BobaMeSettingPageEvent.updateSettingClick, {
        boba_menu: bobaMenuArrayForTracking,
      })
  }
  const validateMessages = {
    required: intl.formatMessage(messages.sthIsRequired, { sth: '${label}' }),
    string: {
      range: intl.formatMessage(messages.sthMustWithin, {
        label: '${label}',
        min: '${min}',
        max: '${max}',
      }),
    },
  }

  return (
    <StyledBobaBoardContainer isMobile={isMobileLayout}>
      <MobileOnly>
        <MobileContainer>
          <BobaBoardTitleMobile
            className="title"
            children={intl.formatMessage(messages.title)}
          />
          {!props.data ? (
            <BobaLoader />
          ) : (
            <>
              <StyledForm
                validateMessages={validateMessages}
                onFinish={onFinish}
              >
                <Subtitle
                  style={{ marginBottom: '20px' }}
                  className="subtitle-icon-image"
                  children={intl.formatMessage(messages.avatar)}
                />
                <Form.Item
                  initialValue={props.data.is_using_default_boba_avatar}
                  name="is_using_default_boba_avatar"
                  label={intl.formatMessage(messages.avatar)}
                >
                  <Radio.Group
                    defaultValue={props.data.is_using_default_boba_avatar}
                  >
                    <IconRadioGroup
                      updateOnClick={props.updateOnClick}
                      imageUrl={props.data.avatar_image_url}
                      defaultIconKey={props.data.default_boba_avatar_key}
                    />
                  </Radio.Group>
                </Form.Item>
                {props.data.is_boba_user_activated && (
                  <>
                    <Subtitle
                      style={{ marginBottom: '12px' }}
                      className="subtitle-bar-name"
                      children={intl.formatMessage(messages.barName)}
                    />
                    <Form.Item
                      initialValue={props.data.full_name}
                      rules={[
                        {
                          max: BAR_FULL_NAME_MAX_LENGTH,
                          min: BAR_FULL_NAME_MIN_LENGTH,
                          required: true,
                        },
                      ]}
                      name="full_name"
                      label={intl.formatMessage(messages.barName)}
                    >
                      <StyledPrimaryTextInputOutlined
                        style={{ marginBottom: '16px' }}
                        className="input-bar-name-placeholder"
                        defaultValue={props.data.full_name}
                      />
                    </Form.Item>
                  </>
                )}
                {props.data.is_boba_user_activated && (
                  <>
                    <Subtitle
                      style={{ marginBottom: '12px' }}
                      className="subtitle-about"
                      children={intl.formatMessage(messages.about)}
                    />
                    <Form.Item
                      initialValue={props.data.about_me}
                      name="about_me"
                      rules={[
                        {
                          max: BAR_ABOUT_MAX_LENGTH,
                          min: BAR_ABOUT_MIN_LENGTH,
                        },
                      ]}
                      label={intl.formatMessage(messages.about)}
                    >
                      <StyledPrimaryTextAreaOutlined
                        style={{ marginBottom: '16px' }}
                        className="input-about-placeholder"
                        defaultValue={props.data.about_me}
                      />
                    </Form.Item>
                  </>
                )}
                <Subtitle
                  style={{ marginBottom: '12px' }}
                  className="subtitle-display-name"
                  children={intl.formatMessage(messages.yourName)}
                />
                <Form.Item
                  initialValue={props.data.display_name}
                  name="display_name"
                  rules={[
                    {
                      max: BAR_FULL_NAME_MAX_LENGTH,
                      min: BAR_FULL_NAME_MIN_LENGTH,
                    },
                  ]}
                  label={intl.formatMessage(messages.yourName)}
                >
                  <StyledPrimaryTextAreaOutlined
                    style={{ marginBottom: '16px' }}
                    className="input-display-name-placeholder"
                    defaultValue={props.data.display_name}
                  />
                </Form.Item>
                <Subtitle
                  style={{ marginBottom: '12px' }}
                  className="subtitle-email"
                  children={intl.formatMessage(messages.email)}
                />
                <Form.Item
                  initialValue={props.data.email}
                  name="email"
                  rules={[{ required: true }]}
                  label={intl.formatMessage(messages.email)}
                >
                  <StyledPrimaryTextAreaOutlined
                    disabled={true}
                    style={{ marginBottom: '16px' }}
                    className="input-email-placeholder"
                    defaultValue={props.data.email}
                  />
                </Form.Item>
                <Subtitle
                  style={{ marginBottom: '20px' }}
                  className="subtitle-boba-price-and-name"
                  children={intl.formatMessage(messages.bobaPriceAndName)}
                />
                <Form.List name="boba_menu">
                  {(fields) =>
                    props.data.boba_menu.map((bobaItem, index) => (
                      <BobaPriceNameDisplay
                        bobaDisplayProps={{
                          style: { width: '64px', height: '64px' },
                          padding: 8,
                        }}
                        currency={props.data.currency}
                        boba={bobaItem}
                        index={index}
                      />
                    ))
                  }
                </Form.List>
                {props.data.is_boba_user_activated && (
                  <>
                    <Subtitle
                      style={{ marginBottom: '12px' }}
                      className="subtitle-display-snack-bio-link"
                      children={intl.formatMessage(
                        messages.displaySnackBioLinkButton,
                      )}
                    />
                    <Form.Item
                      initialValue={props.data.is_snack_bio_display}
                      name="is_snack_bio_display"
                      label={intl.formatMessage(
                        messages.displaySnackBioLinkButton,
                      )}
                    >
                      <BobaSwitch
                        defaultChecked={props.data.is_snack_bio_display}
                        style={{
                          width: 'auto',
                          alignSelf: 'start',
                          marginBottom: '24px',
                        }}
                        className="switch-snack-bio-link"
                      />
                    </Form.Item>
                    <Subtitle
                      style={{ marginBottom: '12px' }}
                      className="subtitle-thank-you-message"
                      children={intl.formatMessage(messages.thankYouMessage)}
                    />
                    <Form.Item
                      initialValue={props.data.thank_you_message}
                      name="thank_you_message"
                      rules={[{ max: 100, min: 0 }]}
                      label={intl.formatMessage(messages.thankYouMessage)}
                    >
                      <StyledPrimaryTextAreaOutlined
                        style={{ marginBottom: '16px' }}
                        className="input-thank-you-message"
                        defaultValue={props.data.thank_you_message}
                      />
                    </Form.Item>
                    <Subtitle
                      style={{ marginBottom: '20px' }}
                      className="subtitle-thank-you-image"
                      children={intl.formatMessage(messages.thankYouImage)}
                    />
                    <Form.Item
                      initialValue={props.data.is_using_default_thank_you_image}
                      name="is_using_default_thank_you_image"
                      label={intl.formatMessage(messages.thankYouImage)}
                    >
                      <Radio.Group
                        defaultValue={
                          props.data.is_using_default_thank_you_image
                        }
                      >
                        <ThankYouImageRadioGroup
                          updateOnClick={props.updateOnClick}
                          imageUrl={props.data.thank_you_image_url}
                        />
                      </Radio.Group>
                    </Form.Item>
                  </>
                )}
                <PrimaryButton
                  style={{ fontSize: '16px', marginTop: 24 }}
                  padding="8px"
                  htmlType="submit"
                  className="button-save"
                  content={intl.formatMessage(messages.save)}
                />
              </StyledForm>
            </>
          )}
        </MobileContainer>
      </MobileOnly>
      <DesktopOnly>
        <DesktopContainer>
          <BobaBoardTitleDesktop
            className="title"
            children={intl.formatMessage(messages.title)}
          />
          {!props.data ? (
            <BobaLoader />
          ) : (
            <>
              <StyledForm
                validateMessages={validateMessages}
                onFinish={onFinish}
              >
                <Subtitle
                  style={{ marginBottom: '20px' }}
                  className="subtitle-icon-image"
                  children={intl.formatMessage(messages.avatar)}
                />
                <Form.Item
                  initialValue={props.data.is_using_default_boba_avatar}
                  name="is_using_default_boba_avatar"
                  label={intl.formatMessage(messages.avatar)}
                >
                  <Radio.Group
                    style={{ whiteSpace: 'nowrap' }}
                    defaultValue={props.data.is_using_default_boba_avatar}
                  >
                    <IconRadioGroup
                      updateOnClick={props.updateOnClick}
                      imageUrl={props.data.avatar_image_url}
                      defaultIconKey={props.data.default_boba_avatar_key}
                    />
                  </Radio.Group>
                </Form.Item>
                {props.data.is_boba_user_activated && (
                  <>
                    <Subtitle
                      style={{ marginBottom: '12px' }}
                      className="subtitle-bar-name"
                      children={intl.formatMessage(messages.barName)}
                    />
                    <Form.Item
                      style={{ marginBottom: '16px' }}
                      initialValue={props.data.full_name}
                      rules={[
                        {
                          max: BAR_FULL_NAME_MAX_LENGTH,
                          min: BAR_FULL_NAME_MIN_LENGTH,
                          required: true,
                        },
                      ]}
                      name="full_name"
                      label={intl.formatMessage(messages.barName)}
                    >
                      <StyledPrimaryTextInputOutlined
                        className="input-full-name"
                        defaultValue={props.data.full_name}
                      />
                    </Form.Item>
                  </>
                )}
                {props.data.is_boba_user_activated && (
                  <>
                    <Subtitle
                      style={{ marginBottom: '12px' }}
                      className="subtitle-about"
                      children={intl.formatMessage(messages.about)}
                    />
                    <Form.Item
                      initialValue={props.data.about_me}
                      name="about_me"
                      style={{ marginBottom: '24px' }}
                      rules={[
                        {
                          max: BAR_ABOUT_MAX_LENGTH,
                          min: BAR_ABOUT_MIN_LENGTH,
                        },
                      ]}
                      label={intl.formatMessage(messages.about)}
                    >
                      <StyledPrimaryTextAreaOutlined
                        className="input-about-me"
                        defaultValue={props.data.about_me}
                      />
                    </Form.Item>
                  </>
                )}
                <Subtitle
                  style={{ marginBottom: '12px' }}
                  className="subtitle-display-name"
                  children={intl.formatMessage(messages.yourName)}
                />
                <Form.Item
                  initialValue={props.data.display_name}
                  name="display_name"
                  rules={[
                    {
                      max: BAR_FULL_NAME_MAX_LENGTH,
                      min: BAR_FULL_NAME_MIN_LENGTH,
                    },
                  ]}
                  label={intl.formatMessage(messages.yourName)}
                >
                  <StyledPrimaryTextAreaOutlined
                    style={{ marginBottom: '16px' }}
                    className="input-display-name-placeholder"
                    defaultValue={props.data.display_name}
                  />
                </Form.Item>
                <Subtitle
                  style={{ marginBottom: '12px' }}
                  className="subtitle-email"
                  children={intl.formatMessage(messages.email)}
                />
                <Form.Item
                  initialValue={props.data.email}
                  name="email"
                  rules={[{ required: true }]}
                  label={intl.formatMessage(messages.email)}
                >
                  <StyledPrimaryTextAreaOutlined
                    disabled={true}
                    style={{ marginBottom: '16px' }}
                    className="input-email-placeholder"
                    defaultValue={props.data.email}
                  />
                </Form.Item>
                <Subtitle
                  style={{ marginBottom: '32px' }}
                  className="subtitle-boba-price-and-name"
                  children={intl.formatMessage(messages.bobaPriceAndName)}
                />
                <Form.List name="boba_menu">
                  {(fields) =>
                    props.data.boba_menu.map((bobaItem, index) => (
                      <BobaPriceNameDisplay
                        bobaDisplayProps={{
                          style: { width: '64px', height: '64px' },
                          padding: 6,
                        }}
                        currency={props.data.currency}
                        boba={bobaItem}
                        index={index}
                      />
                    ))
                  }
                </Form.List>
                {props.data.is_boba_user_activated && (
                  <>
                    <Divider style={{ borderColor: '#B4B4B4' }} />
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginBottom: '32px',
                      }}
                    >
                      <Subtitle
                        style={{ marginRight: '12px' }}
                        className="subtitle-display-snack-bio-link"
                        children={intl.formatMessage(
                          messages.displaySnackBioLinkButton,
                        )}
                      />
                      <Form.Item
                        initialValue={props.data.is_snack_bio_display}
                        name="is_snack_bio_display"
                        label={intl.formatMessage(
                          messages.displaySnackBioLinkButton,
                        )}
                      >
                        <BobaSwitch
                          defaultChecked={props.data.is_snack_bio_display}
                          className="switch-snack-bio-link"
                        />
                      </Form.Item>
                    </div>
                    <Subtitle
                      style={{ marginBottom: '12px' }}
                      className="subtitle-thank-you-message"
                      children={intl.formatMessage(messages.thankYouMessage)}
                    />
                    <Form.Item
                      initialValue={props.data.thank_you_message}
                      name="thank_you_message"
                      style={{ marginBottom: '24px' }}
                      rules={[
                        {
                          max: BAR_THANK_YOU_MESSAGE_MAX_LENGTH,
                          min: BAR_THANK_YOU_MESSAGE_MIN_LENGTH,
                        },
                      ]}
                      label={intl.formatMessage(messages.thankYouMessage)}
                    >
                      <StyledPrimaryTextAreaOutlined
                        className="input-about-thank-you-message"
                        defaultValue={props.data.thank_you_message}
                      />
                    </Form.Item>
                    <Subtitle
                      style={{ marginBottom: '20px' }}
                      className="subtitle-thank-you-image"
                      children={intl.formatMessage(messages.thankYouImage)}
                    />
                    <Form.Item
                      initialValue={props.data.is_using_default_thank_you_image}
                      name="is_using_default_thank_you_image"
                      label={intl.formatMessage(messages.thankYouImage)}
                    >
                      <Radio.Group
                        defaultValue={
                          props.data.is_using_default_thank_you_image
                        }
                      >
                        <ThankYouImageRadioGroup
                          updateOnClick={props.updateOnClick}
                          imageUrl={props.data.thank_you_image_url}
                        />
                      </Radio.Group>
                    </Form.Item>
                    <Divider style={{ borderColor: '#B4B4B4' }} />
                  </>
                )}
                <Form.Item>
                  <PrimaryButton
                    htmlType="submit"
                    style={{ fontSize: '18px' }}
                    padding={'8px 0px'}
                    className="button-save"
                    content={intl.formatMessage(messages.save)}
                  />
                </Form.Item>
              </StyledForm>
            </>
          )}
        </DesktopContainer>
      </DesktopOnly>
    </StyledBobaBoardContainer>
  )
}

const StyledBobaBoardContainer = styled(BobaBoardContainer)<{
  isMobile: boolean
}>`
  .ant-col.ant-form-item-label {
    display: none;
  }
`

const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const DesktopContainer = styled.div`
  max-width: 480px;
  .input-full-name,
  .input-about-me,
  .input-about-thank-you-message {
    font-size: 14px;
  }
`

const Subtitle = styled.div`
  color: #794d2e;
  font-size: 16px;
  font-weight: 600;
`

const Description = styled.div`
  color: #9f9f9f;
  font-family: ${({ theme }) => theme.bobaFontFamily.secondary};
  font-size: 12px;
`

const StyledPrimaryTextInputOutlined = styled(
  (props: PrimaryTextInputProps) => (
    <PrimaryTextInputOutlined borderColor="#ab5337;" {...props} />
  ),
)`
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  height: unset;
  padding: 8px 16px;
`

const StyledPrimaryTextAreaOutlined = styled((props: PrimaryTextAreaProps) => (
  <PrimaryTextAreaOutlined autoSize borderColor="#ab5337;" {...props} />
))`
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  resize: none;
  padding: 8px 16px;
`

const StyledForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 0px;
  }
`

const StyledProfilePicture = styled((props: ProfilePictureProps) => (
  <ProfilePicture {...props} />
))`
  box-shadow: unset;
  flex-grow: 0;
  flex-shrink: 0;
`
