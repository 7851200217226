import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'

interface MobileSnackbioButtonProps {
  className?: string
  onClick: (e) => void
}

export function MobileSnackbioButton({
  className,
  onClick,
  children,
}: PropsWithChildren<MobileSnackbioButtonProps>) {
  return (
    <ButtonStyled className={className} onClick={onClick}>
      {children}
    </ButtonStyled>
  )
}

const ButtonStyled = styled.div`
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px;
  width: 56px;
  height: 56px;
  background-color: white;
  border-radius: ${({ theme }) => theme.borderRadius[16]};
`
