import React from 'react'

type RightArrowProps = {
  stroke?: string
  strokeWidth?: string
} & React.SVGProps<SVGSVGElement>

export const RightArrow = (props: RightArrowProps) => {
  return (
    <svg
      width="15"
      height="25"
      viewBox="0 0 15 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 23L13 12.5L2 2"
        stroke={props.stroke || '#929292'}
        stroke-width={props.strokeWidth || '3'}
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
