import { BobaBarWithCharacter, useWidget, withWidget } from '@anyonelab/common/'
import { BobameWidget } from '@anyonelab/frontend/models/Widget'
import React, { HTMLAttributes } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import { BobameWidgetConfig } from '../config'
import { BOBAME_WIDGET_ID } from '../const'
import { primaryColorToSecondaryColorConvertor } from '../utils'

export type BobameWidgetCreatorProps = {
  data: BobameWidget
  onClick: () => void
} & HTMLAttributes<HTMLDivElement>

export const BobameWidgetCreator = withWidget<BobameWidgetCreatorProps>(
  {
    widgetId: BOBAME_WIDGET_ID,
    configComponent: BobameWidgetConfig,
  },
  ({ data, instanceId, onClick, ...rest }) => {
    const intl = useIntl()

    const { selectWidget, displayMode } = useWidget()
    return (
      <BobameWidgetCreatorStyled onClick={selectWidget} {...rest}>
        <BobaBarWithCharacter
          onClick={onClick}
          animation={displayMode ? 'entry' : 'none'}
          fullName={data.secondaryButton.content}
          primaryColor={data.secondaryButton.backgroundColor}
        />
      </BobameWidgetCreatorStyled>
    )
  },
)

const BobameWidgetCreatorStyled = styled.div``
