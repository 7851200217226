import { apiClient } from '../apiClient'
import { GetBobaUserAccountPaginatedResponse } from './schema/boba-response.schema'

export const getBobaUserAccounts = (): Promise<
  GetBobaUserAccountPaginatedResponse
> => {
  try {
    return apiClient
      .get<GetBobaUserAccountPaginatedResponse>('/boba-user-accounts')
      .then((res) => res.data)
  } catch (err) {
    throw err
  }
}
