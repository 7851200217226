import React from 'react'

export const Matcha = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="53"
      height="82"
      viewBox="0 0 53 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Group 4534642" filter="url(#filter0_d_1038_1800)">
        <g id="Group 4534640">
          <path
            id="Vector"
            d="M5.77682 28.2689L11.8886 71.9515C11.951 72.2813 12.1281 72.5783 12.3887 72.7902C12.6493 73.0021 12.9765 73.1151 13.3125 73.1093H39.0196C39.3548 73.1172 39.6816 73.0047 39.9407 72.7922C40.1997 72.5797 40.3737 72.2814 40.431 71.9515L46.5175 28.2689C46.543 28.0691 46.525 27.8661 46.4646 27.674C46.4042 27.4818 46.3029 27.3049 46.1676 27.1555C46.0324 27.006 45.8663 26.8876 45.6809 26.8083C45.4956 26.7289 45.2952 26.6905 45.0936 26.6958H7.1882C6.98762 26.6924 6.78865 26.7321 6.60479 26.8123C6.42094 26.8924 6.25649 27.0111 6.12262 27.1603C5.98875 27.3096 5.8886 27.4858 5.82893 27.677C5.76926 27.8683 5.75149 28.0702 5.77682 28.2689Z"
            fill="#F2F2F2"
          />
          <path
            id="Vector_2"
            d="M28.447 1.88775C28.447 0.845175 27.6007 0 26.5567 0C25.5128 0 24.6665 0.845175 24.6665 1.88775V39.6679C24.6665 40.7105 25.5128 41.5557 26.5567 41.5557C27.6007 41.5557 28.447 40.7105 28.447 39.6679V1.88775Z"
            fill="#CED8ED"
          />
          <path
            id="Vector_3"
            d="M46.7695 26.6945C46.7662 21.235 44.5931 16 40.7275 12.1395C36.862 8.27906 31.6201 6.1088 26.1534 6.10547C23.4461 6.10547 20.7653 6.63802 18.264 7.67272C15.7627 8.70742 13.49 10.224 11.5757 12.1358C9.66128 14.0477 8.14271 16.3175 7.10666 18.8154C6.0706 21.3134 5.53735 23.9907 5.53735 26.6945H46.7695Z"
            fill="#E3E7F5"
          />
          <path
            id="Vector_4"
            d="M46.6813 24.1484H6.31868C5.03811 24.1484 4 25.1852 4 26.4641C4 27.743 5.03811 28.7797 6.31868 28.7797H46.6813C47.9619 28.7797 49 27.743 49 26.4641C49 25.1852 47.9619 24.1484 46.6813 24.1484Z"
            fill="#CBD3E8"
          />
          <path
            id="Vector_5"
            d="M44.8415 39.5438L40.4436 71.9502C40.3863 72.2801 40.2123 72.5784 39.9533 72.791C39.6942 73.0035 39.3674 73.116 39.0322 73.108H13.3126C12.9766 73.1139 12.6493 73.0008 12.3888 72.789C12.1282 72.5771 11.951 72.28 11.8886 71.9502L7.35205 39.5312L44.8415 39.5438Z"
            fill="#A5B39D"
          />
          <path
            id="Vector_6"
            d="M14.1694 18.6406C14.1694 17.7527 14.5226 16.9013 15.1512 16.2735C15.7798 15.6457 16.6324 15.293 17.5214 15.293C18.4104 15.293 19.263 15.6457 19.8917 16.2735C20.5203 16.9013 20.8734 17.7527 20.8734 18.6406"
            fill="#42210B"
          />
          <path
            id="Vector_7"
            d="M17.5969 17.0759L18.1261 17.0004C18.2269 17.0004 18.3025 17.1388 18.2773 17.3024C18.2867 17.5126 18.2432 17.7219 18.1508 17.9109C18.0583 18.1 17.9198 18.2629 17.7481 18.3848C17.3574 18.5484 16.9668 18.0953 16.8786 17.3779C16.7903 16.6606 17.055 15.9307 17.4582 15.7671C17.8615 15.6035 17.9497 15.7671 18.1009 16.1069C18.1352 16.173 18.153 16.2464 18.153 16.3208C18.153 16.3953 18.1352 16.4686 18.1009 16.5348L17.5969 17.0759Z"
            fill="#F2F2F2"
          />
          <path
            id="Vector_8"
            d="M32.4165 18.6406C32.4165 17.7549 32.7679 16.9053 33.3938 16.2779C34.0197 15.6505 34.8691 15.2963 35.7559 15.293C36.6449 15.293 37.4975 15.6457 38.1261 16.2735C38.7548 16.9013 39.1079 17.7527 39.1079 18.6406"
            fill="#42210B"
          />
          <path
            id="Vector_9"
            d="M35.8314 17.0759L36.3732 17.0004C36.4614 17.0004 36.5371 17.1388 36.5119 17.3024C36.5213 17.5126 36.4778 17.7219 36.3853 17.9109C36.2928 18.1 36.1544 18.2629 35.9826 18.3848C35.5919 18.5484 35.2013 18.0953 35.1257 17.3779C35.0501 16.6606 35.3021 15.9307 35.6928 15.7671C36.0834 15.6035 36.1842 15.7671 36.3354 16.1069C36.3757 16.171 36.397 16.2451 36.397 16.3208C36.397 16.3965 36.3757 16.4707 36.3354 16.5348L35.8314 17.0759Z"
            fill="#F2F2F2"
          />
          <path
            id="Vector_10"
            d="M26.7584 18.5257C26.1142 18.5077 25.4939 18.2776 24.9941 17.8713C24.948 17.8242 24.9221 17.7609 24.9221 17.695C24.9221 17.6292 24.948 17.5659 24.9941 17.5189C25.0164 17.4964 25.0429 17.4786 25.0721 17.4664C25.1013 17.4542 25.1326 17.448 25.1643 17.448C25.1959 17.448 25.2272 17.4542 25.2564 17.4664C25.2856 17.4786 25.3121 17.4964 25.3344 17.5189C25.7017 17.847 26.1773 18.0284 26.6702 18.0284C27.163 18.0284 27.6386 17.847 28.0059 17.5189C28.0283 17.4945 28.0556 17.4749 28.086 17.4616C28.1163 17.4483 28.1492 17.4414 28.1823 17.4414C28.2155 17.4414 28.2483 17.4483 28.2787 17.4616C28.3091 17.4749 28.3363 17.4945 28.3588 17.5189C28.3829 17.5401 28.4022 17.5663 28.4155 17.5956C28.4287 17.6249 28.4355 17.6566 28.4355 17.6887C28.4355 17.7209 28.4287 17.7527 28.4155 17.782C28.4022 17.8112 28.3829 17.8374 28.3588 17.8587C28.1528 18.0745 27.9042 18.2452 27.6287 18.36C27.3532 18.4749 27.0568 18.5313 26.7584 18.5257Z"
            fill="#42210B"
          />
          <path
            id="Vector_11"
            d="M26.2417 68.929C27.4944 68.929 28.5099 67.9148 28.5099 66.6637C28.5099 65.4126 27.4944 64.3984 26.2417 64.3984C24.9889 64.3984 23.9734 65.4126 23.9734 66.6637C23.9734 67.9148 24.9889 68.929 26.2417 68.929Z"
            fill="#42210B"
          />
          <path
            id="Vector_12"
            d="M40.0654 59.2142C41.3181 59.2142 42.3337 58.2 42.3337 56.9489C42.3337 55.6978 41.3181 54.6836 40.0654 54.6836C38.8127 54.6836 37.7971 55.6978 37.7971 56.9489C37.7971 58.2 38.8127 59.2142 40.0654 59.2142Z"
            fill="#42210B"
          />
          <path
            id="Vector_13"
            d="M38.0744 65.5658C39.3272 65.5658 40.3427 64.5516 40.3427 63.3005C40.3427 62.0494 39.3272 61.0352 38.0744 61.0352C36.8217 61.0352 35.8062 62.0494 35.8062 63.3005C35.8062 64.5516 36.8217 65.5658 38.0744 65.5658Z"
            fill="#42210B"
          />
          <path
            id="Vector_14"
            d="M31.988 63.1361C33.2407 63.1361 34.2563 62.1219 34.2563 60.8708C34.2563 59.6197 33.2407 58.6055 31.988 58.6055C30.7353 58.6055 29.7197 59.6197 29.7197 60.8708C29.7197 62.1219 30.7353 63.1361 31.988 63.1361Z"
            fill="#42210B"
          />
          <path
            id="Vector_15"
            d="M33.185 70.8275C34.4378 70.8275 35.4533 69.8133 35.4533 68.5622C35.4533 67.3111 34.4378 66.2969 33.185 66.2969C31.9323 66.2969 30.9167 67.3111 30.9167 68.5622C30.9167 69.8133 31.9323 70.8275 33.185 70.8275Z"
            fill="#42210B"
          />
          <path
            id="Vector_16"
            d="M15.2658 64.6361C16.5186 64.6361 17.5341 63.6219 17.5341 62.3708C17.5341 61.1197 16.5186 60.1055 15.2658 60.1055C14.0131 60.1055 12.9976 61.1197 12.9976 62.3708C12.9976 63.6219 14.0131 64.6361 15.2658 64.6361Z"
            fill="#42210B"
          />
          <path
            id="Vector_17"
            d="M19.2732 72.0345C20.5259 72.0345 21.5414 71.0203 21.5414 69.7692C21.5414 68.5181 20.5259 67.5039 19.2732 67.5039C18.0204 67.5039 17.0049 68.5181 17.0049 69.7692C17.0049 71.0203 18.0204 72.0345 19.2732 72.0345Z"
            fill="#42210B"
          />
          <path
            id="Vector_18"
            d="M8.54901 47.1434C9.23309 46.9553 9.95772 46.9746 10.6308 47.1986C11.304 47.4227 11.8952 47.8415 12.3295 48.4019C12.6283 49.3905 12.8267 50.4067 12.9217 51.4349C13.0595 51.9997 13.3824 52.5022 13.8391 52.8625C14.2958 53.2228 14.86 53.4202 15.442 53.4234C15.8429 53.3833 16.2313 53.2609 16.5826 53.0638C16.9339 52.8667 17.2406 52.5992 17.4835 52.2781C18.2066 51.4383 18.6005 50.3653 18.5924 49.2578C18.5924 48.1754 18.2144 46.8666 19.2855 46.1744C19.8264 45.9098 20.4374 45.8234 21.0306 45.9274C21.6238 46.0314 22.1688 46.3206 22.5871 46.7533C23.2216 47.5566 23.5677 48.5493 23.57 49.5724L24.1371 54.9588C24.1133 55.5662 24.2455 56.1695 24.5212 56.7114C24.797 57.2533 25.207 57.7158 25.7123 58.0547C25.9206 58.1733 26.1506 58.2489 26.3887 58.277C26.6268 58.3051 26.8682 58.2851 27.0984 58.2183C27.3559 58.114 27.5848 57.9501 27.7663 57.74C28.458 56.8861 28.9429 55.8842 29.1833 54.8124C29.4238 53.7406 29.4132 52.6278 29.1525 51.5608C28.9421 50.8729 28.9822 50.1328 29.2659 49.4717C29.5108 49.1399 29.8666 48.9066 30.2688 48.8143C30.6711 48.7219 31.0932 48.7766 31.4585 48.9683C32.4289 49.6982 31.1687 51.2965 32.2525 51.9258C32.6657 52.0844 33.1246 52.0758 33.5317 51.9019C33.9388 51.728 34.2619 51.4025 34.4325 50.9945C34.7223 50.2645 34.5963 49.4465 35.0248 48.7543C35.4532 48.0621 36.9024 47.735 37.3309 48.4145C37.4947 48.6662 37.4569 48.9935 37.6837 49.2074C38.1878 49.673 39.1581 49.0941 39.3345 48.5026C39.5109 47.9112 39.3345 47.1812 39.5739 46.5394C39.7082 46.1784 39.9725 45.8804 40.315 45.7035C40.6575 45.5267 41.0538 45.4837 41.4263 45.5829C41.7414 45.6962 42.006 45.9605 42.3588 45.9479C42.7117 45.9353 43.7576 45.6458 43.871 45.3312C44.4759 43.7455 44.4003 41.455 44.791 39.5547H7.32666L8.54901 47.1434Z"
            fill="white"
          />
          <g id="Group" opacity="0.28">
            <path
              id="Vector_19"
              opacity="0.28"
              d="M46.58 26.6077C46.5816 24.2544 46.1185 21.9239 45.2172 19.7496C44.3158 17.5752 42.9939 15.5998 41.3271 13.9364C39.6603 12.2729 37.6813 10.9541 35.5035 10.0554C33.3257 9.15679 30.9918 8.69593 28.6354 8.69924C23.8829 8.69924 19.3251 10.5847 15.9645 13.9408C12.604 17.297 10.7161 21.8488 10.7161 26.5951L46.58 26.6077Z"
              fill="#E3E7F5"
            />
            <path
              id="Vector_20"
              opacity="0.28"
              d="M10.9302 27.9811L16.2354 65.9375C16.2917 66.2284 16.449 66.4901 16.6795 66.6765C16.91 66.863 17.199 66.9623 17.4955 66.9569H39.8506C40.1472 66.9623 40.4362 66.863 40.6667 66.6765C40.8972 66.4901 41.0545 66.2284 41.1108 65.9375L46.3908 27.9685C46.4048 27.795 46.3825 27.6205 46.3254 27.456C46.2684 27.2915 46.1778 27.1406 46.0593 27.0128C45.9409 26.8851 45.7972 26.7833 45.6374 26.7138C45.4775 26.6444 45.305 26.6088 45.1307 26.6094H12.1777C12.0033 26.6104 11.831 26.6476 11.6717 26.7186C11.5124 26.7895 11.3696 26.8928 11.2524 27.0217C11.1351 27.1507 11.0459 27.3025 10.9904 27.4676C10.935 27.6328 10.9145 27.8077 10.9302 27.9811Z"
              fill="#F2F2F2"
            />
            <path
              id="Vector_21"
              opacity="0.28"
              d="M46.479 24.4062H11.3964C10.2828 24.4062 9.38013 25.3078 9.38013 26.4198C9.38013 27.5319 10.2828 28.4334 11.3964 28.4334H46.479C47.5925 28.4334 48.4952 27.5319 48.4952 26.4198C48.4952 25.3078 47.5925 24.4062 46.479 24.4062Z"
              fill="#CBD3E8"
            />
          </g>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_1038_1800"
          x="0"
          y="0"
          width="53"
          height="81.1094"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1038_1800"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1038_1800"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
