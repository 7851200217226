import { Form, Select, SelectProps } from 'antd'
import React, { HTMLAttributes } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components/macro'
import { BobaDisplay, BobaDisplayProps } from '../BobaDisplay'
import messages from './messages'
import { PrimaryTextInputProps } from '../PrimaryTextInput'
import { PrimaryTextInputOutlined } from '../PrimaryTextInputOutlined'
import { BobaCurrency, BobaMenu } from '../type'
import {
  BOBA_NAME_MAX_LENGTH,
  BOBA_NAME_MIN_LENGTH,
  getBobaPriceOptions,
  useResponsive,
} from '../..'

export type BobaPriceNameDisplayProps = {
  className?: string
  index: number
  currency: BobaCurrency
  boba: BobaMenu
  bobaDisplayProps?: BobaDisplayProps
  isDisplayMode?: boolean
} & HTMLAttributes<HTMLDivElement>

export const BobaPriceNameDisplay = (props: BobaPriceNameDisplayProps) => {
  const intl = useIntl()
  const { isMobileLayout } = useResponsive()

  return (
    <ItemContainer
      style={{ marginBottom: '40px', position: 'relative' }}
      className={`item-container ${isMobileLayout ? 'mobile' : 'desktop'}`}
      {...props}
    >
      {props.isDisplayMode && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1,
          }}
        />
      )}
      <BobaDisplay
        series={props.boba.boba_type.series}
        backgroundColor={props.boba.boba_type.background_color}
        className="boba-display"
        {...props.bobaDisplayProps}
      />
      <ItemDetailsContainer
        style={{ flex: 1 }}
        className={`item-details-container ${
          isMobileLayout ? 'mobile' : 'desktop'
        }`}
      >
        <Subtitle
          className="subtitle-name"
          style={{ fontWeight: 500 }}
          children={intl.formatMessage(messages.name)}
        />
        <StyledFormItem
          style={{ width: '100%' }}
          label={intl.formatMessage(messages.name)}
          initialValue={props.boba.name}
          name={[props.index, 'name']}
          rules={[
            {
              max: BOBA_NAME_MAX_LENGTH,
              min: BOBA_NAME_MIN_LENGTH,
              required: true,
            },
          ]}
        >
          <StyledPrimaryTextInputOutlined
            defaultValue={props.boba.name}
            className="input-name"
          />
        </StyledFormItem>
        <Subtitle
          className="subtitle-price"
          style={{ fontWeight: 500 }}
          children={intl.formatMessage(messages.price)}
        />
        <StyledFormItem
          style={{ width: '100%' }}
          label={intl.formatMessage(messages.price)}
          initialValue={props.boba.value}
          name={[props.index, 'value']}
        >
          <StyledSelect
            currency={props.currency}
            defaultValue={props.boba.value}
            className="select-price"
          >
            {getBobaPriceOptions(props.currency).map((price) => (
              <Select.Option value={price} children={price} />
            ))}
          </StyledSelect>
        </StyledFormItem>
      </ItemDetailsContainer>
      {/* Currently the following Form Item is not used but must exists so we can return the original boba_type back and submit the form. */}
      <Form.Item
        initialValue={props.boba.boba_type}
        name={[props.index, 'boba_type']}
      />
    </ItemContainer>
  )
}

const Subtitle = styled.div`
  color: #794d2e;
  font-size: 16px;
  font-weight: 600;
`

const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  &.mobile {
    > div:first-child {
      margin-right: 12px;
    }
  }
  &.desktop {
    > div:first-child {
      margin-right: 20px;
    }
  }
`

const ItemDetailsContainer = styled.div`
  display: grid;
  gap: 8px;
  place-items: center start;
  grid-template-rows: auto auto;
  &.mobile {
    grid-template-columns: max-content 1fr;
  }
  &.desktop {
    grid-template-columns: max-content 1fr;
  }
`

const StyledPrimaryTextInputOutlined = styled(
  (props: PrimaryTextInputProps) => (
    <PrimaryTextInputOutlined borderColor="#ab5337;" {...props} />
  ),
)`
  font-family: ${({ theme }) => theme.bobaFontFamily.primary};
  font-size: 14px;
  padding: 12px 16px;
  height: 40px;
  text-align: center;
`

const StyledFormItem = styled(Form.Item)`
  margin: 0px !important;
`

const StyledSelect = styled(Select)<
  { currency: BobaCurrency } & SelectProps<any>
>`
  width: 100%;
  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #ab5337;
    border-radius: 12px;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 32px;
  }
  .ant-select-selection-item {
    border-color: #ab5337;
    color: #929292;
    text-align: center;
    ::before {
      content: '${({ currency }) => currency.toUpperCase()} ';
    }
  }
  .ant-select-selection {
  }
`
